export const formFieldsTypeMap = {
  ResultRedirect: ['redirectUrl'],
  CompletionMessageCTA: [
    'redirectUrl',
    'ctaText',
    'successTitle',
    'successText',
    'failureTitle',
    'failureText',
    'includeIcon',
  ],
  CompletionMessage: ['successTitle', 'successText', 'failureTitle', 'failureText', 'includeIcon'],
}
