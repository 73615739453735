import { always, compose, ifElse, isNil, mergeRight } from 'ramda'

import featureFlags from '../../config/feature-flags.json'
import { FeatureFlagsState } from './featureFlags.types'
import { HTTPPayload } from '../redux-http'
import { ActionWithPayload } from '../redux/types'
import { createReducer, createAction } from '@reduxjs/toolkit'

export const INITIAL_STATE = compose(
  mergeRight(featureFlags),
  ifElse(isNil, always({}), (x: string) => JSON.parse(x)),
)(process.env.REACT_APP_FEATURE_FLAGS) as FeatureFlagsState

export const HTTP_GET_FEATURE_FLAGS = '[payment methods] HTTP get'
export const HTTP_GET_FEATURE_FLAGS_SUCCESS = '[payment methods] HTTP get success'
export const HTTP_GET_FEATURE_FLAGS_FAILURE = '[payment methods] HTTP get failure'
export const httpGetFeatureFlags = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_FEATURE_FLAGS)({
    endpoint: 'feature-flags',
  })
export const httpGetFeatureFlagsSuccess = createAction<any>(HTTP_GET_FEATURE_FLAGS_SUCCESS)
export const httpGetFeatureFlagsFailure = createAction<string>(HTTP_GET_FEATURE_FLAGS_FAILURE)

const mergeFatureFlags = (state: FeatureFlagsState, { payload }: ActionWithPayload<FeatureFlagsState>) => {
  return {
    ...state,
    ...payload,
  }
}

const reducer = createReducer<FeatureFlagsState>(INITIAL_STATE, {
  [HTTP_GET_FEATURE_FLAGS_SUCCESS]: mergeFatureFlags,
})

export default reducer
