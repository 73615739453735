import { assoc, pipe, map, pathOr, pick, prop, values, compose, reduce, mergeDeepRight } from 'ramda'
import { createSelector } from 'reselect'

import { JourneyIntegration } from './journeyIntegration.types'
import { JourneyType } from '../JourneyTypes'
import { userSelector } from '../../../modules/User'

export const journeyIntegrationsSelector = createSelector(
  [prop(['journeyIntegrations']), prop('journeyTypes')],
  (journeyIntegrations: JourneyIntegration[], journeyTypes: JourneyType[]) => {
    const getStepsForJourneyIntegration = (journeyTypeId: number) =>
      compose(map(pick(['id', 'name'])), pathOr([], [journeyTypeId, 'journeyTypeSteps']))(journeyTypes)
    return {
      journeyIntegrations: map((journeyIntegration: JourneyIntegration) =>
        pipe(
          pick(['id', 'name', 'description', 'isActive', 'logoUrl']),
          assoc('steps', getStepsForJourneyIntegration(prop('journeyTypeId', journeyIntegration))),
        )(journeyIntegration),
      )(values(journeyIntegrations)),
    }
  },
)

export const journeyIntegrationsAndUserSelector = createSelector(
  [journeyIntegrationsSelector, userSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)
