import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import { pluck, length } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'

import { createJourneyIntegrationSteps } from '../../createJourneyIntegration.constants'
import styles from './Steps.module.css'

interface Props {
  createJourneyIntegrationCompletedSteps: number
  includeDataProtection: boolean
}

const Steps: FC<Props & WithTranslation> = ({
  t,
  createJourneyIntegrationCompletedSteps: completedSteps,
  includeDataProtection,
}) => {
  let steps = pluck('label')(createJourneyIntegrationSteps)
  if (!includeDataProtection) {
    steps = steps.slice(0, -1)
  }
  const totalSteps = length(steps)
  const currentStep = completedSteps + 1 > totalSteps ? totalSteps : completedSteps + 1
  return (
    <>
      <h2 className={`${styles.header} m-m-l-8 m-m-t-6 m-m-b-4`}>
        {t('[journey integration create steps] label', {
          currentStep: currentStep.toString(),
          totalSteps: totalSteps.toString(),
        })}
      </h2>
      <GBG.Progress
        className="m-m-l-8"
        steps={steps.map((s: string, i: number) => {
          return {
            title: t(s),
            // TODO: prettier is not formatting this, yet is complaining and I can't figure out what it wants
            /* eslint-disable */
            state:
              i === completedSteps
                ? GBG.ProgressStepState.Active
                : i < completedSteps
                ? GBG.ProgressStepState.Complete
                : GBG.ProgressStepState.Pending,
            /* eslint-enable */
          }
        })}
        orientation={GBG.ProgressOrientation.Veritical}
      ></GBG.Progress>
    </>
  )
}

export default Steps
