export enum Routes {
  OVERVIEW = '/',
  LOGIN = '/login',
  SIGNUP = '/signup',
  CHANGE_PASSWORD = '/change-password',
  SIGNUP_CONFIRMATION = '/signup-confirmation',
  ONBOARD_DATA_PROTECTION = '/onboard/dataprotection',
  ONBOARD = '/onboard',
  PROFILE = '/profile',
  ACCOUNT = '/account',
  PAYMENT = '/payments',
  AUTO_RENEW = '/auto-renew',
  UPDATE_PAYMENT = '/payments/update',
  PAYMENT_CHECKOUT = '/payments/checkout',
  CREATE_JOURNEY_INTEGRATION = '/redirects/create',
  // TODO: rename these to eg. UPDATE_JOURNEY_INTEGRATION etc
  UPDATE = '/redirects/:id/update/',
  UPDATE_DETAILS = '/redirects/:id/update/details',
  UPDATE_COMPLETION_ACTION = '/redirects/:id/update/completion-action',
  UPDATE_HISTORY = '/redirects/:id/update/history',
  UPDATE_HISTORY_DOWNLOAD = '/redirects/:id/update/history/download/:linkid',
  UPDATE_PERSONALISATION = '/redirects/:id/update/personalisation',
  SETTINGS = '/settings',
  SETTINGS_USERS = '/settings/users',
  SETTINGS_ORGANISATION = '/settings/organisation',
  SETTINGS_DATA_PROTECTION = '/settings/dataprotection',
}
