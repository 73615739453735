import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose, find, propEq, objOf } from 'ramda'

import DeletePaymentMethodModal from './DeletePaymentMethodModal.component'
import { paymentMethodsSelector, confirmDeletePaymentMethod } from '../../../common/modules/PaymentMethods'
import { payloadSelector } from '../../Modal/modal.selectors'

const mapStateToProps = createSelector([paymentMethodsSelector, payloadSelector], ({ paymentMethods }, { payload }) =>
  compose(objOf('paymentMethod'), find(propEq('stripePaymentMethodId', payload)))(paymentMethods),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: (id: string) => {
    dispatch(confirmDeletePaymentMethod(id))
  },
})

export default compose(
  withTranslation('Payment'),
  connect(mapStateToProps, mapDispatchToProps),
)(DeletePaymentMethodModal)
