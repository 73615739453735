import { createAction, createReducer } from '@reduxjs/toolkit'
import { assocPath } from 'ramda'

import { SetFormDataPayload, FormState, SetFormSubmissionStatusPayload, FormData } from './form.types'
import { ActionWithPayload } from '../redux/types'
import { FormSubmissionStatus } from './form.constants'

//---------------------------------
// actions
//---------------------------------

export const SET_FORM_DATA = '[form] set data'
export const setFormData = (id: string, data: FormData): ActionWithPayload<SetFormDataPayload> =>
  createAction<SetFormDataPayload>(SET_FORM_DATA)({ id, data })

export const ASSOC_FORM_DATA = '[form] associate data'
export const assocFormData = (id: string, key: string, value: unknown) =>
  createAction<SetFormDataPayload>(ASSOC_FORM_DATA)({ id, data: { key, value } })

export const SET_FORM_SUBMISSION_STATUS = '[form] set submission status'
export const setFormSubmissionStatus = (
  id: string,
  status: FormSubmissionStatus,
): ActionWithPayload<SetFormSubmissionStatusPayload> =>
  createAction<SetFormSubmissionStatusPayload>(SET_FORM_SUBMISSION_STATUS)({ id, status })

//---------------------------------
// reducers
//---------------------------------

const overwriteData = (state: FormState, { payload }: ActionWithPayload<SetFormDataPayload>) => {
  const { id, data } = payload
  return assocPath([id, 'data'], data)(state)
}
const assocData = (state: FormState, { payload }: ActionWithPayload<SetFormDataPayload>) => {
  const { id, data } = payload
  const { key, value } = data
  return assocPath([id, 'data', key], value)(state)
}

const assocFormSubmissionStatus = (
  state: FormState,
  { payload }: ActionWithPayload<SetFormSubmissionStatusPayload>,
) => {
  const { id, status } = payload
  return assocPath([id, 'submissionStatus'], status)(state)
}

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {} as FormState

const reducer = createReducer<FormState>(INITIAL_STATE, {
  [SET_FORM_DATA]: overwriteData,
  [ASSOC_FORM_DATA]: assocData,
  [SET_FORM_SUBMISSION_STATUS]: assocFormSubmissionStatus,
})

export default reducer
