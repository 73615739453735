import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { ModalFooter } from '../../Modal'

export interface Props {
  onCancel?: () => void
  onSubmit?: () => void
}

const noop = () => null

const JourneyIntegrationLinkFailure: FC<Props & WithTranslation> = ({ t, onCancel = noop, onSubmit = noop }) => (
  <>
    <GBG.ModalHeader>
      <GBG.AlertBar icon={GBG.IconKeys.AlertCircle24} type={GBG.AlertBarType.Warn}>
        <GBG.AlertBarText
          title={t('[create journey integration link insufficient balance] title')}
          text={t('[create journey integration link insufficient balance] description')}
        />
      </GBG.AlertBar>
    </GBG.ModalHeader>
    <GBG.ModalBody>
      <p>
        {t('[create journey integration link insufficient balance] body')}{' '}
        <a aria-label="contact-link">{t('[create journey integration link insufficient balance] body contact link')}</a>
      </p>

      <ModalFooter
        cancelLabel={t('[create journey integration link insufficient balance] cancel')}
        submitLabel={t('[create journey integration link insufficient balance] submit')}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </GBG.ModalBody>
  </>
)

export default JourneyIntegrationLinkFailure
