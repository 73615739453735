import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import { prop } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'

import { ModalView } from './modal.types'
import { JourneyIntegrationLink } from '../../common/modules/JourneyIntegrationLink'
import { DeactivateJourneyIntegrationModal } from '../../common/modules/DeactivateJourneyIntegrationModal'
import { PaymentMethodFormModal } from '../Payment/PaymentMethodFormModal'
import { DeletePaymentMethodModal } from '../Payment/DeletePaymentMethodModal'
import { CancelSubscriptionModal } from '../Payment/CancelSubscriptionModal'
import { CancelAutoRenewModal } from '../Payment/CancelAutoRenewModal'
import styles from './Modal.module.css'
import DPONameHelper from '../../common/modules/DataProtectionForm/helpers/DPOName'
import PurposeOfProcessing from '../../common/modules/DataProtectionForm/helpers/PurposeOfProcessing'
import { ConfirmExportDownloadModal } from '../JourneyIntegration/UpdateJourneyIntegration/History/ConfirmExportDownloadModal'
import { ConfirmExportDeleteModal } from '../JourneyIntegration/UpdateJourneyIntegration/History/ConfirmExportDeleteModal'
import { ExportCreatedModal } from '../JourneyIntegration/UpdateJourneyIntegration/History/ExportCreatedModal'

export interface Props {
  modalView: ModalView
  payload: any
  onReset: () => void
}

const Modal: FC<Props & WithTranslation> = ({ modalView, onReset, payload }) => {
  const VIEWS = {
    [ModalView.NONE]: null,
    [ModalView.DATA_PROTECTION_FORM_HELPER_DPO]: <DPONameHelper onClose={onReset} />,
    [ModalView.DATA_PROTECTION_FORM_HELPER_POP]: <PurposeOfProcessing onClose={onReset} />,
    [ModalView.JOURNEY_INTEGRATION_LINK]: <JourneyIntegrationLink onSubmit={onReset} onCancel={onReset} />,
    [ModalView.JOURNEY_INTEGRATION_DEACTIVATE]: (
      <DeactivateJourneyIntegrationModal onSubmit={onReset} onCancel={onReset} />
    ),
    [ModalView.CREATE_PAYMENT_METHOD]: <PaymentMethodFormModal onSubmit={onReset} onCancel={onReset} />,
    [ModalView.DELETE_PAYMENT_METHOD]: <DeletePaymentMethodModal onSubmit={onReset} onCancel={onReset} />,
    [ModalView.CANCEL_SUBSCRIPTION]: <CancelSubscriptionModal onSubmit={onReset} onCancel={onReset} />,
    [ModalView.CANCEL_AUTO_RENEW]: <CancelAutoRenewModal onSubmit={onReset} onCancel={onReset} />,
    [ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_DELETE]: (
      <ConfirmExportDeleteModal onSubmit={onReset} onCancel={onReset} payload={payload} />
    ),
    [ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_DOWNLOAD]: (
      <ConfirmExportDownloadModal onSubmit={onReset} onCancel={onReset} payload={payload} />
    ),
    [ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_CREATION]: <ExportCreatedModal onSubmit={onReset} />,
  }
  return (
    <>
      <GBG.Modal className={styles.modal} isVisible={modalView !== ModalView.NONE}>
        {prop(modalView)(VIEWS)}
      </GBG.Modal>
    </>
  )
}

export default Modal
