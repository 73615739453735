import { createAction, createReducer } from '@reduxjs/toolkit'

import { Credentials, HubspotState } from './hubspot.types'
import { ActionWithPayload } from '../../common/redux/types'
import { HTTPPayload } from '../../common/redux-http'

//---------------------------------
// actions
//---------------------------------

export const HUBSPOT_UPDATE = '[hubspot] HTTP create hubspot'
export const HUBSPOT_UPDATE_SUCCESS = '[hubspot] HTTP create hubspot success'
export const HUBSPOT_UPDATE_FAILURE = '[hubspot] HTTP create hubspot failure'
export const hubspotUpdate = (data: Credentials): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HUBSPOT_UPDATE)({
    endpoint: 'hubspot',
    data,
  })
export const hubspotUpdateSuccess = createAction(HUBSPOT_UPDATE_SUCCESS)
export const hubspotUpdateFailure = createAction<string>(HUBSPOT_UPDATE_FAILURE)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE: HubspotState = {
  hubspotUpdated: false,
}

//---------------------------------
// reducers
//---------------------------------

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {})

export default reducer
