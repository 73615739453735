import { isNil, pathOr } from 'ramda'
import React, { FC, useEffect, useState, ChangeEvent } from 'react'
import isBase64 from 'is-base64'

import { convertHTMLFileToBase64, formatAcceptedFileTypeArrayToString } from './ImageUpload.utils'
import styles from './imageUpload.module.css'
import { AcceptedFileType } from './ImageUpload.types'

interface Props {
  onSetImage: (image: string) => void
  label: string
  explainer: string
  acceptedFileTypes: AcceptedFileType[]
  defaultImage: string
}

const ImageUpload: FC<Props> = ({ label, explainer, onSetImage, acceptedFileTypes, defaultImage }) => {
  const [image, setImage] = useState(defaultImage)

  useEffect(() => {
    if (!isNil(defaultImage)) {
      setImage(defaultImage)
    }
  }, [defaultImage])

  useEffect(() => {
    isBase64(image, { allowMime: true, allowEmpty: false }) && onSetImage(image)
  }, [image])

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = pathOr(null, ['target', 'files', 0])(event)

    if (file) {
      // TODO: ensure this is correctly logged to Sentry
      convertHTMLFileToBase64(file).then(setImage).catch(console.error)
    }
  }

  const accept = formatAcceptedFileTypeArrayToString(acceptedFileTypes)

  return (
    <div className="image-upload">
      <label htmlFor="file-upload">
        <strong>{label}</strong>
      </label>
      <p className="explainer">{explainer}</p>
      <div className={styles.uploaderWrapper}>
        <div
          style={{
            backgroundImage: `url(${image}`,
          }}
          className={styles.preview}
        />
        <input
          type="file"
          onChange={onFileChange}
          accept={accept}
          aria-label="file-upload"
          className={styles.fileInput}
        />
      </div>
    </div>
  )
}

export default ImageUpload
