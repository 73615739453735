import React, { FC } from 'react'
import { assoc, prop, path, has } from 'ramda'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { ValidationResult } from '../../../common/modules/AJVFormValidation/AJVFormValidation.types'
import { NameFormData } from './NameForm.types'

interface Props {
  formData: NameFormData
  onChange: (formData: NameFormData) => void
  validate: (formData: NameFormData) => boolean
  validationErrors: ValidationResult | null
}

const NameForm: FC<Props & WithTranslation> = ({ t, formData, onChange, validate, validationErrors }) => {
  const onChangeInternal = (key: string) => (event: any) => {
    onChange(assoc(key, path(['target', 'value'])(event))(formData))
  }
  const onBlurInternal = () => {
    validate(formData)
  }
  return (
    <div>
      <GBG.FormGroup>
        <GBG.Label htmlFor="name-redirect-input">{t('[name redirect] input label')}</GBG.Label>
        <GBG.Assistive>{t('[name redirect] input explainer')}</GBG.Assistive>
        <GBG.Text
          id="name-redirect-input"
          error={has('name', validationErrors)}
          placeholder={t('[name redirect] name input placeholder')}
          onChange={onChangeInternal('name')}
          onBlur={onBlurInternal}
          value={prop('name')(formData)}
          aria-label="name-redirect-input"
        />
        {!has('name', validationErrors) ? null : (
          <GBG.ErrorText>{path(['name', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
    </div>
  )
}

export default NameForm
