import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './TotalUsage.module.css'
import { JourneyIntegrationLinkResult } from '../../../common/modules/JourneyIntegrationLinkResults'

import JourneyIntegrationChart from '../../../common/modules/JourneyIntegrationChart'

interface Props {
  results: JourneyIntegrationLinkResult
}

const TotalUsage: FC<Props & WithTranslation> = ({ t, results }) => {
  const { passed, failed } = results
  const data = [
    { name: 'PASS', value: passed, key: 'pass' },
    { name: 'REFER', value: failed, key: 'refer' },
  ]
  return (
    <div className={styles.container}>
      <h3>{t('[dashboard total usage] title')}</h3>
      <p className={styles.description}>{t('[dashboard total usage] explainer')}</p>
      <JourneyIntegrationChart data={data as any} />
    </div>
  )
}

export default TotalUsage
