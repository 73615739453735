import { createAction } from '@reduxjs/toolkit'

//---------------------------------
// actions
//---------------------------------

export const NAVIGATE_TO = '[navigation] navigate'
export const navigateTo = createAction<string>(NAVIGATE_TO)

export const ON_ROUTE = '[routing] on route'
export const onRoute = createAction<string>(ON_ROUTE)
