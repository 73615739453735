import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { compose, pathOr } from 'ramda'
import { withTranslation } from 'react-i18next'

import History from './History.component'
import { historySelector, exportsSelector } from '../../journeyIntegration.selectors'
import { httpCreateJourneyIntegrationLinkReport } from '../../../../common/modules/JourneyIntegrationLink'
import {
  httpCreateJourneyIntegrationHistoryExport,
  httpGetJourneyIntegrationExports,
} from '../../journeyIntegration.reducer'
import { AppState } from '../../../../common/redux/reducers'
import { isHistoryExport, isSpecificHistoryExport } from '../../../../common/feature-flags/featureFlags.selectors'
import { FormSubmissionStatus, setFormSubmissionStatus } from '../../../../common/form'
import { httpGetJourneyIntegrationExportLink } from '../../../../common/modules/JourneyIntegrations/journeyIntegrations.reducer'
import { ModalView } from '../../../Modal/modal.types'
import { setView } from '../../../Modal/modal.reducer'

// TODO: replace hardcoded data with selector
const mapStateToProps = createSelector(
  [
    historySelector,
    (state: AppState) => state.selectedJourneyIntegration,
    isHistoryExport,
    isSpecificHistoryExport,
    pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'history-export', 'submissionStatus']),
    exportsSelector,
  ],
  (history, integrationId, isHistoryExport, isSpecificHistoryExport, exportFormStatus, exports) => ({
    history: history.history,
    isExporting: exportFormStatus === FormSubmissionStatus.SUBMITTING,
    integrationId,
    isHistoryExport,
    isSpecificHistoryExport,
    exports,
  }),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onDownloadReport: (id: string) => {
    dispatch(httpCreateJourneyIntegrationLinkReport(id))
  },
  onRenewExports: (id: number) => {
    dispatch(httpGetJourneyIntegrationExports(id))
  },
  onDownloadExport: (journeyId: number, exportId: number) => {
    dispatch(httpGetJourneyIntegrationExportLink(journeyId, exportId))
  },
  onConfirmingExportDownload: (exportItem: any) => {
    dispatch(setView({ view: ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_DOWNLOAD, payload: { exportItem } }))
  },
  onConfirmingExportDelete: (exportItem: any) => {
    dispatch(setView({ view: ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_DELETE, payload: { exportItem } }))
  },
  onExport: (id: string, selectedItems: string[], searchFilter: string, resultFilter: string) => {
    dispatch(setFormSubmissionStatus('history-export', FormSubmissionStatus.SUBMITTING))
    dispatch(
      httpCreateJourneyIntegrationHistoryExport(id, {
        selectedItems,
        searchFilter,
        resultFilter,
      }),
    )
  },
})

export default compose(withTranslation('JourneyIntegration'), connect(mapStateToProps, mapDispatchToProps))(History)
