import { createSelector } from 'reselect'

import { currentRouteSelector } from '../../common/modules/Routing'
import { isAuthenticatedSelector } from '../Auth/auth.selectors'

export const NavigationSelector = createSelector(
  [currentRouteSelector, isAuthenticatedSelector],
  ({ currentRoutePathname }, { isAuthenticated }) => ({
    currentRoutePathname,
    isAuthenticated,
  }),
)
