import React, { FC } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'
import history from '../../common/redux/history'
import { navigateTo, Routes } from '../../common/modules/Routing'
import styles from './Settings.module.css'
import Users from '../../common/modules/Users'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import Organisation from '../Organisation'
import { useDispatch } from 'react-redux'
import DataProtectionForm from '../../common/modules/DataProtectionForm'

const SettingsRoutes: FC = () => (
  <Router history={history}>
    {/* TODO: figure out how to redirect redirects/1/update to redirects/1/update/details */}
    {/* TODO: or how to do nested routing here where you cannot navigate from redirects/1/update to redirects/1/details but instead to redirects/1/update/details */}
    <Switch>
      <Route path={[Routes.SETTINGS, Routes.SETTINGS_ORGANISATION]} exact>
        <Organisation />
      </Route>
      <Route path={[Routes.SETTINGS_DATA_PROTECTION]} exact>
        <DataProtectionForm />
      </Route>
      <Route path={Routes.SETTINGS_USERS} exact>
        <Users />
      </Route>
    </Switch>
  </Router>
)

const tabsMap = [
  {
    title: 'Organisation',
    route: Routes.SETTINGS_ORGANISATION,
  },
  {
    title: 'Data protection',
    route: Routes.SETTINGS_DATA_PROTECTION,
  },
  {
    title: 'Users',
    route: Routes.SETTINGS_USERS,
  },
]

const Settings: FC<WithTranslation> = ({ t }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>{t('[settings] title')}</h1>
      </div>

      <GBG.TabBar
        className="w-100"
        selectedIndex={tabsMap.indexOf(tabsMap.find(i => i.route == location.pathname) as any)}
        onTabSelected={(i: number) => {
          dispatch(navigateTo(tabsMap[i].route))
        }}
        tabs={tabsMap.map(t => t.title)}
      />
      <div className={styles.content}>
        <SettingsRoutes />
      </div>
    </div>
  )
}

export default Settings
