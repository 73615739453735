import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import SignupConfirmation from './SignupConfirmation.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'

const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateToLogin: () => {
    dispatch(navigateTo(Routes.LOGIN))
  },
})

export default compose(withTranslation('Auth'), connect(mapStateToProps, mapDispatchToProps))(SignupConfirmation)
