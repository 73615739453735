export enum IconType {
  Document = 'document',
  DocumentLiveness = 'document-liveness',
  DocumentLivesnessAddress = 'document-liveness-address',
  ArrowLightBlue = 'arrow-light-blue',
  ArrowDeepBlue = 'arrow-deep-blue',
  ModalWarning = 'modal-warning',
  ModalLink = 'modal-link',
  Info = 'info',
  Lock = 'lock',
  ExampleCompanyLogo = 'example-company-logo',
  DropDownMenuButton = 'drop-down-menu-button',
  ListStyle = 'list-style',
  CopyToClipBoard = 'copy-to-clipboard',
  PasswordEye = 'password-eye',
  AlertWarning = 'alert-warning',
  WelcomeProfile = 'welcome-profile',
  NoFillAlertWarning = 'no-fill-alert-warning',
  Plus = 'plus',
  Cards = 'cards',
  Mastercard = 'mastercard',
  AmericanExpress = 'american-express',
  Visa = 'visa',
  PaymentComplete = 'payment-complete',
  Confetti = 'confetti',
  Calendar = 'calendar',
  ResultCheckmark = 'result-checkmark',
  ResultAlert = 'result-alert',
}

export enum AlertIconType {
  INFO = 'info',
  WARNING = 'alert-warning',
  ERROR = 'lock', // TODO: add correct
  SUCCESS = 'arrow-light-blue', // TODO: add correct
}
