import { includes, isNil, path } from 'ramda'
import { Middleware } from 'redux'
import jwt_decode from 'jwt-decode'

import {
  httpCreateUser,
  httpGetUser,
  HTTP_GET_USER_FAILURE,
  setUser,
  httpUpdateUser,
  HTTP_UPDATE_USER_SUCCESS,
  UPDATE_USER,
} from './user.reducer'
import { navigateTo, Routes } from '../../common/modules/Routing'

//---------------------------------
// get user
//---------------------------------

export const getUserFlow = (actions: string[]): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const { auth } = getState()
  const { accessToken } = auth
  if (includes(type)(actions) && !isNil(accessToken)) {
    dispatch(httpGetUser())
  }
}

//---------------------------------
// set createUserFlow
// ... signals intention to create a user
//---------------------------------

export const createUserFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { auth } = getState()
  const { accessToken } = auth
  const { type, payload } = action
  if (type === HTTP_GET_USER_FAILURE && !isNil(accessToken)) {
    const { status } = payload
    if (status === 404) {
      const { sub } = jwt_decode(accessToken) as any
      dispatch(httpCreateUser({ authId: sub }))
    }
  }
}

//---------------------------------
// set user
// ... signals intention to set a user
// ... returned from the api
//---------------------------------

export const setUserFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(setUser(payload))
  }
}

//---------------------------------
// update user
//---------------------------------

export const updateUserFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_USER) {
    const { user } = getState()
    const { id } = user
    dispatch(httpUpdateUser(payload.data, id))
  }
}

export const updateUserSuccessFlow = (excludedRoutes: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_USER_SUCCESS) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (!includes(pathname, excludedRoutes)) {
      dispatch(navigateTo(Routes.OVERVIEW))
    }
  }
}

//---------------------------------
// refresh user
// ... retrieves user after successes that impact user
//---------------------------------

export const refreshUserFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    dispatch(httpGetUser())
  }
}

//---------------------------------
// confirm cancel auto renew for user
//---------------------------------

// export const confirmCancelAutoRenewFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
//   next(action)
//   const { type } = action
//   const state = getState()
//   const userId = path(['user', 'id'])(state)
//   const autoRenew = path(['user', 'autoRenew'])(state)
//   if (isNil(autoRenew)) {
//     return
//   }
//   if (type === CONFIRM_CANCEL_AUTO_RENEW) {
//     dispatch(httpUpdateUser({ autoRenew: false }, userId))
//   }
// }

//---------------------------------
// confirm cancel auto renew success
//---------------------------------

// export const confirmCancelAutoRenewSuccessFlow = (includedRoute: string): Middleware => ({
//   dispatch,
//   getState,
// }) => next => action => {
//   next(action)
//   const { type } = action
//   if (type === HTTP_UPDATE_USER_SUCCESS) {
//     const { router } = getState()
//     const pathname = path(['location', 'pathname'])(router)
//     if (includes(pathname, includedRoute)) {
//       dispatch(navigateTo(Routes.PAYMENT))
//     }
//   }
// }

//---------------------------------
// enable auto renew for user
//---------------------------------

// export const enableAutoRenewFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
//   next(action)
//   const { type } = action
//   const state = getState()
//   const userId = path(['user', 'id'])(state)
//   const autoRenew = path(['user', 'autoRenew'])(state)
//   if (isNil(autoRenew)) {
//     return
//   }
//   if (type === ENABLE_AUTO_RENEW) {
//     dispatch(httpUpdateUser({ autoRenew: true }, userId))
//   }
// }

//---------------------------------
// confirm cancel subscription for user
//---------------------------------

// export const confirmCancelSubscriptionFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
//   next(action)
//   const { type } = action
//   const state = getState()
//   const userId = path(['user', 'id'])(state)
//   const subscriptionCancelled = path(['user', 'subscriptionCancelled'])(state)
//   if (isNil(subscriptionCancelled)) {
//     return
//   }
//   if (type === CONFIRM_CANCEL_SUBSCRIPTION) {
//     dispatch(httpUpdateUser({ subscriptionCancelled: true }, userId))
//   }
// }

//---------------------------------
// confirm cancel subscription success
//---------------------------------

// export const confirmCancelSubscriptionSuccessFlow = (includedRoute: string): Middleware => ({
//   dispatch,
//   getState,
// }) => next => action => {
//   next(action)
//   const { type } = action
//   if (type === HTTP_UPDATE_USER_SUCCESS) {
//     const { router } = getState()
//     const pathname = path(['location', 'pathname'])(router)
//     if (includes(pathname, includedRoute)) {
//       dispatch(reset())
//     }
//   }
// }
