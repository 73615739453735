import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { compose, replace } from 'ramda'
import { withTranslation } from 'react-i18next'

import JourneyIntegrations, { Props } from './JourneyIntegrations.component'
import { journeyIntegrationsAndUserSelector } from './JourneyIntegrations.selectors'
import { navigateTo, Routes } from '../Routing'
import {
  setSelectedJourneyIntegration,
  activateJourneyIntegration,
  deactivateJourneyIntegration,
} from '../../../modules/JourneyIntegration'
import { createJourneyIntegrationLink } from '../JourneyIntegrationLink'

const mapStateToProps = journeyIntegrationsAndUserSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onEdit: (id: number) => {
    dispatch(setSelectedJourneyIntegration(id))
    const pathname = replace(/:id/, id)(Routes.UPDATE_DETAILS)
    dispatch(navigateTo(pathname))
  },
  onActivate: (id: number) => {
    dispatch(activateJourneyIntegration(id))
  },
  onDeactivate: (id: number) => {
    dispatch(setSelectedJourneyIntegration(id))
    dispatch(deactivateJourneyIntegration(id))
  },
  onCreateLink: (id: number) => {
    // TODO: dispatch(setSelectedJourneyIntegration(id))
    dispatch(createJourneyIntegrationLink(id))
  },
})

export default compose(
  withTranslation('JourneyIntegrations'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(JourneyIntegrations)
