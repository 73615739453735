import { createReducer, createAction } from '@reduxjs/toolkit'

import { normalize } from '../../utils/data'
import {
  JourneyIntegration,
  SetJourneyIntegrationsPayload,
  JourneyIntegrationsState,
} from './journeyIntegrations.types'
import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_JOURNEY_INTEGRATIONS = '[journey integrations] HTTP get'
export const HTTP_GET_JOURNEY_INTEGRATIONS_SUCCESS = '[journey integrations] HTTP get success'
export const HTTP_GET_JOURNEY_INTEGRATIONS_FAILURE = '[journey integrations] HTTP get failure'
export const httpGetJourneyIntegrations = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATIONS)({ endpoint: 'journey-integrations' })
export const httpGetJourneyIntegrationsSuccess = createAction<JourneyIntegration[]>(
  HTTP_GET_JOURNEY_INTEGRATIONS_SUCCESS,
)
export const httpGetJourneyIntegrationsFailure = createAction<string>(HTTP_GET_JOURNEY_INTEGRATIONS_FAILURE)

export const SET_JOURNEY_INTEGRATIONS = '[journey integrations] set'
export const setJourneyIntegrations = (
  journeyIntegrations: JourneyIntegration[],
): ActionWithPayload<SetJourneyIntegrationsPayload> =>
  createAction<SetJourneyIntegrationsPayload>(SET_JOURNEY_INTEGRATIONS)({ journeyIntegrations })

export const HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK = '[journey integration] HTTP get export link'
export const HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_SUCCESS = '[journey integration] HTTP get export link success'
export const HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_FAILURE = '[journey integration] HTTP get export link failure'
export const httpGetJourneyIntegrationExportLink = (
  journeyId: number,
  exportId: number,
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK)({
    endpoint: `journey-integrations/${journeyId}/history/export/${exportId}`,
  })
export const httpGetJourneyIntegrationExportLinkSuccess = createAction<{ url: String }>(
  HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_SUCCESS,
)
export const httpGetJourneyIntegrationExportLinkFailure = createAction<{ message: string; status: number }>(
  HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_FAILURE,
)

export const HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT = '[journey integration] HTTP delete export link'
export const HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT_SUCCESS = '[journey integration] HTTP delete export link success'
export const HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT_FAILURE = '[journey integration] HTTP delete export link failure'
export const httpDeleteJourneyIntegrationExport = (
  journeyId: number,
  exportId: number,
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT)({
    endpoint: `journey-integrations/${journeyId}/history/export/${exportId}`,
  })
export const httpDeleteJourneyIntegrationExportSuccess = createAction<{ url: String }>(
  HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT_SUCCESS,
)
export const httpDeleteJourneyIntegrationExportFailure = createAction<{ message: string; status: number }>(
  HTTP_DELETE_JOURNEY_INTEGRATION_EXPORT_FAILURE,
)

//---------------------------------
// reducers
//---------------------------------

const overwriteJourneyIntegrations = (
  state: JourneyIntegrationsState,
  { payload }: ActionWithPayload<SetJourneyIntegrationsPayload>,
) => {
  const { journeyIntegrations } = payload
  return normalize('id')(journeyIntegrations)
}

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {} as JourneyIntegrationsState

const reducer = createReducer<JourneyIntegrationsState>(INITIAL_STATE, {
  [SET_JOURNEY_INTEGRATIONS]: overwriteJourneyIntegrations,
})

export default reducer
