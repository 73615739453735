import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import JourneyCreditAccountBalance from '../../common/modules/JourneyCreditAccount'
import JourneyIntegrations from '../../common/modules/JourneyIntegrations'
import styles from './Dashboard.module.css'
import WelcomeHero from './WelcomeHero/WelcomeHero.container'
import TotalUsage from './TotalUsage/TotalUsage.container'

const Dashboard: FC<WithTranslation> = ({ t }) => (
  <div className={styles.container}>
    <h1>{t('[dashboard] title')}</h1>
    <div className={styles.content}>
      <WelcomeHero />
    </div>
    <div className={styles.transactionSection}>
      <JourneyCreditAccountBalance expiredActions={true} />
      <TotalUsage />
    </div>
    <div className={styles.content}>
      <JourneyIntegrations />
    </div>
  </div>
)

export default Dashboard
