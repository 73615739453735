import { createReducer, createAction } from '@reduxjs/toolkit'

import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'
import { BillingAddress, PaymentMethodsState } from './paymentMethods.types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_PAYMENT_METHODS = '[payment methods] HTTP get'
export const HTTP_GET_PAYMENT_METHODS_SUCCESS = '[payment methods] HTTP get success'
export const HTTP_GET_PAYMENT_METHODS_FAILURE = '[payment methods] HTTP get failure'
export const httpGetPaymentMethods = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_PAYMENT_METHODS)({
    endpoint: 'payment-methods',
  })
export const httpGetPaymentMethodsSuccess = createAction<any>(HTTP_GET_PAYMENT_METHODS_SUCCESS)
export const httpGetPaymentMethodsFailure = createAction<string>(HTTP_GET_PAYMENT_METHODS_FAILURE)

export const SELECT_PAYMENT_METHOD = '[payment method] select'
export const selectPaymentMethod = createAction<string>(SELECT_PAYMENT_METHOD)

export const HTTP_CREATE_SETUP_INTENT = '[payment method] HTTP create setup intent'
export const HTTP_CREATE_SETUP_INTENT_SUCCESS = '[payment method] HTTP create setup intent success'
export const HTTP_CREATE_SETUP_INTENT_FAILURE = '[payment method] HTTP create setup intent failure'
export const httpCreateSetupIntent = createAction<HTTPPayload>(HTTP_CREATE_SETUP_INTENT)({
  endpoint: 'payment-methods/setup-intents',
})
export const httpCreateSetupIntentSuccess = createAction(HTTP_CREATE_SETUP_INTENT_SUCCESS)
export const httpCreateSetupIntentFailure = createAction(HTTP_CREATE_SETUP_INTENT_FAILURE)

export const UPDATE_DEFAULT_PAYMENT_METHOD = '[payment methods] update default'
export const updateDefaultPaymentMethod = createAction<string>(UPDATE_DEFAULT_PAYMENT_METHOD)

export const UPDATE_PAYMENT_METHOD_BILLING_ADDRESS = '[payment methods] update billing address'
export const updatePaymentMethodBillingAddress = createAction<{
  stripePaymentMethodId: string
  address: BillingAddress
}>(UPDATE_PAYMENT_METHOD_BILLING_ADDRESS)

export const HTTP_UPDATE_PAYMENT_METHOD = '[payment method] HTTP update'
export const HTTP_UPDATE_PAYMENT_METHOD_SUCCESS = '[payment method] HTTP update success'
export const HTTP_UPDATE_PAYMENT_METHOD_FAILURE = '[payment method] HTTP update failure'
export const httpUpdatePaymentMethod = (stripePaymentMethodId: string, data: any): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_PAYMENT_METHOD)({
    endpoint: `payment-methods/${stripePaymentMethodId}`,
    data,
  })
export const httpUpdatePaymentMethodSuccess = createAction<any>(HTTP_UPDATE_PAYMENT_METHOD_SUCCESS)
export const httpUpdatePaymentMethodFailure = createAction<string>(HTTP_UPDATE_PAYMENT_METHOD_FAILURE)

export const STRIPE_CARD_CONFIRMED = '[payment method] stripe card confirmed'
export const stripeCardConfirmed = createAction<{ stripePaymentMethodId: string; address: BillingAddress | null }>(
  STRIPE_CARD_CONFIRMED,
)

export const STRIPE_CARD_ERROR = '[payment method] stripe error'
export const stripeCardError = createAction(STRIPE_CARD_ERROR)

export const DELETE_PAYMENT_METHOD = '[payment method] delete'
export const deletePaymentMethod = createAction<string>(DELETE_PAYMENT_METHOD)

export const CONFIRM_DELETE_PAYMENT_METHOD = '[payment method] confirm delete'
export const confirmDeletePaymentMethod = createAction<string>(CONFIRM_DELETE_PAYMENT_METHOD)

export const HTTP_DELETE_PAYMENT_METHOD = '[payment method] HTTP delete payment method'
export const httpDeletePaymentMethod = (stripePaymentMethodId: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_DELETE_PAYMENT_METHOD)({
    endpoint: `payment-methods/${stripePaymentMethodId}`,
  })
export const HTTP_DELETE_PAYMENT_METHOD_SUCCESS = '[payment method] HTTP delete payment method success'
export const httpDeletePaymentMethodSuccess = createAction(HTTP_DELETE_PAYMENT_METHOD_SUCCESS)
export const HTTP_DELETE_PAYMENT_METHOD_FAILURE = '[payment method] HTTP delete payment method failure'
export const httpDeletePaymentMethodFailure = createAction(HTTP_DELETE_PAYMENT_METHOD_FAILURE)

//---------------------------------
// initial state
//---------------------------------

// TODO: normalize
export const INITIAL_STATE = []

//---------------------------------
// reducers
//---------------------------------

const overwritePaymentMethods = (state: PaymentMethodsState, { payload }: ActionWithPayload<PaymentMethodsState>) =>
  payload

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<PaymentMethodsState>(INITIAL_STATE, {
  [HTTP_GET_PAYMENT_METHODS_SUCCESS]: overwritePaymentMethods,
})

export default reducer
