import { createAction, createReducer } from '@reduxjs/toolkit'
import { HTTPPayload } from '../../common/redux-http'
import { ActionWithPayload } from '../../common/redux/types'
import { PaymentHistoryState } from './payment.types'

//---------------------------------
// actions
//---------------------------------

export const CREATE_PAYMENT_METHOD = '[payment] store card details'
export const createPaymentMethod = createAction(CREATE_PAYMENT_METHOD)

export const CHOOSE_SUBSCRIPTION = '[payment] choose subscription'
export const chooseSubscription = createAction(CHOOSE_SUBSCRIPTION)

export const CHANGE_SUBSCRIPTION = '[payment] change subscription'
export const changeSubscription = createAction(CHANGE_SUBSCRIPTION)

export const UPDATE_PAYMENT_SELECT_SUBSCRIPTION = '[payment] update select subscription'
export const updatePaymentSelectSubscription = createAction<string>(UPDATE_PAYMENT_SELECT_SUBSCRIPTION)

export const UPDATE_USER_ADDRESS = '[payment] update user address'
export const updateUserAddress = createAction(UPDATE_USER_ADDRESS)

export const HTTP_GET_PAYMENT_HISTORY = '[payment history] HTTP get'
export const HTTP_GET_PAYMENT_HISTORY_SUCCESS = '[payment history] HTTP get success'
export const HTTP_GET_PAYMENT_HISTORY_FAILURE = '[payment history] HTTP get failure'
export const httpGetPaymentHistory = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_PAYMENT_HISTORY)({
    endpoint: 'payment-history',
  })

export const httpGetPaymentHistorySuccess = createAction<any>(HTTP_GET_PAYMENT_HISTORY_SUCCESS)
export const httpGetPaymentHistoryFailure = createAction<string>(HTTP_GET_PAYMENT_HISTORY_FAILURE)

export const CONFIRM_PAYMENT = '[payment] confirm'
export const confirmPayment = createAction(CONFIRM_PAYMENT)

export const HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION = '[payment] HTTP create payment intent confirmation'
export const httpCreatePaymentIntentConfirmation = (
  stripePaymentIntentId: string,
  stripePaymentMethodId: string,
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION)({
    endpoint: `payment-intents/${stripePaymentIntentId}`,
    data: {
      stripePaymentMethodId,
    },
  })
export const HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_SUCCESS =
  '[payment] HTTP create payment intent confirmation success'
export const httpCreatePaymentIntentConfirmationSuccess = createAction(HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_SUCCESS)
export const HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_FAILURE =
  '[payment] HTTP create payment intent confirmation failure'
export const httpCreatePaymentIntentConfirmationFailure = createAction(HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_FAILURE)

//---------------------------------
// initial state
//---------------------------------

// TODO: normalize
export const INITIAL_STATE = []

//---------------------------------
// reducers
//---------------------------------

const overwritePaymentMethods = (state: PaymentHistoryState, { payload }: ActionWithPayload<PaymentHistoryState>) =>
  payload

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<PaymentHistoryState>(INITIAL_STATE, {
  [HTTP_GET_PAYMENT_HISTORY_SUCCESS]: overwritePaymentMethods,
})

export default reducer
