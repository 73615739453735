import { createSelector } from 'reselect'
import { path } from 'ramda'

export const featureFlagsSelector = createSelector([path(['featureFlags'])], (featureFlags: any) => featureFlags) as any

export const isPaymentsEnabledSelector = createSelector(
  [path(['featureFlags', 'isPaymentsEnabled'])],
  (isPaymentsEnabled: boolean) => ({ isPaymentsEnabled }),
)

export const isAccountSettingsEnabledSelector = createSelector(
  [path(['featureFlags', 'isAccountSettingsEnabled'])],
  (isAccountSettingsEnabled: boolean) => ({ isAccountSettingsEnabled }),
)

export const isGatheringDPOInfoSelector = createSelector(
  [path(['featureFlags', 'isGatheringDPOInfo'])],
  (isGatheringDPOInfo: boolean) => isGatheringDPOInfo,
)

export const isMultiLinkCreation = createSelector(
  [path(['featureFlags', 'isMultiLinkCreation'])],
  (isMultiLinkCreation: boolean) => isMultiLinkCreation,
)

export const isHistoryExport = createSelector(
  [path(['featureFlags', 'isHistoryExport'])],
  (isHistoryExport: boolean) => isHistoryExport,
)

export const isSpecificHistoryExport = createSelector(
  [path(['featureFlags', 'isSpecificHistoryExport'])],
  (isSpecificHistoryExport: boolean) => isSpecificHistoryExport,
)

export const isToggleableOutcomeIcon = createSelector(
  [path(['featureFlags', 'isToggleableOutcomeIcon'])],
  (isToggleableOutcomeIcon: boolean) => isToggleableOutcomeIcon,
)
