import { createSelector } from 'reselect'
import { assoc, compose, map, pathOr, pick, pipe, prop, propEq, takeLast, toString } from 'ramda'

import { PaymentMethodsState } from './paymentMethods.types'

const transform = (currentPaymentMethodId: string) =>
  pipe(
    (x: any) => assoc('isPrimary', propEq('stripePaymentMethodId', currentPaymentMethodId)(x))(x),
    (x: any) =>
      assoc(
        'expiryDate',
        `${compose((y: any) => y.padStart(2, '0'), toString, prop('cardExpiryMonth'))(x)}/${compose(
          takeLast(2),
          toString,
          prop('cardExpiryYear'),
        )(x)}`,
      )(x),
    pick([
      'stripePaymentMethodId',
      'cardBrand',
      'billingName',
      'cardLast4',
      'expiryDate',
      'isPrimary',
      'billingAddressLine1',
      'billingAddressLine2',
      'billingAddressCity',
      'billingAddressPostalCode',
      'billingAddressState',
      'billingAddressCountry',
    ]),
  )

export const paymentMethodsSelector = createSelector(
  [prop('paymentMethods'), pathOr(null, ['user', 'stripePaymentMethodId'])],
  (paymentMethods: PaymentMethodsState, currentPaymentMethodId: string) => ({
    paymentMethods: map(transform(currentPaymentMethodId))(paymentMethods),
    currentPaymentMethodId,
  }),
) as any
