import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import OrderSummary from './OrderSummary.component'
import { updatePaymentOrderSummaryPropsSelector } from '../../payment.selectors'

const mapStateToProps = updatePaymentOrderSummaryPropsSelector
const mapDispatchToProps = null

export default compose(withTranslation('Payment'), connect(mapStateToProps, mapDispatchToProps))(OrderSummary)
