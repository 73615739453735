import { assoc, compose, groupBy, head, map, mapObjIndexed, pipe, prop, values } from 'ramda'

export const denormalize = (keyProp: string): Function =>
  compose(
    values,
    // @ts-ignore
    mapObjIndexed((value, key) => assoc(keyProp, key, value)),
  )

export const normalize = (keyProp: string): Function => pipe(groupBy(prop(keyProp)), map(head))
