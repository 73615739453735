import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'ramda'
import OrganisationForm, { Props } from './organisation.component'
import { organisationSelector } from './organisation.selectors'
import { httpUpdateOrganisation } from './organisation.reducer'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { AppState } from '../../common/redux/reducers'
import { Organisation } from './organisation.types'

//const mapStateToProps = userInvitesSelector as any
const mapStateToProps: any = (state: AppState) => {
  const org = organisationSelector(state)
  return {
    org,
  }
}
// const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onUpdate: (orgId: number, orgData: Partial<Organisation>) => {
    dispatch(httpUpdateOrganisation(orgData as any, orgId))
  },
})

export default compose(
  withTranslation('Organisation'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(OrganisationForm)
