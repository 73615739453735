import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './LogoutHeader.module.css'

interface Props {
  onNavigate: () => void
}

const LogoutHeader: FC<Props & WithTranslation> = ({ t, onNavigate }) => (
  <>
    <button onClick={() => onNavigate()} className={styles.logoutButton}>
      {t('[auth] logout button label')}
    </button>
  </>
)

export default LogoutHeader
