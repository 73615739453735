import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'

import Review from './Review.component'
import { Routes, navigateTo } from '../../../../common/modules/Routing'

const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateToDashboard: () => dispatch(navigateTo(Routes.OVERVIEW)),
})

export default withTranslation('JourneyIntegration')(connect(mapStateToProps, mapDispatchToProps)(Review))
