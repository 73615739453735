import { Dispatch } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'

import { setJourneyType, setupLater } from '../../journeyIntegration.reducer'
import { createJourneyIntegrationProceed } from '../../createJourneyIntegrationCompletedSteps.reducer'
import JourneyTypeForm from './JourneyTypeForm.component'
import { journeyTypeFormSelector } from './JourneyTypeForm.selector'

const mapStateToProps = journeyTypeFormSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSetJourneyType: (type: number) => dispatch(setJourneyType(type)),
  onProceed: () => dispatch(createJourneyIntegrationProceed()),
  onSetupLater: () => dispatch(setupLater()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('JourneyIntegration')(JourneyTypeForm))
