export const DEFAULT_HEADERS = {
  Accept: 'application/json',
}
export const DEFAULT_TIMEOUT = 15000
export const HTTP_REQUEST_REGEX = new RegExp(/HTTP\s(?:get|update|put|create|delete)(?:(?!success|failure).)*$/)
export const HTTP_GET_REQUEST_REGEX = new RegExp(/HTTP\sget/)
export const HTTP_PUT_REQUEST_REGEX = new RegExp(/HTTP\sput/)
export const HTTP_PATCH_REQUEST_REGEX = new RegExp(/HTTP\supdate/)
export const HTTP_POST_REQUEST_REGEX = new RegExp(/HTTP\screate/)
export const HTTP_DELETE_REQUEST_REGEX = new RegExp(/HTTP\sdelete/)
