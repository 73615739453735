import { createReducer, createAction } from '@reduxjs/toolkit'
import { always, mergeRight } from 'ramda'

import { ActionWithPayload } from '../../redux/types'
import { Alert } from './Alert.types'
import { AlertType } from './Alert.constants'

//---------------------------------
// actions
//---------------------------------

export const SET_ALERT = '[alert] set'
export const setAlert = createAction<Partial<Alert>>(SET_ALERT)

export const RESET_ALERT = '[alert] reset'
export const resetAlert = createAction(RESET_ALERT)

//---------------------------------
// default state
//---------------------------------

export const INITIAL_STATE = {
  type: AlertType.INFO,
  title: '',
  message: '',
  isDismissable: true,
}

//---------------------------------
// reducers
//---------------------------------

const overwriteAlert = (state: Alert, { payload }: ActionWithPayload<Alert>) => mergeRight(INITIAL_STATE, payload)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<Alert>(INITIAL_STATE, {
  [SET_ALERT]: overwriteAlert,
  [RESET_ALERT]: always(INITIAL_STATE),
})

export default reducer
