import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose, pick, prop } from 'ramda'

import DeactivateJourneyIntegrationModal, { Props } from './DeactivateJourneyIntegrationModal.component'
import { confirmDeactivateJourneyIntegration } from '../../../modules/JourneyIntegration'
import { selectedJourneyIntegrationSelector } from '../../../modules/JourneyIntegration/journeyIntegration.selectors'

const mapStateToProps = compose(
  pick(['id', 'name']),
  prop('selectedJourneyIntegration'),
  selectedJourneyIntegrationSelector,
)

const noop = () => null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { onSubmit = noop }) => ({
  onSubmit: (id: number) => {
    dispatch(confirmDeactivateJourneyIntegration(id))
    onSubmit() // allow parent component to handle as well
  },
})

export default compose(
  withTranslation('DeactivateJourneyIntegrationModal'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(DeactivateJourneyIntegrationModal)
