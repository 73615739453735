import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { ifElse, tap, compose, isNil, mergeRight, isEmpty } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import JourneyIntegrationSelectedSteps from '../../../../common/modules/JourneyIntegrationSelectedSteps'
import document from '../../../../common/assets/document.svg'
import { useFormValidation } from '../../../../common/modules/AJVFormValidation'
import formValidationSchema from '../../schemas/journey-integration-name-schema.json'
import { NameForm, NameFormData } from '../../NameForm'

// TODO: add type for journey integration details steps
interface Props {
  isSubmitting: boolean
  name: string
  onSubmit: (data: NameFormData) => void
  onInvalidSubmit: () => void
  onValidationChange: (isValid: boolean) => void
  steps: any
}

const DetailsForm: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  onSubmit,
  onInvalidSubmit,
  onValidationChange,
  name,
  steps,
}) => {
  // loading view
  if (isNil(name)) {
    return <div>...</div>
  }

  const validationErrorMap = {
    name: {
      minLength: 'name is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [formData, setFormData] = useState({ name })
  const onChangeInternal = compose(tap(setFormData), mergeRight(formData))
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    if (!isNil(validationErrors)) {
      onValidationChange(isEmpty(validationErrors))
    }
  }, [validationErrors])
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    ifElse(
      validate,
      tap(onSubmit),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(formData)
  }

  return (
    <div>
      <NameForm
        formData={formData}
        onChange={onChangeInternal}
        validate={validate}
        validationErrors={validationErrors}
      />
      <JourneyIntegrationSelectedSteps steps={steps} iconUrl={document} />
      {/* TODO: Pull out into common component*/}

      {isNil(formData) ? null : (
        <GBG.Button
          className="m-m-t-4"
          onClick={() => {
            onSubmitInternal()
          }}
          aria-label="submit-button"
          worker={true}
          active={internalIsSubmitting}
        >
          {t('[journey integration update] update redirect button label')}
        </GBG.Button>
      )}
    </div>
  )
}

export default DetailsForm
