import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import ExportCreatedModal, { Props } from './ExportCreatedModal.component'

// TODO: extract to dedicated selector and test
const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { onSubmit = () => null }: any) => ({
  onConfirm: () => {
    onSubmit()
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(ExportCreatedModal)
