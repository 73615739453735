import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import history from '../../common/redux/history'
import HeaderAndNavWrapper from '../HeaderAndNavWrapper'
import { CreateJourneyIntegration } from '../JourneyIntegration'
import styles from './App.module.css'
import { Routes } from '../../common/modules/Routing'
import Modal from '../Modal'
import { ProtectedRoute } from '../Auth'
import DataProtectionOnboarding from '../Onboarding/DataProtectionOnboarding'

const App: FC = () => (
  <div className={styles.container}>
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={[
            Routes.OVERVIEW,
            Routes.LOGIN,
            Routes.SIGNUP,
            Routes.SIGNUP_CONFIRMATION,
            Routes.CHANGE_PASSWORD,
            Routes.ONBOARD,
            Routes.PROFILE,
            Routes.ACCOUNT,
            Routes.PAYMENT,
            Routes.AUTO_RENEW,
            Routes.UPDATE_PAYMENT,
            Routes.PAYMENT_CHECKOUT,
            Routes.UPDATE,
            Routes.UPDATE_DETAILS,
            Routes.UPDATE_COMPLETION_ACTION,
            Routes.UPDATE_HISTORY,
            Routes.UPDATE_HISTORY_DOWNLOAD,
            Routes.UPDATE_PERSONALISATION,
            Routes.SETTINGS,
            Routes.SETTINGS_USERS,
            Routes.SETTINGS_ORGANISATION,
            Routes.SETTINGS_DATA_PROTECTION,
          ]}
        >
          <HeaderAndNavWrapper />
        </Route>
        <ProtectedRoute path={Routes.CREATE_JOURNEY_INTEGRATION}>
          <CreateJourneyIntegration />
        </ProtectedRoute>
        <ProtectedRoute path={Routes.ONBOARD_DATA_PROTECTION}>
          <DataProtectionOnboarding />
        </ProtectedRoute>
      </Switch>
    </Router>
    <Modal />
  </div>
)

export default App
