import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import DataProtectionForm from '../../../common/modules/DataProtectionForm'
import GBG from '@gbg/gbgcomponentlibrary_react'

export interface Props {
  name: string
  onSkip: () => void
}

const DataProtectionOnboarding: FC<Props & WithTranslation> = ({ t, onSkip, name }) => {
  return (
    <div className="structure structure--stepper">
      <GBG.TopBar
        brand={GBG.TopBarBrand.GBG}
        user={{ username: 'Username', company: 'Company' }}
        includeNavLink={false}
        includeUser={false}
        menu={{
          items: [
            {
              title: 'A menu item',
              onSelected: () => {
                alert('Hello world from the item')
              },
            },
          ],
        }}
      ></GBG.TopBar>
      <div className="page__navigation"></div>
      <div className="page__content">
        <GBG.Card isPrimaryContent={true}>
          <GBG.CardBody style={{ maxWidth: '42rem' }} isPrimaryContent={true}>
            <h1 className="m-m-b-3">{t('[dataprotectiononboarding] Welcome', { name: name.split(' ')[0] })}</h1>
            <p>{t('[dataprotectiononboarding] Intro1')}</p>
            <p className="m-m-b-4">{t('[dataprotectiononboarding] Intro2')}</p>
            <DataProtectionForm onSkip={onSkip} skipable={true} />
          </GBG.CardBody>
        </GBG.Card>
      </div>
    </div>
  )
}

export default DataProtectionOnboarding
