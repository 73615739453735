import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import UpdatePaymentCheckout from './UpdatePaymentCheckout.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'
import { AlertType, resetAlert, setAlert } from '../../../common/modules/Alert'
import { assocFormData, FormSubmissionStatus, setFormSubmissionStatus } from '../../../common/form'
import { updateDefaultPaymentMethod } from '../../../common/modules/PaymentMethods'
import { confirmPayment, createPaymentMethod as showCreatePaymentMethodForm } from '../payment.reducer'
import { updatePaymentCheckoutPropsSelector } from '../payment.selectors'
import { BillingAddress, PaymentMethodCard } from '../../../common/modules/PaymentMethods/paymentMethods.types'
import {
  stripeCardConfirmed,
  stripeCardError,
  updatePaymentMethodBillingAddress,
} from '../../../common/modules/PaymentMethods/paymentMethods.reducer'
import { reset } from '../../Modal/modal.reducer'

const mapStateToProps = updatePaymentCheckoutPropsSelector

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }
const noStripePaymentMethodIdError = {
  type: AlertType.ERROR,
  title: 'Error',
  message: 'There was an error with your Payment. Please try again.',
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onCancel: () => {
    dispatch(navigateTo(Routes.PAYMENT))
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
  onSubmitExisting: (stripePriceId: string, stripePaymentMethodId: string, billingAddressFormData: BillingAddress) => {
    dispatch(resetAlert())
    // TODO: dispatch single action and do (and test) all this in middleware
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.SUBMITTING))
    if (stripePaymentMethodId) {
      dispatch(assocFormData('update-payment', 'stripePaymentMethodId', stripePaymentMethodId))
      dispatch(confirmPayment())
      const address = billingAddressFormData
      dispatch(assocFormData('update-payment', 'address', address))
      dispatch(updatePaymentMethodBillingAddress({ stripePaymentMethodId, address }))
    } else {
      dispatch(setAlert(noStripePaymentMethodIdError))
      dispatch(reset())
      dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.FAILURE))
    }
  },
  onSubmitNew: (paymentMethodCard: PaymentMethodCard, billingAddressFormData: BillingAddress) => {
    // TODO: dispatch single action and do (and test) all this in middleware
    const { stripePaymentMethodId, isDefault } = paymentMethodCard
    dispatch(assocFormData('update-payment', 'stripePaymentMethodId', stripePaymentMethodId))
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.SUBMITTING))
    if (isDefault && stripePaymentMethodId) {
      dispatch(updateDefaultPaymentMethod(stripePaymentMethodId))
    }
    const address = billingAddressFormData
    dispatch(assocFormData('update-payment', 'address', address))
    dispatch(stripeCardConfirmed({ stripePaymentMethodId, address }))
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.SUCCESS))
  },
  onError: () => {
    dispatch(stripeCardError())
  },
  onCreatePaymentMethod: () => {
    dispatch(showCreatePaymentMethodForm())
  },
})

export default compose(withTranslation('Payment'), connect(mapStateToProps, mapDispatchToProps))(UpdatePaymentCheckout)
