import React, { FC, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import {
  DataProtectionInformation,
  ProcessingPurpose,
  LawfulBasis,
  DataProtectionState,
} from '../DataProtection/DataProtection.types'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './DataProtectionForm.module.css'
import { ModalView } from '../../../modules/Modal/modal.types'
import { User } from '../../../modules/User/user.types'

interface Props extends DataProtectionState {
  onSubmit: (create: boolean, formData: DataProtectionInformation) => void
  onSkip: () => void
  skipable?: boolean
  cancelable?: boolean
  onCancel: () => void
  onOpenHelper: (view: ModalView) => void
  user: User
}

const DataProtectionForm: FC<Props & WithTranslation> = ({
  t,
  onSubmit,
  onSkip,
  loaded,
  skipable,
  cancelable,
  onCancel,
  onOpenHelper,
  user,
  info: { dpoName, dpoEmail, dpoPhone, processingPurpose, lawfulBasis, legalContactEmail, companyRegNumber, icoNumber },
}) => {
  const shouldCreate = !loaded || (loaded && dpoName.trim() == '')
  const { getFieldProps, handleSubmit, errors, touched, setFieldValue } = useFormik({
    initialValues: {
      dpoName,
      dpoEmail,
      dpoPhone,
      processingPurpose,
      lawfulBasis,
      legalContactEmail,
      companyRegNumber,
      icoNumber,
    },
    validationSchema: Yup.object().shape({
      dpoName: Yup.string().required(t('[dataprotectionform] dp name required')),
      dpoEmail: Yup.string()
        .required(t('[dataprotectionform] dp email required'))
        .email(t('[dataprotectionform] dp email must be valid')),
      processingPurpose: Yup.string()
        .required(t('[dataprotectionform] processing purpose required'))
        .oneOf(Object.values(ProcessingPurpose), t('[dataprotectionform] processing purpose required')),
      lawfulBasis: Yup.string()
        .required(t('[dataprotectionform] lawfull basis required'))
        .oneOf(Object.values(LawfulBasis), t('[dataprotectionform] lawfull basis required')),
      legalContactEmail: Yup.string()
        .required(t('[dataprotectionform] legal email required'))
        .email(t('[dataprotectionform] legal email valid')),
      companyRegNumber: Yup.string()
        .required(t('[dataprotectionform] company reg number required'))
        .matches(/(SC|NI|[0-9]{2})([0-9]{6}|[0-9]{4}(C|B))/gm, t('[dataprotectionform] company reg number valid')),
      icoNumber: Yup.string().required(t('[dataprotectionform] ico number required')),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      onSubmit(shouldCreate, values)
    },
  })

  const [iamdpo, setIamdpo] = useState(false)
  const [iamLegal, setIamLegal] = useState(false)

  return (
    <div className={styles.container}>
      <GBG.Form onSubmit={handleSubmit}>
        {shouldCreate ? (
          <GBG.FormGroup type={GBG.FormGroupType.Checkbox}>
            <GBG.Checkbox
              id="iAmDPO"
              onChange={(evt: React.FormEvent<HTMLInputElement>) => {
                setIamdpo(evt.currentTarget.checked)
                if (evt.currentTarget.checked) {
                  setFieldValue('dpoName', user.name)
                  setFieldValue('dpoEmail', user.email)
                }
              }}
            />
            <GBG.FormGroupInfo>
              <GBG.Label htmlFor="iAmDPO">
                <GBG.LabelText>{t('[dataprotectionform] i am dpo')}</GBG.LabelText>
              </GBG.Label>
            </GBG.FormGroupInfo>
          </GBG.FormGroup>
        ) : null}

        <GBG.FormGroup className={iamdpo ? styles.hidden : ''}>
          <GBG.Label>
            {t('[dataprotectionform] Name of data protection officer')} *
            <GBG.Icon
              onClick={() => {
                onOpenHelper(ModalView.DATA_PROTECTION_FORM_HELPER_DPO)
              }}
              icon={GBG.IconKeys.QuestionCircle16}
              className="text-b800"
            />
          </GBG.Label>
          <GBG.Assistive className={styles.blockLabel}>
            {t('[dataprotectionform] Name of data protection officer assistive')}{' '}
            <a
              className="link"
              onClick={() => {
                onOpenHelper(ModalView.DATA_PROTECTION_FORM_HELPER_DPO)
              }}
            >
              {t('[dataprotectionform] Name of data protection officer assistive2')}
            </a>
          </GBG.Assistive>
          <GBG.Text
            error={touched.dpoName && errors.dpoName}
            className={styles.shortField}
            {...getFieldProps('dpoName')}
          />
          {touched.dpoName && errors.dpoName && <GBG.ErrorText>{errors.dpoName}</GBG.ErrorText>}
        </GBG.FormGroup>
        <GBG.FormGroup className={iamdpo ? styles.hidden : ''}>
          <GBG.Label>{t('[dataprotectionform] Email of data protection officer')}</GBG.Label>
          <GBG.Text
            error={touched.dpoEmail && errors.dpoEmail}
            className={styles.shortField}
            type="email"
            {...getFieldProps('dpoEmail')}
          />
          {touched.dpoEmail && errors.dpoEmail && <GBG.ErrorText>{errors.dpoEmail}</GBG.ErrorText>}
        </GBG.FormGroup>
        <GBG.FormGroup>
          <GBG.Label>{t('[dataprotectionform] Phone of data protection officer')}</GBG.Label>
          <GBG.Text
            error={touched.dpoPhone && errors.dpoPhone}
            className={styles.shortField}
            type="tel"
            {...getFieldProps('dpoPhone')}
          />
          {touched.dpoPhone && errors.dpoPhone && <GBG.ErrorText>{errors.dpoPhone}</GBG.ErrorText>}
        </GBG.FormGroup>
        {
          ////////
        }
        <GBG.FormGroup>
          <GBG.Label>
            {t('[dataprotectionform] Purpose for processing')} *
            <GBG.Icon
              onClick={() => {
                onOpenHelper(ModalView.DATA_PROTECTION_FORM_HELPER_POP)
              }}
              icon={GBG.IconKeys.QuestionCircle16}
              className="text-b800"
            />
          </GBG.Label>
          <GBG.Select
            error={touched.processingPurpose && errors.processingPurpose}
            {...getFieldProps('processingPurpose')}
          >
            <option>{t('[dataprotectionform] Select a processing purpose')}:</option>
            {Object.values(ProcessingPurpose).map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </GBG.Select>
          {touched.processingPurpose && errors.processingPurpose && (
            <GBG.ErrorText>{errors.processingPurpose}</GBG.ErrorText>
          )}
        </GBG.FormGroup>
        <GBG.FormGroup>
          <GBG.Label>{t('[dataprotectionform] Lawful basis for processing data')} *</GBG.Label>
          <GBG.Assistive
            className={styles.blockLabel}
            dangerouslySetInnerHTML={{
              __html: t('[dataprotectionform] Lawful basis for processing data assistive', {
                interpolation: { escapeValue: false },
              }),
            }}
          ></GBG.Assistive>
          <GBG.Select error={touched.lawfulBasis && errors.lawfulBasis} {...getFieldProps('lawfulBasis')}>
            <option>{t('[dataprotectionform] Select a lawful basis')}:</option>
            {Object.values(LawfulBasis).map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </GBG.Select>

          {touched.lawfulBasis && errors.lawfulBasis && <GBG.ErrorText>{errors.lawfulBasis}</GBG.ErrorText>}
        </GBG.FormGroup>
        {
          ////////
        }
        {shouldCreate ? (
          <GBG.FormGroup type={GBG.FormGroupType.Checkbox}>
            <GBG.Checkbox
              id="iAmLegalContact"
              onChange={(evt: React.FormEvent<HTMLInputElement>) => {
                setIamLegal(evt.currentTarget.checked)
                if (evt.currentTarget.checked) {
                  setFieldValue('legalContactEmail', user.email)
                }
              }}
            />
            <GBG.FormGroupInfo>
              <GBG.Label htmlFor="iAmLegalContact">
                <GBG.LabelText>{t('[dataprotectionform] i am legal contact')}</GBG.LabelText>
              </GBG.Label>
            </GBG.FormGroupInfo>
          </GBG.FormGroup>
        ) : null}
        <GBG.FormGroup className={`${styles.shortField} ${iamLegal ? styles.hidden : ''}`}>
          <GBG.Label>{t('[dataprotectionform] Email of legal contact')} *</GBG.Label>
          <GBG.Text
            error={touched.legalContactEmail && errors.legalContactEmail}
            className={styles.shortField}
            type="email"
            {...getFieldProps('legalContactEmail')}
          />
          {touched.legalContactEmail && errors.legalContactEmail && (
            <GBG.ErrorText>{errors.legalContactEmail}</GBG.ErrorText>
          )}
        </GBG.FormGroup>
        <GBG.FormGroup className={styles.shortField}>
          <GBG.Label>{t('[dataprotectionform] Company registration number')} *</GBG.Label>
          <GBG.Assistive>{t('[dataprotectionform] company reg number assistive')}</GBG.Assistive>
          <GBG.Text
            error={touched.companyRegNumber && errors.companyRegNumber}
            className={styles.shortField}
            {...getFieldProps('companyRegNumber')}
          />
          {touched.companyRegNumber && errors.companyRegNumber && (
            <GBG.ErrorText>{errors.companyRegNumber}</GBG.ErrorText>
          )}
        </GBG.FormGroup>
        <GBG.FormGroup className={styles.shortField}>
          <GBG.Label>{t('[dataprotectionform] ICO registration number')} *</GBG.Label>
          <GBG.Assistive>{t('[dataprotectionform] ICO registration number assistive')}</GBG.Assistive>
          <GBG.Text
            error={touched.icoNumber && errors.icoNumber}
            className={styles.shortField}
            {...getFieldProps('icoNumber')}
          />
          {touched.icoNumber && errors.icoNumber && <GBG.ErrorText>{errors.icoNumber}</GBG.ErrorText>}
        </GBG.FormGroup>

        <GBG.FormGroup>
          {!skipable ? null : (
            <GBG.Button type="button" onClick={onSkip} className="m-m-r-2" kind={GBG.ButtonKind.Tertiary}>
              {t('[dataprotectionform] Skip button text')}
            </GBG.Button>
          )}
          {!cancelable ? null : (
            <GBG.Button type="button" onClick={onCancel} className="m-m-r-2" kind={GBG.ButtonKind.Secondary}>
              {t('[dataprotectionform] Cancel button text')}
            </GBG.Button>
          )}
          <GBG.Button type="submit">{t('[dataprotectionform] Submission button text')}</GBG.Button>
        </GBG.FormGroup>
      </GBG.Form>
    </div>
  )
}

export default DataProtectionForm
