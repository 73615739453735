import { Middleware } from 'redux'
import { has, prop } from 'ramda'

import { ModalView } from './modal.types'
import { setView } from './modal.reducer'

//---------------------------------
// set modal view
//---------------------------------

export const setModalViewFlow = (actionViewMap: { [action: string]: ModalView }): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)
  const { type, payload } = action
  if (has(type)(actionViewMap)) {
    dispatch(setView({ view: prop(type)(actionViewMap), payload }))
  }
}
