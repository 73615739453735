import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import DataProtectionForm from './DataProtectionForm.component'
import { dataProtectionSelector } from '../DataProtection'
import { DataProtectionInformation, DataProtectionState } from '../DataProtection/DataProtection.types'
import { Dispatch } from 'react'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { httpCreateDataProtection, httpUpdateDataProtection } from '../DataProtection/dataProtection.reducer'
import { setView } from '../../../modules/Modal/modal.reducer'
import { ModalView } from '../../../modules/Modal/modal.types'
import { userSelector } from '../../../modules/User'
import { User } from '../../../modules/User/user.types'

const mapStateToProps = createSelector(
  [dataProtectionSelector, userSelector],
  (dataProtectionInfo: DataProtectionState, user: User) => ({
    ...dataProtectionInfo,
    user,
  }),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: (create: boolean, data: DataProtectionInformation) => {
    if (create) {
      dispatch(httpCreateDataProtection(data))
    } else {
      dispatch(httpUpdateDataProtection(data))
    }
  },
  onOpenHelper: (view: ModalView) => {
    dispatch(setView({ view, payload: null }))
  },
})

export default compose(
  withTranslation('DataProtectionForm'),
  connect(mapStateToProps, mapDispatchToProps),
)(DataProtectionForm)
