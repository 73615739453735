import {
  pipe,
  compose,
  map,
  assoc,
  prop,
  replace,
  __,
  reduce,
  mergeRight,
  mapObjIndexed,
  pathOr,
  filter,
  propSatisfies,
  complement,
  isEmpty,
} from 'ramda'
import { DefinedError } from 'ajv'

import { ValidationErrorMap, ValidationError } from './AJVFormValidation.types'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const transformAjvValidationErrors = (errorMap: ValidationErrorMap) =>
  pipe(
    filter(propSatisfies(complement(isEmpty), 'dataPath')),
    map((data: DefinedError) => compose(assoc('key', __, data), replace(/^\//, ''), prop('dataPath'))(data)),
    map(({ key, keyword, message }: Pick<DefinedError, 'keyword' | 'message'> & { key: string }) => ({
      [key]: { type: keyword, message },
    })),
    reduce(mergeRight, {}),
    mapObjIndexed(({ type, message }: ValidationError, key: string) => ({
      type,
      message: pathOr(message, [key, type])(errorMap),
    })),
  )
