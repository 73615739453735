import { path } from 'ramda'
import { createSelector } from 'reselect'

export const accountBalanceSelector = createSelector(
  [path(['journeyCreditAccount', 'balance'])],
  (balance: number) => balance,
)

export const accountExpiresSelector = createSelector(
  [path(['journeyCreditAccount', 'expiresAt'])],
  (expiresAt: Date | null) => expiresAt,
)
