import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './SignupConfirmation.module.css'

interface Props {
  onNavigateToLogin: () => void
}

const SignupConfirmation: FC<Props & WithTranslation> = ({ t, onNavigateToLogin }) => (
  <div className={styles.container}>
    <h1 className={styles.title}>{t('[auth] signup confirmation title')}</h1>
    <p className={styles.explainer}>{t('[auth] signup confirmation email sent explainer')}</p>
    <h2 className={styles.emailTitle}>{t('[auth] signup confirmation email not received title')}</h2>
    <p className={styles.emailExplainer}>{t('[auth] signup confirmation email not received explainer')}</p>
    <ul className={styles.emailSteps}>
      <li>{t('[auth] signup confirmation email not received reason 1')}</li>
      <li>{t('[auth] signup confirmation email not received reason 2')}</li>
      <li>{t('[auth] signup confirmation email not received reason 3')}</li>
    </ul>
    <button className={styles.activationButton} onClick={() => onNavigateToLogin()}>
      {t('[auth] login submit label')}
    </button>
  </div>
)

export default SignupConfirmation
