import React, { FC, useState } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { addIndex, map } from 'ramda'
import { WithTranslation } from 'react-i18next'
import Dinero from 'dinero.js'

import styles from './PaymentHistory.module.css'
import { Currency, PaymentHistoryState } from '../Payment/payment.types'
import { formatStripeDate } from '../../common/utils/dateTime'

interface Props {
  paymentHistory: PaymentHistoryState
}

const PaymentHistory: FC<Props & WithTranslation> = ({ t, paymentHistory }) => {
  const hasPaymentHistory = paymentHistory ? paymentHistory.length > 0 : false
  const [viewAllPaymentHistory, setViewAllPaymentHistory] = useState(false)
  const getPaymentHistory = (_paymentHistory: PaymentHistoryState) => {
    if (!paymentHistory) {
      return []
    }
    return viewAllPaymentHistory ? _paymentHistory : _paymentHistory.slice(0, 6)
  }
  const renderMobileHistoryDataView = (
    {
      stripeInvoicePaidAt,
      stripeProductName,
      stripeInvoiceUrl,
      stripeInvoiceNumber,
      stripeInvoiceStatus,
      stripeInvoiceAmountDue,
      stripeInvoiceCurrency,
    }: {
      stripeInvoicePaidAt: number
      stripeProductName: string
      stripeInvoiceUrl: string
      stripeInvoiceNumber: string
      stripeInvoiceStatus: string
      stripeInvoiceAmountDue: number
      stripeInvoiceCurrency: Currency
    },
    i: number,
  ) => {
    const amount = stripeInvoiceAmountDue
    const currency = stripeInvoiceCurrency
    return (
      <GBG.TableRow key={i}>
        <GBG.TableCell>
          <GBG.Assistive>{formatStripeDate(stripeInvoicePaidAt)}</GBG.Assistive>
          {stripeProductName}
        </GBG.TableCell>
        <GBG.TableCell>
          <a href={stripeInvoiceUrl} target="_blank" rel="noopener noreferrer" className={styles.historyTableLink}>
            {stripeInvoiceNumber}
          </a>
        </GBG.TableCell>
        <GBG.TableCell className={styles.invoiceStatus}>{stripeInvoiceStatus}</GBG.TableCell>
        <GBG.TableCell>{Dinero({ amount, currency }).toFormat()}</GBG.TableCell>
      </GBG.TableRow>
    )
  }
  const renderDesktopHistoryDataView = (
    {
      stripeInvoicePaidAt,
      stripeProductName,
      stripeInvoiceNumber,
      stripeInvoiceStatus,
      stripeInvoiceAmountDue,
      stripeInvoiceCurrency,
      stripeInvoiceUrl,
    }: {
      stripeInvoicePaidAt: number
      stripeProductName: string
      stripeInvoiceNumber: string
      stripeInvoiceStatus: string
      stripeInvoiceAmountDue: number
      stripeInvoiceCurrency: Currency
      stripeInvoiceUrl: string
    },
    i: number,
  ) => {
    const amount = stripeInvoiceAmountDue
    const currency = stripeInvoiceCurrency
    const actionText = t('[payment history] view pdf text')
    return (
      <GBG.TableRow key={i}>
        <GBG.TableCell>{formatStripeDate(stripeInvoicePaidAt)}</GBG.TableCell>
        <GBG.TableCell>{stripeProductName}</GBG.TableCell>
        <GBG.TableCell>
          <a className={styles.historyTableLink} href={stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
            {stripeInvoiceNumber}
          </a>
        </GBG.TableCell>
        <GBG.TableCell className={styles.invoiceStatus}>{stripeInvoiceStatus}</GBG.TableCell>
        <GBG.TableCell>{Dinero({ amount, currency }).toFormat()}</GBG.TableCell>
        <GBG.TableCell>
          <a className={styles.historyTableLink} href={stripeInvoiceUrl} target="_blank" rel="noopener noreferrer">
            {actionText}
          </a>
        </GBG.TableCell>
        <GBG.TableCell>&nbsp;</GBG.TableCell>
        <GBG.TableCell>&nbsp;</GBG.TableCell>
      </GBG.TableRow>
    )
  }
  const mobilePaymentHistoryView = (
    <GBG.Table>
      <GBG.TableBody className={styles.tableBody}>
        {addIndex(map)(renderMobileHistoryDataView)(getPaymentHistory(paymentHistory))}
      </GBG.TableBody>
    </GBG.Table>
  )
  const desktopPaymentHistoryView = (
    <GBG.Table>
      {
        <GBG.TableHead className={styles.tableHead}>
          <GBG.TableRow isHeader={true}>
            <GBG.TableHeaderCell>{t('[payment history] date title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>{t('[payment history] type title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>{t('[payment history] invoice title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>{t('[payment history] status title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>{t('[payment history] amount title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>{t('[payment history] action title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>&nbsp;</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>&nbsp;</GBG.TableHeaderCell>
          </GBG.TableRow>
        </GBG.TableHead>
      }
      <GBG.TableBody className={styles.tableBody}>
        {addIndex(map)(renderDesktopHistoryDataView)(getPaymentHistory(paymentHistory))}
      </GBG.TableBody>
    </GBG.Table>
  )
  const noPaymentHistoryView = (
    <div className={styles.billingHistoryPlaceholder}>{t('[payment] billing history placeholder label')}</div>
  )
  return (
    <>
      {hasPaymentHistory ? (
        <>
          <div className={styles.mobileBillingHistoryWrapper}>{mobilePaymentHistoryView}</div>
          <div className={styles.desktopBillingHistoryWrapper}>{desktopPaymentHistoryView}</div>
          {paymentHistory.length > 5 ? (
            <GBG.Button
              className={styles.viewAllButton}
              kind={GBG.ButtonKind.Tertiary}
              onClick={() => setViewAllPaymentHistory(!viewAllPaymentHistory)}
            >
              {viewAllPaymentHistory
                ? t('[payment history] hide payment history button text')
                : t('[payment history] view all payment history button text')}
            </GBG.Button>
          ) : null}
        </>
      ) : (
        noPaymentHistoryView
      )}
    </>
  )
}

export default PaymentHistory
