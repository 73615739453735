import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'ramda'

import Users, { Props } from './Users.component'
import { userInvitesSelector } from './UserInvites.selectors'
import { usersSelector } from './Users.selectors'
import { httpCreateUserInvite, httpDeleteUserInvite } from './Users.reducer'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { AppState } from '../../redux/reducers'

//const mapStateToProps = userInvitesSelector as any
const mapStateToProps: any = (state: AppState) => {
  const { userInvites } = userInvitesSelector(state)
  const { users } = usersSelector(state)
  return {
    userInvites,
    users,
  }
}
// const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({})
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onCreate: (email: string) => {
    dispatch(httpCreateUserInvite(email))
  },
  onDeleteInvite: (id: number) => {
    dispatch(httpDeleteUserInvite(id))
  },
})

export default compose(withTranslation('Users'), connect<Props>(mapStateToProps, mapDispatchToProps))(Users)
