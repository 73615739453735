import React, { FC, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { compose, assoc, path, __, tap, ifElse, prop, has } from 'ramda'

import { useFormValidation } from '../../common/modules/AJVFormValidation'
import formValidationSchema from './schemas/onboard-schema.json'
import styles from './OnboardForm.module.css'
import { BusinessDetails } from './onboard.types'

interface Props {
  onSubmit: (formData: BusinessDetails) => void
  onInvalidSubmit: () => void
  onNavigate: () => void
  name: string
  companyType: string
  companyName: string
  address: string
  vatNumber: string
}

const OnboardForm: FC<Props & WithTranslation> = ({
  t,
  onSubmit,
  onInvalidSubmit,
  onNavigate,
  name,
  companyType,
  companyName,
  address,
  vatNumber,
}) => {
  const validationErrorMap = {
    vatNumber: {
      minLength: 'VAT number is required', // TODO: translate
    },
    address: {
      minLength: 'address is required', // TODO: translate
    },
    companyType: {
      enum: 'company type is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [formData, setFormData] = useState({
    vatNumber,
    address,
    companyType,
  })
  const onChangeInternal = (key: string) => (event: any) => {
    compose(tap(setFormData), assoc(key, __, formData), path(['target', 'value']))(event)
  }
  const onBlurInternal = () => {
    validate(formData)
  }
  const onSubmitInternal = () => {
    ifElse(
      validate,
      tap(onSubmit),
      tap(() => onInvalidSubmit()),
    )(formData)
  }

  return (
    <div className={styles.container}>
      <div className={styles.onboardForm}>
        <h1 className={styles.onboardTitle}>
          {t('[onboard] title')} {name}
        </h1>
        <p className={styles.onboardExplainer}>{t('[onboard] explainer notification')}</p>
        <p className={styles.onboardExplainer}>
          {t('[onboard] explainer form 1')} <strong>{companyName}</strong> {t('[onboard] explainer form 2')}
        </p>

        <label className={styles.onboardInputLabel} htmlFor="business-type-input">
          {t('[onboard] business type input label')}
        </label>
        <div className={styles.onboardSelectWrapper}>
          <select
            className={styles.onboardSelect}
            onChange={onChangeInternal('companyType')}
            onBlur={onBlurInternal}
            value={prop('companyType')(formData)}
            aria-label="business-type-select"
          >
            <option value="" disabled>
              {t('[onboard] business type prompt')}
            </option>
            <option value="pty-ltd">{t('[onboard] business type option 1')}</option>
            <option value="partnership">{t('[onboard] business type option 2')}</option>
            <option value="sole-trader">{t('[onboard] business type option 3')}</option>
            <option value="llp">{t('[onboard] business type option 4')}</option>
          </select>
          {!has('companyType', validationErrors) ? null : (
            <p className="formValidationMessage">{path(['companyType', 'message'])(validationErrors)}</p>
          )}
        </div>

        <label htmlFor="address-input">{t('[onboard] business address label')}</label>
        <p className={styles.onboardInputExplainer}>{t('[onboard] business address explainer')}</p>
        {/* TODO: why no placeholder ? */}
        <textarea
          className={styles.onboardTextArea}
          onChange={onChangeInternal('address')}
          onBlur={onBlurInternal}
          value={prop('address')(formData)}
          aria-label="address-input"
        />
        <p className={styles.onboardAddressPrompt}>{t('[onboard] business address prompt')}</p>
        {!has('address', validationErrors) ? null : (
          <p className="formValidationMessage">{path(['address', 'message'])(validationErrors)}</p>
        )}

        <label htmlFor="vat-number-input">{t('[onboard] business vat number label')}</label>
        <p className={styles.onboardInputExplainer}>{t('[onboard] business vat number explainer')}</p>
        {/* TODO: translate placeholder */}
        <input
          type="text"
          onChange={onChangeInternal('vatNumber')}
          onBlur={onBlurInternal}
          value={prop('vatNumber')(formData)}
          placeholder="GB 123456789"
          aria-label="vat-number-input"
        />
        {!has('vatNumber', validationErrors) ? null : (
          <p className="formValidationMessage">{path(['vatNumber', 'message'])(validationErrors)}</p>
        )}

        <div className={styles.onboardButtonsWrapper}>
          <button
            className={styles.onboardContinueButton}
            onClick={() => onSubmitInternal()}
            aria-label="submit-button"
          >
            {t('[onboard] continue button')}
          </button>
          <button className={styles.onboardLaterButton} onClick={() => onNavigate()} aria-label="not-now-button">
            {t('[onboard] later button')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default OnboardForm
