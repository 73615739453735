import React, { FC, useRef, useEffect } from 'react'
import { select, scaleLinear } from 'd3'

import styles from './JourneyIntegrationChart.module.css'
import { ChartData } from './journeyIntegrationChart.types'

export interface Props {
  data: ChartData[]
}

const SVG_HEIGHT = 90
const SVG_WIDTH = 300
const BAR_HEIGHT = 40
const PADDING = 10
const MAX_RESULTS = 200

const JourneyIntegrationChart: FC<Props> = ({ data }) => {
  const svgRef = useRef()
  const svgBarsGroupRef = useRef() as any
  const svgTextGroup1Ref = useRef() as any
  const svgTextGroup2Ref = useRef() as any

  useEffect(() => {
    const x = scaleLinear().domain([0, MAX_RESULTS]).range([0, SVG_WIDTH])

    const barsGroup = select(svgBarsGroupRef.current)
    const textGroup1 = select(svgTextGroup1Ref.current)
    const textGroup2 = select(svgTextGroup2Ref.current)

    barsGroup
      .selectAll('rect')
      .data(data)
      .join('rect')
      .attr('class', ({ key }) => styles[key])
      .attr('x', x(0))
      .attr('y', (d: any, i: any) => BAR_HEIGHT * i + PADDING * i)
      .attr('width', (d: any) => x(d.value as any) - x(0))
      .attr('height', BAR_HEIGHT)

    textGroup1
      .attr('class', styles.labelText)
      .selectAll('text')
      .data(data)
      .join('text')
      .attr('x', (d: any) => x(d.value as any))
      .attr('y', (d: any, i: any) => BAR_HEIGHT * i + PADDING * i + BAR_HEIGHT / 2)
      .attr('dy', '0.35em')
      .attr('dx', 5)
      .text((d: any) => d.name)
      .call(text =>
        text
          .filter(d => x(d.value as any) - x(0) < 50) // short bars
          .attr('dx', 35),
      )

    textGroup2
      .attr('class', styles.valueText)
      .selectAll('text')
      .data(data)
      .join('text')
      .attr('class', styles.valueTextInside)
      .attr('x', (d: any) => x(d.value as any))
      .attr('y', (d: any, i: any) => BAR_HEIGHT * i + PADDING * i + BAR_HEIGHT / 2)
      .attr('dy', '0.35em')
      .attr('dx', -8)
      .text((d: any) => d.value)
      .call(text =>
        text
          .filter((d: any) => (x(d.value) as any) - x(0) < 50) // short bars
          .attr('class', styles.valueTextOutside)
          .attr('dx', 5),
      )
  }, [data])

  return (
    <div className={styles.chart}>
      <div className={styles.container}>
        <svg
          width="100%"
          height={SVG_HEIGHT}
          viewBox={`0 0 ${SVG_WIDTH} ${SVG_HEIGHT}`}
          ref={svgRef as any}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g ref={svgBarsGroupRef as any} />
          <g ref={svgTextGroup1Ref as any} />
          <g ref={svgTextGroup2Ref as any} />
        </svg>
      </div>
    </div>
  )
}

export default JourneyIntegrationChart
