export interface DataProtectionInformation {
  dpoName: string
  dpoEmail: string
  dpoPhone: string
  processingPurpose?: ProcessingPurpose
  lawfulBasis?: LawfulBasis
  legalContactEmail: string
  companyRegNumber: string
  icoNumber: string
}

export interface DataProtectionState {
  loaded: boolean
  info: DataProtectionInformation
}

export enum ProcessingPurpose {
  RegulatoryRequirement = 'Regulatory Requirement',
  FraudPrevention = 'Fraud Prevention',
}

export enum LawfulBasis {
  LegitimateInterest = 'Legitimate Interest',
  Consent = 'Consent',
  ComplianceWithLegalObligation = 'Compliance with Legal Obligation',
  ComplianceWithAContract = 'Compliance with a Contract',
  PublicInterest = 'Public Interest',
  VitalInterest = 'Vital Interest',
}
