import { Middleware } from 'redux'
import { includes } from 'ramda'

import { httpGetFeatureFlags } from './featureFlags.reducer'

export const getFeatureFlagsFlow = (actions: string[]): Middleware => {
  let hasInitialized = false
  return ({ dispatch }) => next => action => {
    next(action)
    if (hasInitialized) {
      return
    }
    const { type } = action
    if (includes(type)(actions)) {
      hasInitialized = true
      dispatch(httpGetFeatureFlags())
      return
    }
  }
}
