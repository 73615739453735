import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { addIndex, isNil, map } from 'ramda'
import Dinero from 'dinero.js'

import styles from './OrderSummary.module.css'
import { Currency } from '../../payment.types'

export interface OrderSummaryItem {
  renewalDate: string
  amount: number
}

interface Props {
  items: OrderSummaryItem[]
  totalAmount: number
  currency: Currency
}

const OrderSummary: FC<WithTranslation & Props> = ({ t, items, totalAmount, currency }) => {
  const renderItemView = ({ renewalDate, amount }: { renewalDate: string; amount: number }, i: number) => (
    <GBG.TableRow key={i}>
      <GBG.TableCell>
        {t('[order summary] item name')}{' '}
        <GBG.Assistive>
          {t('[order summary] item renews')} {renewalDate}
        </GBG.Assistive>
      </GBG.TableCell>
      <GBG.TableCell />
      <GBG.TableCell>{Dinero({ amount, currency }).toFormat()}</GBG.TableCell>
    </GBG.TableRow>
  )

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('[order summary] title')}</h3>
      <GBG.Table>
        <GBG.TableHead className={styles.tableHead}>
          <GBG.TableRow isHeader={true}>
            <GBG.TableHeaderCell className={styles.headerTitle}>{t('[order summary] item title')}</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>&nbsp;</GBG.TableHeaderCell>
            <GBG.TableHeaderCell className={styles.headerTitle}>{t('[order summary] price title')}</GBG.TableHeaderCell>
          </GBG.TableRow>
        </GBG.TableHead>
        <GBG.TableBody className={styles.tableBody}>{addIndex(map)(renderItemView)(items)}</GBG.TableBody>
        <GBG.TableFoot className={styles.tableFoot}>
          <GBG.TableRow>
            <GBG.TableCell style={{ border: 'none' }}>&nbsp;</GBG.TableCell>
            <GBG.TableCell>{t('[order summary] vat title')}</GBG.TableCell>
            <GBG.TableCell>
              {isNil(currency) ? null : Dinero({ amount: totalAmount, currency }).percentage(20).toFormat()}
            </GBG.TableCell>
          </GBG.TableRow>
          <GBG.TableRow>
            <GBG.TableCell style={{ border: 'none' }}>&nbsp;</GBG.TableCell>
            <GBG.TableCell>{t('[order summary] total title')}</GBG.TableCell>
            <GBG.TableCell>
              {isNil(currency) ? null : Dinero({ amount: totalAmount * 1.2, currency }).toFormat()}
            </GBG.TableCell>
          </GBG.TableRow>
        </GBG.TableFoot>
      </GBG.Table>
    </div>
  )
}

export default OrderSummary
