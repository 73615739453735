import { LOCATION_CHANGE } from 'connected-react-router'
import { Middleware } from 'redux'

import { resetAlert } from './alert.reducer'

//---------------------------------
// reset alert
//---------------------------------

export const resetAlertFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === LOCATION_CHANGE) {
    dispatch(resetAlert())
  }
}
