import { compose, prepend, prop, pickAll, mergeDeepRight } from 'ramda'
import { CompletionAction, CompletionActionData, CompletionActionType } from './journeyIntegration.types'

export const prepareCompletionAction = (completionAction: CompletionActionData): CompletionAction => {
  const { type } = completionAction
  const fieldMap = {
    [CompletionActionType.ResultRedirect]: ['redirectUrl'],
    [CompletionActionType.CompletionMessage]: [
      'successTitle',
      'successText',
      'failureTitle',
      'failureText',
      'includeIcon',
    ],
    [CompletionActionType.CompletionMessageCTA]: [
      'redirectUrl',
      'ctaText',
      'successTitle',
      'successText',
      'failureTitle',
      'failureText',
      'includeIcon',
    ],
  }
  const typeFields = compose(prepend('type'), prop(type))(fieldMap)
  return pickAll(typeFields)(completionAction)
}

export const prepareCompletionActionForUpdate = (completionAction: CompletionActionData): CompletionAction => {
  const data = {
    type: null,
    redirectUrl: null,
    ctaText: null,
    successTitle: null,
    successText: null,
    failureTitle: null,
    failureText: null,
    includeIcon: null,
  }
  return mergeDeepRight(data, prepareCompletionAction(completionAction))
}
