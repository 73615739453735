import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './JourneyIntegrationLinkCreate.module.css'
import { ModalFooter } from '../../Modal'

export interface Props {
  isSubmitting: boolean
  balance: number
  expires: Date | null
  isMultiLinkCreation: boolean
  onCancel?: () => void
  onSubmit?: (value: string[]) => void
}

const noop = () => null

const JourneyIntegrationLinkCreate: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  onCancel = noop,
  onSubmit = noop,
  balance,
  isMultiLinkCreation,
}) => {
  const [value, setValue] = useState<string[]>([])
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onSubmitInternal = (value: string[]) => {
    setInternalIsSubmitting(true)
    onSubmit(value.length ? value : [''])
  }
  const linkCount = value.length ? value.length : 1
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar type={GBG.AlertBarType.Info} icon={GBG.IconKeys.Link24}>
          <GBG.AlertBarText
            title={t('[create journey integration link] title', { count: isMultiLinkCreation ? 2 : 1 })}
            text={t('[create journey integration link] description', { count: isMultiLinkCreation ? 2 : 1 })}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <div aria-label="body-text" className={styles.content}>
          <GBG.FormGroup>
            <GBG.Label>
              {t('[create journey integration link] customer id', { count: isMultiLinkCreation ? 2 : 1 })}
            </GBG.Label>
            <GBG.Assistive>
              {t('[create journey integration link] customer id description', { count: isMultiLinkCreation ? 2 : 1 })}
            </GBG.Assistive>
            {isMultiLinkCreation ? (
              <GBG.Textarea disabled={isSubmitting} onChange={(e: any) => setValue(e.target.value.split('\n'))} />
            ) : (
              <GBG.Text disabled={isSubmitting} onChange={(e: any) => setValue([e.target.value])}></GBG.Text>
            )}

            {linkCount > 1 && isMultiLinkCreation ? (
              <GBG.Assistive
                className={styles.linkCount}
                dangerouslySetInnerHTML={{
                  __html: t('[create journey integration link] links will be created', {
                    interpolation: { escapeValue: false },
                    linkCount,
                  }),
                }}
              ></GBG.Assistive>
            ) : null}
          </GBG.FormGroup>
          {linkCount > balance ? (
            <GBG.InlineNotification
              dismissable={false}
              icon={GBG.IconKeys.AlertTriangle16}
              type={GBG.InlineNotificationType.Warn}
              title={t('[create journey integration link] balance warning title')}
            >
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: t('[create journey integration link] balance warning', {
                    interpolation: { escapeValue: false },
                    balance,
                    linkCount,
                  }),
                }}
              ></p>
            </GBG.InlineNotification>
          ) : null}
          <ModalFooter
            isSubmitting={internalIsSubmitting}
            cancelLabel={t('[create journey integration link] cancel')}
            submitLabel={t('[create journey integration link] submit', { count: linkCount })}
            onCancel={onCancel}
            onSubmit={() => {
              onSubmitInternal(value)
            }}
            noSubmit={linkCount > balance}
          />
        </div>
        {linkCount <= balance ? (
          <div className={styles.info}>
            <GBG.Icon className={styles.infoIcon} icon={GBG.IconKeys.InfoCircle16} />
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                __html: t('[create journey integration link] balance message', {
                  interpolation: { escapeValue: false },
                  count: linkCount,
                  balance: balance,
                  postBalance: balance - linkCount,
                }),
              }}
            ></p>
          </div>
        ) : null}
      </GBG.ModalBody>
    </>
  )
}
export default JourneyIntegrationLinkCreate
