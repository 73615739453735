import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import PersonalisationForm from './PersonalisationForm.component'
import { updatePersonalisationFormSelector } from '../../journeyIntegration.selectors'
import { setPersonalisation, updateJourneyIntegration } from '../../journeyIntegration.reducer'

const mapStateToProps = updatePersonalisationFormSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onUpdate: (logo: string) => {
    dispatch(setPersonalisation({ logo }))
    dispatch(updateJourneyIntegration())
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect(mapStateToProps, mapDispatchToProps),
)(PersonalisationForm)
