import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'
import { navigateTo } from '../../../common/modules/Routing'
import JourneyCreditAccountBalance from './JourneyCreditAccountBalance.component'
import { accountBalanceSelector, accountExpiresSelector } from './journeyCreditAccount.selectors'
import { createSelector } from 'reselect'

//const mapStateToProps = compose(objOf('accountBalance'), accountBalanceSelector)
const mapStateToProps = createSelector(
  [accountBalanceSelector, accountExpiresSelector],
  (accountBalance, expiryDate) => ({
    accountBalance,
    expiryDate,
  }),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateTo: (path: string) => dispatch(navigateTo(path)),
})

export default compose(
  withTranslation('JourneyCreditAccount'),
  connect(mapStateToProps, mapDispatchToProps),
)(JourneyCreditAccountBalance)
