import { Dispatch } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import { compose, prop, replace } from 'ramda'

import Header, { Props } from './Header.component'
import { currentRouteSelector } from '../../../../common/modules/Routing'
import { selectedJourneyIntegrationSelector } from '../../journeyIntegration.selectors'
import { selectedJourneyIntegrationNameSelector } from '../../journeyIntegration.selectors'
import {
  updateJourneyIntegrationNavigateTo,
  activateJourneyIntegration,
  deactivateJourneyIntegration,
} from '../../journeyIntegration.reducer'
import { createJourneyIntegrationLink } from '../../../../common/modules/JourneyIntegrationLink'

// TODO: extract to dedicated selector and test
const mapStateToProps = createSelector(
  [currentRouteSelector, selectedJourneyIntegrationSelector, selectedJourneyIntegrationNameSelector],
  ({ currentRoutePathname }, { selectedJourneyIntegration }, { name }) => ({
    currentRoutePathname,
    selectedJourneyIntegrationId: prop('id')(selectedJourneyIntegration),
    isActive: prop('isActive')(selectedJourneyIntegration),
    selectedJourneyIntegrationName: name,
  }),
) as any

// TODO: test through UI test
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateTo: (id: number, path: string) => {
    const pathname = replace(/:id/, id)(path)
    dispatch(updateJourneyIntegrationNavigateTo(pathname))
  },
  onActivate: (id: number) => {
    dispatch(activateJourneyIntegration(id))
  },
  onDeactivate: (id: number) => {
    dispatch(deactivateJourneyIntegration(id))
  },
  onCreateLink: (id: number) => {
    dispatch(createJourneyIntegrationLink(id))
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(Header)
