import { createAction, createReducer } from '@reduxjs/toolkit'

import { HTTPPayload } from '../../common/redux-http'
import { ActionWithPayload } from '../../common/redux/types'
import { BusinessDetails } from '../OnboardForm/onboard.types'
import { Organisation } from './organisation.types'

export const HTTP_GET_ORGANISATION = '[organisation] HTTP get organisation'
export const httpGetOrganisation = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_ORGANISATION)({ endpoint: 'organisations' })
export const HTTP_GET_ORGANISATION_SUCCESS = '[organisation] HTTP get organisation success'
export const httpGetOrganisationSuccess = createAction(HTTP_GET_ORGANISATION_SUCCESS)
export const HTTP_GET_ORGANISATION_FAILURE = '[organisation] HTTP get organisation failure'
export const httpGetOrganisationFailure = createAction(HTTP_GET_ORGANISATION_FAILURE)
export const UPDATE_ORGANISATION = '[organisation] update organisation'
export const updateOrganisation = (businessDetails: BusinessDetails): ActionWithPayload<any> => ({
  type: UPDATE_ORGANISATION,
  payload: { data: businessDetails },
})

export const HTTP_UPDATE_ORGANISATION = '[organisation] HTTP update organisation'
export const httpUpdateOrganisation = (
  data: {
    companyType?: string
    vatNumber?: string
    address?: string
    stripeSubscriptionCurrentPeriodEnd?: number
    autoRenew?: boolean
    subscriptionCancelled?: boolean
  },
  organisationId: number,
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_ORGANISATION)({ endpoint: `organisations/${organisationId}`, data })
export const HTTP_UPDATE_ORGANISATION_SUCCESS = '[organisation] HTTP update organisation success'
export const httpUpdateOrganisationSuccess = createAction(HTTP_UPDATE_ORGANISATION_SUCCESS)

export const HTTP_UPDATE_SUBSCRIPTION = '[organisation] HTTP update subscription'
export const httpUpdateSubscription = (data: { cancelSubscription?: boolean }): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_SUBSCRIPTION)({ endpoint: 'subscriptions', data })
export const HTTP_UPDATE_SUBSCRIPTION_SUCCESS = '[organisation] HTTP update subscription success'
export const httpUpdateSubscriptionSuccess = createAction(HTTP_UPDATE_ORGANISATION_SUCCESS)

export const SET_ORGANISATION = '[organisation] set organisation'
export const setOrganisation = createAction<Organisation>(SET_ORGANISATION)

export const CANCEL_AUTO_RENEW = '[organisation] cancel auto renew'
export const cancelAutoRenew = createAction(CANCEL_AUTO_RENEW)

export const CONFIRM_CANCEL_AUTO_RENEW = '[organisation] confirm cancel auto renew'
export const confirmCancelAutoRenew = createAction(CONFIRM_CANCEL_AUTO_RENEW)

export const ENABLE_AUTO_RENEW = '[organisation] enable auto renew'
export const enableAutoRenew = createAction(ENABLE_AUTO_RENEW)

export const CANCEL_SUBSCRIPTION = '[organisation] cancel subscription'
export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION)

export const CONFIRM_CANCEL_SUBSCRIPTION = '[organisation] confirm cancel subscription'
export const confirmCancelSubscription = createAction(CONFIRM_CANCEL_SUBSCRIPTION)

//---------------------------------
// reducers
//---------------------------------

const overwriteOrganisation = (state: Organisation, { payload }: ActionWithPayload<Organisation>) => payload

export const INITIAL_STATE = {
  id: null,
  email: '',
  name: '',
  address: '',
  companyType: '',
  vatNumber: '',
  stripeSubscriptionCurrentPeriodEnd: null,
  autoRenew: true,
  subscriptionCancelled: false,
}

const reducer = createReducer<Organisation>(INITIAL_STATE, {
  [SET_ORGANISATION]: overwriteOrganisation,
})
export default reducer
