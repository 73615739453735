import React, { FC } from 'react'
import { prop } from 'ramda'

import styles from './UpdatePayment.module.css'
import { UpdatePaymentView } from '../payment.constants'
import UpdatePaymentSubscription from '../UpdatePaymentSubscription/UpdatePaymentSubscription.container'
import UpdatePaymentCheckout from '../UpdatePaymentCheckout/UpdatePaymentCheckout.container'
import UpdatePaymentSuccess from '../UpdatePaymentSuccess/UpdatePaymentSuccess.container'

interface Props {
  viewName: string
}

const UpdatePayment: FC<Props> = ({ viewName }) => {
  const viewMap = {
    [UpdatePaymentView.SUBSCRIPTION]: <UpdatePaymentSubscription />,
    [UpdatePaymentView.CHECKOUT]: <UpdatePaymentCheckout />,
    [UpdatePaymentView.SUCCESS]: <UpdatePaymentSuccess />,
  }
  const view = prop(viewName)(viewMap)

  return (
    <div className={styles.container}>
      <main className={styles.body}>
        <div className={styles.right}>
          <div className={styles.content}>{view}</div>
        </div>
      </main>
    </div>
  )
}

export default UpdatePayment
