import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { RouteProps } from 'react-router'

interface Props extends RouteProps {
  isAuthenticated: boolean
}

const ProtectedRoute: FC<Props> = ({ isAuthenticated, children, ...props }) =>
  isAuthenticated ? <Route {...props}>{children}</Route> : null

export default ProtectedRoute
