import classnames from 'classnames'
import { map, compose, propOr, prop } from 'ramda'
import React, { FC, useRef, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

import { CARD_TYPE_LABELS } from '../PaymentCard/constants'
import { cardBrandFallbackIcon, cardBrandIcons } from '../PaymentCard/PaymentCard.component'
import { useDetectOutsideClick } from '../UseDetectOutsideClick/UseDetectOutsideClick.component'
import { Position } from './constants'
import { OptionType } from './DropdownMenu.types'

import styles from './DropdownMenuOptions.module.css'

interface Props {
  options: OptionType[]
  position?: Position
  onSelect: (stripePaymentMethodId: string) => void
}

export const DropdownMenuOptions: FC<Props & WithTranslation> = ({ t, options, onSelect }) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const [menuIsActive, setMenuIsActive] = useDetectOutsideClick(dropdownRef, false) as any
  const [itemSelected, setItemSelected] = useState('')

  const internalClickEvent = (_stripePaymentMethodId: string) => {
    setItemSelected(_stripePaymentMethodId)
    setMenuIsActive(!menuIsActive)
  }

  const chevron = classnames({
    [styles.upChevron]: menuIsActive,
    [styles.downChevron]: !menuIsActive,
  })

  const renderLinkOptions = (onSelect: (stripePaymentMethodId: string) => void) => ({
    stripePaymentMethodId,
    cardBrand,
    cardLast4,
    billingName,
    expiryDate,
  }: any) => (
    <li
      className={itemSelected === stripePaymentMethodId ? styles.menuItemActive : styles.menuItem}
      key={stripePaymentMethodId}
      onClick={() => {
        onSelect(stripePaymentMethodId)
        internalClickEvent(stripePaymentMethodId)
      }}
    >
      <div className={styles.menuItemWrapper}>
        {propOr(cardBrandFallbackIcon, cardBrand)(cardBrandIcons)}
        <div className={styles.cardContent}>
          <p className={styles.header}>
            {' '}
            {prop(cardBrand)(CARD_TYPE_LABELS)} {t('[payment card] ending text')} {cardLast4}
          </p>
          <p className={styles.explainer}>
            {billingName} <span>&#183;</span> {t('[payment card] expires text')} {expiryDate}
          </p>
        </div>
      </div>
    </li>
  )

  return (
    <div className={`${styles.menuContainer} `}>
      <button className={styles.chevronWrapper} onClick={(): void => setMenuIsActive(!menuIsActive)}>
        <i className={chevron}></i>
      </button>
      {menuIsActive ? (
        <nav ref={dropdownRef} className={`${styles.menu}`}>
          <ul className={styles.menuItemsList}>{map(renderLinkOptions(onSelect))(options)}</ul>
        </nav>
      ) : null}
    </div>
  )
}

export default compose(withTranslation('Payment'))(DropdownMenuOptions)
