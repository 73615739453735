import { createSelector } from 'reselect'

import {
  selectedJourneyIntegrationNameSelector,
  selectedJourneyIntegrationIdSelector,
} from '../../../JourneyIntegration/journeyIntegration.selectors'
import { compose, map, mergeDeepRight, pathOr, pick, prop, reduce } from 'ramda'
import { JourneyType } from '../../../../common/modules/JourneyTypes/journeyTypes.types'
import { FormSubmissionStatus } from '../../../../common/form'

export const updateJourneyIntegrationSubmissionStatusSelector = createSelector(
  [pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'update-journey-integration', 'submissionStatus'])],
  (formSubmissionStatus: string) => ({
    isSubmitting: formSubmissionStatus === FormSubmissionStatus.SUBMITTING,
  }),
) as any

export const selectedJourneyIntegrationDetailsSelector = createSelector(
  [prop('journeyTypes'), selectedJourneyIntegrationIdSelector, selectedJourneyIntegrationNameSelector],
  (journeyTypes: JourneyType[], selectedJourneyIntegration, { name: selectedJourneyIntegrationName }) => {
    const getStepsForJourneyIntegration = (journeyTypeId: number) =>
      compose(map(pick(['id', 'name'])), pathOr([], [journeyTypeId, 'journeyTypeSteps']))(journeyTypes)

    return {
      name: selectedJourneyIntegrationName,
      steps: getStepsForJourneyIntegration(prop('selectedJourneyIntegration')(selectedJourneyIntegration)),
    }
  },
)

export const updateDetailsFormSelector = createSelector(
  [selectedJourneyIntegrationDetailsSelector, updateJourneyIntegrationSubmissionStatusSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)
