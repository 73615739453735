import { createAction, createReducer } from '@reduxjs/toolkit'
import { always } from 'ramda'

import { ModalState, ModalView } from './modal.types'
import { ActionWithPayload } from '../../common/redux/types'

//---------------------------------
// actions
//---------------------------------

export const SET_VIEW = '[modal] set view'
export const setView = createAction<ModalState>(SET_VIEW)

export const RESET = '[modal] reset'
export const reset = createAction(RESET)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE: ModalState = {
  view: ModalView.NONE,
  payload: null,
}

//---------------------------------
// reducers
//---------------------------------

const assocView = (state: ModalState, { payload }: ActionWithPayload<ModalState>) => payload

const resetState = always(INITIAL_STATE)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {
  [SET_VIEW]: assocView,
  [RESET]: resetState,
})

export default reducer
