import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { compose, pathOr, prop } from 'ramda'
import { withTranslation } from 'react-i18next'

import Subscriptions from './Subscriptions.component'
import { SubscriptionsState } from './subscriptions.types'

// TODO: type this correctly and extract and test
const mapStateToProps = createSelector(
  [prop('subscriptions'), pathOr(null, ['user', 'stripePriceId'])],
  (subscriptions: SubscriptionsState, currentStripePriceId: string) => ({
    subscriptions,
    currentStripePriceId,
  }),
) as any

export default compose(withTranslation('Subscriptions'), connect(mapStateToProps))(Subscriptions)
