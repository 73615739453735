import React, { FC, useEffect, useState } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { addIndex, map, propOr, isNil } from 'ramda'
import { WithTranslation } from 'react-i18next'

import styles from './History.module.css'
import { HighLevelResult, HistoryAction, HistoryItem } from './history.types'
import { IconType } from '../../../../common/modules/SVGIcon/SVGIcon.types'
import Icon from '../../../../common/modules/SVGIcon/SVGIcon.component'
import { formatDate } from '../../../../common/utils/dateTime'
import { useInterval } from '../../../../common/hooks/useInterval'

interface Props {
  history: HistoryItem[]
  exports: any[]
  isHistoryExport: boolean
  isSpecificHistoryExport: boolean
  isExporting: boolean
  accessToken: string
  integrationId: number
  onExport: (id: string, selectedItems: string[], searchFilter: string, resultFilter: string) => void
  onDownloadReport: (id: string) => void
  onRenewExports: (id: number) => void
  onDownloadExport: (journeyId: number, exportId: number) => void
  onConfirmingExportDownload: (exportItem: any) => void
  onConfirmingExportDelete: (exportItem: any) => void
}

const History: FC<Props & WithTranslation> = ({
  t,
  integrationId,
  isHistoryExport,
  isSpecificHistoryExport,
  history,
  onDownloadReport,
  onExport,
  isExporting,
  onRenewExports,
  exports,
  onConfirmingExportDownload,
  onConfirmingExportDelete,
}) => {
  const hasHistory = !isNil(history)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [copiedLinkId, setCopiedLinkId] = useState<string | null>(null)
  const [currentPageHistory, setCurrentPageHistory] = useState<HistoryItem[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [rangeStart, setRangeStart] = useState<number>(0)
  const [rangeEnd, setRangeEnd] = useState<number>(0)
  const [rangeTotal, setRangeTotal] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [resultFilter, setResultFilter] = useState<string>('')

  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const highLevelResultTextMap = {
    [HighLevelResult.Passed]: t('[journey integration update history] result pass'),
    [HighLevelResult.Refer]: t('[journey integration update history] result refer'),
    [HighLevelResult.Undefined]: t('[journey integration update history] result refer'),
    [HighLevelResult.Notsupported]: t('[journey integration update history] result refer'),
    [HighLevelResult.Expired]: t('[journey integration update history] result refer'),
    null: t('[journey integration update history] not activated text'),
  }

  const resultOptions = history
    .map(hi => hi.gbgJourneyHighLevelResult)
    .map(hi => highLevelResultTextMap[hi as HighLevelResult])
    .filter((item, pos, a) => a.indexOf(item) === pos)

  useInterval(() => {
    onRenewExports(integrationId)
  }, 2 * 1000)

  useEffect(() => {
    const start = (currentPage - 1) * pageSize
    setCurrentPageHistory(
      history
        .filter(
          i =>
            (searchFilter == '' || i.customerId.toLowerCase().includes(searchFilter.toLowerCase())) &&
            (resultFilter == '' ||
              highLevelResultTextMap[i.gbgJourneyHighLevelResult as HighLevelResult] == resultFilter),
        )
        .slice(start, start + pageSize),
    )
  }, [currentPage, history, pageSize, searchFilter, resultFilter])

  useEffect(() => {
    const pages = Math.ceil(history.length / pageSize)
    setTotalPages(pages)
    setRangeTotal(history.length)
  }, [history, pageSize])

  useEffect(() => {
    setRangeStart((currentPage - 1) * pageSize + 1)
    setRangeEnd((currentPage - 1) * pageSize + currentPageHistory.length)
  }, [currentPage, currentPageHistory, pageSize])

  const copyToClipBoard = async (url: string, id: string) => {
    try {
      await navigator.clipboard.writeText(url)
      setCopiedLinkId(id)
    } catch (e) {
      setCopiedLinkId(null)
    }
  }

  const actionMap = (id: string) => ({
    [HistoryAction.CopyLink]: (
      <span
        className={`${styles.link} ${styles.historyTableLink}`}
        onClick={() => copyToClipBoard(`${process.env.REACT_APP_JOURNEY_UI_URL}?id=${id}`, id)}
      >
        {t('[journey integration update history] copy link')}{' '}
        {id === copiedLinkId && <GBG.Assistive>Copied</GBG.Assistive>}
      </span>
    ),
    [HistoryAction.DownloadReport]: isSpecificHistoryExport ? (
      <a className={`${styles.referLink} ${styles.link}`} onClick={() => onDownloadReport(id)}>
        {t('[journey integration update history] download report')}
      </a>
    ) : (
      <span></span>
    ),
    [HistoryAction.Expired]: (
      <span className={styles.referLink}>{t('[journey integration update history] download expired')}</span>
    ),
  })

  const highLevelResultMap = {
    [HighLevelResult.Passed]: (
      <span>
        <span className={styles.icon}>
          <Icon name={IconType.ResultCheckmark} />
        </span>
        <span className={styles.passText}>{t('[journey integration update history] result pass')}</span>
      </span>
    ),
    [HighLevelResult.Refer]: (
      <span>
        <span className={styles.icon}>
          <Icon name={IconType.ResultAlert} />
        </span>
        <span className={styles.referText}>{t('[journey integration update history] result refer')}</span>
      </span>
    ),
    [HighLevelResult.Expired]: (
      <span>
        <span className={styles.icon}>
          <Icon name={IconType.ResultAlert} />
        </span>
        <span className={styles.referText}>{t('[journey integration update history] result refer')}</span>
      </span>
    ),
    [HighLevelResult.Undefined]: (
      <span>
        <span className={styles.icon}>
          <Icon name={IconType.ResultAlert} />
        </span>
        <span className={styles.referText}>{t('[journey integration update history] result refer')}</span>
      </span>
    ),
    [HighLevelResult.Notsupported]: (
      <span>
        <span className={styles.icon}>
          <Icon name={IconType.ResultAlert} />
        </span>
        <span className={styles.referText}>{t('[journey integration update history] result refer')}</span>
      </span>
    ),
  }

  const renderMobileHistoryDataView = (
    { gbgJourneyUpdatedAt, customerId, gbgJourneyHighLevelResult, action, id }: HistoryItem,
    i: number,
  ) => (
    <GBG.TableRow key={i}>
      <GBG.TableCell>
        <GBG.Assistive>{formatDate(gbgJourneyUpdatedAt)}</GBG.Assistive>
        <p className={styles.firstMobileCell}>{customerId}</p>
        {/* <p className={styles.firstMobileCell}>{name}</p> */}
      </GBG.TableCell>
      <GBG.TableCell>
        {propOr(
          t('[journey integration update history] not activated text'),
          gbgJourneyHighLevelResult,
        )(highLevelResultMap)}
      </GBG.TableCell>
      <GBG.TableCell>{propOr('', action)(actionMap(id))}</GBG.TableCell>
    </GBG.TableRow>
  )
  const renderDesktopHistoryDataView = (
    { gbgJourneyUpdatedAt, customerId, gbgJourneyHighLevelResult, action, id }: HistoryItem,
    i: number,
  ) => (
    <GBG.TableRow key={i}>
      {isHistoryExport ? (
        <GBG.TableCell>
          <GBG.Checkbox
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
              if (evt.target.checked) {
                setSelectedItems([...selectedItems, id])
              } else {
                setSelectedItems(selectedItems.filter(i => i != id))
              }
            }}
            checked={selectedItems.includes(id)}
            disabled={false}
            error={false}
          />
        </GBG.TableCell>
      ) : null}
      <GBG.TableCell>{formatDate(gbgJourneyUpdatedAt)}</GBG.TableCell>
      <GBG.TableCell>{customerId}</GBG.TableCell>
      {/* <GBG.TableCell>{name}</GBG.TableCell> */}
      <GBG.TableCell>
        {propOr(
          t('[journey integration update history] not activated text'),
          gbgJourneyHighLevelResult,
        )(highLevelResultMap)}
      </GBG.TableCell>
      <GBG.TableCell>{propOr('', action)(actionMap(id))}</GBG.TableCell>
    </GBG.TableRow>
  )
  const mobileHistoryView = (
    <GBG.Table>
      <GBG.TableBody className={styles.tableBody}>
        {addIndex(map)(renderMobileHistoryDataView)(currentPageHistory)}
      </GBG.TableBody>
      <GBG.TableFoot className={styles.tableFoot}>
        <GBG.TableRow>
          <GBG.TableCell colSpan="2">
            {t('[journey integration update history] showing label', {
              rangeStart,
              rangeEnd,
              rangeTotal,
            })}
          </GBG.TableCell>
          <GBG.TableCell>
            <GBG.TablePager
              totalPages={totalPages}
              currentPage={currentPage}
              onNext={() => {
                setCurrentPage(currentPage + 1)
              }}
              onPrevious={() => {
                setCurrentPage(currentPage - 1)
              }}
            ></GBG.TablePager>
          </GBG.TableCell>
        </GBG.TableRow>
      </GBG.TableFoot>
    </GBG.Table>
  )
  const desktopHistoryView = (
    <GBG.Table>
      <GBG.TableHead className={styles.tableHead}>
        <GBG.TableRow isHeader={true}>
          {isHistoryExport ? (
            <GBG.TableHeaderCell className={styles.checkboxCell}>
              <GBG.Checkbox
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  if (evt.target.checked) {
                    setSelectedItems((currentPageHistory as HistoryItem[]).map(h => h.id))
                  } else {
                    setSelectedItems([])
                  }
                }}
                disabled={false}
                error={false}
              />
            </GBG.TableHeaderCell>
          ) : null}
          <GBG.TableHeaderCell>{t('[journey integration update history] date title')}</GBG.TableHeaderCell>
          <GBG.TableHeaderCell>{t('[journey integration update history] id title')}</GBG.TableHeaderCell>
          {/* <GBG.TableHeaderCell>{t('[journey integration update history] journey name title')}</GBG.TableHeaderCell> */}
          <GBG.TableHeaderCell>{t('[journey integration update history] result title')}</GBG.TableHeaderCell>
          <GBG.TableHeaderCell>&nbsp;</GBG.TableHeaderCell>
        </GBG.TableRow>
      </GBG.TableHead>
      <GBG.TableBody className={styles.tableBody}>
        {addIndex(map)(renderDesktopHistoryDataView)(currentPageHistory)}
      </GBG.TableBody>
      <GBG.TableFoot className={styles.tableFoot}>
        <GBG.TableRow>
          <GBG.TableCell colSpan={isHistoryExport ? 2 : 3}>
            {t('[journey integration update history] showing label', {
              rangeStart,
              rangeEnd,
              rangeTotal,
            })}
          </GBG.TableCell>
          {isHistoryExport ? (
            <GBG.TableCell colSpan="2">
              <div className={styles.perPage}>
                <span>{t('[journey integration update history] Show')}</span>
                <select
                  onChange={ev => {
                    setCurrentPage(1)
                    setPageSize(ev.target.value == 'All' ? history.length : Number(ev.target.value))
                  }}
                  className={styles.perPageValue}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="All">All</option>
                </select>
                <span>{t('[journey integration update history] items')}</span>
              </div>
            </GBG.TableCell>
          ) : null}
          <GBG.TableCell>
            <GBG.TablePager
              totalPages={totalPages}
              currentPage={currentPage}
              onNext={() => {
                if (currentPage < totalPages) {
                  setCurrentPage(currentPage + 1)
                }
              }}
              onPrevious={() => {
                if (currentPage > 1) {
                  setCurrentPage(currentPage - 1)
                }
              }}
            ></GBG.TablePager>
          </GBG.TableCell>
        </GBG.TableRow>
      </GBG.TableFoot>
    </GBG.Table>
  )
  const noHistoryView = <span className={styles.noHistory}>{t('[journey integration update history] no history')}</span>
  return (
    <>
      {hasHistory ? (
        <>
          <p className={styles.tableExplainer}>{t('[journey integration update history] table explainer text')}</p>
          <p className={styles.reportExplainer}>{t('[journey integration update history] report explainer text')}</p>
          {exports.length ? (
            <div className={styles.exportsContainer}>
              <p>Exports</p>
              <div className={styles.exports}>
                {exports.map(exportItem => (
                  <div key={exportItem.id} className={`${styles.export} card`}>
                    <div className={`${styles.card__head} card__head`}>
                      <span>
                        {new Intl.DateTimeFormat().format(new Date(exportItem.createdAt))} -{' '}
                        {exportItem.status.charAt(0).toUpperCase() + exportItem.status.slice(1)}
                      </span>
                      {exportItem.status === 'processing' ? <GBG.Spinner></GBG.Spinner> : null}
                    </div>
                    <div className="card__body">
                      <p className="m-t-0">{exportItem.items.length} results</p>
                      {exportItem.status !== 'complete' ? (
                        <div className={styles.progress}>
                          <progress
                            className={styles.progressBar}
                            max="100"
                            value={Math.floor(
                              (exportItem.items.filter((item: any) => item.processed).length /
                                exportItem.items.length) *
                                100,
                            )}
                          ></progress>
                          {Math.floor(
                            (exportItem.items.filter((item: any) => item.processed).length / exportItem.items.length) *
                              100,
                          )}
                          %
                        </div>
                      ) : (
                        <div className={styles.completeButtons}>
                          <GBG.Button
                            size={GBG.ButtonSize.Small}
                            onClick={() => {
                              onConfirmingExportDelete(exportItem)
                            }}
                            kind={GBG.ButtonKind.SecondaryDestructive}
                          >
                            Delete
                          </GBG.Button>
                          <GBG.Button
                            size={GBG.ButtonSize.Small}
                            onClick={() => {
                              onConfirmingExportDownload(exportItem)
                            }}
                            kind={GBG.ButtonKind.Secondary}
                          >
                            Download
                          </GBG.Button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {isHistoryExport ? (
            <div className={`${styles.controls} m-m-b-2`}>
              <div className={styles.controls__actions}>
                <GBG.Button
                  onClick={() => {
                    if (selectedItems.length > 0) {
                      onExport(integrationId + '', selectedItems, searchFilter, resultFilter)
                    } else {
                      alert('Please select some results to export.')
                    }
                  }}
                  worker={true}
                  active={isExporting}
                  kind={GBG.ButtonKind.Secondary}
                  size={GBG.ButtonSize.Regular}
                >
                  {t('[journey integration update history] export button text')}
                </GBG.Button>
              </div>

              <div className={styles.controls__filters}>
                <GBG.Select
                  onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
                    setSelectedItems([])
                    setResultFilter(ev.target.value)
                  }}
                  defaultValue={''}
                  disabled={false}
                  error={false}
                >
                  <option value="">{t('[journey integration update history] filter result placeholder text')}</option>
                  {resultOptions.map(ro => (
                    <option key={ro} value={ro}>
                      {ro}
                    </option>
                  ))}
                </GBG.Select>
                <GBG.Slug
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    setSelectedItems([])
                    setSearchFilter(ev.target.value)
                  }}
                  slug={GBG.IconKeys.Search24}
                  placeholder={t('[journey integration update history] search placeholder text')}
                />
              </div>
            </div>
          ) : null}
          <div className={styles.mobileHistoryWrapper}>{mobileHistoryView}</div>
          <div className={styles.desktopHistoryWrapper}>{desktopHistoryView}</div>
        </>
      ) : (
        noHistoryView
      )}
    </>
  )
}

export default History
