import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from 'redux'

import { navigateTo } from '../../common/modules/Routing'
import Navigation from './Navigation.component'
import { NavigationSelector } from './Navigation.selectors'

const mapStateToProps = (state: any, ownProps: any) => {
  const { currentRoutePathname, isAuthenticated } = NavigationSelector(state)
  return {
    currentRoutePathname,
    isAuthenticated,
    ownProps,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateTo: (path: string) => dispatch(navigateTo(path)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
