import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import { UserInvite } from './UserInvites.types'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { User } from '../../../modules/User/user.types'
import styles from './Users.module.css'

export interface Props {
  users: User[]
  userInvites: UserInvite[]
  onCreate: (email: string) => void
  onDeleteInvite: (id: number) => void
}

const Users: FC<Props & WithTranslation> = ({ t, users, userInvites, onCreate, onDeleteInvite }) => {
  return (
    <div>
      <div className={styles.controls}>
        <GBG.Button
          className={styles.control}
          onClick={() => {
            const email = prompt('Please enter the email address of the new user')
            if (email) {
              onCreate(email)
            }
          }}
          kind={GBG.ButtonKind.Primary}
          size={GBG.ButtonSize.Small}
        >
          Invite a new user
        </GBG.Button>
      </div>
      <GBG.Table className="w-100">
        <GBG.TableHead>
          <GBG.TableRow isHeader={true}>
            <GBG.TableHeaderCell className={styles.avatarHeaderCell}>&nbsp;</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>Email</GBG.TableHeaderCell>
            <GBG.TableHeaderCell>Name</GBG.TableHeaderCell>
            <GBG.TableHeaderCell className={styles.menu_cell}>&nbsp;</GBG.TableHeaderCell>
          </GBG.TableRow>
        </GBG.TableHead>
        <GBG.TableBody>
          {(users ?? []).map(u => (
            <GBG.TableRow key={u.id}>
              <GBG.TableCell className={styles.avatarCell}>
                <GBG.Avatar name={u.name} size={GBG.AvatarSize.Regular} />
              </GBG.TableCell>
              <GBG.TableCell title={u.email} className={styles.overflowing}>
                {u.email}
              </GBG.TableCell>
              <GBG.TableCell>{u.name}</GBG.TableCell>
              <GBG.TableCell buttons={true} className={styles.menu_cell}>
                {/* <GBG.OverflowMenu
                  onItemSelected={(index: number, item: any) => {
                    console.log(index, item)
                  }}
                  items={[
                    {
                      title: 'Remove user',
                      danger: true,
                    },
                  ]}
                  direction={GBG.OverflowMenuDirection.Left}
                ></GBG.OverflowMenu> */}
              </GBG.TableCell>
            </GBG.TableRow>
          ))}
          {userInvites.map(u => (
            <GBG.TableRow key={u.id}>
              <GBG.TableCell colspan="2" title={u.email} className={`${styles.overflowing} ${styles.invite_cell}`}>
                {u.email}
              </GBG.TableCell>
              <GBG.TableCell className={styles.invite_cell}>{t('[users] Invite pending')}</GBG.TableCell>
              <GBG.TableCell buttons={true} className={styles.invite_cell}>
                <GBG.OverflowMenu
                  onItemSelected={(index: number) => {
                    if (index === 0) {
                      if (confirm(t('[users] Are you sure you want to revoke this invite?'))) {
                        onDeleteInvite(u.id)
                      }
                    }
                  }}
                  items={[
                    {
                      title: 'Revoke invite',
                    },
                  ]}
                  direction={GBG.OverflowMenuDirection.Left}
                ></GBG.OverflowMenu>
              </GBG.TableCell>
            </GBG.TableRow>
          ))}
        </GBG.TableBody>
      </GBG.Table>
    </div>
  )
}

export default Users
