import { Middleware } from 'redux'
import { complement, includes, isNil, path, pathEq, pathSatisfies, replace, propSatisfies } from 'ramda'

import {
  CHANGE_SUBSCRIPTION,
  CHOOSE_SUBSCRIPTION,
  CONFIRM_PAYMENT,
  httpCreatePaymentIntentConfirmation,
  httpGetPaymentHistory,
  UPDATE_PAYMENT_SELECT_SUBSCRIPTION,
  UPDATE_USER_ADDRESS,
} from './payment.reducer'
import { assocFormData, FormSubmissionStatus, setFormData, setFormSubmissionStatus } from '../../common/form'
import {
  HTTP_UPDATE_SUBSCRIPTION_SUCCESS,
  HTTP_GET_SUBSCRIPTIONS_SUCCESS,
  HTTP_UPDATE_SUBSCRIPTION_FAILURE,
  updateDefaultSubscription,
  httpCreateSubscription,
  httpUpdateSubscription,
} from '../../common/modules/Subscriptions/subscriptions.reducer'
import {
  httpGetPaymentMethods,
  HTTP_UPDATE_PAYMENT_METHOD_FAILURE,
  STRIPE_CARD_CONFIRMED,
} from '../../common/modules/PaymentMethods/paymentMethods.reducer'
import { httpUpdateUser, HTTP_GET_USER_SUCCESS } from '../User/user.reducer'
import { AlertType } from '../../common/modules/Alert/Alert.constants'
import { setAlert } from '../../common/modules/Alert/alert.reducer'
import { reset } from '../Modal/modal.reducer'

//---------------------------------
// update payment
// ... initiates multi-part update payment form flow
// ... on any of the provided routes
// ... or on choose or change subscription actions
//---------------------------------

export const initUpdatePaymentFlow = (APP_INIT: string, routes: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action

  if (type === APP_INIT) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (includes(replace(/(\d+)/)(':id')(pathname), routes)) {
      dispatch(
        setFormData('update-payment', {
          stripePriceId: null,
          stripePaymentMethodId: null,
        }),
      )
    }
    return
  }

  if (includes(type, [CHOOSE_SUBSCRIPTION, CHANGE_SUBSCRIPTION])) {
    dispatch(
      setFormData('update-payment', {
        stripePriceId: null,
        stripePaymentMethodId: null,
      }),
    )
  }
}

//---------------------------------
// update payment select subscription
// ... initiates multi-part update payment form flow
//---------------------------------

export const updatePaymentSelectSubscriptionFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_PAYMENT_SELECT_SUBSCRIPTION) {
    const {
      user: { stripeSubscriptionId },
    } = getState()
    if (isNil(stripeSubscriptionId)) {
      dispatch(httpCreateSubscription(payload))
      return
    }
    dispatch(assocFormData('update-payment', 'stripePriceId', payload))
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.UNSUBMITTED))
  }
}

//---------------------------------
// update payment subscription loading
//---------------------------------

export const updatePaymentSubscriptionLoadingFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_SUBSCRIPTIONS_SUCCESS) {
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.UNSUBMITTED))
  }
}

//---------------------------------
// update payment form success
//---------------------------------

export const updatePaymentFormSuccessFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.SUCCESS))
  }
}

//---------------------------------
// update payment form failure
//---------------------------------

export const updatePaymentFormFailureFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)([HTTP_UPDATE_SUBSCRIPTION_FAILURE, HTTP_UPDATE_PAYMENT_METHOD_FAILURE])) {
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.FAILURE))
    dispatch(
      setAlert({
        type: AlertType.ERROR,
        title: 'Error',
        message: 'There was an error with your Payment. Please try again.',
      }),
    )
    dispatch(reset())
  }
}

//---------------------------------
// update default subscription success
//---------------------------------

export const updateDefaultSubscriptionSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_SUBSCRIPTION_SUCCESS) {
    dispatch(httpGetPaymentMethods())
  }
}

//---------------------------------
// update default subscription with payment method
//---------------------------------

export const updateDefaultSubscriptionWithNewPaymentMethodFlow = (): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === STRIPE_CARD_CONFIRMED) {
    const state = getState()
    if (
      pathEq(['form', 'update-payment', 'submissionStatus'], FormSubmissionStatus.SUBMITTING)(state) &&
      pathSatisfies(complement(isNil), ['form', 'update-payment', 'data', 'stripePriceId'])(state) &&
      pathSatisfies(complement(isNil), ['user', 'stripeSubscriptionId'])(state)
    ) {
      const stripePriceId = path(['form', 'update-payment', 'data', 'stripePriceId'])(state)
      const stripePaymentMethodId = path(['stripePaymentMethodId'])(payload)
      const address = path(['address'])(payload)
      dispatch(updateDefaultSubscription({ stripePriceId, stripePaymentMethodId, address }))
    }
  }
}

//---------------------------------
// update user address
//---------------------------------

export const updateUserAddressFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === UPDATE_USER_ADDRESS) {
    const state = getState()
    const userid = path(['user', 'id'])(state)
    const address = path(['form', 'update-payment', 'data', 'address'])(state)
    if (isNil(address)) {
      return
    }
    dispatch(httpUpdateUser({ address }, userid))
  }
}

//---------------------------------
// get payment history
// ... initiates the API get paymentHistory flow
// ... on provided actions
// ... or on provided routes (if authenticated)
//---------------------------------

export const getPaymentHistoryFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_USER_SUCCESS) {
    const {
      user: { stripeSubscriptionId },
    } = getState()
    if (!isNil(stripeSubscriptionId)) {
      dispatch(httpGetPaymentHistory())
    }
  }
}

//---------------------------------
// confirm payment
//---------------------------------

export const confirmPaymentFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === CONFIRM_PAYMENT) {
    const state = getState()
    const formData = path(['form', 'update-payment', 'data'])(state)
    if (propSatisfies(complement(isNil), 'stripePaymentIntentId')(formData)) {
      const { stripePaymentIntentId, stripePaymentMethodId } = formData
      dispatch(httpCreatePaymentIntentConfirmation(stripePaymentIntentId, stripePaymentMethodId))
    } else {
      const { stripePaymentMethodId, stripePriceId, address } = formData
      dispatch(httpUpdateSubscription({ stripePriceId, stripePaymentMethodId, address }))
    }
  }
}
