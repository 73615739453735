import { map } from 'ramda'
import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import { JourneyIntegration as IJourneyIntegration } from './journeyIntegration.types'
import JourneyIntegration from '../JourneyIntegration'
import styles from './JourneyIntegrations.module.css'
import { useDispatch } from 'react-redux'
import { navigateTo, Routes } from '../Routing'

export interface Props {
  journeyIntegrations: IJourneyIntegration[]
  onEdit: (id: number) => void
  onActivate: (id: number) => void
  onDeactivate: (id: number) => void
  onCreateLink: (id: number) => void
  companyName: string
}

const JourneyIntegrations: FC<Props & WithTranslation> = ({
  t,
  journeyIntegrations,
  onEdit,
  onActivate,
  onDeactivate,
  onCreateLink,
  companyName,
}) => {
  const dispatch = useDispatch()

  return (
    <div className={styles.container}>
      <h2>{t('[journey integrations] title')}</h2>
      {journeyIntegrations.length ? (
        <ul className={styles.list}>
          {map(({ id, name, description, isActive, logoUrl, steps }: IJourneyIntegration) => (
            <li key={id} className={styles.listItem}>
              <JourneyIntegration
                id={id}
                name={name}
                description={description}
                logoUrl={logoUrl}
                isActive={isActive}
                steps={steps}
                onManage={onEdit}
                onToggleActivation={isActive ? onDeactivate : onActivate}
                onCreateLink={onCreateLink}
                companyName={companyName}
              />
            </li>
          ))(journeyIntegrations)}
        </ul>
      ) : (
        <div className={styles.integrationsEmpty}>
          {t('[journey integrations] empty state')}
          <br />
          <a
            className="link"
            onClick={() => {
              dispatch(navigateTo(Routes.CREATE_JOURNEY_INTEGRATION))
            }}
          >
            {t('[journey integrations] Create one to get started')}
          </a>
        </div>
      )}
    </div>
  )
}

export default JourneyIntegrations
