import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './PaymentPlaceholder.module.css'

const PaymentPlaceholder: FC<WithTranslation> = ({ t }) => {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('[payment placeholder] title')}</h1>
      <p>{t('[payment placeholder] first paragraph')}</p>
      <br />
      <p>
        {t('[payment placeholder] second paragraph 1')}
        <a className={styles.link} href="mailto:gbg-rapid@gbgplc.com">
          {t('[payment placeholder] link text')}
        </a>{' '}
        {t('[payment placeholder] second paragraph 2')}
      </p>
    </div>
  )
}

export default PaymentPlaceholder
