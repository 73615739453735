import { createReducer, createAction } from '@reduxjs/toolkit'
import { assoc, assocPath } from 'ramda'

import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'
import {
  JourneyIntegrationLinkResultDateRanges,
  JourneyIntegrationLinkResultsState,
} from './journeyIntegrationLinkResults.types'
import { HTTPMeta } from '../../redux-http/http.types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS = '[journey integration link results] HTTP get'
export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_SUCCESS = '[journey integration link results] HTTP get success'
export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FAILURE = '[journey integration link results] HTTP get failure'
export const httpGetJourneyIntegrationLinkResults = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS)({
    endpoint: 'journey-integration-links/results',
  })
export const httpGetJourneyIntegrationLinkResultsSuccess = createAction<JourneyIntegrationLinkResultDateRanges>(
  HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_SUCCESS,
)
export const httpGetJourneyIntegrationLinkResultsFailure = createAction<string>(
  HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FAILURE,
)

export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY =
  '[journey integration link results] HTTP get for journey'
export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY_SUCCESS =
  '[journey integration link results] HTTP get for journey success'
export const HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY_FAILURE =
  '[journey integration link results] HTTP get for journey failure'
export const httpGetJourneyIntegrationLinkResultsForJourney = (id: number): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY)({
    endpoint: `journey-integration-links/results?journeyIntegrationId=${id}`,
    meta: { id },
  })

export const httpGetJourneyIntegrationLinkResultsForJourneySuccess = (
  payload: HTTPPayload,
  meta: HTTPMeta,
): ActionWithPayload<HTTPPayload> & { meta?: HTTPMeta } => ({
  type: HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY_SUCCESS,
  payload,
  meta,
})
export const httpGetJourneyIntegrationLinkResultsForJourneyFailure = createAction(
  HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY_FAILURE,
)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE = {
  byJourneyIntegrationId: {},
  all: {
    week: {
      passed: 0,
      failed: 0,
    },
  },
}

//---------------------------------
// reducers
//---------------------------------

const assocAll = (
  state: JourneyIntegrationLinkResultsState,
  { payload }: ActionWithPayload<{ id: number; data: JourneyIntegrationLinkResultDateRanges }>,
) => assoc('all', payload)(state)

const assocByJourneyIntegrationId = (
  state: JourneyIntegrationLinkResultsState,
  { payload, meta }: ActionWithPayload<JourneyIntegrationLinkResultDateRanges> & { meta: { id: number } },
) => {
  const { id } = meta
  return assocPath(['byJourneyIntegrationId', id], payload)(state)
}

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<JourneyIntegrationLinkResultsState>(INITIAL_STATE, {
  [HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_SUCCESS]: assocAll,
  [HTTP_GET_JOURNEY_INTEGRATION_LINK_RESULTS_FOR_JOURNEY_SUCCESS]: assocByJourneyIntegrationId,
})

export default reducer
