import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import {
  createJourneyIntegrationProceed,
  createJourneyIntegrationBack,
} from '../../createJourneyIntegrationCompletedSteps.reducer'
import PersonalisationForm from './PersonalisationForm.component'
import { setPersonalisation } from '../../journeyIntegration.reducer'
import { Personalisation } from '../../journeyIntegration.types'
import { personalisationFormSelector } from '../../journeyIntegration.selectors'

const mapStateToProps = personalisationFormSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onBack: () => dispatch(createJourneyIntegrationBack()),
  onProceed: () => dispatch(createJourneyIntegrationProceed()),
  onProceedWithPersonalisation: (personalisation: Personalisation) => {
    dispatch(setPersonalisation(personalisation))
    dispatch(createJourneyIntegrationProceed())
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect(mapStateToProps, mapDispatchToProps),
)(PersonalisationForm)
