import React, { FC } from 'react'
import { isEmpty, propOr } from 'ramda'

import styles from './Alert.module.css'
import { AlertType } from './Alert.constants'
import { Alert as IAlert } from './Alert.types'
import Icon from '../SVGIcon/SVGIcon.component'
import { AlertIconType } from '../SVGIcon/SVGIcon.types'

export type Props = IAlert & { onDismiss: () => void }

const alertTypeIconTypeMap = {
  [AlertType.INFO]: AlertIconType.INFO,
  [AlertType.WARNING]: AlertIconType.WARNING,
  [AlertType.ERROR]: AlertIconType.ERROR,
  [AlertType.SUCCESS]: AlertIconType.SUCCESS,
}

const Alert: FC<Props> = ({ type, title, message, isDismissable, onDismiss }) => {
  const typeStyle = propOr(styles.info, type)(styles)
  return isEmpty(message) ? null : (
    <div className={typeStyle}>
      <div className={styles.icon}>
        <Icon name={propOr(AlertIconType.INFO, type, alertTypeIconTypeMap)} />
      </div>
      <div className={styles.body}>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.message}>{message}</div>
      </div>
      {!isDismissable ? null : (
        <div className={styles.buttons}>
          <button className={styles.closeButton} onClick={() => onDismiss()}>
            &times;
          </button>
        </div>
      )}
    </div>
  )
}

export default Alert
