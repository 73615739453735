import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import UpdatePaymentSubscription from './UpdatePaymentSubscription.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'
import { updatePaymentSelectSubscription } from '../payment.reducer'
import { FormSubmissionStatus, setFormSubmissionStatus } from '../../../common/form'
import { cancelSubscription } from '../../Organisation'
import { updatePaymentSubscriptionSelector } from './updatePaymentSubscription.selectors'

const mapStateToProps = updatePaymentSubscriptionSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onCancel: () => {
    dispatch(navigateTo(Routes.PAYMENT))
  },
  onSubmit: (id: string) => {
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.SUBMITTING))
    dispatch(updatePaymentSelectSubscription(id))
  },
  onCancelSubscription: () => {
    dispatch(cancelSubscription())
  },
})

export default compose(
  withTranslation('Payment'),
  connect(mapStateToProps, mapDispatchToProps),
)(UpdatePaymentSubscription)
