import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import Steps from './Steps.component'
import { createJourneyIntegrationCompletedStepsSelector } from '../../createJourneyIntegrationCompletedSteps.selectors'
import { createSelector } from 'reselect'
import { dataProtectionSelector } from '../../../../common/modules/DataProtection'
import { isGatheringDPOInfoSelector } from '../../../../common/feature-flags'

const mapStateToProps = createSelector(
  [createJourneyIntegrationCompletedStepsSelector, dataProtectionSelector, isGatheringDPOInfoSelector],
  (stepsData, dataProtection, isGatheringDPOInfo) => ({
    ...stepsData,
    includeDataProtection: dataProtection.loaded && dataProtection.info.dpoName.trim() == '' && isGatheringDPOInfo,
  }),
)

export default connect(mapStateToProps)(withTranslation('JourneyIntegration')(Steps))
