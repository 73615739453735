import { Middleware } from 'redux'
import { compose, includes, pathEq, replace } from 'ramda'

import { ON_ROUTE, Routes } from '../Routing'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'
import {
  httpGetPaymentMethods,
  httpUpdatePaymentMethod,
  STRIPE_CARD_ERROR,
  UPDATE_DEFAULT_PAYMENT_METHOD,
  CONFIRM_DELETE_PAYMENT_METHOD,
  httpDeletePaymentMethod,
  HTTP_DELETE_PAYMENT_METHOD_SUCCESS,
  HTTP_DELETE_PAYMENT_METHOD_FAILURE,
  UPDATE_PAYMENT_METHOD_BILLING_ADDRESS,
} from './paymentMethods.reducer'
import { FormSubmissionStatus } from '../../form'
import { AlertType } from '../Alert/Alert.constants'
import { setAlert } from '../Alert/alert.reducer'
import { reset } from '../../../modules/Modal/modal.reducer'

//---------------------------------
// get payment methods
// ... initiates the API get paymentMethods flow
// ... on provided actions
// ... or on provided routes (if authenticated)
//---------------------------------

export const getPaymentMethodsFlow = (actions: string[], routes: Routes[]): Middleware => {
  let hasInitialized = false
  return ({ dispatch, getState }) => next => action => {
    next(action)
    if (hasInitialized) {
      return
    }
    const { type, payload } = action
    if (includes(type)(actions)) {
      hasInitialized = true
      dispatch(httpGetPaymentMethods())
      return
    }
    if (type === ON_ROUTE && includes(replace(/(\d+)/)(':id')(payload), routes)) {
      const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
      if (isAuthenticated) {
        hasInitialized = true
        dispatch(httpGetPaymentMethods())
      }
    }
  }
}

//---------------------------------
// refresh payment methods
// ... retrieves payment methods after successful create
//---------------------------------

export const refreshPaymentMethodsFlow = (actions: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    const state = getState()
    // TODO: do this better (preferably somewhere else)
    if (pathEq(['form', 'update-payment', 'submissionStatus'], FormSubmissionStatus.SUBMITTING)(state)) {
      return
    }
    dispatch(httpGetPaymentMethods())
  }
}

//---------------------------------
// update default payment method
//---------------------------------

export const updateDefaultPaymentMethodFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_DEFAULT_PAYMENT_METHOD) {
    dispatch(httpUpdatePaymentMethod(payload, { isDefault: true }))
  }
}

//---------------------------------
// update payment method billing address
//---------------------------------

export const updatePaymentMethodBillingAddressFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_PAYMENT_METHOD_BILLING_ADDRESS) {
    const { stripePaymentMethodId, address } = payload
    dispatch(httpUpdatePaymentMethod(stripePaymentMethodId, { address }))
  }
}

//---------------------------------
// stripe card error
//---------------------------------

export const stripeCardErrorFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === STRIPE_CARD_ERROR) {
    dispatch(
      setAlert({
        type: AlertType.ERROR,
        title: 'Payment failed',
        message: 'There was an error with your card. Please try again.',
      }),
    )
  }
}

//---------------------------------
// confirm delete payment method
//---------------------------------

export const confirmDeletePaymentMethodFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === CONFIRM_DELETE_PAYMENT_METHOD) {
    dispatch(httpDeletePaymentMethod(payload))
  }
}

export const httpDeletePaymentMethodSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_DELETE_PAYMENT_METHOD_SUCCESS) {
    dispatch(
      setAlert({
        type: AlertType.SUCCESS,
        title: 'Success',
        message: 'The payment method has been removed',
      }),
    )
    dispatch(httpGetPaymentMethods())
    dispatch(reset())
  }
}

export const httpDeletePaymentMethodFailureFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_DELETE_PAYMENT_METHOD_FAILURE) {
    dispatch(
      setAlert({
        type: AlertType.ERROR,
        title: 'Error',
        message: 'The payment method could not be removed',
      }),
    )
    dispatch(httpGetPaymentMethods())
    dispatch(reset())
  }
}
