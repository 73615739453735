import { createAction, createReducer } from '@reduxjs/toolkit'

import { ActionWithPayload } from '../../common/redux/types'

//---------------------------------
// actions
//---------------------------------

export const SET_SELECTED_JOURNEY_INTEGRATION = '[selected journey integration] set'
export const setSelectedJourneyIntegration = createAction<number>(SET_SELECTED_JOURNEY_INTEGRATION)

//---------------------------------
// reducers
//---------------------------------

const overwriteSelectedJourneyIntegration = (state: number, { payload }: ActionWithPayload<number>) => payload

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = -1

const reducer = createReducer(INITIAL_STATE, {
  [SET_SELECTED_JOURNEY_INTEGRATION]: overwriteSelectedJourneyIntegration,
})
export default reducer
