import React, { FC, useEffect, useState } from 'react'
import Dinero from 'dinero.js'
import { WithTranslation } from 'react-i18next'

import styles from './Subscriptions.module.css'
import { Subscription } from './subscriptions.types'
import SubscriptionItem from './SubscriptionItem/SubscriptionItem.component'

export interface Props {
  subscriptions: Subscription[]
  currentStripePriceId: string
  onUpdate?: (id: string) => void
  onSelect?: (id: string) => void
}

const noop = () => null

const Subscriptions: FC<Props & WithTranslation> = ({ t, currentStripePriceId, subscriptions, onSelect = noop }) => {
  const [selectedId, setSelectedId] = useState('')
  const onSelectInternal = (id: string) => {
    setSelectedId(id)
    onSelect(id)
  }
  useEffect(() => {
    onSelectInternal(currentStripePriceId)
  }, [currentStripePriceId])

  const SubscriptionList = subscriptions.map(sub => {
    const { name, price: amount, currency, stripePriceId, unitLabel, units } = sub
    const translatedUnitLabel = t(`[subscription] ${unitLabel}`)
    const translatedUnitsLabel = t(`[subscription] ${unitLabel} plural`)
    const pricePerUnit = Dinero({ amount, currency }).divide(units).toFormat()
    const description = `${units} ${translatedUnitsLabel} per month`
    const descriptionRatio = `${pricePerUnit}/${translatedUnitLabel}`
    const price = `${Dinero({ amount, currency }).toFormat()}/month`
    return (
      <div className={styles.item} onClick={() => onSelectInternal(stripePriceId)} key={stripePriceId}>
        <SubscriptionItem
          name={name}
          description={description}
          descriptionRatio={descriptionRatio}
          price={price}
          isSelected={stripePriceId === selectedId}
          isCurrent={stripePriceId === currentStripePriceId}
          isSelectable={true}
        />
      </div>
    )
  })
  return <ul className={styles.container}>{SubscriptionList}</ul>
}

export default Subscriptions
