import { isNil, cond, path, pathOr, pathEq, T, assocPath, prop } from 'ramda'
import Bluebird from 'bluebird'
import { Middleware, MiddlewareAPI } from 'redux'
import { AxiosError } from 'axios'

import { httpResponseFailure, httpResponseSuccess, httpResponseUnauthorized } from './http.reducer'
import { HTTP_REQUEST_REGEX } from './constants'
import { calculateHTTPMethod } from './http.utils'
import { HttpRequestFlowProps } from './http.types'

//---------------------------------
// http request auth flow
//---------------------------------

export const httpRequestAuthFlow = (): Middleware => ({ getState }) => next => action => {
  const { type } = action
  if (HTTP_REQUEST_REGEX.test(type)) {
    const state = getState()
    const token = pathOr(null, ['auth', 'accessToken'])(state)
    if (isNil(token)) {
      next(action)
      return
    }
    next(assocPath(['payload', 'headers', 'Authorization'], `Bearer ${token}`)(action))
    return
  }
  next(action)
}

//---------------------------------
// http request flow
// ... makes HTTP request on resource
// ... and either succeeds with the response data
// ... or fails with the received error message
//---------------------------------

const onErrorResponseUnknown = () => (e: Error) => {
  // logger.error(e) // TODO: obsfuscate before logging
  return Bluebird.reject(e)
}

const onErrorResponseUnauthorized = ({ dispatch }: MiddlewareAPI) => (e: Error) => {
  dispatch(httpResponseUnauthorized())
  return Bluebird.reject(e)
}

const onErrorResponse = (store: MiddlewareAPI) =>
  cond([
    [pathEq(['response', 'status'], 401), onErrorResponseUnauthorized(store)],
    [T, onErrorResponseUnknown()],
  ])

export const httpRequestFlow = ({ httpRequest }: HttpRequestFlowProps): Middleware => store => next => action => {
  next(action)
  const { type, payload } = action
  const { dispatch } = store
  if (HTTP_REQUEST_REGEX.test(type) && !isNil(payload)) {
    const { endpoint, data, headers = {}, params = {}, meta = {} } = payload
    const method = calculateHTTPMethod(type)
    Bluebird.resolve(httpRequest({ url: endpoint, method, data, headers, params }))
      .then(path(['data', 'data']))
      .then(data => {
        dispatch(httpResponseSuccess<unknown>(type, data, meta))
      })
      // TODO: only catch HTTP errors
      .catch(e =>
        onErrorResponse(store)(e).catch((e: AxiosError) => {
          dispatch(httpResponseFailure(type, prop('message')(e), path(['response', 'status'])(e)))
        }),
      )
  }
}
