import { Middleware } from 'redux'
import { compose, includes, replace } from 'ramda'

import {
  HTTP_GET_JOURNEY_INTEGRATIONS_SUCCESS,
  httpGetJourneyIntegrations,
  setJourneyIntegrations,
  HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_SUCCESS,
} from './journeyIntegrations.reducer'
import { ON_ROUTE, Routes } from '../Routing'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'

//---------------------------------
// journey integrations init
// ... starts the API get journey integrations flow
// ... on provided route
//---------------------------------

export const journeyIntegrationsInitFlow = (actions: string[], routes: Routes[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(httpGetJourneyIntegrations())
    return
  }
  if (type === ON_ROUTE && includes(replace(/(\d+)/)(':id')(payload), routes)) {
    const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
    if (isAuthenticated) {
      dispatch(httpGetJourneyIntegrations())
    }
  }
}

//---------------------------------
// set journey integrations
// ... signals intention to update state
// ... with list of journey integrations returned from API
//---------------------------------

export const setJourneyIntegrationsFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATIONS_SUCCESS) {
    dispatch(setJourneyIntegrations(payload))
  }
}

//---------------------------------
// download journey integration export report success
//---------------------------------

export const httpDownloadJourneyIntegrationExportLinkSuccessFlow = (): Middleware => () => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_EXPORT_LINK_SUCCESS) {
    const { payload } = action
    const { url } = payload
    // TODO: is there a more react/redux way to do this?
    window.location.href = url
  }
}
