import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import styles from './ExportCreatedModal.module.css'

export interface Props {
  onConfirm: () => void
}

const ExportCreatedModal: FC<Props & WithTranslation> = ({ t, onConfirm }) => {
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar type={GBG.AlertBarType.Info} icon={GBG.IconKeys.InfoCircle24}>
          <GBG.AlertBarText title={t('[export created] title')} text={t('[export created] prompt')} />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <div aria-label="body-text" className={styles.content}>
          <p>{t('[export created] description')}</p>
          <div className={`m-m-t-2 ${styles.controls}`}>
            <GBG.Button
              className={styles.button}
              onClick={() => {
                onConfirm()
              }}
            >
              {t('[export created] ok')}
            </GBG.Button>
          </div>
        </div>
      </GBG.ModalBody>
    </>
  )
}

export default ExportCreatedModal
