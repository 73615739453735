import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { Organisation } from './organisation.types'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import styles from './organisation.module.css'

export interface Props {
  org: Organisation
  onUpdate: (orgId: number, data: Partial<Organisation>) => void
}

export interface FormValues {
  name: string
}

export interface FormProps {
  name?: string
}

const OrganisationForm: FC<Props & WithTranslation> = ({ t, org, onUpdate }) => {
  const { name } = org

  const { getFieldProps, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(t('[organisation] Company name is a required field')),
    }),
    enableReinitialize: true,
    onSubmit: values => {
      onUpdate(org.id as number, values)
    },
  })

  return (
    <div>
      {org.id && (
        <form className={styles.form} onSubmit={handleSubmit}>
          <GBG.FormGroup>
            <GBG.Label>{t('[organisation] Company name label')}</GBG.Label>
            <GBG.Text placeholder="Acme Inc" {...getFieldProps('name')} />
            {touched.name && errors.name && <GBG.ErrorText>{errors.name}</GBG.ErrorText>}
          </GBG.FormGroup>
          <GBG.FormGroup>
            <GBG.Button type="submit">{t('[organisation] Update')}</GBG.Button>
          </GBG.FormGroup>
        </form>
      )}
    </div>
  )
}

export default OrganisationForm
