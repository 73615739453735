import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './SignupHeader.module.css'

interface Props {
  onNavigate: () => void
}

const SignupHeader: FC<Props & WithTranslation> = ({ t, onNavigate }) => (
  <>
    <p className={styles.signupExplainer}>{t('[auth] navigate to login prompt')}</p>
    <button onClick={() => onNavigate()} className={styles.signupButton}>
      {t('[auth] login submit label')}
    </button>
  </>
)

export default SignupHeader
