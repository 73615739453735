import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import CancelAutoRenewModal, { Props } from './CancelAutoRenewModal.component'
import { AlertType, setAlert } from '../../../common/modules/Alert'
import { confirmCancelAutoRenew } from '../../Organisation'

const cancelAutoRenewError = {
  type: AlertType.ERROR,
  title: 'Cancel auto renew error',
  message: 'There was an error with cancelling auto renew. Please try again.',
}

const mapStateToProps = null

const noop = () => null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { onSubmit = noop }) => ({
  onSubmit: () => {
    dispatch(confirmCancelAutoRenew())
    onSubmit()
  },
  onError: () => {
    dispatch(setAlert(cancelAutoRenewError))
  },
})

export default compose(
  withTranslation('Payment'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(CancelAutoRenewModal)
