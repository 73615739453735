import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import ChangePasswordHeader from './ChangePasswordHeader.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'

const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigate: () => {
    dispatch(navigateTo(Routes.LOGIN))
  },
})

export default compose(withTranslation('Auth'), connect(mapStateToProps, mapDispatchToProps))(ChangePasswordHeader)
