import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import styles from './ConfirmExportDeleteModal.module.css'

export interface Props {
  onConfirm: () => void
  onCancel?: () => void
}

const noop = () => null

const ConfirmExportDeleteModal: FC<Props & WithTranslation> = ({ t, onConfirm, onCancel = noop }) => {
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar type={GBG.AlertBarType.Warn} icon={GBG.IconKeys.Link24}>
          <GBG.AlertBarText title={t('[confirm export delete] title')} />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <div aria-label="body-text" className={styles.content}>
          <p>{t('[confirm export delete] description')}</p>
          <div className={`m-m-t-2 ${styles.controls}`}>
            <GBG.Button
              onClick={() => {
                onCancel()
              }}
              className="m-m-r-2"
              kind={GBG.ButtonKind.Secondary}
            >
              {t('[confirm export delete] cancel')}
            </GBG.Button>
            <GBG.Button
              onClick={() => {
                onConfirm()
              }}
            >
              {t('[confirm export delete] delete')}
            </GBG.Button>
          </div>
        </div>
      </GBG.ModalBody>
    </>
  )
}

export default ConfirmExportDeleteModal
