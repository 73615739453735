import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { assoc, compose, ifElse, isEmpty, isNil, mergeRight, pick, prop, tap } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { useFormValidation } from '../../../../common/modules/AJVFormValidation'
import formValidationSchema from '../../schemas/journey-integration-completion-action-schema.json'
import { CompletionActionType } from '../../journeyIntegration.types'
import {
  CompletionActionForm,
  CompletionActionFormData,
  CompletionActionFormFieldsTypeMap,
} from '../../CompletionActionForm'
import styles from './CompletionActionForm.module.css'

interface Props {
  onBack: () => void
  onSubmit: (data: CompletionActionFormData) => void
  onInvalidSubmit: () => void
  onValidationChange: (isValid: boolean) => void
  completionActionType: CompletionActionType
  redirectUrl: string
  ctaText: string
  successTitle: string
  successText: string
  failureTitle: string
  failureText: string
  includeIcon: boolean
  isToggleableOutcomeIcon: boolean
}

const CreateJourneyIntegrationCompletionActionForm: FC<Props & WithTranslation> = ({
  t,
  onSubmit,
  onInvalidSubmit,
  onValidationChange,
  onBack,
  completionActionType: type,
  redirectUrl,
  ctaText,
  successTitle,
  successText,
  failureTitle,
  failureText,
  includeIcon,
  isToggleableOutcomeIcon,
}) => {
  const initialFormData = {
    type,
    ctaText,
    redirectUrl,
    successTitle,
    successText,
    failureTitle,
    failureText,
    includeIcon,
  }
  const validationErrorMap = {
    redirectUrl: {
      minLength: 'Redirect URL is required', // TODO: translate
    },
    ctaText: {
      minLength: 'CTA text is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [formData, setFormData] = useState(initialFormData)
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    setInternalIsSubmitting(false)
  }, [])
  useEffect(() => {
    if (!isNil(validationErrors)) {
      onValidationChange(isEmpty(validationErrors))
    }
  }, [validationErrors])
  const onChangeInternal = compose(tap(setFormData), mergeRight(formData))
  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    const { type } = formData
    const formFieldsForType = prop(type)(CompletionActionFormFieldsTypeMap)
    ifElse(
      validate,
      compose(tap(onSubmit), assoc('type', type)),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(pick(formFieldsForType)(formData))
  }

  return (
    <div>
      <h1>{t('[completion action] title')}</h1>
      <p className="introduction">{t('[completion action] introduction')}</p>
      <div className={styles.container}>
        <CompletionActionForm
          formData={formData}
          onChange={onChangeInternal}
          validate={validate}
          validationErrors={validationErrors}
          isToggleableOutcomeIcon={isToggleableOutcomeIcon}
        />
      </div>
      <div className={`m-m-t-4 ${styles.navigation}`}>
        <GBG.Button
          onClick={() => {
            onBack()
          }}
          iconBefore={GBG.IconKeys.ChevronLeft24}
          kind={GBG.ButtonKind.Tertiary}
          aria-label="back-button"
        >
          {t('[journey integration] back')}
        </GBG.Button>
        <GBG.Button
          onClick={() => {
            onSubmitInternal()
          }}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          active={internalIsSubmitting}
          aria-label="submit-button"
        >
          {t('[journey integration create] continue')}
        </GBG.Button>
      </div>
    </div>
  )
}

export default CreateJourneyIntegrationCompletionActionForm
