import { useState } from 'react'
import Ajv, { JSONSchemaType } from 'ajv'
import { compose, propOr, isEmpty } from 'ramda'

import { ValidationErrorMap, ValidationResult } from './AJVFormValidation.types'
import { transformAjvValidationErrors } from './AJVFormValidation.utils'

// TODO: move this and all contents of this module to common/form module
export const useFormValidation = <T extends any | null>(
  schema: JSONSchemaType<any>,
  errorMap: ValidationErrorMap,
): [ValidationResult | null, (data: T, key?: string | null) => boolean] => {
  const [validationErrors, setValidationErrors] = useState(null)

  const ajv = new Ajv({ allErrors: true })
  const validation = ajv.compile(schema)

  const validate = (data: T) => {
    validation(data)
    const _validationErrors = compose(transformAjvValidationErrors(errorMap), propOr([], 'errors'))(validation)
    if (JSON.stringify(_validationErrors) !== JSON.stringify(validationErrors)) {
      setValidationErrors(_validationErrors)
    }
    return isEmpty(_validationErrors)
  }

  return [validationErrors, validate]
}
