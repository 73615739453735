import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './JourneyIntegrationLinkData.module.css'
import DataProtectionForm from '../../DataProtectionForm'

export interface Props {
  onCancel?: () => void
}

const JourneyIntegrationLinkData: FC<Props & WithTranslation> = ({ t, onCancel }) => {
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar type={GBG.AlertBarType.Info}>
          <GBG.AlertBarText
            title={t('[create journey integration link data protection] title')}
            text={t('[create journey integration link data protection] description')}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <div aria-label="body-text" className={styles.content}>
          <DataProtectionForm cancelable={true} onCancel={onCancel} />
        </div>
      </GBG.ModalBody>
    </>
  )
}
export default JourneyIntegrationLinkData
