import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { compose, prop } from 'ramda'
import { withTranslation } from 'react-i18next'

import JourneyIntegrationLinkModal, { Props } from './JourneyIntegrationLink.component'
import { CreateJourneyIntegrationLinkStatus, JourneyIntegrationLinkState } from './journeyIntegrationLink.types'
import { httpCreateJourneyIntegrationLink, resetJourneyIntegrationLink } from './journeyIntegrationLink.reducer'
import { navigateTo, Routes } from '../Routing'
import { dataProtectionSelector, DataProtectionState } from '../DataProtection'
import { isGatheringDPOInfoSelector } from '../../feature-flags'
import { accountBalanceSelector, accountExpiresSelector } from '../JourneyCreditAccount/journeyCreditAccount.selectors'
import { isMultiLinkCreation } from '../../feature-flags/featureFlags.selectors'

const noop = () => null

// TODO: type this correctly
const mapStateToProps = createSelector(
  [
    prop('journeyIntegrationLink'),
    dataProtectionSelector,
    isGatheringDPOInfoSelector,
    accountBalanceSelector,
    isMultiLinkCreation,
    accountExpiresSelector,
  ],
  (
    journeyIntegrationLink: JourneyIntegrationLinkState,
    dataProtection: DataProtectionState,
    isGatheringDPOInfo: boolean,
    accountBalance: number,
    isMultiLinkCreation: boolean,
    accountExpires: Date | null,
  ) => ({
    ...journeyIntegrationLink,
    /* eslint-disable */
    createStatus:
      dataProtection.loaded && dataProtection.info.dpoName.trim() === '' && isGatheringDPOInfo
        ? CreateJourneyIntegrationLinkStatus.DataProtection
        : accountBalance > 0 && (accountExpires == null || accountExpires >= new Date())
        ? journeyIntegrationLink.createStatus
        : CreateJourneyIntegrationLinkStatus.Failure,
    /* eslint-enable */
    accountBalance,
    isMultiLinkCreation,
    accountExpires,
  }),
) as any

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { onCancel = noop }) => ({
  onUpdate: (journeyIntegrationId: number, customerIds: string[]) => {
    dispatch(
      httpCreateJourneyIntegrationLink(
        customerIds.map(customerId => ({
          journeyIntegrationId,
          customerId,
        })),
      ),
    )
  },
  onNavigateToSubscriptionSettings: () => {
    dispatch(resetJourneyIntegrationLink())
    dispatch(navigateTo(Routes.PAYMENT))
    onCancel()
  },
  onCancel: () => {
    dispatch(resetJourneyIntegrationLink())
    onCancel()
  },
})

export default compose(
  withTranslation('JourneyIntegrationLink'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(JourneyIntegrationLinkModal)
