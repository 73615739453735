import { createMiddleware } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'
import { APP_INIT } from '../../modules/App'
import { AUTH_SIGNUP_SUCCESS, AUTH_LOGIN_SUCCESS } from '../../modules/Auth'
import { HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS } from '../../modules/JourneyIntegration'
import { HTTP_CREATE_JOURNEY_INTEGRATION_LINK_SUCCESS } from '../modules/JourneyIntegrationLink'
import { HTTP_CREATE_USER_SUCCESS } from '../../modules/User'

// Event definitions

const appInitEventDefinition = (action: any) => ({
  hitType: 'initialise',
  event: 'initialise',
  eventAction: 'APP INIT',
  eventCategory: 'APP INIT',
  eventLabel: 'APP INIT',
  eventValue: action.type,
})

const authSignupSuccessEventDefinition = (action: any) => ({
  hitType: 'signup',
  event: 'signup',
  eventAction: action.type,
  eventCategory: 'SIGNUP SUCCESS',
  eventLabel: 'SIGNUP SUCCESS',
  eventValue: 'SIGNUP SUCCESS',
})

const authLoginSuccessEventDefinition = (action: any) => ({
  hitType: 'login',
  event: 'login',
  eventAction: action.type,
  eventCategory: 'LOGIN SUCCESS',
  eventLabel: 'LOGIN SUCCESS',
  eventValue: 'LOGIN SUCCESS',
})

const createUserSuccessEventDefinition = (action: any) => ({
  hitType: 'createUserSuccess',
  event: 'createUserSuccess',
  eventAction: action.type,
  eventCategory: 'CREATE USER SUCCESS',
  eventLabel: 'CREATE USER SUCCESS',
  eventValue: action.payload,
})

const createJourneyIntegrationSuccessEventDefinition = (action: any) => ({
  hitType: 'createJourneyIntegration',
  event: 'createJourneyIntegration',
  eventAction: action.type,
  eventCategory: 'CREATE JOURNEY INTEGRATION SUCCESS',
  eventLabel: 'CREATE JOURNEY INTEGRATION SUCCESS',
  eventValue: action.payload,
})

const createJourneyIntegrationLinkSuccessEventDefinition = (action: any) => ({
  hitType: 'createJourneyLink',
  event: 'createJourneyLink',
  eventAction: action.type,
  eventCategory: 'CREATE LINK SUCCESS',
  eventLabel: 'CREATE LINK SUCCESS',
  eventValue: action.payload,
})

const eventsMap = {
  [APP_INIT]: appInitEventDefinition,
  [AUTH_SIGNUP_SUCCESS]: authSignupSuccessEventDefinition,
  [AUTH_LOGIN_SUCCESS]: authLoginSuccessEventDefinition,
  [HTTP_CREATE_USER_SUCCESS]: createUserSuccessEventDefinition,
  [HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS]: createJourneyIntegrationSuccessEventDefinition,
  [HTTP_CREATE_JOURNEY_INTEGRATION_LINK_SUCCESS]: createJourneyIntegrationLinkSuccessEventDefinition,
}

const gtm = GoogleTagManager()

//@ts-ignore
export const gaMiddleware = createMiddleware(eventsMap, gtm)
