import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose, pathOr } from 'ramda'
import { withTranslation } from 'react-i18next'

import PaymentMethodForm, { Props } from './PaymentMethodFormModal.component'
import { updateDefaultPaymentMethod } from '../../../common/modules/PaymentMethods'
import { createSelector } from 'reselect'
import { FormSubmissionStatus } from '../../../common/form'
import { PaymentMethodCard } from '../../../common/modules/PaymentMethods/paymentMethods.types'
import { stripeCardConfirmed } from '../../../common/modules/PaymentMethods/paymentMethods.reducer'
import { AlertType, setAlert } from '../../../common/modules/Alert'

const cardError = {
  type: AlertType.ERROR,
  title: 'Card error',
  message: 'There was an error with your card. Please try again.',
}

// TODO: extract to dedicated selector and test
const mapStateToProps = createSelector(
  [pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'createPaymentMethod', 'submissionStatus'])],
  (formSubmissionStatus: string) => ({
    isSubmitting: formSubmissionStatus === FormSubmissionStatus.SUBMITTING,
  }),
) as any

const noop = () => null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { onSubmit = noop }) => ({
  onSubmit: (paymentMethodCard: PaymentMethodCard) => {
    const { stripePaymentMethodId, isDefault } = paymentMethodCard
    isDefault && dispatch(updateDefaultPaymentMethod(stripePaymentMethodId))
    dispatch(stripeCardConfirmed({ stripePaymentMethodId, address: null }))
    onSubmit() // allow parent component to handle as well
  },
  onError: () => {
    dispatch(setAlert(cardError))
  },
})

export default compose(
  withTranslation('Payment'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(PaymentMethodForm)
