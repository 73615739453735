import { createSelector } from 'reselect'
import {
  always,
  complement,
  compose,
  equals,
  find,
  isNil,
  none,
  path,
  pathOr,
  pick,
  prop,
  propEq,
  propOr,
  values,
  when,
  head,
} from 'ramda'
import { FormState, FormSubmissionStatus } from '../../common/form'
import { UpdatePaymentView } from './payment.constants'
import { SubscriptionsState } from '../../common/modules/Subscriptions'
import { Subscription } from '../../common/modules/Subscriptions/subscriptions.types'
import { paymentMethodsSelector } from '../../common/modules/PaymentMethods/paymentMethods.selectors'
import { PaymentMethod } from '../../common/modules/PaymentMethods/paymentMethods.types'
import { PaymentHistoryState } from './payment.types'
import { UserState } from '../User'

//---------------------------------
// update payment props
//---------------------------------

export const updatePaymentPropsSelector = createSelector([path(['form', 'update-payment'])], (form: FormState) => {
  if (isNil(form)) {
    return {
      isSubmitting: false,
      viewName: null,
    }
  }
  const isSubmitting = compose(
    equals(FormSubmissionStatus.SUBMITTING),
    propOr(FormSubmissionStatus.UNSUBMITTED, 'submissionStatus'),
  )(form)
  const hasAllData = compose(none(isNil), values, pick(['stripePriceId', 'stripePaymentMethodId']), prop('data'))(form)
  const hasStripePriceId = compose(complement(isNil), path(['data', 'stripePriceId']))(form)
  const allDataSubmissionStatusViewMap = {
    [FormSubmissionStatus.SUCCESS]: UpdatePaymentView.SUCCESS,
  }
  const viewName = !hasAllData
    ? hasStripePriceId
      ? UpdatePaymentView.CHECKOUT
      : UpdatePaymentView.SUBSCRIPTION
    : propOr(UpdatePaymentView.CHECKOUT, prop('submissionStatus')(form))(allDataSubmissionStatusViewMap)
  return {
    isSubmitting,
    viewName,
  }
}) as any

//---------------------------------
// selected subscription
// ... for update payment
//---------------------------------

export const updatePaymentSelectedSubscriptionSelector = createSelector(
  [prop('subscriptions'), pathOr(null, ['form', 'update-payment', 'data'])],
  (subscriptions: SubscriptionsState, formData: FormData) => {
    const stripePriceId = prop('stripePriceId')(formData)
    return compose(when(isNil, always(null)), find(propEq('stripePriceId', stripePriceId)))(subscriptions)
  },
)

//---------------------------------
// order summary props
// ... for update payment
//---------------------------------

export const updatePaymentOrderSummaryPropsSelector = createSelector(
  [updatePaymentSelectedSubscriptionSelector],
  (subscription: Subscription) => {
    const todayDate = new Intl.DateTimeFormat(process.env.REACT_APP_LOCALE).format(new Date())
    if (isNil(subscription)) {
      return {
        items: [],
        totalAmount: null,
        currency: null,
      }
    }
    return {
      items: [
        {
          renewalDate: todayDate,
          amount: prop('price')(subscription),
        },
      ],
      totalAmount: prop('price')(subscription),
      currency: prop('currency')(subscription),
    }
  },
) as any

//---------------------------------
// checkout props
// ... for update payment
//---------------------------------

export const updatePaymentCheckoutPropsSelector = createSelector(
  [path(['form', 'update-payment']), paymentMethodsSelector, updatePaymentSelectedSubscriptionSelector],
  (
    form,
    { paymentMethods, currentPaymentMethodId }: { paymentMethods: PaymentMethod[]; currentPaymentMethodId: string },
    subscription: Subscription,
  ) => ({
    isSubmitting:
      propOr(FormSubmissionStatus.UNSUBMITTED, 'submissionStatus')(form) === FormSubmissionStatus.SUBMITTING,
    hasPaymentMethods: paymentMethods.length > 0,
    paymentMethods,
    selectedStripePriceId: pathOr(null, ['data', 'stripePriceId'])(form),
    clientSecret: pathOr(null, ['data', 'stripeClientSecret'])(form),
    currentPaymentMethodId: isNil(currentPaymentMethodId)
      ? compose(prop('stripePaymentMethodId'), head)(paymentMethods)
      : currentPaymentMethodId,
    totalAmount: propOr(null, 'price')(subscription),
    currency: propOr(null, 'currency')(subscription),
  }),
) as any

//---------------------------------
// payment props
// ... for payment
//---------------------------------

export const paymentPropsSelector = createSelector(
  [paymentMethodsSelector, prop('subscriptions'), path(['user', 'autoRenew']), path(['user', 'stripeSubscriptionId'])],
  (
    { paymentMethods }: { paymentMethods: PaymentMethod[] },
    subscriptions: SubscriptionsState,
    isAutoRenewing: boolean,
    stripeSubscriptionId: string,
  ) => ({
    hasPaymentMethods: paymentMethods.length > 0,
    numberOfPaymentMethods: paymentMethods.length,
    paymentMethods,
    subscriptions,
    isAutoRenewing,
    stripeSubscriptionId,
  }),
) as any

//---------------------------------
// payment auto renew
//---------------------------------

export const paymentAutoRenewSelector = createSelector(
  [paymentMethodsSelector, prop('user'), prop('subscriptions'), path(['user', 'autoRenew'])],
  (
    { paymentMethods, currentPaymentMethodId }: { paymentMethods: PaymentMethod[]; currentPaymentMethodId: string },
    user: UserState,
    subscriptions: Subscription[],
    isAutoRenewing: boolean,
  ) => ({
    hasPaymentMethods: paymentMethods.length > 0,
    paymentMethods,
    currentStripePaymentMethodId: currentPaymentMethodId,
    currentStripePriceId: propOr(null, 'stripePriceId')(user),
    subscriptions,
    isAutoRenewing,
  }),
) as any

//---------------------------------
// payment history props
// ... for payment
//---------------------------------

export const paymentHistorySelector = createSelector(
  [prop(['paymentHistory'])],
  (paymentHistory: PaymentHistoryState) => ({
    paymentHistory,
  }),
)
