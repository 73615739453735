import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import { AlertType, resetAlert, setAlert } from '../../../../common/modules/Alert'
import {
  createJourneyIntegrationProceed,
  createJourneyIntegrationBack,
} from '../../createJourneyIntegrationCompletedSteps.reducer'
import { setCompletionAction } from '../../journeyIntegration.reducer'
import { completionActionSelector } from '../../journeyIntegration.selectors'
import { CompletionAction } from '../../journeyIntegration.types'
import CompletionActionForm from './CompletionActionForm.component'
import { isToggleableOutcomeIcon } from '../../../../common/feature-flags/featureFlags.selectors'

const mapStateToProps = createSelector(
  [completionActionSelector, isToggleableOutcomeIcon],
  (completionActionSelector, isToggleableOutcomeIcon) => ({
    ...completionActionSelector,
    isToggleableOutcomeIcon,
  }),
)

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onBack: () => dispatch(createJourneyIntegrationBack()),
  onSubmit: (completionAction: CompletionAction) => {
    dispatch(resetAlert())
    dispatch(setCompletionAction(completionAction))
    dispatch(createJourneyIntegrationProceed())
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
  onValidationChange: (isValid: boolean) => {
    dispatch(isValid ? resetAlert() : setAlert(invalidPayload))
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect(mapStateToProps, mapDispatchToProps),
)(CompletionActionForm)
