import { Middleware } from 'redux'
import { compose, includes } from 'ramda'

import {
  HTTP_GET_JOURNEY_CREDIT_ACCOUNT_SUCCESS,
  httpGetJourneyCreditAccount,
  setCreditAccount,
} from './journeyCreditAccount.reducer'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'

//---------------------------------
// get journey credit account
// ... starts the API get journey credit account
//---------------------------------

export const getJourneyCreditAccountFlow = (actions: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
    if (isAuthenticated) {
      dispatch(httpGetJourneyCreditAccount())
    }
  }
}

//---------------------------------
// set account balance
// ... signals intention to set account balance
// ... returned from the api
//--------------------------------

export const setBalanceFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_CREDIT_ACCOUNT_SUCCESS) {
    dispatch(setCreditAccount(payload))
  }
}
