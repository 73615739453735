export enum CreateJourneyIntegrationLinkStatus {
  DataProtection = 'data_protection',
  Ready = 'ready',
  Loading = 'loading',
  Success = 'success',
  Failure = 'failure',
}

export interface JourneyIntegrationLinkResponse {
  journeyIntegrationId: number
  customerId: string
  url: string
}

export interface JourneyIntegrationLinkState {
  journeyIntegrationId: number | null
  customerId: string | null
  urls: string[] | null
  createStatus: CreateJourneyIntegrationLinkStatus
}
