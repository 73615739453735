import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './WelcomeHero.module.css'
import Icon from '../../../common/modules/SVGIcon/SVGIcon.component'
import { IconType } from '../../../common/modules/SVGIcon/SVGIcon.types'

interface Props {
  name: string
  onNavigateToCreateJourney: () => void
}

const WelcomeHero: FC<Props & WithTranslation> = ({ t, name, onNavigateToCreateJourney }) => {
  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <h3 className={styles.title}>
          {t('[dashboard welcome] title')} {name}
        </h3>
        <p className={styles.paragraph}>{t('[dashboard welcome] explainer')}</p>
        <a onClick={() => onNavigateToCreateJourney()} className={styles.link}>
          {t('[dashboard welcome] create new journey template link')} <Icon name={IconType.ArrowDeepBlue} />
        </a>
      </div>
      <div className={styles.icon}>
        <Icon name={IconType.WelcomeProfile} />
      </div>
    </div>
  )
}

export default WelcomeHero
