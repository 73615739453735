import { createAction, createReducer } from '@reduxjs/toolkit'
import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'

import { JourneyCreditAccountState } from './journeyCreditAccount.types'

export const GET_JOURNEY_CREDIT_ACCOUNT = '[dashboard] get journey credit account'
export const getJourneyCreditAccount = createAction(GET_JOURNEY_CREDIT_ACCOUNT)

export const HTTP_GET_JOURNEY_CREDIT_ACCOUNT = '[dashboard] HTTP get journey credit account'
export const httpGetJourneyCreditAccount = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_CREDIT_ACCOUNT)({ endpoint: 'journey-credit-accounts' })
export const HTTP_GET_JOURNEY_CREDIT_ACCOUNT_SUCCESS = '[dashboard] HTTP get journey credit account success'
export const httpGetJourneyCreditAccountSuccess = createAction(HTTP_GET_JOURNEY_CREDIT_ACCOUNT_SUCCESS)
export const HTTP_GET_JOURNEY_CREDIT_ACCOUNT_FAILURE = '[dashboard] HTTP get journey credit account failure'
export const httpGetJourneyCreditAccountFailure = createAction(HTTP_GET_JOURNEY_CREDIT_ACCOUNT_FAILURE)

export const SET_CREDIT_ACCOUNT = '[dashboard] set account balance'
export const setCreditAccount = createAction<{ balance: number; expiresAt: Date | null }>(SET_CREDIT_ACCOUNT)

const assocCreditAccount = (
  state: JourneyCreditAccountState,
  { payload: { expiresAt, balance } }: ActionWithPayload<{ balance: number; expiresAt: Date | null }>,
) => ({
  ...state,
  balance,
  expiresAt: expiresAt ? new Date(expiresAt) : expiresAt,
})

export const INITIAL_STATE: JourneyCreditAccountState = {
  balance: 0,
  expiresAt: null,
}

const reducer = createReducer(INITIAL_STATE, {
  [SET_CREDIT_ACCOUNT]: assocCreditAccount,
})
export default reducer
