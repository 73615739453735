import { Middleware } from 'redux'

export { default as CreateJourneyIntegration } from './CreateJourneyIntegration'
export { default as UpdateJourneyIntegration } from './UpdateJourneyIntegration'
export { default as translations } from './JourneyIntegration.intl'
export {
  default as journeyIntegration,
  DEACTIVATE_JOURNEY_INTEGRATION,
  activateJourneyIntegration,
  deactivateJourneyIntegration,
  confirmDeactivateJourneyIntegration,
  HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS,
} from './journeyIntegration.reducer'
export {
  default as createJourneyIntegrationCompletedSteps,
  INITIAL_STATE as createJourneyIntegrationCompletedStepsInitialState,
} from './createJourneyIntegrationCompletedSteps.reducer'
export {
  default as selectedJourneyIntegration,
  setSelectedJourneyIntegration,
} from './selectedJourneyIntegration.reducer'
import { createJourneyIntegrationSteps as steps } from './CreateJourneyIntegration'
import { createJourneyIntegrationProceedFlow as _createJourneyIntegrationProceedFlow } from './journeyIntegration.middleware'
export {
  createJourneyIntegrationFlow,
  setupLaterFlow,
  resetFlow,
  getJourneyIntegrationLogoUploadURLSuccessFlow,
  updateJourneyIntegrationNavigateFlow,
  createJourneyIntegrationBackFlow,
  getJourneyIntegrationLogoUploadURLFlow,
  uploadJourneyIntegrationLogoFlow,
  uploadJourneyIntegrationLogoSuccessFlow,
  updateJourneyIntegrationFlow,
  httpCreateJourneyIntegrationSuccessFlow,
  httpUpdateJourneyIntegrationSuccessFlow,
  initialSelectedJourneyIntegrationFlow,
  deactivateJourneyIntegrationFlow,
  activateJourneyIntegrationFlow,
  setIsLoadingFlow,
  createJourneyIntegrationFormSubmissionSuccessFlow,
  createJourneyIntegrationFormSubmissionFailureFlow,
  updateJourneyIntegrationFormSubmissionSubmittingFlow,
  updateJourneyIntegrationFormSubmissionFailureFlow,
  updateJourneyIntegrationPersonalisationFormSubmissionFailureFlow,
} from './journeyIntegration.middleware'
export type { JourneyIntegrationState } from './journeyIntegration.types'

export const createJourneyIntegrationProceedFlow = (): Middleware => _createJourneyIntegrationProceedFlow({ steps })
