import React, { FC, useState } from 'react'
import { map, isEmpty } from 'ramda'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import styles from './JourneyIntegration.module.css'
import Icon from '../SVGIcon/SVGIcon.component'
import { IconType } from '../SVGIcon/SVGIcon.types'

interface JourneyTypeStepExtra {
  id: number
  name: string
}

interface JourneyTypeStepWithExtras {
  id: number
  name: string
  extras?: JourneyTypeStepExtra[]
}

interface LogoImageType {
  srcUrl: string
  alt: string
}

interface Props {
  id: number
  name: string
  description: string
  steps: JourneyTypeStepWithExtras[]
  isActive: boolean
  logoUrl: string
  onManage: (id: number) => void
  onToggleActivation: (id: number) => void
  onCreateLink: (id: number) => void
  companyName: string
}

const JourneyIntegration: FC<Props & WithTranslation> = ({
  t,
  id,
  name,
  description,
  steps,
  isActive,
  onManage,
  onToggleActivation,
  onCreateLink,
  logoUrl,
  companyName,
}) => {
  const onManageInternal = () => {
    onManage(id)
  }
  const onToggleActivationInternal = () => {
    onToggleActivation(id)
  }
  const renderJourneyIntegrationStepExtra = ({ id, name }: JourneyTypeStepExtra) => (
    <li key={id} className={styles.subListItem}>
      {name}
    </li>
  )
  const renderJourneyIntegrationSteps = ({ id, name, extras = [] }: JourneyTypeStepWithExtras) => (
    <li key={id} className={styles.listItem}>
      <div className={styles.positionListStyleIcon}>
        <Icon name={IconType.ListStyle} />
      </div>
      {name}
      {!isEmpty(extras) && <span className={styles.journeyTypeStepsCount}>({extras.length})</span>}
      {!isEmpty(extras) && <ul className={styles.subList}>{map(renderJourneyIntegrationStepExtra, extras)}</ul>}
    </li>
  )
  const LogoImage = ({ srcUrl, alt }: LogoImageType) => {
    const [error, setError] = useState(false)

    const onError = () => {
      setError(true)
    }

    return error ? (
      <GBG.Avatar name={companyName.charAt(0)} />
    ) : (
      <img className={styles.logoImage} src={srcUrl} alt={alt} onError={onError} />
    )
  }

  return (
    <GBG.Card style={{ width: '100%', maxWidth: '25.25rem' }}>
      <GBG.CardHeader>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LogoImage srcUrl={logoUrl} alt={companyName} />
          <GBG.OverflowMenu
            onItemSelected={(index: number) => {
              index == 0 ? onManageInternal() : onToggleActivationInternal()
            }}
            items={[
              {
                title: t('[journey integration component] manage'),
              },
              {
                title: isActive
                  ? t('[journey integration component] deactivate')
                  : t('[journey integration component] activate'),
                danger: true,
              },
            ]}
            direction={GBG.OverflowMenuDirection.Left}
          ></GBG.OverflowMenu>
        </div>

        <h1 className={styles.title}>{name}</h1>
        <p className={styles.description}>{description}</p>
      </GBG.CardHeader>
      <GBG.CardBody>
        <ul className={styles.list}>{!isEmpty(steps) && map(renderJourneyIntegrationSteps, steps)}</ul>
        <div className={styles.statusIndicator}>
          {isActive ? '' : t('[journey integration component] status indicator')}
        </div>
        <GBG.Button
          style={{ float: 'right', marginBottom: '1rem' }}
          onClick={() => {
            onCreateLink(id)
          }}
          iconBefore={GBG.IconKeys.Link24}
          kind={GBG.ButtonKind.Secondary}
          size={GBG.ButtonSize.Small}
        >
          {t('[journey integration component] create link')}
        </GBG.Button>
      </GBG.CardBody>
    </GBG.Card>
  )
}

export default JourneyIntegration
