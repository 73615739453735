import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { compose, path } from 'ramda'

import TotalUsage from './TotalUsage.component'
import { JourneyIntegrationLinkResult } from '../../../common/modules/JourneyIntegrationLinkResults'

// TODO: extract and test
const mapStateToProps = createSelector(
  [path(['journeyIntegrationLinkResults', 'all', 'week'])],
  (results: JourneyIntegrationLinkResult) => ({ results }),
) as any

export default compose(withTranslation('Dashboard'), connect(mapStateToProps))(TotalUsage)
