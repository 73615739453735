import { useState, useEffect } from 'react'
// TODO: replace any with correct types
export const useDetectOutsideClick = (dropdownRef: any, initialState: boolean) => {
  const [menuIsActive, setMenuIsActive] = useState(initialState)

  useEffect(() => {
    const onClick = (e: any) => {
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setMenuIsActive(!menuIsActive)
      }
    }

    if (menuIsActive) {
      window.addEventListener('click', onClick)
    }

    return () => {
      window.removeEventListener('click', onClick)
    }
  }, [menuIsActive])

  return [menuIsActive, setMenuIsActive]
}
