import { createAction, createReducer } from '@reduxjs/toolkit'
import { add, always, subtract, __ } from 'ramda'

import { RESET } from './journeyIntegration.reducer'

//---------------------------------
// actions
//---------------------------------

export const CREATE_JOURNEY_INTEGRATION_PROCEED = '[create journey integration] proceed'
export const CREATE_JOURNEY_INTEGRATION_PROCEED_FAILURE = '[create journey integration] proceed failure'
export const createJourneyIntegrationProceed = createAction(CREATE_JOURNEY_INTEGRATION_PROCEED)
export const createJourneyIntegrationProceedFailure = createAction<string>(CREATE_JOURNEY_INTEGRATION_PROCEED_FAILURE)

export const CREATE_JOURNEY_INTEGRATION_BACK = '[create journey integration] back'
export const createJourneyIntegrationBack = createAction(CREATE_JOURNEY_INTEGRATION_BACK)

export const CREATE_JOURNEY_INTEGRATION_COMPLETE = '[create journey integration] complete'
export const createJourneyIntegrationComplete = createAction(CREATE_JOURNEY_INTEGRATION_COMPLETE)

export const CREATE_JOURNEY_INTEGRATION_INCREMENT_COMPLETED_STEPS =
  '[create journey integration] increment completed steps'
export const createJourneyIntegrationIncrementCompletedSteps = createAction(
  CREATE_JOURNEY_INTEGRATION_INCREMENT_COMPLETED_STEPS,
)

export const CREATE_JOURNEY_INTEGRATION_DECREMENT_COMPLETED_STEPS =
  '[create journey integration] decrement completed steps'
export const createJourneyIntegrationDecrementCompletedSteps = createAction(
  CREATE_JOURNEY_INTEGRATION_DECREMENT_COMPLETED_STEPS,
)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE = 0

//---------------------------------
// reducers
//---------------------------------

const incrementCompletedSteps = add(1)
const decrementCompletedSteps = subtract(__, 1)

const resetState = always(INITIAL_STATE)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {
  [CREATE_JOURNEY_INTEGRATION_INCREMENT_COMPLETED_STEPS]: incrementCompletedSteps,
  [CREATE_JOURNEY_INTEGRATION_DECREMENT_COMPLETED_STEPS]: decrementCompletedSteps,
  [RESET]: resetState,
})

export default reducer
