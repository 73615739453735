import React, { FC } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './SubscriptionItem.module.css'

interface Props {
  name: string
  description: string
  descriptionRatio: string
  price: string
  isCurrent?: boolean
  isSelected?: boolean
  isSelectable?: boolean
}

const noop = () => null

const SubscriptionItem: FC<Props> = ({ name, description, descriptionRatio, price, isSelected, isSelectable }) => (
  <div>
    <div className={isSelected ? styles.cardItemIsSelected : styles.cardItem}>
      <span className={styles.checkboxName}>
        <GBG.FormGroup type={GBG.FormGroupType.Checkbox} className={styles.checkboxInput}>
          {isSelectable && <GBG.Checkbox checked={isSelected} onChange={noop} />}
          <GBG.FormGroupInfo>
            <GBG.Label>
              <GBG.LabelText>{name}</GBG.LabelText>
            </GBG.Label>
          </GBG.FormGroupInfo>
        </GBG.FormGroup>
      </span>
      <span className={styles.description}>
        <strong>{description}</strong> ({descriptionRatio})
      </span>
      <span className={styles.price}>{price}</span>
    </div>
  </div>
)

export default SubscriptionItem
