import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { nAry } from 'ramda'

import { Modal } from '../../../common/modules/Modal'
import styles from './CancelAutoRenewModal.module.css'

export interface Props {
  onSubmit: () => void
  onCancel?: () => void
}

const noop = () => null

const CancelAutoRenewModal: FC<Props & WithTranslation> = ({ t, onSubmit, onCancel = noop }) => {
  const renderCancelAutoRenewFooter = (onCancel: () => void, onSubmit: () => void) => (
    <>
      <div className={`m-m-t-2 ${styles.buttonWrapper}`}>
        <GBG.Button
          className={`m-m-r-1 ${styles.cancelButton}`}
          onClick={() => {
            onCancel()
          }}
          aria-label="cancel-button"
          kind={GBG.ButtonKind.Secondary}
        >
          {t('[payment auto renew] cancel auto renew no button title')}
        </GBG.Button>
        <GBG.Button
          className={`m-m-r-2 ${styles.confirmButton}`}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          aria-label="submit-button"
          onClick={nAry(0, onSubmit)}
        >
          {t('[payment auto renew] cancel auto renew yes button title')}
        </GBG.Button>
      </div>
    </>
  )
  return (
    <div className={styles.container}>
      <Modal
        style="none"
        modalType="ModalCard"
        title={t('[payment auto renew] cancel auto renew modal title')}
        description=""
        renderBody={() => (
          <div aria-label="body-text">
            {t('[payment auto renew] cancel auto renew modal description')}
            {renderCancelAutoRenewFooter(onCancel, onSubmit)}
          </div>
        )}
      />
    </div>
  )
}

export default CancelAutoRenewModal
