import React, { FC, useEffect, useState } from 'react'
import { assoc, prop, path, has, isNil, mapObjIndexed, compose, values, pick } from 'ramda'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { ValidationResult } from '../../../common/modules/AJVFormValidation'
import { CompletionActionFormData } from './CompletionActionForm.types'
import styles from './CompletionActionForm.module.css'
import { CompletionActionTypesForm } from '../CompletionActionTypesForm'
import { formFieldsTypeMap } from './CompletionActionForm.constants'

interface RenderFormFieldProps {
  type: string
  label: string
  placeholder: string
  explainer?: string
  optional?: string
}

interface Props {
  formData: CompletionActionFormData
  onChange: (formData: CompletionActionFormData) => void
  validate: (formData: CompletionActionFormData) => boolean
  validationErrors: ValidationResult | null
  isToggleableOutcomeIcon?: boolean
}

const CompletionActionForm: FC<Props & WithTranslation> = ({
  t,
  formData,
  onChange,
  validate,
  validationErrors,
  isToggleableOutcomeIcon,
}) => {
  const [completionActionType, setCompletionActionType] = useState(prop('type')(formData))
  const [showFields, setShowFields] = useState(prop(completionActionType)(formFieldsTypeMap))
  useEffect(() => {
    setShowFields(prop(completionActionType)(formFieldsTypeMap))
    onChange(assoc('type', completionActionType)(formData))
  }, [completionActionType])
  const onChangeInternal = (key: string) => (event: any) => {
    if (key === 'includeIcon') {
      onChange(assoc(key, path(['target', 'checked'])(event))(formData))
    } else {
      onChange(assoc(key, path(['target', 'value'])(event))(formData))
    }
  }
  const onBlurInternal = () => {
    validate(pick(showFields)(formData))
  }
  const renderFormField = ({ type, label, explainer, placeholder, optional }: RenderFormFieldProps, key: string) => {
    const inputProps = {
      placeholder,
      onChange: onChangeInternal(key),
      onBlur: onBlurInternal,
      value: prop(key)(formData),
      'aria-label': key,
    }
    return (
      <GBG.FormGroup key={key}>
        <GBG.Label optional={!isNil(optional)} htmlFor={key}>
          {label}
        </GBG.Label>
        {isNil(explainer) ? null : <GBG.Assistive>{explainer}</GBG.Assistive>}
        {type === 'textarea' ? (
          <GBG.Textarea error={has(key, validationErrors)} {...inputProps} />
        ) : type === 'switch' ? (
          <GBG.Switch
            error={has(key, validationErrors)}
            checked={prop(key)(formData)}
            onBlur={onBlurInternal}
            onChange={onChangeInternal(key)}
            ariaLabel={key}
          />
        ) : (
          <GBG.Text error={has(key, validationErrors)} {...inputProps} />
        )}
        {!has(key, validationErrors) ? null : <GBG.ErrorText>{path([key, 'message'])(validationErrors)}</GBG.ErrorText>}
      </GBG.FormGroup>
    )
  }
  const formFieldsConfig = {
    redirectUrl: {
      label: t('[completion action] result redirect url input label'),
      explainer: t('[completion action] result redirect url input explainer'),
      placeholder: t('[completion action] redirect url placeholder'),
      type: 'url',
    },
    ctaText: {
      label: t('[completion action] cta text input label'),
      explainer: t('[completion action] cta text input explainer'),
      placeholder: t('[completion action] cta text input placeholder'),
      type: 'text',
    },
    successTitle: {
      label: t('[completion action] success title input label'),
      explainer: t('[completion action] success title input explainer'),
      placeholder: t('[completion action] success title input placeholder'),
      type: 'text',
      optional: t('[completion action] optional input marker'),
    },
    successText: {
      label: t('[completion action] success text input label'),
      explainer: t('[completion action] success text input explainer'),
      placeholder: t('[completion action] success text input placeholder'),
      type: 'textarea',
      optional: t('[completion action] optional input marker'),
    },
    failureTitle: {
      label: t('[completion action] failure title input label'),
      explainer: t('[completion action] failure title input explainer'),
      placeholder: t('[completion action] failure title input placeholder'),
      type: 'text',
      optional: t('[completion action] optional input marker'),
    },
    failureText: {
      label: t('[completion action] failure text input label'),
      explainer: t('[completion action] failure text input explainer'),
      placeholder: t('[completion action] failure text input placeholder'),
      type: 'textarea',
      optional: t('[completion action] optional input marker'),
    },
    // includeIcon: {
    //   label: t('[completion action] include icon input label'),
    //   explainer: t('[completion action] include icon input explainer'),
    //   placeholder: t('[completion action] include icon input placeholder'),
    //   type: 'switch',
    //   optional: t('[completion action] optional input marker'),
    // },
  } as any // TODO: fix
  if (isToggleableOutcomeIcon) {
    formFieldsConfig.includeIcon = {
      label: t('[completion action] include icon input label'),
      explainer: t('[completion action] include icon input explainer'),
      placeholder: t('[completion action] include icon input placeholder'),
      type: 'switch',
      optional: t('[completion action] optional input marker'),
    }
  }
  const formFields = compose(values, mapObjIndexed(renderFormField), pick(showFields))(formFieldsConfig)
  return (
    <div>
      <div className={`m-m-b-3 ${styles.content}`}>
        <GBG.FormGroup type={GBG.FormGroupType.RadioList}>
          <GBG.Label>{t('[journey integration] completion action title')}</GBG.Label>
          <GBG.Assistive className={styles.assistive}>
            {t('[journey integration] completion action explainer')}
          </GBG.Assistive>
          <GBG.FormGroupControls layout={GBG.FormGroupControlsLayout.Horizontal}>
            <CompletionActionTypesForm type={completionActionType} onChange={setCompletionActionType} />
          </GBG.FormGroupControls>
        </GBG.FormGroup>
      </div>
      <div className={styles.mainform}>{formFields}</div>
    </div>
  )
}

export default CompletionActionForm
