import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import PaymentAutoRenew from './PaymentAutoRenew.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'
import { paymentAutoRenewSelector } from '../payment.selectors'
import { cancelAutoRenew, enableAutoRenew } from '../../Organisation'

const mapStateToProps = paymentAutoRenewSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onCancel: () => {
    dispatch(navigateTo(Routes.PAYMENT))
  },
  onCancelAutoRenew: () => {
    dispatch(cancelAutoRenew())
  },
  onEnableAutoRenew: () => {
    dispatch(enableAutoRenew())
  },
})

export default compose(withTranslation('Payment'), connect(mapStateToProps, mapDispatchToProps))(PaymentAutoRenew)
