import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import styles from './journeyCreditAccountBalance.module.css'
import JourneyIntegrationChart from '../JourneyIntegrationChart'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { Routes } from '../Routing/constants'

interface Props {
  expiredActions: boolean
  accountBalance: number
  expiryDate: Date
  onNavigateTo: (path: string) => void
}

// TODO: add auto renew text and number
const AccountBalance: FC<Props & WithTranslation> = ({
  t,
  expiredActions,
  accountBalance,
  expiryDate,
  onNavigateTo,
}) => {
  const dateFormatter = new Intl.DateTimeFormat()
  const data = [{ name: '', value: expiryDate == null || expiryDate > new Date() ? accountBalance : 0, key: 'balance' }]
  return (
    <div className={styles.AccountBalance}>
      <h3>{t('[account] balance title')}</h3>
      {expiryDate == null || expiryDate > new Date() ? (
        <span>
          <p className={styles.description}>
            {accountBalance} {t('[account] balance remaining text')}
            {expiryDate ? (
              <span>
                {' '}
                {t('[account] balance expires text')}: {dateFormatter.format(new Date(expiryDate))}
              </span>
            ) : null}
            <JourneyIntegrationChart data={data as any} />
          </p>
        </span>
      ) : (
        <>
          <p className={styles.description}>
            {t('[account] balance expired text')}: {dateFormatter.format(new Date(expiryDate))}
          </p>
          {expiredActions ? (
            <GBG.Button onClick={() => onNavigateTo(Routes.PAYMENT)}>Update your subscription</GBG.Button>
          ) : null}
        </>
      )}
    </div>
  )
}

export default AccountBalance
