import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import CancelSubscriptionModal from './CancelSubscriptionModal.component'

import { confirmCancelSubscription } from '../../Organisation'
import { updatePaymentSubscriptionSelector } from '../UpdatePaymentSubscription/updatePaymentSubscription.selectors'

const mapStateToProps = updatePaymentSubscriptionSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: () => {
    dispatch(confirmCancelSubscription())
  },
})

export default compose(
  withTranslation('Payment'),
  connect(mapStateToProps, mapDispatchToProps),
)(CancelSubscriptionModal)
