import { compose, join, map } from 'ramda'

import { AcceptedFileType } from './ImageUpload.types'

export const convertHTMLFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })

export const formatAcceptedFileTypeArrayToString = compose(
  join(', '),
  map((x: AcceptedFileType) => `.${x}`),
)
