import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

export interface Props {
  onClose: () => void
}

const PurposeOfProcessing: FC<Props & WithTranslation> = ({ t, onClose }) => (
  <>
    <GBG.ModalHeader>
      <GBG.AlertBar type={GBG.AlertBarType.Info}>{t('[dataprotectionform] POP helper title')}</GBG.AlertBar>
    </GBG.ModalHeader>
    <GBG.ModalBody>
      <p>{t('[dataprotectionform] POP helper para1')}</p>
      <ol>
        <li>
          <strong>{t('[dataprotectionform] POP helper point1 title')}</strong>
          <p>{t('[dataprotectionform] POP helper point1')}</p>
        </li>
        <li>
          <strong>{t('[dataprotectionform] POP helper point2 title')}</strong>
          <p>{t('[dataprotectionform] POP helper point2')}</p>
        </li>
      </ol>
      <GBG.Button onClick={onClose}>Close</GBG.Button>
    </GBG.ModalBody>
  </>
)

export default PurposeOfProcessing
