import { createAction, createReducer } from '@reduxjs/toolkit'

import { DataProtectionInformation, DataProtectionState } from './DataProtection.types'
import { ActionWithPayload } from '../../../common/redux/types'
import { HTTPPayload } from '../../../common/redux-http'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_DATA_PROTECTION = '[dataprotection] HTTP get dataprotection'
export const httpGetDataProtection = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_DATA_PROTECTION)({ endpoint: 'data-protection' })

export const HTTP_GET_DATA_PROTECTION_SUCCESS = '[dataprotection] HTTP get dataprotection success'
export const httpGetDataProtectionSuccess = createAction(HTTP_GET_DATA_PROTECTION_SUCCESS)

export const HTTP_GET_DATA_PROTECTION_FAILURE = '[dataprotection] HTTP get dataprotection failure'
export const httpGetDataProtectionFailure = createAction(HTTP_GET_DATA_PROTECTION_FAILURE)

export const UPDATE_DATA_PROTECTION = '[dataprotection] update data protection'

export const updateDataProtection = (info: DataProtectionInformation): ActionWithPayload<any> => ({
  type: UPDATE_DATA_PROTECTION,
  payload: { data: info },
})

//-----------------
// CREATE
//-----------------

export const HTTP_CREATE_DATA_PROTECTION = '[dataprotection] HTTP create'
export const HTTP_CREATE_DATA_PROTECTION_SUCCESS = '[dataprotection] HTTP create success'
export const HTTP_CREATE_DATA_PROTECTION_FAILURE = '[dataprotection] HTTP create failure'
export const httpCreateDataProtection = (data: DataProtectionInformation): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_DATA_PROTECTION)({ endpoint: 'data-protection', data })
export const httpCreateDataProtectionSuccess = createAction<DataProtectionInformation>(
  HTTP_CREATE_DATA_PROTECTION_SUCCESS,
)
export const httpCreateDataProtectionFailure = createAction<string>(HTTP_CREATE_DATA_PROTECTION_FAILURE)

//-----------------
// UPDATE
//-----------------

export const HTTP_UPDATE_DATA_PROTECTION = '[dataprotection] HTTP update data protection'
export const HTTP_UPDATE_DATA_PROTECTION_SUCCESS = '[dataprotection] HTTP update data protection success'
export const HTTP_UPDATE_DATA_PROTECTION_FAILURE = '[dataprotection] HTTP update data protection failure'

export const httpUpdateDataProtection = (data: {
  dpOfficerName?: string
  dpOfficerEmail?: string
  dpOfficerPhone?: string
  processingPurpose?: string
  lawfulBasis?: string
  legalEmail?: string
  companyRegNumber?: string
  icoNumber?: string
}): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_DATA_PROTECTION)({ endpoint: 'data-protection', data })

export const httpUpdateDataProtectionSuccess = createAction(HTTP_UPDATE_DATA_PROTECTION_SUCCESS)
export const httpUpdateDataProtectionFailure = createAction(HTTP_UPDATE_DATA_PROTECTION_FAILURE)

export const SET_DATA_PROTECTION = '[dataprotection] set data protection'
export const setDataProtection = createAction<DataProtectionInformation>(SET_DATA_PROTECTION)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE: DataProtectionState = {
  loaded: false,
  info: {
    dpoName: '',
    dpoEmail: '',
    dpoPhone: '',
    processingPurpose: undefined,
    lawfulBasis: undefined,
    legalContactEmail: '',
    companyRegNumber: '',
    icoNumber: '',
  },
}

//---------------------------------
// reducers
//---------------------------------

const overwriteDataProtection = (
  state: DataProtectionState,
  { payload }: ActionWithPayload<DataProtectionInformation>,
) => ({
  ...state,
  loaded: true,
  info: payload,
})

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {
  [SET_DATA_PROTECTION]: overwriteDataProtection,
  [HTTP_GET_DATA_PROTECTION_FAILURE]: (state: DataProtectionState) => ({
    ...state,
    loaded: true,
  }),
})

export default reducer
