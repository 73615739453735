import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './ChangePasswordHeader.module.css'

interface Props {
  onNavigate: () => void
}

const ChangePasswordHeader: FC<Props & WithTranslation> = ({ t, onNavigate }) => (
  <>
    <button onClick={() => onNavigate()} className={styles.navigateToLoginButton}>
      {t('[auth] login submit label')}
    </button>
  </>
)

export default ChangePasswordHeader
