import {
  HTTP_DELETE_REQUEST_REGEX,
  HTTP_GET_REQUEST_REGEX,
  HTTP_PUT_REQUEST_REGEX,
  HTTP_PATCH_REQUEST_REGEX,
  HTTP_POST_REQUEST_REGEX,
} from './constants'

//---------------------------------
// calculate HTTP method
//---------------------------------

export const calculateHTTPMethod = (type: string): string | null => {
  if (HTTP_GET_REQUEST_REGEX.test(type)) {
    return 'get'
  }
  if (HTTP_PATCH_REQUEST_REGEX.test(type)) {
    return 'patch'
  }
  if (HTTP_PUT_REQUEST_REGEX.test(type)) {
    return 'put'
  }
  if (HTTP_POST_REQUEST_REGEX.test(type)) {
    return 'post'
  }
  if (HTTP_DELETE_REQUEST_REGEX.test(type)) {
    return 'delete'
  }
  return null
}
