import React, { FC } from 'react'

import styles from './Modal.module.css'
import ModalHeader from './ModalHeader.component'

interface Props {
  style: string
  modalType: string
  title: string
  description: string
  renderBody: () => JSX.Element
}

const Modal: FC<Props> = ({ style, modalType, title, description, renderBody }) => (
  <div className={styles.modal}>
    <div className={styles[style]}>
      <div className={styles.modalContent}>
        <ModalHeader modalType={modalType} title={title} description={description} />
        <div className={styles.body}>{renderBody()}</div>
      </div>
    </div>
  </div>
)

export default Modal
