import { Middleware } from 'redux'
import {
  equals,
  path,
  omit,
  isNil,
  compose,
  match,
  nth,
  replace,
  includes,
  head,
  toPairs,
  prop,
  filter,
  fromPairs,
  pipe,
} from 'ramda'
import { Push } from 'connected-react-router'
import Bluebird from 'bluebird'

import { CreateJourneyIntegrationStep } from './journeyIntegration.types'
import {
  CREATE_JOURNEY_INTEGRATION_PROCEED,
  CREATE_JOURNEY_INTEGRATION_COMPLETE,
  createJourneyIntegrationComplete,
  createJourneyIntegrationProceedFailure,
  createJourneyIntegrationIncrementCompletedSteps,
  CREATE_JOURNEY_INTEGRATION_BACK,
  createJourneyIntegrationDecrementCompletedSteps,
} from './createJourneyIntegrationCompletedSteps.reducer'
import {
  reset,
  SETUP_LATER,
  UPDATE_JOURNEY_INTEGRATION_NAVIGATE,
  httpCreateJourneyIntegration,
  HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS,
  httpGetJourneyIntegrationLogoUploadURL,
  HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_SUCCESS,
  S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_SUCCESS,
  s3UploadJourneyIntegrationLogo,
  S3_UPLOAD_JOURNEY_INTEGRATION_LOGO,
  s3UploadJourneyIntegrationLogoSuccess,
  s3UploadJourneyIntegrationLogoFailure,
  httpUpdateJourneyIntegration,
  UPDATE_JOURNEY_INTEGRATION,
  HTTP_UPDATE_JOURNEY_INTEGRATION_SUCCESS,
  getJourneyIntegrationLogoUploadUrl,
  GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL,
  INITIAL_STATE,
  CONFIRM_DEACTIVATE_JOURNEY_INTEGRATION,
  ACTIVATE_JOURNEY_INTEGRATION,
  setIsLoading,
  HTTP_UPDATE_JOURNEY_INTEGRATION,
  HTTP_UPDATE_JOURNEY_INTEGRATION_FAILURE,
  HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_FAILURE,
  httpGetJourneyIntegrationHistory,
  HTTP_GET_JOURNEY_INTEGRATION_HISTORY_SUCCESS,
  setJourneyIntegrationHistory,
  HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_SUCCESS,
  httpGetJourneyIntegrationExports,
  HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_SUCCESS,
  setJourneyIntegrationExports,
} from './journeyIntegration.reducer'
import { isValid } from './journeyIntegration.validation'
import { Routes, NAVIGATE_TO } from '../../common/modules/Routing'
import { BASE64_LOGO_EXTENSION_REGEX, BASE64_LOGO_PREFIX_REGEX } from './createJourneyIntegration.constants'
import { httpGetJourneyIntegrations } from '../../common/modules/JourneyIntegrations/journeyIntegrations.reducer'
import { setSelectedJourneyIntegration, SET_SELECTED_JOURNEY_INTEGRATION } from './selectedJourneyIntegration.reducer'
import { FormSubmissionStatus, setFormSubmissionStatus } from '../../common/form'
// TODO: issues importing these from index
import { setAlert } from '../../common/modules/Alert/alert.reducer'
import { AlertType } from '../../common/modules/Alert/Alert.constants'
import { setView } from '../Modal/modal.reducer'
import { ModalView } from '../Modal/modal.types'

//---------------------------------
// create journey integration proceed
//---------------------------------

export const createJourneyIntegrationProceedFlow = ({
  steps,
}: {
  steps: CreateJourneyIntegrationStep[]
}): Middleware => ({ getState, dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === CREATE_JOURNEY_INTEGRATION_PROCEED) {
    const state = getState()
    const completedSteps = path(['createJourneyIntegrationCompletedSteps'])(state)
    const data = path(['journeyIntegration'])(state)
    const currentStepRequiredProps = path([completedSteps, 'requiredData'])(steps)
    const isValidResult = isValid(currentStepRequiredProps)(data)
    const isComplete = completedSteps + 2 === steps.length
    if (!isValidResult) {
      dispatch(createJourneyIntegrationProceedFailure('invalid attempt to proceed')) // TODO: replace with validation message
      return
    }
    if (isComplete) {
      dispatch(createJourneyIntegrationComplete())
      return
    }
    dispatch(createJourneyIntegrationIncrementCompletedSteps())
  }
}

//---------------------------------
// create journey integration back
//---------------------------------

export const createJourneyIntegrationBackFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === CREATE_JOURNEY_INTEGRATION_BACK) {
    dispatch(createJourneyIntegrationDecrementCompletedSteps())
  }
}

//---------------------------------
// create journey integration
//---------------------------------

export const createJourneyIntegrationFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === CREATE_JOURNEY_INTEGRATION_COMPLETE) {
    const { journeyIntegration } = getState()
    const data = omit(['personalisation', 'isLoading', 'history', 'exports'])(journeyIntegration)
    dispatch(httpCreateJourneyIntegration(data))
    dispatch(setFormSubmissionStatus('create-journey-integration', FormSubmissionStatus.SUBMITTING))
  }
}

//---------------------------------
// create journey integration form submission success flow
//---------------------------------

export const createJourneyIntegrationFormSubmissionSuccessFlow = (): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS) {
    const { journeyIntegration } = getState()
    const logo = path(['personalisation', 'logo'])(journeyIntegration)
    if (isNil(logo)) {
      dispatch(setFormSubmissionStatus('create-journey-integration', FormSubmissionStatus.SUCCESS))
    }
  }
  if (type === S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_SUCCESS) {
    dispatch(setFormSubmissionStatus('create-journey-integration', FormSubmissionStatus.SUCCESS))
  }
}

//---------------------------------
// create journey integration form submission failure flow
//---------------------------------

export const createJourneyIntegrationFormSubmissionFailureFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_FAILURE) {
    dispatch(setFormSubmissionStatus('create-journey-integration', FormSubmissionStatus.FAILURE))
  }
}

//---------------------------------
// create journey integration success
//---------------------------------

export const httpCreateJourneyIntegrationSuccessFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS) {
    const { id } = payload
    const { journeyIntegration } = getState()
    const logo = path(['personalisation', 'logo'])(journeyIntegration)
    if (!isNil(logo)) {
      dispatch(getJourneyIntegrationLogoUploadUrl(id))
      return
    }
    dispatch(createJourneyIntegrationIncrementCompletedSteps())
  }
}

//---------------------------------
// HTTP get upload journey integration logo url
//---------------------------------

export const getJourneyIntegrationLogoUploadURLFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL) {
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.SUBMITTING))
    const { journeyIntegration } = getState()
    const extension = pipe(
      path(['personalisation', 'logo']),
      match(new RegExp(BASE64_LOGO_EXTENSION_REGEX)),
      nth(1),
    )(journeyIntegration)
    dispatch(httpGetJourneyIntegrationLogoUploadURL(payload, extension))
  }
}

//---------------------------------
// get upload journey integration logo url success
//---------------------------------

export const getJourneyIntegrationLogoUploadURLSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_SUCCESS) {
    const { preSignedUrl } = payload
    dispatch(s3UploadJourneyIntegrationLogo(preSignedUrl))
  }
}

//---------------------------------
// upload journey integration logo
//---------------------------------

export const uploadJourneyIntegrationLogoFlow = ({ httpRequest }: { httpRequest: Function }): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === S3_UPLOAD_JOURNEY_INTEGRATION_LOGO) {
    const { journeyIntegration } = getState()
    const logo = path(['personalisation', 'logo'])(journeyIntegration) as string
    const data = compose(
      (x: string) => Buffer.from(x, 'base64'),
      replace(new RegExp(BASE64_LOGO_PREFIX_REGEX), ''),
    )(logo)
    const extension = compose(nth(1), match(new RegExp(BASE64_LOGO_EXTENSION_REGEX)))(logo)
    const headers = { 'Content-Type': `image/${extension}` }
    Bluebird.resolve(httpRequest({ baseURL: payload, url: '', method: 'put', data, headers }))
      .then(() => {
        dispatch(s3UploadJourneyIntegrationLogoSuccess())
      })
      // TODO: only catch HTTP errors
      .catch(e => {
        // logger.error(e) // TODO: obfuscate before logging
        const { message } = e
        dispatch(setFormSubmissionStatus('create-journey-integration', FormSubmissionStatus.FAILURE))
        dispatch(s3UploadJourneyIntegrationLogoFailure(message))
      })
  }
}

//---------------------------------
// upload journey integration logo success
//---------------------------------

export const uploadJourneyIntegrationLogoSuccessFlow = (
  createJourneyCompletedStepsInitialState: number,
): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_SUCCESS) {
    const { createJourneyIntegrationCompletedSteps } = getState()
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.SUCCESS))
    // TODO translate, move to Alert module
    dispatch(
      setAlert({
        type: AlertType.SUCCESS,
        title: 'Successful Update',
        message: 'you have successfully updated the journey template',
      }),
    )
    if (createJourneyIntegrationCompletedSteps !== createJourneyCompletedStepsInitialState) {
      dispatch(createJourneyIntegrationIncrementCompletedSteps())
    }
  }
}

//---------------------------------
// set up later flow
//---------------------------------

export const setupLaterFlow = (navigateTo: Push): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === SETUP_LATER) {
    dispatch(navigateTo(Routes.OVERVIEW))
  }
}

//---------------------------------
// reset flow
//---------------------------------

export const resetFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === NAVIGATE_TO && equals(Routes.CREATE_JOURNEY_INTEGRATION)(payload)) {
    dispatch(reset())
  }
}

//---------------------------------
// update journey integration navigate
//---------------------------------

export const updateJourneyIntegrationNavigateFlow = (navigateTo: Push): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === UPDATE_JOURNEY_INTEGRATION_NAVIGATE) {
    dispatch(navigateTo(payload))
  }
}

//---------------------------------
// update journey integration
//---------------------------------

// TODO: this may have too many behaviours/responsibilities
export const updateJourneyIntegrationFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === UPDATE_JOURNEY_INTEGRATION) {
    const { selectedJourneyIntegration, journeyIntegration } = getState()
    const logo = path(['personalisation', 'logo'])(journeyIntegration)

    if (!isNil(logo)) {
      dispatch(getJourneyIntegrationLogoUploadUrl(selectedJourneyIntegration))
      return
    }

    const data = compose(
      fromPairs,
      filter(([k, v]: [string, unknown]) => JSON.stringify(v) !== JSON.stringify(prop(k)(INITIAL_STATE))),
      toPairs,
      omit(['history', 'exports']),
    )(journeyIntegration)
    dispatch(httpUpdateJourneyIntegration(selectedJourneyIntegration, data))
  }
}

//---------------------------------
// update journey integration (deactivate)
//---------------------------------

// TODO: can this be incorporated into updateJourneyIntegrationFlow?
export const deactivateJourneyIntegrationFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === CONFIRM_DEACTIVATE_JOURNEY_INTEGRATION) {
    const journeyIntegrationId = payload
    dispatch(httpUpdateJourneyIntegration(journeyIntegrationId, { isActive: false }))
  }
}

//---------------------------------
// update journey integration (activate)
//---------------------------------

export const activateJourneyIntegrationFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === ACTIVATE_JOURNEY_INTEGRATION) {
    const journeyIntegrationId = payload
    dispatch(httpUpdateJourneyIntegration(journeyIntegrationId, { isActive: true }))
  }
}
//---------------------------------
// update journey integration submission status to submitting
//---------------------------------

export const updateJourneyIntegrationFormSubmissionSubmittingFlow = (): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_JOURNEY_INTEGRATION) {
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.SUBMITTING))
  }
}

//---------------------------------
// update journey integration success
//---------------------------------

export const httpUpdateJourneyIntegrationSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_JOURNEY_INTEGRATION_SUCCESS) {
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.SUCCESS))
    // TODO translate, move to Alert module
    dispatch(
      setAlert({
        type: AlertType.SUCCESS,
        title: 'Successful Update',
        message: 'you have successfully updated the journey template',
      }),
    )
    dispatch(httpGetJourneyIntegrations())
  }
}

//---------------------------------
// update journey integration failure
//---------------------------------

export const updateJourneyIntegrationFormSubmissionFailureFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_JOURNEY_INTEGRATION_FAILURE) {
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.FAILURE))
    // TODO translate, move to Alert module
    dispatch(
      setAlert({
        type: AlertType.ERROR,
        title: 'Error',
        message: 'There was an error updating your journey',
      }),
    )
  }
}

//---------------------------------
// update journey integration personalisation submission failure flow
//---------------------------------

export const updateJourneyIntegrationPersonalisationFormSubmissionFailureFlow = (): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_FAILURE) {
    dispatch(setFormSubmissionStatus('update-journey-integration', FormSubmissionStatus.FAILURE))
    // TODO translate, move to Alert module
    dispatch(
      setAlert({
        type: AlertType.ERROR,
        title: 'Error',
        message: 'There was an error updating your journey',
      }),
    )
  }
}

//---------------------------------
// initial selected journey integration
//---------------------------------

export const initialSelectedJourneyIntegrationFlow = ({
  APP_INIT,
  routes,
}: {
  APP_INIT: string
  routes: Routes[]
}): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === APP_INIT) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (includes(replace(/(\d+)/)(':id')(pathname), routes)) {
      const id = compose((x: string) => parseInt(x), head, match(/(\d+)/))(pathname)
      dispatch(setSelectedJourneyIntegration(id))
    }
  }
}

//---------------------------------
// get History
//---------------------------------

export const getJourneyIntegrationHistoryFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === SET_SELECTED_JOURNEY_INTEGRATION) {
    dispatch(httpGetJourneyIntegrationHistory(payload))
    dispatch(httpGetJourneyIntegrationExports(payload))
  }
}

export const getJourneyIntegrationHistorySuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_HISTORY_SUCCESS) {
    dispatch(setJourneyIntegrationHistory(payload))
  }
}

export const getJourneyIntegrationExportsSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_SUCCESS) {
    dispatch(setJourneyIntegrationExports(payload))
  }
}

//---------------------------------
// set loading state
//---------------------------------

// TODO: remove
export const setIsLoadingFlow = (setLoadingTrueActions: string[], setLoadingFalseActions: string[]): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(setLoadingTrueActions)) {
    dispatch(setIsLoading(true))
  }
  if (includes(type)(setLoadingFalseActions)) {
    dispatch(setIsLoading(false))
  }
}

//---------------------------------
// download journey integration link report success
//---------------------------------

export const httpDownloadJourneyIntegrationExportSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_SUCCESS) {
    //const { payload } = action
    //const { url } = payload
    dispatch(setFormSubmissionStatus('history-export', FormSubmissionStatus.SUCCESS))
    dispatch(setView({ view: ModalView.JOURNEY_INTEGRATION_EXPORT_CONFIRM_CREATION, payload: {} }))
    // TODO: is there a more react/redux way to do this?
    //window.location.href = url
  }
}
