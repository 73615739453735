import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { has, prop, assoc, path, values, pipe, mapObjIndexed, propEq } from 'ramda'
import { countries, Country } from 'countries-list'

import styles from './BillingAddress.module.css'
import { BillingAddress as BillingAddressType } from '../../../../common/modules/PaymentMethods'
interface Props {
  onChange: (formData: BillingAddressType) => void
  validationErrors: any
  billingAddress: any
}

const BillingAddress: FC<WithTranslation & Props> = ({ t, onChange, validationErrors, billingAddress }) => {
  const [formData, setFormData] = useState(billingAddress)
  useEffect(() => {
    onChange(formData)
  }, [formData])

  useEffect(() => {
    setFormData(billingAddress)
  }, [billingAddress])

  const onChangeInternal = (field: string) => (event: React.FormEvent<HTMLInputElement>) => {
    const {
      currentTarget: { value },
    } = event
    setFormData(assoc(field, value)(formData))
  }

  const countryOptionsView = pipe(
    mapObjIndexed((country: Country, key: string) => (
      <option key={key} value={key} selected={propEq('country', key)(formData)}>
        {prop('name')(country)}
      </option>
    )),
    values,
  )(countries)

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{t('[billing address] title')}</h3>
      <p className={styles.explainer}>{t('[billing address] explainer')}</p>
      <GBG.FormGroup>
        <GBG.Label htmlFor="address-line-1-input">{t('[billing address] address line 1 label')}</GBG.Label>
        <GBG.Text
          id="address-line-1-input"
          type="text"
          error={has('addressLine1', validationErrors)}
          onChange={onChangeInternal('addressLine1')}
          value={prop('addressLine1')(formData)}
          placeholder={t('[billing address] address line 1 input placeholder')}
          aria-label="address-line-1-input"
        />
        {!has('addressLine1', validationErrors) ? null : (
          <GBG.ErrorText>{path(['addressLine1', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
      <GBG.FormGroup>
        <GBG.Label htmlFor="address-line-2-input">{t('[billing address] address line 2 label')}</GBG.Label>
        <GBG.Text
          id="address-line-2-input"
          type="text"
          onChange={onChangeInternal('addressLine2')}
          value={prop('addressLine2')(formData)}
          placeholder={t('[billing address] address line 2 input placeholder')}
          aria-label="address-line-2-input"
        />
      </GBG.FormGroup>
      <GBG.FormGroup>
        <GBG.Label htmlFor="city-input">{t('[billing address] city label')}</GBG.Label>
        <GBG.Text
          id="city-input"
          type="text"
          error={has('city', validationErrors)}
          onChange={onChangeInternal('city')}
          value={prop('city')(formData)}
          placeholder={t('[billing address] city input placeholder')}
          aria-label="city-input"
        />
        {!has('city', validationErrors) ? null : (
          <GBG.ErrorText>{path(['city', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
      <GBG.FormGroup>
        <GBG.Label htmlFor="city-input">{t('[billing address] postal code label')}</GBG.Label>
        <GBG.Text
          id="postal-code-input"
          type="text"
          error={has('postalCode', validationErrors)}
          onChange={onChangeInternal('postalCode')}
          value={prop('postalCode')(formData)}
          placeholder={t('[billing address] postal code input placeholder')}
          aria-label="postal-code-input"
        />
        {!has('postalCode', validationErrors) ? null : (
          <GBG.ErrorText>{path(['postalCode', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
      <GBG.FormGroup>
        <GBG.Label htmlFor="region-input">{t('[billing address] region label')}</GBG.Label>
        <GBG.Text
          id="region-input"
          type="text"
          error={has('state', validationErrors)}
          onChange={onChangeInternal('state')}
          value={prop('state')(formData)}
          placeholder={t('[billing address] region input placeholder')}
          aria-label="region-input"
        />
        {!has('state', validationErrors) ? null : (
          <GBG.ErrorText>{path(['state', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
      <GBG.FormGroup>
        <GBG.Label htmlFor="country-input">{t('[billing address] country label')}</GBG.Label>
        <GBG.Select onChange={onChangeInternal('country')} error={has('country', validationErrors)}>
          <option value="">{t('[billing address] country please select label')}</option>
          {countryOptionsView}
        </GBG.Select>
        {!has('country', validationErrors) ? null : (
          <GBG.ErrorText>{path(['country', 'message'])(validationErrors)}</GBG.ErrorText>
        )}
      </GBG.FormGroup>
    </div>
  )
}

export default BillingAddress
