import { isNil } from 'ramda'
import React, { FC, useState, useEffect } from 'react'
import { WithTranslation } from 'react-i18next'

import GBG from '@gbg/gbgcomponentlibrary_react'
import ImageUpload from '../../../../common/modules/ImageUpload'
import { AcceptedFileType } from '../../../../common/modules/ImageUpload/ImageUpload.types'

interface Props {
  isSubmitting: boolean
  logoUrl: string
  onUpdate: (image: string) => void
}

const PersonalisationForm: FC<Props & WithTranslation> = ({ t, isSubmitting, logoUrl, onUpdate }) => {
  const [formData, setFormData] = useState('')
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    if (!isNil(logoUrl)) {
      setFormData(logoUrl)
    }
  }, [logoUrl])
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onConfirmPersonalisation = () => {
    setInternalIsSubmitting(true)
    if (formData) {
      setInternalIsSubmitting(false)
      onUpdate(formData)
    }
    // TODO: Add Failure case https://nonaredteam.atlassian.net/browse/GBG-212
  }

  return (
    <div>
      <div>
        <ImageUpload
          defaultImage={logoUrl}
          onSetImage={setFormData}
          label={t('[personalisation] logo input label')}
          explainer={t('[personalisation] logo input explainer')}
          acceptedFileTypes={[AcceptedFileType.JPG, AcceptedFileType.JPEG, AcceptedFileType.PNG]}
        />
      </div>
      <div className="m-m-t-4">
        {isNil(formData) ? null : (
          <GBG.Button
            onClick={onConfirmPersonalisation}
            aria-label="submit-button"
            worker={true}
            active={internalIsSubmitting}
          >
            {t('[journey integration update] update redirect button label')}
          </GBG.Button>
        )}
      </div>
    </div>
  )
}

export default PersonalisationForm
