import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { Routes } from '../../../../common/modules/Routing'
import styles from './Header.module.css'
import Icon from '../../../../common/modules/SVGIcon/SVGIcon.component'
import { IconType } from '../../../../common/modules/SVGIcon/SVGIcon.types'
import TotalUsage from '../TotalUsage/TotalUsage.container'

export interface Props {
  currentRoutePathname: string
  selectedJourneyIntegrationId: number
  selectedJourneyIntegrationName: string
  isActive: boolean
  onNavigateTo: (id: number, path: string) => void
  onActivate: (id: number) => void
  onDeactivate: (id: number) => void
  onCreateLink: (id: number) => void
}

const Header: FC<Props & WithTranslation> = ({
  t,
  selectedJourneyIntegrationId,
  selectedJourneyIntegrationName,
  isActive,
  onNavigateTo,
  onDeactivate,
  onActivate,
  onCreateLink,
}) => {
  const nav = [
    { path: Routes.UPDATE_DETAILS, label: 'Details' },
    { path: Routes.UPDATE_COMPLETION_ACTION, label: 'Completion Action' },
    { path: Routes.UPDATE_PERSONALISATION, label: 'Personalisation' },
    { path: Routes.UPDATE_HISTORY, label: 'History' },
  ]
  return (
    <div className={styles.container}>
      <h1>{selectedJourneyIntegrationName}</h1>
      <div className={styles.actionWrapper}>
        <div className={styles.usage}>
          <span className={styles.subHeader}>{t('[journey integration update] usage label')}</span>
          <TotalUsage />
        </div>
        <div className={styles.commonTasks}>
          <span className={styles.subHeader}>{t('[journey integration update] common tasks label')}</span>
          <div className={styles.linkWrapper}>
            <a className={styles.link} onClick={() => onCreateLink(selectedJourneyIntegrationId)}>
              <Icon name={IconType.ArrowLightBlue} /> {t('[journey integration update] create link')}
            </a>
            <a
              className={styles.link}
              onClick={() =>
                isActive ? onDeactivate(selectedJourneyIntegrationId) : onActivate(selectedJourneyIntegrationId)
              }
            >
              <Icon name={IconType.ArrowLightBlue} />
              &nbsp;
              {isActive ? t('[journey integration update] deactivate') : t('[journey integration update] activate')}
            </a>
          </div>
        </div>
      </div>

      <GBG.TabBar
        className="w-100 m-m-t-6"
        onTabSelected={(i: number) => {
          onNavigateTo(selectedJourneyIntegrationId, nav[i].path)
        }}
        tabs={nav.map(ni => ni.label)}
      />
    </div>
  )
}

export default Header
