import { combineReducers, Reducer } from 'redux'
import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'

import { form, FormState } from '../../common/form'
import { modal, ModalState } from '../../modules/Modal'
import { alert, AlertState } from '../modules/Alert'
import { auth, AuthState } from '../../modules/Auth'
import { hubspot } from '../../modules/Hubspot'
import { journeyTypes, JourneyTypesState } from '../modules/JourneyTypes'
import { journeyIntegrations, JourneyIntegrationsState } from '../modules/JourneyIntegrations'
import { journeyIntegrationLink, JourneyIntegrationLinkState } from '../modules/JourneyIntegrationLink'
import {
  journeyIntegrationLinkResults,
  JourneyIntegrationLinkResultsState,
} from '../modules/JourneyIntegrationLinkResults'
import {
  journeyIntegration,
  createJourneyIntegrationCompletedSteps,
  JourneyIntegrationState,
  selectedJourneyIntegration,
} from '../../modules/JourneyIntegration'
import { user, UserState } from '../../modules/User'
import { JourneyCreditAccountState, journeyCreditAccount } from '../modules/JourneyCreditAccount'
import { subscriptions, SubscriptionsState } from '../modules/Subscriptions'
import { paymentMethods, PaymentMethodsState } from '../modules/PaymentMethods'
import { paymentHistory, PaymentHistoryState } from '../../modules/Payment'
import { featureFlags, FeatureFlagsState } from '../feature-flags'
import { HubspotState } from '../../modules/Hubspot/hubspot.types'
/* eslint-disable-next-line */
import { UsersState, users } from '../modules/Users'
import { OrganisationState, organisation } from '../../modules/Organisation'
import { dataProtection, DataProtectionState } from '../modules/DataProtection'

//---------------------------------
// types
//---------------------------------

export interface AppState {
  featureFlags: FeatureFlagsState
  form: FormState
  alert: AlertState
  router: RouterState
  auth: AuthState
  hubspot: HubspotState
  modal: ModalState
  journeyTypes: JourneyTypesState
  journeyIntegrations: JourneyIntegrationsState
  journeyIntegration: JourneyIntegrationState
  createJourneyIntegrationCompletedSteps: number
  selectedJourneyIntegration: number
  journeyIntegrationLink: JourneyIntegrationLinkState
  journeyIntegrationLinkResults: JourneyIntegrationLinkResultsState
  user: UserState
  journeyCreditAccount: JourneyCreditAccountState
  subscriptions: SubscriptionsState
  paymentMethods: PaymentMethodsState
  paymentHistory: PaymentHistoryState
  users: UsersState
  organisation: OrganisationState
  dataProtection: DataProtectionState
}

//---------------------------------
// state
//---------------------------------

export default (history: History): Reducer<AppState> =>
  combineReducers<AppState>({
    router: connectRouter(history),
    featureFlags,
    form,
    alert,
    auth,
    hubspot,
    modal,
    journeyTypes,
    journeyIntegrations,
    journeyIntegration,
    createJourneyIntegrationCompletedSteps,
    selectedJourneyIntegration,
    journeyIntegrationLink,
    journeyIntegrationLinkResults,
    user,
    journeyCreditAccount,
    subscriptions,
    paymentMethods,
    paymentHistory,
    users,
    organisation,
    dataProtection,
  })
