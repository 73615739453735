export enum ModalView {
  NONE = 'none',
  JOURNEY_INTEGRATION_LINK = 'journey-integration-link',
  JOURNEY_INTEGRATION_DEACTIVATE = 'journey-integration-deactivate',
  JOURNEY_INTEGRATION_EXPORT_CONFIRM_DELETE = 'journey-integration-export-confirm-delete',
  JOURNEY_INTEGRATION_EXPORT_CONFIRM_DOWNLOAD = 'journey-integration-export-confirm-download',
  CREATE_PAYMENT_METHOD = 'create-payment-method',
  DELETE_PAYMENT_METHOD = 'delete-payment-method',
  CANCEL_SUBSCRIPTION = 'cancel-subscription',
  CANCEL_AUTO_RENEW = 'cancel-auto-renew',
  DATA_PROTECTION_FORM_HELPER_DPO = 'data-protection-form-dpo',
  DATA_PROTECTION_FORM_HELPER_POP = 'data-protection-form-pop',
  JOURNEY_INTEGRATION_EXPORT_CONFIRM_CREATION = 'journey-integration-export-confirm-creation',
}

export interface ModalState {
  view: ModalView
  payload: unknown
}

export enum ButtonType {
  Primary = 'primary',
  Destructive = 'destructive',
}
