import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import { AlertType, resetAlert, setAlert } from '../../../../common/modules/Alert'
import CompletionActionForm from './CompletionActionForm.component'
import { setCompletionAction, updateJourneyIntegration } from '../../journeyIntegration.reducer'
import { updateCompletionActionFormSelector } from '../../journeyIntegration.selectors'
import { CompletionActionData } from '../../journeyIntegration.types'
import { prepareCompletionActionForUpdate } from '../../journeyIntegration.utils'
import { isToggleableOutcomeIcon } from '../../../../common/feature-flags/featureFlags.selectors'

const mapStateToProps = createSelector(
  [updateCompletionActionFormSelector, isToggleableOutcomeIcon],
  (updateCompletionActionFormSelector, isToggleableOutcomeIcon) => ({
    ...updateCompletionActionFormSelector,
    isToggleableOutcomeIcon,
  }),
)

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: (completionActionData: CompletionActionData) => {
    // TODO: do this somewhere else eg. middleware (and test)
    const completionAction = prepareCompletionActionForUpdate(completionActionData)
    dispatch(resetAlert())
    dispatch(setCompletionAction(completionAction))
    dispatch(updateJourneyIntegration())
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
  onValidationChange: (isValid: boolean) => {
    dispatch(isValid ? resetAlert() : setAlert(invalidPayload))
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect(mapStateToProps, mapDispatchToProps),
)(CompletionActionForm)
