import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { nAry } from 'ramda'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Modal } from '../../../common/modules/Modal'
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm.component'
import styles from './PaymentMethodFormModal.module.css'
import { PaymentMethodCard } from '../../../common/modules/PaymentMethods/paymentMethods.types'

export interface Props {
  isSubmitting: boolean
  onSubmit: (paymentMethodCard: PaymentMethodCard) => void
  onInvalidSubmit: () => void
  onError: () => void
  onCancel?: () => void
}

const noop = () => null

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`)

const PaymentMethodFormModal: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  onSubmit,
  onError,
  onCancel = noop,
}) => {
  const renderPaymentMethodFormFooter = (onCancel: any, onSubmit: any, isSubmitting: boolean, isDisabled: boolean) => (
    <>
      <div className="m-m-t-2 buttonWrapper">
        <GBG.Button
          className="m-m-r-1 cancelButton"
          onClick={() => {
            onCancel()
          }}
          aria-label="cancel-button"
          kind={GBG.ButtonKind.Secondary}
          disabled={false}
        >
          {t('[payment method form] cancel label')}
        </GBG.Button>
        <GBG.Button
          className={`m-m-r-2 ${styles.submitButton}`}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          active={isSubmitting}
          aria-label="submit-button"
          onClick={nAry(0, onSubmit)}
          disabled={isDisabled}
        >
          {t('[payment method form] add card label')}
        </GBG.Button>
      </div>
      {/* TODO: should be able to use ModalFooter here, but I'm having issues with isSubmitting not getting updated */}
      {/*<ModalFooter*/}
      {/*  cancelLabel={t('[payment method form] cancel label')}*/}
      {/*  submitLabel={t('[payment method form] add card label')}*/}
      {/*  onCancel={onCancel}*/}
      {/*  onSubmit={onSubmit}*/}
      {/*  isSubmitting={isSubmitting}*/}
      {/*  isDisabled={isDisabled}*/}
      {/*/>*/}
    </>
  )
  return (
    <div className={styles.container}>
      <Modal
        style="none"
        modalType="ModalCard"
        title={t('[payment method form] title')}
        description=""
        renderBody={() => (
          <div aria-label="body-text">
            <Elements stripe={stripePromise}>
              <PaymentMethodForm
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
                onError={onError}
                onCancel={onCancel}
                primaryCardExplainerText={t('[payment method form] checkbox explainer')}
                renderFooter={renderPaymentMethodFormFooter}
              />
            </Elements>
          </div>
        )}
      />
    </div>
  )
}

export default PaymentMethodFormModal
