import React, { FC } from 'react'
import { addIndex, map } from 'ramda'

import styles from './PaymentMethods.module.css'
import { PaymentMethod } from './paymentMethods.types'
import { PaymentCard, PaymentCardProps } from '../PaymentCard'
import { Dropdown } from '../PaymentCard/constants'
import classnames from 'classnames'

export interface Props {
  paymentMethods: PaymentMethod[]
  currentPaymentMethodId: string
  onUpdate?: (id: string) => void
  onSelect?: (id: string) => void
  onDelete?: (id: string) => void
}

const noop = () => null

const PaymentMethods: FC<Props> = ({ paymentMethods, onSelect = noop, onDelete = noop }) => {
  const onSelectInternal = (id: string) => {
    onSelect(id)
  }
  const list = classnames({
    '': true,
    [styles.listWrapper]: paymentMethods.length > 4,
  })
  return (
    <div className={styles.container}>
      <ul className={list}>
        {addIndex(map)((props: PaymentCardProps, i: number) => (
          <PaymentCard key={i} {...props} onSelect={onSelectInternal} dropdown={Dropdown.manage} onDelete={onDelete} />
        ))(paymentMethods)}
      </ul>
    </div>
  )
}

export default PaymentMethods
