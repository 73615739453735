import { compose, includes, replace } from 'ramda'
import { Middleware } from 'redux'

import {
  httpGetJourneyIntegrationLinkResults,
  httpGetJourneyIntegrationLinkResultsForJourney,
} from './journeyIntegrationLinkResults.reducer'
import { ON_ROUTE, Routes } from '../Routing'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'
import { SET_SELECTED_JOURNEY_INTEGRATION } from '../../../modules/JourneyIntegration/selectedJourneyIntegration.reducer'

//---------------------------------
// get journey integration link results
// ... initiates the API get journey integration link results flow
// ... on provided actions
// ... or on provided routes (if authenticated)
//---------------------------------

export const getJourneyIntegrationLinkResultsFlow = (actions: string[], routes: Routes[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(httpGetJourneyIntegrationLinkResults())
    return
  }
  if (type === ON_ROUTE && includes(replace(/(\d+)/)(':id')(payload), routes)) {
    const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
    if (isAuthenticated) {
      dispatch(httpGetJourneyIntegrationLinkResults())
    }
  }
}

//---------------------------------
// get journey integration link results for journey
// ... initiates the API get journey integration link results by journey flow
// ... on provided actions
// ... or on provided routes (if authenticated)
//---------------------------------

export const getJourneyIntegrationLinkResultsForJourneyFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === SET_SELECTED_JOURNEY_INTEGRATION) {
    dispatch(httpGetJourneyIntegrationLinkResultsForJourney(payload))
  }
}
