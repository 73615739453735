import { createTransform } from 'redux-persist'
import { reject, isNil, propOr, identity } from 'ramda'

const transformMap = {
  auth: reject(isNil),
}

export const transform = createTransform(
  // ... transform state before persisting
  (state, key) => propOr(identity, key)(transformMap)(state),
  // ... transform state before rehydration
  state => state,
  // ... config
  { whitelist: ['auth'] },
)

export default [transform]
