import { Middleware } from 'redux'
import { isEmpty, isNil, path, propSatisfies, includes, prop } from 'ramda'
import { LOCATION_CHANGE } from 'connected-react-router'
// TODO: issues importing these from index
import { setAlert } from '../../common/modules/Alert/alert.reducer'
import { AlertType } from '../../common/modules/Alert/Alert.constants'
import {
  AUTH_CHANGE_PASSWORD,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SIGNUP,
  authChangePasswordFailure,
  authChangePasswordSuccess,
  authLoginFailure,
  authLoginSuccess,
  authLogoutFailure,
  authLogoutSuccess,
  authSignupFailure,
  authSignupSuccess,
  authStateReset,
  setAfterLoginRedirectRoute,
  unauthorized,
} from './auth.reducer'
import { WebAuthAsync } from './auth.types'
import {
  ERROR_MESSAGE_COULD_NOT_CHANGE_PASSWORD,
  ERROR_MESSAGE_COULD_NOT_LOGIN,
  ERROR_MESSAGE_COULD_NOT_LOGOUT,
  ERROR_MESSAGE_COULD_NOT_PARSE_AUTH_TOKEN,
  ERROR_MESSAGE_COULD_NOT_SIGN_UP,
  MESSAGE_CHANGE_PASSWORD_EMAIL_SENT,
} from './auth.constants'
import { navigateTo, Routes } from '../../common/modules/Routing'
import { FormSubmissionStatus, setFormSubmissionStatus } from '../../common/form'
import { hubspotUpdate } from '../Hubspot/hubspot.reducer'

//---------------------------------
// authenticated
//---------------------------------

export const authenticatedFlow = (webAuth: WebAuthAsync, initAction: string): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)

  const { type } = action
  if (type === initAction) {
    const { router, auth } = getState()
    const hash = path(['location', 'hash'])(router)
    if (!isEmpty(hash) && propSatisfies(isNil, 'accessToken')(auth)) {
      webAuth
        .parseHashAsync({ hash })
        .then(({ accessToken }) => {
          dispatch(setFormSubmissionStatus('login', FormSubmissionStatus.SUCCESS))
          dispatch(authLoginSuccess(accessToken))
        })
        .catch(e => {
          //check if the error is about email verification
          if (e.errorDescription == 'Please verify your email before logging in.') {
            dispatch(navigateTo(Routes.SIGNUP_CONFIRMATION))
          } else {
            // logger.error(e) TODO: obfuscate this before logging
            dispatch(authLoginFailure(ERROR_MESSAGE_COULD_NOT_PARSE_AUTH_TOKEN))
            // TODO: do this in common/form middleware instead
            dispatch(setFormSubmissionStatus('login', FormSubmissionStatus.FAILURE))
          }
        })
    }
  }
}

//---------------------------------
// auth signup
//---------------------------------

export const authSignupFlow = (webAuth: WebAuthAsync): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === AUTH_SIGNUP) {
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('signup', FormSubmissionStatus.SUBMITTING))
    const { email, password, name, companyName, orgId } = payload

    webAuth
      .signupAsync({
        connection: 'Username-Password-Authentication',
        email,
        password,
        user_metadata: {
          name,
          companyName,
          orgId: '' + orgId,
        },
      })
      .then(() => {
        try {
          const cookies = Object.assign(
            {},
            ...document.cookie
              .split(';')
              .map(i => i.trim())
              .map(i => {
                const parts = i.split('=')
                return {
                  [parts[0]]: parts[1],
                }
              }),
          )
          dispatch(
            hubspotUpdate({
              email,
              name,
              companyName,
              utk: Object.prototype.hasOwnProperty.call(cookies, 'hubspotutk') ? cookies.hubspotutk : '',
              pageTitle: document.title,
              url: document.location.href,
            }),
          )
        } catch (err) {
          //do nothing - hubspot fails silently...
          console.log(err)
        }
        dispatch(authSignupSuccess())
        // TODO: do this in common/form middleware instead
        dispatch(setFormSubmissionStatus('signup', FormSubmissionStatus.SUCCESS))
        // TODO: move to Routing module
        dispatch(navigateTo(Routes.SIGNUP_CONFIRMATION))
      })
      .catch(() => {
        // logger.error(e) TODO: obfuscate this before logging
        // TODO: translate
        dispatch(
          // TODO: move to Alert module
          setAlert({
            type: AlertType.ERROR,
            title: 'Error',
            message: ERROR_MESSAGE_COULD_NOT_SIGN_UP,
          }),
        )
        dispatch(authSignupFailure(ERROR_MESSAGE_COULD_NOT_SIGN_UP))
        // TODO: do this in common/form middleware instead
        dispatch(setFormSubmissionStatus('signup', FormSubmissionStatus.FAILURE))
      })
  }
}

//---------------------------------
// auth login
//---------------------------------

export const authLoginFlow = (webAuth: WebAuthAsync): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === AUTH_LOGIN) {
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('login', FormSubmissionStatus.SUBMITTING))

    const { email: username, password } = payload
    webAuth.loginAsync({ realm: 'Username-Password-Authentication', username, password }).catch(() => {
      // logger.error(e) TODO: obfuscate this before logging
      // TODO: translate
      // TODO: move to Alert module
      dispatch(
        setAlert({
          type: AlertType.ERROR,
          title: 'Error',
          message: ERROR_MESSAGE_COULD_NOT_LOGIN,
        }),
      )
      dispatch(authLoginFailure(ERROR_MESSAGE_COULD_NOT_LOGIN))
      // TODO: do this in common/form middleware instead
      dispatch(setFormSubmissionStatus('login', FormSubmissionStatus.FAILURE))
    })
  }
}

//---------------------------------
// auth logout
//---------------------------------

export const authLogoutFlow = (webAuth: WebAuthAsync): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === AUTH_LOGOUT) {
    webAuth
      .logoutAsync({
        returnTo: process.env.REACT_APP_UI_URL,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      })
      .catch(() => {
        // logger.error(e) TODO: obfuscate this before logging
        // TODO: translate
        // TODO: move to Alert module
        dispatch(
          setAlert({
            type: AlertType.ERROR,
            title: 'Error',
            message: ERROR_MESSAGE_COULD_NOT_LOGOUT,
          }),
        )
        dispatch(authLogoutFailure(ERROR_MESSAGE_COULD_NOT_LOGOUT))
      })
      .then(() => {
        dispatch(authLogoutSuccess())
      })
  }
}

//---------------------------------
// auth change password
//---------------------------------

export const authChangePasswordFlow = (webAuth: WebAuthAsync): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === AUTH_CHANGE_PASSWORD) {
    const { email } = payload
    // TODO: do this in common/form middleware instead
    dispatch(setFormSubmissionStatus('changePassword', FormSubmissionStatus.SUBMITTING))
    webAuth
      .changePasswordAsync({
        connection: 'Username-Password-Authentication',
        email,
      })
      .then(() => {
        // TODO: move to Routing module
        dispatch(navigateTo(Routes.LOGIN))
        // TODO: move to Alert module
        dispatch(
          setAlert({
            type: AlertType.SUCCESS,
            title: 'Email Sent',
            message: MESSAGE_CHANGE_PASSWORD_EMAIL_SENT,
          }),
        )
        // TODO: do this in common/form middleware instead
        dispatch(setFormSubmissionStatus('changePassword', FormSubmissionStatus.SUCCESS))
        dispatch(authChangePasswordSuccess())
      })
      .catch(() => {
        // logger.error(e) TODO: obfuscate this before logging
        // TODO: translate
        // TODO: move to Alert module
        dispatch(
          setAlert({
            type: AlertType.ERROR,
            title: 'Error',
            message: ERROR_MESSAGE_COULD_NOT_CHANGE_PASSWORD,
          }),
        )
        // TODO: do this in common/form middleware instead
        dispatch(setFormSubmissionStatus('changePassword', FormSubmissionStatus.FAILURE))
        dispatch(authChangePasswordFailure(ERROR_MESSAGE_COULD_NOT_CHANGE_PASSWORD))
      })
  }
}

//---------------------------------
// auth reset
//---------------------------------

export const authStateResetFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === LOCATION_CHANGE) {
    const pathname = path(['location', 'pathname'])(payload)
    // TODO: inject route
    if (pathname === Routes.LOGIN) {
      dispatch(authStateReset())
    }
  }
}

//---------------------------------
// auth reset
//---------------------------------

export const setAfterLoginRedirectRouteFlow = (unauthorizedAction: string, routes: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === unauthorizedAction) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (!includes(pathname)(routes)) {
      // TODO: should this maybe be in Routing module?
      dispatch(setAfterLoginRedirectRoute(pathname))
    }
  }
}

//---------------------------------
// unauthorized
//---------------------------------

export const unauthorizedFlow = (publicRoutes: string[], unauthorizedActions: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action

  if (includes(type, unauthorizedActions)) {
    dispatch(unauthorized())
  }

  // TODO: use constant (can we import from NPM package?)
  if (type === '@@router/LOCATION_CHANGE') {
    const { router, auth } = getState()
    const { hash, pathname } = prop('location')(router)

    if (!includes(pathname)(publicRoutes) && isEmpty(hash) && propSatisfies(isNil, 'accessToken')(auth)) {
      dispatch(unauthorized())
    }
  }
}
