import React, { FC, useState } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'

import { ModalFooter } from '../../../common/modules/Modal'
import { WithTranslation } from 'react-i18next'
import { PaymentMethod } from '../../../common/modules/PaymentMethods/paymentMethods.types'
import { ButtonType } from '../../Modal/modal.types'

interface Props {
  paymentMethod: PaymentMethod
  onCancel: () => void
  onSubmit: (stripePaymentMethodId: string) => void
}

const DeletePaymentMethodModal: FC<Props & WithTranslation> = ({ t, paymentMethod, onCancel, onSubmit }) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const { cardBrand, cardLast4, stripePaymentMethodId } = paymentMethod

  const validateConfirmation = (text: string) => {
    if (text === cardLast4) {
      setIsConfirmed(true)
      return
    }
    setIsConfirmed(false)
  }

  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar icon={GBG.IconKeys.AlertCircle24} type={GBG.AlertBarType.Warn}>
          <GBG.AlertBarText
            title={t('[delete payment method modal] title', { cardBrand, cardLast4 })}
            text={t('[delete payment method modal] prompt')}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <p>{t('[delete payment method modal] description')}</p>
        <GBG.FormGroup>
          <GBG.Label>{t('[delete payment method modal] confirmation input label', { cardLast4 })}</GBG.Label>
          <GBG.Text
            aria-label="confirm-input"
            onChange={(event: React.FormEvent<HTMLInputElement>) => validateConfirmation(event.currentTarget.value)}
          />
        </GBG.FormGroup>
        <ModalFooter
          cancelLabel={t('[delete payment method modal] cancel')}
          submitLabel={t('[delete payment method modal] submit')}
          onCancel={onCancel}
          onSubmit={() => {
            if (isConfirmed) {
              onSubmit(stripePaymentMethodId)
            }
          }}
          buttonType={ButtonType.Destructive}
        />
      </GBG.ModalBody>
    </>
  )
}

export default DeletePaymentMethodModal
