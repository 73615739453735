import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Dinero from 'dinero.js'
import '@gbg/gbgcomponentlibrary/dist/gbgcomponentlibrary.min.css'
import '@gbg/gbgcomponentlibrary_react/build/index.css'
import './index.css'
import './common/intl'
import { store, persistor } from './common/redux/store'
import App from './modules/App'

Dinero.globalLocale = process.env.REACT_APP_LOCALE as string

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
