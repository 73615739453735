import { createSelector } from 'reselect'
import {
  prop,
  values,
  compose,
  omit,
  path,
  mergeDeepRight,
  reduce,
  propOr,
  assoc,
  map,
  isNil,
  mapObjIndexed,
  when,
  always,
  pathOr,
} from 'ramda'

import { JourneyType } from '../../common/modules/JourneyTypes/journeyTypes.types'
import { CompletionAction, CompletionActionType, Personalisation } from './journeyIntegration.types'
import { JOURNEY_TYPE_ICON_MAP } from './journeyIntegration.constants'
import { JourneyIntegration } from '../../common/modules/JourneyIntegrations/journeyIntegration.types'
import { FormSubmissionStatus } from '../../common/form'

export const createJourneyIntegrationSubmissionStatusSelector = createSelector(
  [pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'create-journey-integration', 'submissionStatus'])],
  (formSubmissionStatus: string) => ({
    isSubmitting: formSubmissionStatus === FormSubmissionStatus.SUBMITTING,
  }),
) as any

export const updateJourneyIntegrationSubmissionStatusSelector = createSelector(
  [pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'update-journey-integration', 'submissionStatus'])],
  (formSubmissionStatus: string) => ({
    isSubmitting: formSubmissionStatus === FormSubmissionStatus.SUBMITTING,
  }),
) as any

export const journeyTypesSelector = createSelector([prop('journeyTypes')], (journeyTypes: JourneyType[]) => {
  const assocIcon = map((journeyType: JourneyType) => {
    const { id } = journeyType
    const icon = propOr(null, id)(JOURNEY_TYPE_ICON_MAP)
    return assoc('icon', icon)(journeyType)
  })
  return { journeyTypes: compose(values, assocIcon)(journeyTypes) }
})

export const journeyTypeIdSelector = createSelector(
  [path(['journeyIntegration', 'journeyTypeId'])],
  (journeyTypeId: number) => ({ journeyTypeId }),
)

export const nameSelector = createSelector([path(['journeyIntegration', 'name'])], (name: string) => ({ name }))

export const completionActionTypeSelector = createSelector(
  [path(['journeyIntegration', 'completionAction', 'type'])],
  (completionActionType: CompletionActionType) => ({
    completionActionType,
  }),
)

export const completionActionResultRedirectSelector = createSelector(
  [path(['journeyIntegration', 'completionAction', 'redirectUrl'])],
  (redirectUrl: string) => ({
    redirectUrl,
  }),
)

export const completionActionMessageSelector = createSelector(
  [compose(omit(['type', 'resultRedirect', 'CTAText']), path(['journeyIntegration', 'completionAction']))],
  ({ successTitle, successText, failureTitle, failureText, includeIcon }: CompletionAction) => ({
    successTitle,
    successText,
    failureTitle,
    failureText,
    includeIcon,
  }),
)

export const completionActionCTATextSelector = createSelector(
  [path(['journeyIntegration', 'completionAction', 'ctaText'])],
  (ctaText: string) => ({
    ctaText,
  }),
)

export const personalisationSelector = createSelector(
  [path(['journeyIntegration', 'personalisation'])],
  (personalisation: Personalisation) => ({
    personalisation,
  }),
)

export const personalisationFormSelector = createSelector(
  [personalisationSelector, createJourneyIntegrationSubmissionStatusSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)

export const completionActionSelector = createSelector(
  [
    completionActionTypeSelector,
    completionActionMessageSelector,
    completionActionCTATextSelector,
    completionActionResultRedirectSelector,
  ],
  (...data) => reduce(mergeDeepRight, {})(data),
)

export const selectedJourneyIntegrationIdSelector = createSelector(
  [prop('selectedJourneyIntegration')],
  (selectedJourneyIntegration: number) => ({ selectedJourneyIntegration }),
)

export const selectedJourneyIntegrationSelector = createSelector(
  [prop('journeyIntegrations'), selectedJourneyIntegrationIdSelector],
  (journeyIntegrations: JourneyIntegration[], { selectedJourneyIntegration }) => ({
    selectedJourneyIntegration: assoc('id')(selectedJourneyIntegration)(
      prop(selectedJourneyIntegration)(journeyIntegrations),
    ),
  }),
)

// TODO: rename result key to selectedJourneyIntegrationName
export const selectedJourneyIntegrationNameSelector = createSelector(
  [selectedJourneyIntegrationIdSelector, prop('journeyIntegrations')],
  ({ selectedJourneyIntegration }, data) => ({
    name: path([selectedJourneyIntegration, 'name'])(data),
  }),
)

export const selectedJourneyIntegrationCompletionActionSelector = createSelector(
  [selectedJourneyIntegrationIdSelector, prop('journeyIntegrations')],
  ({ selectedJourneyIntegration }, data) =>
    // TODO: this `when(isNil, always(''))` may not be needed anymore
    compose(mapObjIndexed(when(isNil, always(''))), path([selectedJourneyIntegration, 'completionAction']))(data),
)

export const updateCompletionActionFormSelector = createSelector(
  [selectedJourneyIntegrationCompletionActionSelector, updateJourneyIntegrationSubmissionStatusSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)

export const selectedJourneyIntegrationLogoUrlSelector = createSelector(
  [selectedJourneyIntegrationIdSelector, prop('journeyIntegrations')],
  ({ selectedJourneyIntegration }, data) => ({
    logoUrl: path([selectedJourneyIntegration, 'logoUrl'])(data),
  }),
)

export const updatePersonalisationFormSelector = createSelector(
  [selectedJourneyIntegrationLogoUrlSelector, updateJourneyIntegrationSubmissionStatusSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)

export const historySelector = createSelector([path(['journeyIntegration', 'history'])], (history: any) => ({
  history,
}))

export const exportsSelector = createSelector([path(['journeyIntegration', 'exports'])], (exports: any[]) => exports)
