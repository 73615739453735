import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import DataProtectionOnboarding, { Props } from './DataProtectionOnboarding.component'
import { headerAndNavWrapperSelector } from '../../HeaderAndNavWrapper/headerAndNavWrapper.selector'

import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { Dispatch } from 'react'
import { AnyAction } from 'redux'
import { navigateTo, Routes } from '../../../common/modules/Routing'

const mapStateToProps = createSelector([headerAndNavWrapperSelector], headAndNavData => ({
  ...headAndNavData,
}))

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSkip: () => {
    dispatch(navigateTo(Routes.OVERVIEW))
  },
})

export default compose(
  withTranslation('DataProtectionOnboarding'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(DataProtectionOnboarding)
