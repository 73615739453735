import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import { setAlert, resetAlert, AlertType } from '../../common/modules/Alert'
import OnboardForm from './OnboardForm.component'
import { BusinessDetails } from './onboard.types'
import { navigateTo, Routes } from '../../common/modules/Routing'
import { updateUser, userSelector } from '../User'

const mapStateToProps = userSelector

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: (businessDetails: BusinessDetails) => {
    dispatch(resetAlert())
    dispatch(updateUser(businessDetails))
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
  onNavigate: () => {
    dispatch(navigateTo(Routes.OVERVIEW))
  },
})

export default compose(withTranslation('Onboard'), connect(mapStateToProps, mapDispatchToProps))(OnboardForm)
