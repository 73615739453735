import React, { FC } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'

import { ModalFooter } from '../../../common/modules/Modal'
import { WithTranslation } from 'react-i18next'
import { ButtonType } from '../../Modal/modal.types'

interface Props {
  onCancel: () => void
  onSubmit: () => void
}

const CancelSubscriptionModal: FC<Props & WithTranslation> = ({ t, onCancel, onSubmit }) => {
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar icon={GBG.IconKeys.AlertCircle24} type={GBG.AlertBarType.Warn}>
          <GBG.AlertBarText
            title={t('[subscription] cancel subscription modal title')}
            text={t('[subscription] cancel subscription modal explainer')}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <p>{t('[subscription] cancel subscription modal description')}</p>
        <ModalFooter
          cancelLabel={t('[subscription] cancel subscription modal cancel button label')}
          submitLabel={t('[subscription] cancel subscription modal confirm button label')}
          onCancel={onCancel}
          onSubmit={onSubmit}
          buttonType={ButtonType.Destructive}
        />
      </GBG.ModalBody>
    </>
  )
}

export default CancelSubscriptionModal
