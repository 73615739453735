import React, { FC, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { ModalFooter } from '../Modal'

export interface Props {
  id: number
  name: string
  onCancel?: () => void
  onSubmit?: (id: number) => void
}

const noop = () => null

const DeactivateJourneyIntegrationModal: FC<Props & WithTranslation> = ({
  t,
  id,
  name,
  onCancel = noop,
  onSubmit = noop,
}) => {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const validateConfirmation = (text: string) => {
    if (text === name) {
      setIsConfirmed(true)
    }
  }
  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar icon={GBG.IconKeys.AlertCircle24} type={GBG.AlertBarType.Warn}>
          <GBG.AlertBarText
            title={t('[journey integration deactivate] title')}
            text={t('[journey integration deactivate] prompt')}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <GBG.FormGroup>
          <GBG.Label>{t('[journey integration deactivate] description')}</GBG.Label>
          <GBG.Assistive>{t('[journey integration deactivate] please confirm', { name })}</GBG.Assistive>
          <GBG.Text aria-label="confirm-input" onChange={(e: any) => validateConfirmation(e.target.value)} />
        </GBG.FormGroup>
        <ModalFooter
          cancelLabel={t('[journey integration deactivate] cancel')}
          submitLabel={t('[journey integration deactivate] submit')}
          onCancel={onCancel}
          onSubmit={() => {
            if (isConfirmed) {
              onSubmit(id)
            }
          }}
        />
      </GBG.ModalBody>
    </>
  )
}

export default DeactivateJourneyIntegrationModal
