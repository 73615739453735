import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'

import styles from './Review.module.css'

interface Props {
  onNavigateToDashboard: () => void
}

const Review: FC<Props & WithTranslation> = ({ t, onNavigateToDashboard }) => (
  <div>
    <h1 className={styles.title}>{t('[review] title')}</h1>
    <h2 className={styles.whatNowTitle}>{t('[review] what now title')}</h2>
    <p className={styles.whatNowDescription}>{t('[review] what now description')}</p>
    <p className={styles.readyToGoLiveDescription}>{t('[review] ready to go live description')}</p>
    <button className={`button-solid ${styles.dashboardButton}`} onClick={() => onNavigateToDashboard()}>
      {t('[review] navigate to dashboard button')}
    </button>
  </div>
)

export default Review
