import { applyMiddleware, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootReducer from './reducers'
import middleware from './middleware'
import history from './history'
import transforms from './transforms'

const persistConfig = {
  key: 'root',
  storage,
  transforms,
  whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer(history))
const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(routerMiddleware(history), ...middleware)),
)
const persistor = persistStore(store)

export { store, persistor }
