import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import { prop } from 'ramda'

import styles from './JourneyIntegrationLink.module.css'
import { CreateJourneyIntegrationLinkStatus } from './journeyIntegrationLink.types'
import JourneyIntegrationLinkCreate from './JourneyIntegrationLinkCreate'
import JourneyIntegrationLinkDetail from './JourneyIntegrationLinkDetail'
import JourneyIntegrationLinkFailure from './JourneyIntegrationLinkFailure'
import JourneyIntegrationLinkData from './JourneyIntegrationLinkData'

export interface Props {
  journeyIntegrationId: number
  customerId: string
  urls: string[]
  createStatus: CreateJourneyIntegrationLinkStatus
  accountBalance: number
  accountExpires: Date | null
  isMultiLinkCreation: boolean
  onUpdate: (journeyIntegrationId: number, customerIds: string[]) => void
  onNavigateToSubscriptionSettings: () => void
  onCancel: () => void
}

const JourneyIntegrationLink: FC<Props & WithTranslation> = ({
  journeyIntegrationId,
  urls,
  createStatus,
  onUpdate,
  onNavigateToSubscriptionSettings,
  onCancel,
  accountBalance,
  accountExpires,
  isMultiLinkCreation,
}) => {
  // TODO: use common/form status
  const VIEW_STATUS_MAP = {
    [CreateJourneyIntegrationLinkStatus.DataProtection]: 'data',
    [CreateJourneyIntegrationLinkStatus.Ready]: 'create',
    [CreateJourneyIntegrationLinkStatus.Loading]: 'create',
    [CreateJourneyIntegrationLinkStatus.Success]: 'success',
    [CreateJourneyIntegrationLinkStatus.Failure]: 'failure',
  }
  const VIEWS = {
    data: <JourneyIntegrationLinkData onCancel={() => onCancel()} />,
    create: (
      <JourneyIntegrationLinkCreate
        isMultiLinkCreation={isMultiLinkCreation}
        balance={accountBalance}
        expires={accountExpires}
        onSubmit={(customerIds: string[]) => onUpdate(journeyIntegrationId, customerIds)}
        onCancel={() => onCancel()}
        isSubmitting={createStatus === CreateJourneyIntegrationLinkStatus.Loading}
      />
    ),
    success: <JourneyIntegrationLinkDetail urls={urls} onSubmit={() => onCancel()} />,
    failure: (
      <JourneyIntegrationLinkFailure onSubmit={() => onNavigateToSubscriptionSettings()} onCancel={() => onCancel()} />
    ),
  }
  return <div className={styles.container}>{prop(prop(createStatus)(VIEW_STATUS_MAP))(VIEWS)}</div>
}

export default JourneyIntegrationLink
