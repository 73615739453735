import { createAction, createReducer } from '@reduxjs/toolkit'

import { HTTPPayload } from '../../common/redux-http'
import { ActionWithPayload } from '../../common/redux/types'
import { BusinessDetails } from '../OnboardForm/onboard.types'
import { User } from './user.types'

export const CREATE_USER = '[user] create user'
export const createUser = createAction(CREATE_USER)

export const HTTP_CREATE_USER = '[user] HTTP create user'
export const httpCreateUser = (data: { [key: string]: unknown }): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_USER)({ endpoint: 'users', data })
export const HTTP_CREATE_USER_SUCCESS = '[user] HTTP create user success'
export const httpCreateUserSuccess = createAction(HTTP_CREATE_USER_SUCCESS)
export const HTTP_CREATE_USER_FAILURE = '[user] HTTP create user failure'
export const httpCreateUserFailure = createAction(HTTP_CREATE_USER_FAILURE)

export const HTTP_GET_USER = '[user] HTTP get user'
export const httpGetUser = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_USER)({ endpoint: 'users' })
export const HTTP_GET_USER_SUCCESS = '[user] HTTP get user success'
export const httpGetUserSuccess = createAction(HTTP_GET_USER_SUCCESS)
export const HTTP_GET_USER_FAILURE = '[user] HTTP get user failure'
export const httpGetUserFailure = createAction(HTTP_GET_USER_FAILURE)
export const UPDATE_USER = '[user] update user'
export const updateUser = (businessDetails: BusinessDetails): ActionWithPayload<any> => ({
  type: UPDATE_USER,
  payload: { data: businessDetails },
})

export const HTTP_UPDATE_USER = '[user] HTTP update user'
export const httpUpdateUser = (
  data: {
    companyType?: string
    vatNumber?: string
    address?: string
    stripeSubscriptionCurrentPeriodEnd?: number
    autoRenew?: boolean
    subscriptionCancelled?: boolean
  },
  userId: number,
): ActionWithPayload<HTTPPayload> => createAction<HTTPPayload>(HTTP_UPDATE_USER)({ endpoint: `users/${userId}`, data })
export const HTTP_UPDATE_USER_SUCCESS = '[user] HTTP update user success'
export const httpUpdateUserSuccess = createAction(HTTP_UPDATE_USER_SUCCESS)

export const SET_USER = '[user] set user'
export const setUser = createAction<User>(SET_USER)

// export const CANCEL_AUTO_RENEW = '[user] cancel auto renew'
// export const cancelAutoRenew = createAction(CANCEL_AUTO_RENEW)

// export const CONFIRM_CANCEL_AUTO_RENEW = '[user] confirm cancel auto renew'
// export const confirmCancelAutoRenew = createAction(CONFIRM_CANCEL_AUTO_RENEW)

// export const ENABLE_AUTO_RENEW = '[user] enable auto renew'
// export const enableAutoRenew = createAction(ENABLE_AUTO_RENEW)

// export const CANCEL_SUBSCRIPTION = '[user] cancel subscription'
// export const cancelSubscription = createAction(CANCEL_SUBSCRIPTION)

// export const CONFIRM_CANCEL_SUBSCRIPTION = '[user] confirm cancel subscription'
// export const confirmCancelSubscription = createAction(CONFIRM_CANCEL_SUBSCRIPTION)

//---------------------------------
// reducers
//---------------------------------

const overwriteUser = (state: User, { payload }: ActionWithPayload<User>) => payload

export const INITIAL_STATE = {
  id: null,
  authId: '',
  email: '',
  name: '',
  companyName: '',
  address: '',
  companyType: '',
  vatNumber: '',
  stripeSubscriptionCurrentPeriodEnd: null,
  autoRenew: true,
  subscriptionCancelled: false,
}

const reducer = createReducer<User>(INITIAL_STATE, {
  [SET_USER]: overwriteUser,
})
export default reducer
