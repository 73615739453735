import Axios from 'axios'
import { push } from 'connected-react-router'
import { WebAuth } from 'auth0-js'
import Bluebird from 'bluebird'

import { APP_INIT, appInitFlow } from '../../modules/App'
import {
  DEFAULT_HEADERS,
  DEFAULT_TIMEOUT,
  HTTP_RESPONSE_UNAUTHORIZED,
  httpRequestAuthFlow,
  httpRequestFlow,
} from '../redux-http'
import {
  authenticatedFlow,
  authSignupFlow,
  authLoginFlow,
  authLogoutFlow,
  WebAuthAsync,
  authStateResetFlow,
  authChangePasswordFlow,
  setAfterLoginRedirectRouteFlow,
  unauthorizedFlow,
} from '../../modules/Auth'
import { resetAlertFlow } from '../modules/Alert'
import { journeyTypesInitFlow, setJourneyTypesFlow } from '../modules/JourneyTypes'
import { journeyIntegrationsInitFlow, setJourneyIntegrationsFlow } from '../modules/JourneyIntegrations'
import {
  createJourneyIntegrationProceedFlow,
  createJourneyIntegrationBackFlow,
  createJourneyIntegrationFlow,
  getJourneyIntegrationLogoUploadURLFlow,
  getJourneyIntegrationLogoUploadURLSuccessFlow,
  uploadJourneyIntegrationLogoFlow,
  uploadJourneyIntegrationLogoSuccessFlow,
  setupLaterFlow,
  resetFlow,
  updateJourneyIntegrationNavigateFlow,
  updateJourneyIntegrationFlow,
  httpCreateJourneyIntegrationSuccessFlow,
  httpUpdateJourneyIntegrationSuccessFlow,
  initialSelectedJourneyIntegrationFlow,
  createJourneyIntegrationCompletedStepsInitialState,
  deactivateJourneyIntegrationFlow,
  activateJourneyIntegrationFlow,
  setIsLoadingFlow,
  createJourneyIntegrationFormSubmissionSuccessFlow,
  createJourneyIntegrationFormSubmissionFailureFlow,
  updateJourneyIntegrationFormSubmissionSubmittingFlow,
  updateJourneyIntegrationFormSubmissionFailureFlow,
  updateJourneyIntegrationPersonalisationFormSubmissionFailureFlow,
} from '../../modules/JourneyIntegration'
import {
  getJourneyIntegrationLinkResultsFlow,
  getJourneyIntegrationLinkResultsForJourneyFlow,
} from '../modules/JourneyIntegrationLinkResults'
import {
  navigateToFlow,
  onRouteFlow,
  redirectToLoginFlow,
  redirectAfterLoginFlow,
  Routes,
  ON_ROUTE,
} from '../modules/Routing'
import {
  CREATE_JOURNEY_INTEGRATION_LINK,
  downloadJourneyIntegrationLinkReportFlow,
  httpDownloadJourneyIntegrationLinkReportFailureFlow,
  httpDownloadJourneyIntegrationLinkReportSuccessFlow,
} from '../modules/JourneyIntegrationLink'
import { ModalView } from '../../modules/Modal/modal.types'
import { DEACTIVATE_JOURNEY_INTEGRATION } from '../../modules/JourneyIntegration'
import {
  CREATE_PAYMENT_METHOD,
  initUpdatePaymentFlow,
  updatePaymentSelectSubscriptionFlow,
  updatePaymentFormSuccessFlow,
  updatePaymentFormFailureFlow,
  updatePaymentSubscriptionLoadingFlow,
  updateDefaultSubscriptionWithNewPaymentMethodFlow,
  updateDefaultSubscriptionSuccessFlow,
  getPaymentHistoryFlow,
} from '../../modules/Payment'
import { setModalViewFlow } from '../../modules/Modal'
import {
  getUserFlow,
  createUserFlow,
  setUserFlow,
  updateUserFlow,
  updateUserSuccessFlow,
  refreshUserFlow,
} from '../../modules/User'
import { AUTH_LOGIN_SUCCESS, UNAUTHORIZED } from '../../modules/Auth'
import {
  HTTP_CREATE_USER_SUCCESS,
  HTTP_GET_USER_SUCCESS,
  HTTP_UPDATE_USER_SUCCESS,
} from '../../modules/User/user.reducer'
import { getJourneyCreditAccountFlow, setBalanceFlow } from '../modules/JourneyCreditAccount'
import { gaMiddleware } from '../redux-gtm'
import {
  HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL,
  S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_FAILURE,
} from '../../modules/JourneyIntegration/journeyIntegration.reducer'
import { AUTH_LOGIN_FAILURE } from '../../modules/Auth/auth.reducer'
import {
  getSubscriptionsFlow,
  updateDefaultSubscriptionFlow,
  HTTP_UPDATE_SUBSCRIPTION_SUCCESS,
} from '../modules/Subscriptions'
import {
  getPaymentMethodsFlow,
  refreshPaymentMethodsFlow,
  updateDefaultPaymentMethodFlow,
  HTTP_UPDATE_PAYMENT_METHOD_SUCCESS,
} from '../modules/PaymentMethods'
import { DELETE_PAYMENT_METHOD, STRIPE_CARD_CONFIRMED } from '../modules/PaymentMethods/paymentMethods.reducer'
import { confirmPaymentFlow, updateUserAddressFlow } from '../../modules/Payment/payment.middleware'
import {
  confirmDeletePaymentMethodFlow,
  httpDeletePaymentMethodFailureFlow,
  httpDeletePaymentMethodSuccessFlow,
  stripeCardErrorFlow,
  updatePaymentMethodBillingAddressFlow,
} from '../modules/PaymentMethods/paymentMethods.middleware'
import { createSubscriptionSuccessFlow } from '../modules/Subscriptions/subscriptions.middleware'
import {
  getJourneyIntegrationExportsSuccessFlow,
  getJourneyIntegrationHistoryFlow,
  getJourneyIntegrationHistorySuccessFlow,
  httpDownloadJourneyIntegrationExportSuccessFlow,
} from '../../modules/JourneyIntegration/journeyIntegration.middleware'
import { HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_SUCCESS } from '../../modules/Payment/payment.reducer'
import {
  createUserInviteFlow,
  setUserInvitesFlow,
  userInvitesInitFlow,
  usersInitFlow,
  setUsersFlow,
  deleteUserInviteFlow,
} from '../modules/Users'
import {
  getOrganisationFlow,
  setOrganisationFlow,
  updateOrganisationFlow,
  updateOrganisationSuccessFlow,
  refreshOrganisationFlow,
  confirmCancelAutoRenewFlow,
  confirmCancelAutoRenewSuccessFlow,
  confirmCancelSubscriptionFlow,
  confirmCancelSubscriptionSuccessFlow,
  enableAutoRenewFlow,
} from '../../modules/Organisation'
import {
  CANCEL_AUTO_RENEW,
  CANCEL_SUBSCRIPTION,
  HTTP_GET_ORGANISATION_SUCCESS,
  HTTP_UPDATE_ORGANISATION_SUCCESS,
} from '../../modules/Organisation/organisation.reducer'
import { getFeatureFlagsFlow } from '../feature-flags/featureFlags.middleware'
import {
  getDataProtectionFlow,
  setDataProtectionFlow,
  HTTP_GET_DATA_PROTECTION_SUCCESS,
  updateDataProtectionSuccessFlow,
  getDataProtectionFailureFlow,
  createDataProtectionSuccessFlow,
} from '../modules/DataProtection'
import {
  HTTP_CREATE_DATA_PROTECTION_SUCCESS,
  HTTP_UPDATE_DATA_PROTECTION_SUCCESS,
} from '../modules/DataProtection/dataProtection.reducer'
import { httpDownloadJourneyIntegrationExportLinkSuccessFlow } from '../modules/JourneyIntegrations/journeyIntegrations.middleware'

const apiHTTPRequest = Axios.create({
  headers: DEFAULT_HEADERS,
  baseURL: process.env.REACT_APP_API_URL,
  timeout: DEFAULT_TIMEOUT,
})

const anyHTTPRequest = Axios.create({
  headers: DEFAULT_HEADERS,
  timeout: DEFAULT_TIMEOUT,
})

// TODO: move to a constants file in the correct module
const journeyIntegrationUpdateRoutes = [
  Routes.UPDATE,
  Routes.UPDATE_DETAILS,
  Routes.UPDATE_COMPLETION_ACTION,
  Routes.UPDATE_HISTORY,
  Routes.UPDATE_HISTORY_DOWNLOAD,
  Routes.UPDATE_PERSONALISATION,
]

// TODO: move to a constants file in the correct module
const httpGetJourneyIntegrationsRoutes = [Routes.OVERVIEW, ...journeyIntegrationUpdateRoutes]
const httpGetJourneyTypesRoutes = [Routes.OVERVIEW, Routes.CREATE_JOURNEY_INTEGRATION]

// TODO: move to a constants file in the correct module
const actionModalViewMap = {
  [CREATE_JOURNEY_INTEGRATION_LINK]: ModalView.JOURNEY_INTEGRATION_LINK,
  [DEACTIVATE_JOURNEY_INTEGRATION]: ModalView.JOURNEY_INTEGRATION_DEACTIVATE,
  [CREATE_PAYMENT_METHOD]: ModalView.CREATE_PAYMENT_METHOD,
  [DELETE_PAYMENT_METHOD]: ModalView.DELETE_PAYMENT_METHOD,
  [CANCEL_SUBSCRIPTION]: ModalView.CANCEL_SUBSCRIPTION,
  [CANCEL_AUTO_RENEW]: ModalView.CANCEL_AUTO_RENEW,
}

const webAuth = Bluebird.promisifyAll(
  new WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    redirectUri: process.env.REACT_APP_UI_URL as string,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE as string,
    responseType: 'token',
  }),
) as WebAuthAsync

const publicRoutes = [Routes.LOGIN, Routes.SIGNUP, Routes.SIGNUP_CONFIRMATION, Routes.CHANGE_PASSWORD]
const paymentRoutes = [Routes.PAYMENT]

export default [
  // app
  appInitFlow(),
  // alert
  resetAlertFlow(),
  // http
  httpRequestAuthFlow(),
  httpRequestFlow({ httpRequest: apiHTTPRequest }),
  // navigation / routing
  navigateToFlow(push),
  onRouteFlow(),
  redirectToLoginFlow(UNAUTHORIZED),
  redirectAfterLoginFlow(AUTH_LOGIN_SUCCESS),
  // auth
  authenticatedFlow(webAuth, ON_ROUTE),
  authSignupFlow(webAuth),
  authLoginFlow(webAuth),
  authLogoutFlow(webAuth),
  authStateResetFlow(),
  authChangePasswordFlow(webAuth),
  setAfterLoginRedirectRouteFlow(UNAUTHORIZED, publicRoutes),
  unauthorizedFlow(publicRoutes, [HTTP_RESPONSE_UNAUTHORIZED, AUTH_LOGIN_FAILURE]),
  // user
  getUserFlow([AUTH_LOGIN_SUCCESS, APP_INIT, 'persist/REHYDRATE', HTTP_UPDATE_USER_SUCCESS]),
  setUserFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  refreshUserFlow([HTTP_UPDATE_PAYMENT_METHOD_SUCCESS, HTTP_UPDATE_SUBSCRIPTION_SUCCESS]),
  // user : create
  createUserFlow(),
  // user : update
  updateUserFlow(),
  updateUserSuccessFlow([Routes.UPDATE_PAYMENT, Routes.AUTO_RENEW, Routes.PAYMENT]),

  // organisation
  getOrganisationFlow([
    AUTH_LOGIN_SUCCESS,
    APP_INIT,
    'persist/REHYDRATE',
    HTTP_UPDATE_ORGANISATION_SUCCESS,
    HTTP_GET_USER_SUCCESS,
    HTTP_CREATE_USER_SUCCESS,
  ]),
  setOrganisationFlow([HTTP_GET_ORGANISATION_SUCCESS]),
  refreshOrganisationFlow([HTTP_UPDATE_PAYMENT_METHOD_SUCCESS, HTTP_UPDATE_SUBSCRIPTION_SUCCESS]),
  // organisation : update
  updateOrganisationFlow(),
  updateOrganisationSuccessFlow([
    Routes.UPDATE_PAYMENT,
    Routes.AUTO_RENEW,
    Routes.PAYMENT,
    Routes.SETTINGS_ORGANISATION,
    Routes.SETTINGS,
  ]),

  // modal
  setModalViewFlow(actionModalViewMap),
  // journey types
  journeyTypesInitFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS], httpGetJourneyTypesRoutes),
  setJourneyTypesFlow(),
  // journey integrations
  journeyIntegrationsInitFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS], httpGetJourneyIntegrationsRoutes),
  setJourneyIntegrationsFlow(),
  initialSelectedJourneyIntegrationFlow({
    APP_INIT,
    routes: journeyIntegrationUpdateRoutes,
  }),
  getJourneyIntegrationHistoryFlow(),
  getJourneyIntegrationHistorySuccessFlow(),
  getJourneyIntegrationExportsSuccessFlow(),
  // journey integration link results
  getJourneyIntegrationLinkResultsFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS], [Routes.OVERVIEW]),
  getJourneyIntegrationLinkResultsForJourneyFlow(),
  // create/update journey integration
  getJourneyIntegrationLogoUploadURLFlow(),
  // create journey integration
  createJourneyIntegrationProceedFlow(),
  createJourneyIntegrationBackFlow(),
  createJourneyIntegrationFlow(),
  getJourneyIntegrationLogoUploadURLSuccessFlow(),
  uploadJourneyIntegrationLogoFlow({ httpRequest: anyHTTPRequest }),
  uploadJourneyIntegrationLogoSuccessFlow(createJourneyIntegrationCompletedStepsInitialState),
  setupLaterFlow(push),
  resetFlow(),
  httpCreateJourneyIntegrationSuccessFlow(),
  setIsLoadingFlow(
    [HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL],
    [S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_FAILURE, S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_FAILURE],
  ),
  createJourneyIntegrationFormSubmissionSuccessFlow(),
  createJourneyIntegrationFormSubmissionFailureFlow(),
  // update journey integration
  updateJourneyIntegrationNavigateFlow(push),
  updateJourneyIntegrationFlow(),
  httpUpdateJourneyIntegrationSuccessFlow(),
  deactivateJourneyIntegrationFlow(),
  activateJourneyIntegrationFlow(),
  updateJourneyIntegrationFormSubmissionSubmittingFlow(),
  updateJourneyIntegrationFormSubmissionFailureFlow(),
  updateJourneyIntegrationPersonalisationFormSubmissionFailureFlow(),
  //Export journey integration
  httpDownloadJourneyIntegrationExportLinkSuccessFlow(),
  // journey credit account
  getJourneyCreditAccountFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  setBalanceFlow(),
  gaMiddleware,
  // journey integration link
  downloadJourneyIntegrationLinkReportFlow(),
  httpDownloadJourneyIntegrationLinkReportFailureFlow(),
  httpDownloadJourneyIntegrationLinkReportSuccessFlow(),
  // payments
  initUpdatePaymentFlow(APP_INIT, [Routes.UPDATE_PAYMENT]),
  updatePaymentSelectSubscriptionFlow(),
  updatePaymentFormSuccessFlow([HTTP_UPDATE_SUBSCRIPTION_SUCCESS, HTTP_CREATE_PAYMENT_INTENT_CONFIRMATION_SUCCESS]),
  updatePaymentFormFailureFlow(),
  // payments : subscriptions
  getSubscriptionsFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS], paymentRoutes),
  createSubscriptionSuccessFlow(),
  updateDefaultSubscriptionFlow(),
  updatePaymentSubscriptionLoadingFlow(),
  updateDefaultSubscriptionWithNewPaymentMethodFlow(),
  updateDefaultSubscriptionSuccessFlow(),
  confirmCancelSubscriptionFlow(),
  confirmCancelSubscriptionSuccessFlow(Routes.UPDATE_PAYMENT),
  // payments : payment methods
  getPaymentMethodsFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS], paymentRoutes),
  refreshPaymentMethodsFlow([HTTP_UPDATE_PAYMENT_METHOD_SUCCESS, STRIPE_CARD_CONFIRMED]),
  updateDefaultPaymentMethodFlow(),
  stripeCardErrorFlow(),
  updateUserAddressFlow(),
  confirmDeletePaymentMethodFlow(),
  httpDeletePaymentMethodSuccessFlow(),
  httpDeletePaymentMethodFailureFlow(),
  updatePaymentMethodBillingAddressFlow(),
  // payments : auto renew
  confirmCancelAutoRenewFlow(),
  confirmCancelAutoRenewSuccessFlow(Routes.AUTO_RENEW),
  enableAutoRenewFlow(),
  // payments : payment history
  getPaymentHistoryFlow(),
  // payments
  confirmPaymentFlow(),
  //invites
  userInvitesInitFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  setUserInvitesFlow(),
  createUserInviteFlow(),
  deleteUserInviteFlow(),
  usersInitFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  setUsersFlow(),
  //featureflags
  getFeatureFlagsFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  //data protection info
  getDataProtectionFlow([HTTP_GET_USER_SUCCESS, HTTP_CREATE_USER_SUCCESS]),
  setDataProtectionFlow([
    HTTP_GET_DATA_PROTECTION_SUCCESS,
    HTTP_CREATE_DATA_PROTECTION_SUCCESS,
    HTTP_UPDATE_DATA_PROTECTION_SUCCESS,
  ]),
  updateDataProtectionSuccessFlow([]),
  getDataProtectionFailureFlow([]),
  createDataProtectionSuccessFlow(),
  httpDownloadJourneyIntegrationExportSuccessFlow(),
]
