import { createReducer, createAction } from '@reduxjs/toolkit'
import { HTTPPayload } from '../../../common/redux-http'
import { ActionWithPayload } from '../../../common/redux/types'
import { User } from '../../../modules/User/user.types'
import { SetUsersPayload } from './Users.types'
import { UserInvite, UsersState, SetUserInvitesPayload } from './UserInvites.types'

//-------------------
// LIST
//-------------------

export const HTTP_GET_USERS = '[users] HTTP get'
export const HTTP_GET_USERS_SUCCESS = '[users] HTTP get success'
export const HTTP_GET_USERS_FAILURE = '[users] HTTP get failure'

export const httpGetUsers = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_USERS)({ endpoint: 'users/all' })
export const httpGetUsersSuccess = createAction<User[]>(HTTP_GET_USERS_SUCCESS)
export const httpGetUsersFailure = createAction<string>(HTTP_GET_USERS_FAILURE)

//-------------------
// SET
//-------------------

export const SET_USERS = '[users] set'
export const setUsers = (users: User[]): ActionWithPayload<SetUsersPayload> =>
  createAction<SetUsersPayload>(SET_USERS)({ users })

export const PUSH_USER = '[user] add'
export const pushUser = (user: User): ActionWithPayload<User> => createAction<User>(PUSH_USER)(user)

//---------------------------------
// reducers
//---------------------------------

const overwriteUsers = (state: UsersState, { payload }: ActionWithPayload<SetUsersPayload>) => {
  const { users } = payload
  return { ...state, users }
}

const addUser = (state: UsersState, { payload }: ActionWithPayload<User>) => {
  return {
    ...state,
    users: [payload, ...state.users],
  }
}

//--------------------------------------------

//-------------------
// DELETE
//-------------------

export const HTTP_DELETE_USER_INVITE = '[userinvite] HTTP delete'
export const HTTP_DELETE_USER_INVITE_SUCCESS = '[userinvite] HTTP delete success'
export const HTTP_DELETE_USER_INVITE_FAILURE = '[userinvite] HTTP delete failure'
export const httpDeleteUserInvite = (id: number): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_DELETE_USER_INVITE)({ endpoint: `userinvites/${id}` })
export const httpDeleteUserInviteSuccess = createAction<UserInvite>(HTTP_DELETE_USER_INVITE_SUCCESS)
export const httpDeleteUserInviteFailure = createAction(HTTP_DELETE_USER_INVITE_FAILURE)

//-------------------
// CREATE
//-------------------

export const HTTP_CREATE_USER_INVITE = '[userinvite] HTTP create'
export const HTTP_CREATE_USER_INVITE_SUCCESS = '[userinvite] HTTP create success'
export const HTTP_CREATE_USER_INVITE_FAILURE = '[userinvite] HTTP create failure'
export const httpCreateUserInvite = (email: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_USER_INVITE)({ endpoint: 'userinvites', data: { email } })
export const httpCreateUserInviteSuccess = createAction<UserInvite>(HTTP_CREATE_USER_INVITE_SUCCESS)
export const httpCreateUserInviteFailure = createAction<string>(HTTP_CREATE_USER_INVITE_FAILURE)

//-------------------
// GET
//-------------------

export const HTTP_GET_USER_INVITE = '[userinvite] HTTP get'
export const HTTP_GET_USER_INVITE_SUCCESS = '[userinvite] HTTP get success'
export const HTTP_GET_USER_INVITE_FAILURE = '[userinvite] HTTP get failure'

export const httpGetUserInvites = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_USER_INVITE)({ endpoint: 'userinvites' })
export const httpGetUserInvitesSuccess = createAction<UserInvite[]>(HTTP_GET_USER_INVITE_SUCCESS)
export const httpGetUserInvitesFailure = createAction<string>(HTTP_GET_USER_INVITE_FAILURE)

//-------------------
// SET
//-------------------

export const SET_USER_INVITES = '[userinvite] set'
export const setUserInvites = (userInvites: UserInvite[]): ActionWithPayload<SetUserInvitesPayload> =>
  createAction<SetUserInvitesPayload>(SET_USER_INVITES)({ userInvites })

export const PUSH_USER_INVITES = '[userinvite] add'
export const pushUserInvite = (userInvite: UserInvite): ActionWithPayload<UserInvite> =>
  createAction<UserInvite>(PUSH_USER_INVITES)(userInvite)

//---------------------------------
// reducers
//---------------------------------

const overwriteUserInvites = (state: UsersState, { payload }: ActionWithPayload<SetUserInvitesPayload>) => {
  const { userInvites } = payload
  return { ...state, userInvites }
}

const addUserInvite = (state: UsersState, { payload }: ActionWithPayload<UserInvite>) => {
  return {
    ...state,
    userInvites: [payload, ...state.userInvites],
  }
}

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {
  userInvites: [],
  users: [],
} as UsersState

const reducer = createReducer<UsersState>(INITIAL_STATE, {
  [SET_USERS]: overwriteUsers,
  [PUSH_USER]: addUser,
  [SET_USER_INVITES]: overwriteUserInvites,
  [PUSH_USER_INVITES]: addUserInvite,
})

export default reducer
