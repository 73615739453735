import { connect } from 'react-redux'

import CreateJourneyIntegration, { Props } from './CreateJourneyIntegration.component'
import { createJourneyIntegrationCompletedStepsSelector } from '../createJourneyIntegrationCompletedSteps.selectors'
import { createSelector } from 'reselect'
import { dataProtectionSelector } from '../../../common/modules/DataProtection'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { createJourneyIntegrationProceed } from '../createJourneyIntegrationCompletedSteps.reducer'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'
import { isGatheringDPOInfoSelector } from '../../../common/feature-flags'

const mapStateToProps = createSelector(
  [createJourneyIntegrationCompletedStepsSelector, dataProtectionSelector, isGatheringDPOInfoSelector],
  (createJourneyIntegrationCompletedStepsData, dataProtectionData, isGatheringDPOInfo) => ({
    ...createJourneyIntegrationCompletedStepsData,
    includeDataProtection:
      dataProtectionData.loaded && dataProtectionData.info.dpoName.trim() == '' && isGatheringDPOInfo,
  }),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSkipStep: () => dispatch(createJourneyIntegrationProceed()),
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(CreateJourneyIntegration)
