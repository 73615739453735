import { includes, isNil, path } from 'ramda'
import { Middleware } from 'redux'
import GBG from '@gbg/gbgcomponentlibrary_react'
import {
  httpGetDataProtection,
  HTTP_CREATE_DATA_PROTECTION_SUCCESS,
  HTTP_GET_DATA_PROTECTION_FAILURE,
  HTTP_UPDATE_DATA_PROTECTION_SUCCESS,
  setDataProtection,
} from './dataProtection.reducer'
import { navigateTo, Routes } from '../Routing'
import { createJourneyIntegrationProceed } from '../../../modules/JourneyIntegration/createJourneyIntegrationCompletedSteps.reducer'

//---------------------------------
// get data protection
//---------------------------------

export const getDataProtectionFlow = (actions: string[]): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const { auth } = getState()
  const { accessToken } = auth
  if (includes(type)(actions) && !isNil(accessToken)) {
    dispatch(httpGetDataProtection())
  }
}

export const getDataProtectionFailureFlow = (excludedRoutes: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_GET_DATA_PROTECTION_FAILURE) {
    const { router, featureFlags } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (!includes(pathname, excludedRoutes) && featureFlags.isGatheringDPOInfo) {
      dispatch(navigateTo(Routes.ONBOARD_DATA_PROTECTION))
    }
  }
}

//---------------------------------
// set data protection
// ... signals intention to set data protection info
// ... returned from the api
//---------------------------------

export const setDataProtectionFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(setDataProtection(payload))
  }
}

export const updateDataProtectionSuccessFlow = (excludedRoutes: string[]): Middleware => ({
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_DATA_PROTECTION_SUCCESS) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (!includes(pathname, excludedRoutes)) {
      GBG.toast({
        position: GBG.ToastPosition.TopRight,
        type: GBG.ToastType.Success,
        title: 'Data protection info updated',
        content: 'Your data protection information has been updated',
      })
    }
  }
}

export const createDataProtectionSuccessFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_CREATE_DATA_PROTECTION_SUCCESS) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (pathname == Routes.ONBOARD_DATA_PROTECTION) {
      //Navigate to dash
      dispatch(navigateTo(Routes.OVERVIEW))
    } else if (pathname == Routes.CREATE_JOURNEY_INTEGRATION) {
      //dispatch move to next journey creation step
      dispatch(createJourneyIntegrationProceed())
    }
  }
}

//---------------------------------
// refresh data protection
// ... retrieves data protection info after successes that impact data protection info
//---------------------------------

export const refreshDataProtectionFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    dispatch(httpGetDataProtection())
  }
}
