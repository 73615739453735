import React, { FC, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'
import GBG from '@gbg/gbgcomponentlibrary_react'
import classnames from 'classnames'

import history from '../../common/redux/history'
import Dashboard from '../Dashboard'
import Profile from '../Profile'
import Account from '../Account'
import Navigation from '../Navigation'
import { Routes, ScrollToTop } from '../../common/modules/Routing'
import { UpdateJourneyIntegration } from '../JourneyIntegration'
import {
  ProtectedRoute,
  LoginHeader,
  SignupHeader,
  ChangePasswordHeader,
  LoginForm,
  SignupForm,
  SignupConfirmation,
  ChangePasswordForm,
} from '../Auth'
import OnboardForm from '../OnboardForm'
import { Alert } from '../../common/modules/Alert'
import { PaymentPlaceholder } from '../Payment/PaymentPlaceholder'
import { Payment, UpdatePayment } from '../Payment'
import { PaymentAutoRenew } from '../Payment/PaymentAutoRenew'
import Settings from '../Settings'
import DataProtectionOnboarding from '../Onboarding/DataProtectionOnboarding'

export interface Props {
  isPaymentsEnabled: boolean
  isAccountSettingsEnabled: boolean
  onNavigate: () => void
  onNavigateTo: (path: string) => void
  currentRoutePathname: string
  name: string
  companyName: string
}

const HeaderAndNavWrapper: FC<Props> = ({
  isPaymentsEnabled,
  isAccountSettingsEnabled,
  onNavigate,
  currentRoutePathname,
  name,
  companyName,
  onNavigateTo,
}) => {
  const location = useLocation()

  const SURVEY_PATHS: string[] = [Routes.OVERVIEW]

  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://resources.customersure.com/assets/survey_widget.js'
    script.defer = true

    document.body.appendChild(script)
  }, [])

  useEffect(() => {
    //TODO: this whole thing feels absolutely terrible
    const x = setInterval(() => {
      if (SURVEY_PATHS.includes(location.pathname)) {
        try {
          //@ts-ignore
          if (CustomerSureSurveyWidget) {
            try {
              //@ts-ignore
              CustomerSureSurveyWidget.init()
            } finally {
              clearInterval(x)
            }
          }
        } catch (ex) {
          /* EMPTY */
        }
      }
    }, 1000)
    return () => {
      clearInterval(x)
    }
  }, [location.pathname])

  const structureclasses = classnames('structure', {
    'structure--stepper structure--stepper--colored--identity':
      currentRoutePathname == Routes.LOGIN ||
      currentRoutePathname == Routes.SIGNUP ||
      currentRoutePathname == Routes.CHANGE_PASSWORD,
  })
  const [menuOpen, setMenuOpen] = useState(false)

  const topMenuItems = [
    {
      title: 'Logout',
      onSelected: () => {
        onNavigate()
      },
    },
  ]
  if (isAccountSettingsEnabled) {
    topMenuItems.unshift({
      title: 'Account settings',
      onSelected: () => {
        onNavigateTo(Routes.SETTINGS)
      },
    })
  }

  return (
    <div className={structureclasses}>
      <GBG.TopBar
        onNavigationToggle={() => {
          setMenuOpen(!menuOpen)
        }}
        includeNavLink={
          currentRoutePathname != Routes.LOGIN &&
          currentRoutePathname != Routes.SIGNUP &&
          currentRoutePathname != Routes.CHANGE_PASSWORD
        }
        includeUser={
          currentRoutePathname != Routes.LOGIN &&
          currentRoutePathname != Routes.SIGNUP &&
          currentRoutePathname != Routes.CHANGE_PASSWORD
        }
        brand={GBG.TopBarBrand.GBG}
        user={{ username: name, company: companyName }}
        menu={{
          items: topMenuItems,
        }}
      >
        <Router history={history}>
          <Switch>
            <Route path={Routes.LOGIN}>
              <LoginHeader />
            </Route>
            <Route path={Routes.SIGNUP}>
              <SignupHeader />
            </Route>
            <Route path={Routes.CHANGE_PASSWORD}>
              <ChangePasswordHeader />
            </Route>
          </Switch>
        </Router>
      </GBG.TopBar>
      <Navigation
        menuOpen={menuOpen}
        onMenuClose={() => {
          setMenuOpen(false)
        }}
      />
      <div className="page__content">
        <GBG.Card isPrimaryContent={true}>
          <Alert />
          <GBG.CardBody isPrimaryContent={true}>
            <Router history={history}>
              <ScrollToTop />
              <Switch>
                <Route path={Routes.LOGIN}>
                  <LoginForm />
                </Route>
                <Route path={Routes.SIGNUP}>
                  <SignupForm />
                </Route>
                <Route path={Routes.SIGNUP_CONFIRMATION}>
                  <SignupConfirmation />
                </Route>
                <Route path={Routes.CHANGE_PASSWORD}>
                  <ChangePasswordForm />
                </Route>
                <Route path={Routes.ONBOARD_DATA_PROTECTION}>
                  <DataProtectionOnboarding />
                </Route>
                <Route path={Routes.ONBOARD}>
                  <OnboardForm />
                </Route>
                <ProtectedRoute exact path={Routes.OVERVIEW}>
                  <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.PROFILE}>
                  <Profile />
                </ProtectedRoute>
                <ProtectedRoute path={Routes.ACCOUNT}>
                  <Account />
                </ProtectedRoute>
                <ProtectedRoute path={[Routes.SETTINGS, Routes.SETTINGS_USERS]}>
                  <Settings />
                </ProtectedRoute>
                <ProtectedRoute
                  exact
                  path={[
                    Routes.UPDATE,
                    Routes.UPDATE_DETAILS,
                    Routes.UPDATE_COMPLETION_ACTION,
                    Routes.UPDATE_HISTORY,
                    Routes.UPDATE_PERSONALISATION,
                  ]}
                >
                  <UpdateJourneyIntegration />
                </ProtectedRoute>
                {isPaymentsEnabled ? (
                  <>
                    <ProtectedRoute exact path={Routes.PAYMENT}>
                      <Payment />
                    </ProtectedRoute>
                    <ProtectedRoute exact path={Routes.AUTO_RENEW}>
                      <PaymentAutoRenew />
                    </ProtectedRoute>
                    <ProtectedRoute path={Routes.UPDATE_PAYMENT}>
                      <UpdatePayment />
                    </ProtectedRoute>
                  </>
                ) : (
                  <ProtectedRoute exact path={Routes.PAYMENT}>
                    <PaymentPlaceholder />
                  </ProtectedRoute>
                )}
              </Switch>
              <Switch>
                <Route exact path={SURVEY_PATHS}>
                  <div
                    data-customersure-survey-widget
                    data-widget-params='{"org": "gbg", "survey": "Zu8WwloGD-YDDnwaSC2q"}'
                  ></div>
                </Route>
              </Switch>
            </Router>
          </GBG.CardBody>
        </GBG.Card>
      </div>
    </div>
  )
}

export default HeaderAndNavWrapper
