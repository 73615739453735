import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose, pathOr } from 'ramda'

import { setAlert, resetAlert, AlertType } from '../../../common/modules/Alert'
import SignupForm from './SignupForm.component'
import { authSignup } from '../auth.reducer'
import { Credentials } from '../auth.types'
import { createSelector } from 'reselect'
import { FormSubmissionStatus } from '../../../common/form'

// TODO: extract to dedicated selector and test
const mapStateToProps = createSelector(
  [pathOr(FormSubmissionStatus.UNSUBMITTED, ['form', 'signup', 'submissionStatus'])],
  (formSubmissionStatus: string) => ({
    isSubmitting: formSubmissionStatus === FormSubmissionStatus.SUBMITTING,
  }),
) as any

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSubmit: (credentials: Credentials) => {
    dispatch(resetAlert())
    dispatch(authSignup(credentials))
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
})

export default compose(withTranslation('Auth'), connect(mapStateToProps, mapDispatchToProps))(SignupForm)
