import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { compose, tap, prop, assoc, path, __, has, ifElse } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'

import { useFormValidation } from '../../../common/modules/AJVFormValidation'
import formValidationSchema from '../schemas/login-schema.json'
import styles from '../AuthForm.module.css'
import { Credentials } from '../auth.types'

interface Props {
  isSubmitting: boolean
  onSubmit: (formData: Credentials) => void
  onInvalidSubmit: () => void
  onForgotPassword: () => void
}

const LoginForm: FC<Props & WithTranslation> = ({ t, isSubmitting, onSubmit, onInvalidSubmit, onForgotPassword }) => {
  const validationErrorMap = {
    email: {
      minLength: 'email is required', // TODO: translate
    },
    password: {
      minLength: 'password is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onChangeInternal = (key: string) => (event: any) => {
    compose(tap(setFormData), assoc(key, __, formData), path(['target', 'value']))(event)
  }
  const onBlurInternal = () => {
    validate(formData)
  }

  const onPasswordKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmitInternal()
    }
  }

  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    ifElse(
      validate,
      tap(onSubmit),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(formData)
  }

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <h1 className={styles.loginTitle}>{t('[auth] login title')}</h1>
        <GBG.FormGroup>
          <GBG.Label htmlFor="email-input">{t('[auth] login email label')}</GBG.Label>
          <GBG.Text
            id="email-input"
            type="email"
            error={has('email', validationErrors)}
            onChange={onChangeInternal('email')}
            onBlur={onBlurInternal}
            value={prop('email')(formData)}
            placeholder={t('[auth] login email input placeholder')}
            aria-label="email-input"
          />
          {!has('email', validationErrors) ? null : (
            <GBG.ErrorText>{path(['email', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
        </GBG.FormGroup>
        <GBG.FormGroup>
          <GBG.Label htmlFor="password-input">{t('[auth] login password label')}</GBG.Label>
          <GBG.Password
            id="password-input"
            onKeyDown={onPasswordKeyDown}
            onChange={onChangeInternal('password')}
            onBlur={onBlurInternal}
            aria-label="password-input"
            value={prop('password')(formData)}
            error={has('password', validationErrors)}
            placeholder={t('[auth] login password input placeholder')}
          />
          {!has('password', validationErrors) ? null : (
            <GBG.ErrorText>{path(['password', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
        </GBG.FormGroup>
        <a className={styles.recoveryLink} onClick={() => onForgotPassword()}>
          {t('[auth] login recover password link')}
        </a>
        <GBG.FormGroup type={GBG.FormGroupType.Checkbox}>
          <GBG.Checkbox />
          <GBG.FormGroupInfo>
            <GBG.Label>
              <GBG.LabelText>{t('[auth] login remember me label')}</GBG.LabelText>
            </GBG.Label>
          </GBG.FormGroupInfo>
        </GBG.FormGroup>
        <GBG.Button
          className="m-m-t-4"
          aria-label="submit-button"
          onClick={() => {
            onSubmitInternal()
          }}
          worker={true}
          active={internalIsSubmitting}
        >
          {t('[auth] login submit label')}
        </GBG.Button>
      </div>
    </div>
  )
}

export default LoginForm
