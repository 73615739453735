import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { find, isNil, propEq } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import Dinero from 'dinero.js'
import Loader from 'react-loader-spinner'

import styles from './PaymentAutoRenew.module.css'
import SubscriptionItem from '../../../common/modules/Subscriptions/SubscriptionItem'
import { PaymentCard } from '../../../common/modules/PaymentCard'
import { PaymentMethod } from '../../../common/modules/PaymentMethods/paymentMethods.types'
import { Dropdown } from '../../../common/modules/PaymentCard/constants'
import { Subscription } from '../../../common/modules/Subscriptions/subscriptions.types'

interface Props {
  onEnableAutoRenew?: () => void
  onCancel?: () => void
  hasPaymentMethods: boolean
  paymentMethods: PaymentMethod[]
  currentStripePaymentMethodId: string
  currentStripePriceId: string
  subscriptions: Subscription[]
  isAutoRenewing: boolean
  onCancelAutoRenew?: () => void
}

const noop = () => null

const PaymentAutoRenew: FC<Props & WithTranslation> = ({
  t,
  onEnableAutoRenew = noop,
  onCancel = noop,
  paymentMethods,
  hasPaymentMethods,
  currentStripePaymentMethodId,
  currentStripePriceId,
  subscriptions,
  isAutoRenewing,
  onCancelAutoRenew,
}) => {
  const [internalIsLoading, setInternalIsLoading] = useState<boolean>(false)
  const [selectedStripePaymentMethodId, setSelectedStripePaymentMethodId] = useState(currentStripePaymentMethodId)
  const [selectedStripePriceId, setSelectedStripePriceId] = useState(currentStripePriceId)
  useEffect(() => {
    setSelectedStripePaymentMethodId(currentStripePaymentMethodId)
  }, [currentStripePaymentMethodId])
  useEffect(() => {
    setSelectedStripePriceId(currentStripePriceId)
  }, [currentStripePriceId])
  useEffect(() => {
    setInternalIsLoading(false)
  }, [isAutoRenewing])

  const onEnableAutoRenewInternal = () => {
    setInternalIsLoading(true)
    onEnableAutoRenew()
  }

  const renderFooter = (onCancel: () => void, _isAutoRenewing: boolean) => (
    <>
      <div className="m-m-t-2 buttonWrapper">
        <GBG.Button className="m-m-r-2 cancelButton" onClick={onCancel} kind={GBG.ButtonKind.Secondary}>
          {t('[subscription] cancel button')}
        </GBG.Button>
        {!_isAutoRenewing ? (
          <GBG.Button
            className={'button-solid submitButton'}
            onClick={onEnableAutoRenewInternal}
            worker={true}
            workerPosition={GBG.ButtonWorkerPosition.Right}
            active={internalIsLoading && !_isAutoRenewing}
            disabled={_isAutoRenewing}
            kind={GBG.ButtonKind.Primary}
          >
            {t('[payment auto renew] enable auto renew button title')}
          </GBG.Button>
        ) : null}
      </div>
    </>
  )
  const findSubscriptionDetailsByPriceId = (_selectedStripePriceId: string) => {
    return find(propEq('stripePriceId', _selectedStripePriceId))(subscriptions)
  }
  const selectedSubscriptionDetails = findSubscriptionDetailsByPriceId(selectedStripePriceId)
  const renderSubscriptionItem = (_selectedSubscriptionDetails?: Subscription) => {
    if (!_selectedSubscriptionDetails) {
      return
    }
    const name = _selectedSubscriptionDetails.name
    const amount = _selectedSubscriptionDetails.price
    const currency = _selectedSubscriptionDetails.currency
    const unitLabel = _selectedSubscriptionDetails.unitLabel
    const units = _selectedSubscriptionDetails.units
    const translatedUnitLabel = t(`[payment auto renew] ${unitLabel}`)
    const translatedUnitsLabel = t(`[payment auto renew] ${unitLabel} plural`)
    const pricePerUnit = Dinero({ amount, currency }).divide(units).toFormat()
    const description = `${units} ${translatedUnitsLabel} per month`
    const descriptionRatio = `${pricePerUnit}/${translatedUnitLabel}`
    const price = `${Dinero({ amount, currency }).toFormat()}/month`
    return (
      <SubscriptionItem
        name={name}
        description={description}
        descriptionRatio={descriptionRatio}
        price={price}
        isSelected={true}
        isCurrent={true}
        isSelectable={false}
      />
    )
  }
  const findCardDetailsByPaymentMethodId = (_selectedStripePaymentMethodId: string) => {
    return find(propEq('stripePaymentMethodId', _selectedStripePaymentMethodId))(paymentMethods)
  }
  const selectedCardDetails = findCardDetailsByPaymentMethodId(selectedStripePaymentMethodId)
  const defaultCardDetails = paymentMethods[0]
  const renderPaymentCard = (_selectedCardDetails: PaymentMethod) => {
    if (isNil(_selectedCardDetails)) {
      if (isNil(defaultCardDetails)) {
        return
      }
      return (
        <PaymentCard
          cardBrand={defaultCardDetails.cardBrand}
          cardLast4={defaultCardDetails.cardLast4}
          expiryDate={defaultCardDetails.expiryDate}
          billingName={defaultCardDetails.billingName}
          dropdown={Dropdown.none}
          paymentMethods={paymentMethods}
          onSelect={setSelectedStripePaymentMethodId}
        />
      )
    }
    return (
      <PaymentCard
        cardBrand={_selectedCardDetails.cardBrand}
        cardLast4={_selectedCardDetails.cardLast4}
        expiryDate={_selectedCardDetails.expiryDate}
        billingName={_selectedCardDetails.billingName}
        dropdown={Dropdown.none}
        paymentMethods={paymentMethods}
        onSelect={setSelectedStripePaymentMethodId}
      />
    )
  }

  const paymentMethodsView = (
    <>
      <p className={styles.subscriptionExplainer}>{t('[payment auto renew] subscription explainer')}</p>
      {renderSubscriptionItem(selectedSubscriptionDetails)}
      <p className={styles.cardExplainer}>{t('Using the card')}</p>
      <div className={styles.paymentCardWrapper}>{renderPaymentCard(selectedCardDetails)}</div>
      {renderFooter(onCancel, isAutoRenewing)}
    </>
  )

  const noPaymentMethodsView = (
    <span className={styles.loader}>
      <Loader type="TailSpin" color="#00BFFF" height={80} width={80} />{' '}
    </span>
  )

  const hasSubscriptions = subscriptions.length > 0
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {isAutoRenewing
          ? t('[payment auto renew] manage auto renew title')
          : t('[payment auto renew] enable auto renew title')}
      </h1>
      <p className={styles.description}>{t('[payment auto renew] description')}</p>
      {hasPaymentMethods && hasSubscriptions ? paymentMethodsView : noPaymentMethodsView}
      {isAutoRenewing ? (
        <div className={styles.cancelAutoRenewWrapper}>
          <h3 className={styles.cancelAutoRenewTitle}>{t('[payment auto renew] cancel auto renew title')}</h3>
          <p className={styles.cancelAutoRenewExplainer}>{t('[payment auto renew] cancel auto renew explainer')}</p>
          <GBG.Button
            className={`m-m-r-2 cancelButton  ${styles.cancelAutoRenewButton}`}
            onClick={onCancelAutoRenew}
            kind={GBG.ButtonKind.Secondary}
          >
            {t('[payment auto renew] cancel auto renew button title')}
          </GBG.Button>
        </div>
      ) : null}
    </div>
  )
}

export default PaymentAutoRenew
