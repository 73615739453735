import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'

import PaymentMethods from './PaymentMethods.component'
import { selectPaymentMethod, updateDefaultPaymentMethod, deletePaymentMethod } from './paymentMethods.reducer'
import { paymentMethodsSelector } from './paymentMethods.selectors'

const mapStateToProps = paymentMethodsSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onSelect: (id: string) => {
    dispatch(selectPaymentMethod(id))
  },
  onUpdate: (id: string) => {
    dispatch(updateDefaultPaymentMethod(id))
  },
  onDelete: (id: string) => {
    dispatch(deletePaymentMethod(id))
  },
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(PaymentMethods)
