import React, { FC, useEffect, useState } from 'react'
import { isNil, nAry } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './Modal.module.css'
import { ButtonType } from '../../../modules/Modal/modal.types'

interface Props {
  submitLabel: string
  cancelLabel?: string | null
  isDisabled?: boolean
  isSubmitting?: boolean
  onCancel?: Function
  onSubmit?: Function
  buttonType?: ButtonType
  submitButtonClasses?: string
  noSubmit?: boolean
}

const noop = () => null

const ModalFooter: FC<Props> = ({
  submitLabel,
  cancelLabel = null,
  isSubmitting = false,
  isDisabled = false,
  onCancel = noop,
  onSubmit = noop,
  buttonType = ButtonType.Primary,
  submitButtonClasses = '',
  noSubmit = false,
}: Props) => {
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onSubmitInternal = (value: unknown) => {
    setInternalIsSubmitting(true)
    onSubmit(value)
  }
  return (
    <div className={`m-m-t-2 ${styles.footer}`}>
      {isNil(cancelLabel) ? null : (
        <GBG.Button
          className="m-m-r-1"
          onClick={() => {
            onCancel()
          }}
          aria-label="cancel-button"
          kind={GBG.ButtonKind.Secondary}
          disabled={isDisabled}
        >
          {cancelLabel}
        </GBG.Button>
      )}
      {noSubmit ? null : (
        <GBG.Button
          className={`button--${buttonType} ${submitButtonClasses}`}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          active={internalIsSubmitting}
          aria-label="submit-button"
          onClick={nAry(0, onSubmitInternal)}
          disabled={isDisabled}
        >
          {submitLabel}
        </GBG.Button>
      )}
    </div>
  )
}

export default ModalFooter
