import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

export interface Props {
  onClose: () => void
}

const DPONameHelper: FC<Props & WithTranslation> = ({ t, onClose }) => (
  <>
    <GBG.ModalHeader>
      <GBG.AlertBar type={GBG.AlertBarType.Info}>{t('[dataprotectionform] DPO helper title')}</GBG.AlertBar>
    </GBG.ModalHeader>
    <GBG.ModalBody>
      <p>{t('[dataprotectionform] DPO helper para1')}</p>
      <p>{t('[dataprotectionform] DPO helper para2')}</p>
      <ol>
        <li>{t('[dataprotectionform] DPO helper point1')}</li>
        <li>{t('[dataprotectionform] DPO helper point2')}</li>
      </ol>
      <p
        dangerouslySetInnerHTML={{
          __html: t('[dataprotectionform] DPO helper para3', {
            interpolation: { escapeValue: false },
          }),
        }}
      ></p>
      <GBG.Button onClick={onClose}>Close</GBG.Button>
    </GBG.ModalBody>
  </>
)

export default DPONameHelper
