import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './UpdatePaymentSubscription.module.css'
import { Subscriptions } from '../../../common/modules/Subscriptions'

interface Props {
  currentStripePriceId: string
  onSubmit: (id: string) => void
  onCancel?: () => void
  onCancelSubscription?: () => void
  subscriptionCancelled: boolean
  stripeSubscriptionCurrentPeriodEndDate: string
}

const noop = () => null

const UpdatePaymentSubscription: FC<Props & WithTranslation> = ({
  t,
  currentStripePriceId,
  onSubmit,
  onCancel = noop,
  onCancelSubscription,
  subscriptionCancelled,
  stripeSubscriptionCurrentPeriodEndDate,
}) => {
  const [selectedId, setSelectedId] = useState('')
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    setSelectedId(currentStripePriceId)
  }, [currentStripePriceId])

  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    onSubmit(selectedId)
  }

  const subscriptionCancelledView = (
    <div className={styles.cancelSubscriptionWrapper}>
      <h3>{t('[subscription] cancelled subscription title')}</h3>
      <p>
        {t('[subscription] cancelled subscription explainer 1a')} {stripeSubscriptionCurrentPeriodEndDate}{' '}
        {t('[subscription] cancelled subscription explainer 1b')}
      </p>
      <br />
      <p>{t('[subscription] cancelled subscription explainer 2')}</p>
    </div>
  )

  const subscriptionActiveView = (
    <div className={styles.cancelSubscriptionWrapper}>
      <h3>{t('[subscription] cancel subscription title')}</h3>
      <p className={styles.cancelSubscriptionDescription}>{t('[subscription] cancel subscription description')}</p>
      <GBG.Button
        className={`m-m-r-2 cancelButton ${styles.cancelSubscriptionButton}`}
        onClick={onCancelSubscription}
        kind={GBG.ButtonKind.Secondary}
      >
        {t('[subscription] cancel subscription button')}
      </GBG.Button>
    </div>
  )

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('[subscription] title')}</h1>
      <p className={styles.description}>
        {t('[subscription] explainer')} <a className={styles.contactLink}>{t('[subscription] contact link')}</a>
      </p>
      <Subscriptions onSelect={setSelectedId} />
      <div className="m-m-t-2 buttonWrapper">
        <GBG.Button className="m-m-r-2 cancelButton" onClick={onCancel} kind={GBG.ButtonKind.Secondary}>
          {t('[subscription] cancel button')}
        </GBG.Button>
        <GBG.Button
          className={isEmpty(selectedId) ? 'button-disabled submitButton' : 'button-solid submitButton'}
          onClick={onSubmitInternal}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          active={internalIsSubmitting}
          disabled={isEmpty(selectedId)}
          kind={GBG.ButtonKind.Primary}
        >
          {t('[subscription] continue button')}
        </GBG.Button>
      </div>
      {currentStripePriceId
        ? subscriptionCancelled && stripeSubscriptionCurrentPeriodEndDate
          ? subscriptionCancelledView
          : subscriptionActiveView
        : null}
    </div>
  )
}

export default UpdatePaymentSubscription
