import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './Payment.module.css'
import Icon from '../../common/modules/SVGIcon/SVGIcon.component'
import { IconType } from '../../common/modules/SVGIcon/SVGIcon.types'
import JourneyCreditAccountBalance from '../../common/modules/JourneyCreditAccount'
import { PaymentMethods } from '../../common/modules/PaymentMethods'
import { PaymentHistory } from '../PaymentHistory'
import { SubscriptionsState } from '../../common/modules/Subscriptions'

interface Props {
  onChooseSubscription: () => void
  onEnableAutoRenew: () => void
  onCreatePaymentMethod: () => void
  hasPaymentMethods: boolean
  numberOfPaymentMethods: number
  subscriptions?: SubscriptionsState
  isAutoRenewing: boolean
  stripeSubscriptionId: string
}

const Payment: FC<Props & WithTranslation> = ({
  t,
  onChooseSubscription,
  onEnableAutoRenew,
  onCreatePaymentMethod,
  hasPaymentMethods,
  numberOfPaymentMethods,
  isAutoRenewing,
  subscriptions,
  stripeSubscriptionId,
}) => {
  const noPaymentMethodsView = (
    <button
      onClick={(): void => onCreatePaymentMethod()}
      className={`button-ghost ${styles.button} ${styles.addFirstCardButton}`}
    >
      <Icon name={IconType.Plus} />
      {t('[payment] add a card button label')}
    </button>
  )
  const paymentMethodsView = (
    <>
      <PaymentMethods />
      <GBG.Button
        onClick={(): void => onCreatePaymentMethod()}
        className={styles.addCardButtonWithPaymentMethods}
        kind={GBG.ButtonKind.Tertiary}
      >
        {t('[payment] add a card button label')}
      </GBG.Button>
    </>
  )
  return (
    <div>
      <h1 className={styles.title}>{t('[payment] title')}</h1>
      <JourneyCreditAccountBalance />
      <div className={styles.buttonWrapper}>
        <h3>{t('[payment] actions title')}</h3>
        <div className="m-m-t-2 buttonWrapper">
          <GBG.Button
            className="m-m-r-1 submitButton"
            onClick={() => {
              onChooseSubscription()
            }}
            aria-label="choose-subscription-button"
            kind={GBG.ButtonKind.Primary}
          >
            {t('[payment] navigate to subscription button label')}
          </GBG.Button>
          <GBG.Button
            className="m-m-r-2 submitButton"
            onClick={() => {
              onEnableAutoRenew()
            }}
            worker={true}
            aria-label="enable-auto-renew-button"
            kind={GBG.ButtonKind.Secondary}
            disabled={subscriptions && stripeSubscriptionId ? !(subscriptions.length > 0) : true}
          >
            {isAutoRenewing
              ? t('[payment] manage auto renew button label')
              : t('[payment] enable auto renew button label')}
          </GBG.Button>
        </div>
      </div>
      <h3>
        {t('[payment] cards title')} {numberOfPaymentMethods > 2 ? <>({numberOfPaymentMethods})</> : null}
      </h3>
      {hasPaymentMethods ? paymentMethodsView : noPaymentMethodsView}
      <h3>{t('[payment] billing history')}</h3>
      <PaymentHistory />
    </div>
  )
}

export default Payment
