export const STATIC_JOURNEY_TYPES = [
  // {
  //   name: 'Full anti-money laundering checks',
  //   description: 'Combined data and document checks - coming soon!',
  //   icon: 'document-liveness-address',
  //   id: 0,
  //   journeyDefId: null,
  //   journeyTypeSteps: [],
  // },
]
