import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

//import { createSelector } from 'reselect'
import ConfirmExportDeleteModal, { Props } from './ConfirmExportDeleteModal.component'
import { httpDeleteJourneyIntegrationExport } from '../../../../../common/modules/JourneyIntegrations/journeyIntegrations.reducer'

// TODO: extract to dedicated selector and test
const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, { payload, onSubmit = () => null }: any) => ({
  onConfirm: () => {
    dispatch(httpDeleteJourneyIntegrationExport(payload.exportItem.journeyIntegrationId, payload.exportItem.id))
    onSubmit()
  },
})

export default compose(
  withTranslation('JourneyIntegration'),
  connect<Props>(mapStateToProps, mapDispatchToProps),
)(ConfirmExportDeleteModal)
