import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'

import { withTranslation } from 'react-i18next'

import UpdatePaymentSuccess from './UpdatePaymentSuccess.component'
import { navigateTo, Routes } from '../../../common/modules/Routing'
import { updatePaymentSuccessSelector } from './updatePaymentSuccess.selectors'

interface StateProps {
  isFirstPayment: boolean
  onNavigateToDashboard: () => void
}

const mapStateToProps = updatePaymentSuccessSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateToDashboard: () => {
    dispatch(navigateTo(Routes.OVERVIEW))
  },
})

export default compose(
  withTranslation('Payment'),
  connect<StateProps, {}, {}>(mapStateToProps, mapDispatchToProps),
)(UpdatePaymentSuccess)
