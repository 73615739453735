import { createReducer, createAction } from '@reduxjs/toolkit'

import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'
import { BillingAddress } from '../PaymentMethods'
import { CreateSubscriptionResponse, SubscriptionsState } from './subscriptions.types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_SUBSCRIPTIONS = '[subscriptions] HTTP get'
export const HTTP_GET_SUBSCRIPTIONS_SUCCESS = '[subscriptions] HTTP get success'
export const HTTP_GET_SUBSCRIPTIONS_FAILURE = '[subscriptions] HTTP get failure'
export const httpGetSubscriptions = (currency: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_SUBSCRIPTIONS)({
    endpoint: `subscriptions?currency=${currency}`,
  })
export const httpGetSubscriptionsSuccess = createAction<any>(HTTP_GET_SUBSCRIPTIONS_SUCCESS)
export const httpGetSubscriptionsFailure = createAction<string>(HTTP_GET_SUBSCRIPTIONS_FAILURE)

export const SELECT_SUBSCRIPTION = '[subscription] select'
export const selectSubscription = createAction<string>(SELECT_SUBSCRIPTION)

export const HTTP_CREATE_SUBSCRIPTION = '[subscription] HTTP create subscription'
export const httpCreateSubscription = (stripePriceId: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_SUBSCRIPTION)({
    endpoint: 'subscriptions',
    data: {
      stripePriceId,
    },
  })
export const HTTP_CREATE_SUBSCRIPTION_SUCCESS = '[subscription] HTTP create subscription success'
export const httpCreateSubscriptionSuccess = createAction<CreateSubscriptionResponse>(HTTP_CREATE_SUBSCRIPTION_SUCCESS)
export const HTTP_CREATE_SUBSCRIPTION_FAILURE = '[subscription] HTTP create subscription failure'
export const httpCreateSubscriptionFailure = createAction(HTTP_CREATE_SUBSCRIPTION_FAILURE)

export const UPDATE_DEFAULT_SUBSCRIPTION = '[subscription] update default'
export const updateDefaultSubscription = createAction<{
  stripePriceId: string
  stripePaymentMethodId?: string
  address: BillingAddress
}>(UPDATE_DEFAULT_SUBSCRIPTION)

export const HTTP_UPDATE_SUBSCRIPTION = '[subscription] HTTP update'
export const HTTP_UPDATE_SUBSCRIPTION_SUCCESS = '[subscription] HTTP update success'
export const HTTP_UPDATE_SUBSCRIPTION_FAILURE = '[subscription] HTTP update failure'
export const httpUpdateSubscription = ({
  stripePriceId,
  stripePaymentMethodId,
  address,
}: {
  stripePriceId: string
  stripePaymentMethodId?: string
  address: BillingAddress
}): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_SUBSCRIPTION)({
    endpoint: 'subscriptions',
    data: { stripePriceId, stripePaymentMethodId, address },
  })
export const httpUpdateSubscriptionSuccess = createAction<any>(HTTP_UPDATE_SUBSCRIPTION_SUCCESS)
export const httpUpdateSubscriptionFailure = createAction<string>(HTTP_UPDATE_SUBSCRIPTION_FAILURE)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE = []

//---------------------------------
// reducers
//---------------------------------

const overwriteSelectedJourneyIntegration = (
  state: SubscriptionsState,
  { payload }: ActionWithPayload<SubscriptionsState>,
) => payload

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<SubscriptionsState>(INITIAL_STATE, {
  [HTTP_GET_SUBSCRIPTIONS_SUCCESS]: overwriteSelectedJourneyIntegration,
})

export default reducer
