import { pathOr } from 'ramda'
import { createSelector } from 'reselect'
import { formatStripeDate } from '../../../common/utils/dateTime'

export const updatePaymentSubscriptionSelector = createSelector(
  [
    pathOr('', ['organisation', 'stripePriceId']),
    pathOr('', ['organisation', 'stripeSubscriptionCurrentPeriodEnd']),
    pathOr(null, ['organisation', 'subscriptionCancelled']),
  ],
  (currentStripePriceId: string, stripeSubscriptionCurrentPeriodEnd: number, subscriptionCancelled: boolean) => ({
    currentStripePriceId,
    stripeSubscriptionCurrentPeriodEndDate: formatStripeDate(stripeSubscriptionCurrentPeriodEnd),
    subscriptionCancelled,
  }),
) as any
