import { createAction } from '@reduxjs/toolkit'

import { ActionWithPayload } from '../redux/types'
import { HTTPMeta } from './http.types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_RESPONSE_UNAUTHORIZED = '[http] unauthorized'
export const httpResponseUnauthorized = createAction(HTTP_RESPONSE_UNAUTHORIZED)

export const httpResponseSuccess = <T>(
  type: string,
  payload: T,
  meta: HTTPMeta,
): ActionWithPayload<T> & { meta?: HTTPMeta } => ({ type: `${type} success`, payload, meta })
export const httpResponseFailure = (
  type: string,
  message: string,
  status: number,
): ActionWithPayload<{ message: string; status: number }> => ({
  type: `${type} failure`,
  payload: { message, status },
})
