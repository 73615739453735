import React, { FC } from 'react'
import { isEmpty, map } from 'ramda'

import styles from './JourneyIntegrationSelectedSteps.module.css'

interface JourneyTypeStep {
  id: number
  name: string
  description: string
}

interface Props {
  iconUrl: string
  steps: JourneyTypeStep[]
}

const JourneyIntegrationSelectedSteps: FC<Props> = ({ iconUrl, steps }) => {
  const renderSteps = ({ id, name, description }: JourneyTypeStep) => (
    <div key={id} className={`m-m-t-4 ${styles.wrapper}`}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <img src={iconUrl} />
        </div>
        <div className={styles.title}>{name}</div>
      </div>
      <div className={styles.footer}>{description}</div>
    </div>
  )
  return <div className={styles.container}>{!isEmpty(steps) && map(renderSteps, steps)}</div>
}

export default JourneyIntegrationSelectedSteps
