import { createSelector } from 'reselect'
import { User } from '../../../modules/User/user.types'
import { AppState } from '../../redux/reducers'

export const usersSelector = createSelector(
  (state: AppState) => {
    return state.users.users
  },
  (users: User[]) => {
    return {
      users,
    }
  },
)
