import React, { FC, ChangeEvent } from 'react'
import { WithTranslation } from 'react-i18next'
import { isNil, isEmpty, map, path, equals, compose, addIndex } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import styles from './JourneyTypeForm.module.css'
import { JourneyType } from '../../../../common/modules/JourneyTypes/journeyTypes.types'
import Icon from '../../../../common/modules/SVGIcon/SVGIcon.component'

interface Props {
  journeyTypes: JourneyType[]
  selectedJourneyTypeId: number
  onSetJourneyType: (choice: number) => void
  onProceed: () => void
  onSetupLater: () => void
}

const JourneyTypeForm: FC<Props & WithTranslation> = ({
  t,
  journeyTypes,
  onSetJourneyType,
  onProceed,
  onSetupLater,
  selectedJourneyTypeId = null,
}) => {
  const onChangeSelection = (event: ChangeEvent<HTMLInputElement>) =>
    compose(onSetJourneyType, (x: string) => parseInt(x), path(['target', 'value']))(event)

  const renderJourneyType = ({ name, description, id = 0, icon }: JourneyType, i: number) => (
    <li key={i} className={equals(id, 0) ? styles.disabled : ''}>
      <label>
        <div className={styles.icon}>{isNil(icon) ? null : <Icon name={icon as string} />}</div>
        <div className={styles.labelContent}>
          <div className={styles.description}>
            <h3>{name}</h3>
            <p>{description}</p>
          </div>
          <GBG.Radio
            name="journey-types"
            value={id}
            onChange={onChangeSelection}
            checked={!equals(id, 0) && equals(id, selectedJourneyTypeId)}
          />
        </div>
      </label>
    </li>
  )

  return (
    <div>
      <h1>{t('[choose journey] title')}</h1>
      <p className="introduction">{t('[choose journey] introduction')}</p>
      {!isEmpty(journeyTypes) && (
        <ul className={styles.journeyTypes}>{addIndex(map)(renderJourneyType, journeyTypes)} </ul>
      )}
      {/* TODO: Pull out into common component*/}
      <div className={styles.navigation}>
        <GBG.Button
          className="m-m-r-2 m-m-b-8"
          onClick={() => {
            onSetupLater()
          }}
          kind={GBG.ButtonKind.Secondary}
        >
          {t('[choose journey] setup later')}
        </GBG.Button>
        <GBG.Button
          className="m-m-b-8"
          onClick={() => {
            onProceed()
          }}
        >
          {t('[journey integration create] continue')}
        </GBG.Button>
      </div>
    </div>
  )
}

export default JourneyTypeForm
