import { CreateJourneyIntegrationStep, CompletionActionOption, CompletionActionType } from './journeyIntegration.types'

export const createJourneyIntegrationSteps: CreateJourneyIntegrationStep[] = [
  {
    label: '[journey integration create steps] choose journey',
    component: 'CreateJourneyIntegrationJourneyType',
    requiredData: ['journeyTypeId'],
  },
  {
    label: '[journey integration create steps] redirect name',
    component: 'CreateJourneyIntegrationName',
    requiredData: [],
  },
  {
    label: '[journey integration create steps] completion action',
    component: 'CreateJourneyIntegrationCompletionAction',
    requiredData: [],
  },
  {
    label: '[journey integration create steps] personalisation',
    component: 'CreateJourneyIntegrationPersonalisation',
    requiredData: [],
  },
  {
    label: '[journey integration create steps] data protection',
    component: 'CreateJourneyIntegrationDataProtection',
    requiredData: [],
  },
]

export const REDIRECT_ACTION = CompletionActionType.ResultRedirect
export const COMPLETE_MESSAGE_ACTION = CompletionActionType.CompletionMessage
export const COMPLETE_MESSAGE_CTA_ACTION = CompletionActionType.CompletionMessageCTA

export const COMPLETION_ACTIONS: { [key: string]: CompletionActionOption } = {
  [REDIRECT_ACTION]: {
    label: '[completion action] option redirect',
  },
  [COMPLETE_MESSAGE_ACTION]: {
    label: '[completion action] option complete message',
  },
  [COMPLETE_MESSAGE_CTA_ACTION]: {
    label: '[completion action] option complete message and CTA',
  },
}
export const BASE64_LOGO_EXTENSION_REGEX = /^data:image\/(\w+);base64/
export const BASE64_LOGO_PREFIX_REGEX = /^data:image\/(\w+);base64,/
