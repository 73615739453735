import { Middleware } from 'redux'

import { HTTP_GET_JOURNEY_TYPES_SUCCESS, httpGetJourneyTypes, setJourneyTypes } from './journeyTypes.reducer'
import { compose, includes, replace } from 'ramda'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'
import { ON_ROUTE, Routes } from '../Routing'

//---------------------------------
// journey types init
// ... starts the API get journey types flow
// ... on App initialization
//---------------------------------

export const journeyTypesInitFlow = (actions: string[], routes: Routes[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)

  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(httpGetJourneyTypes())
    return
  }
  if (type === ON_ROUTE && includes(replace(/(\d+)/)(':id')(payload), routes)) {
    const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
    if (isAuthenticated) {
      dispatch(httpGetJourneyTypes())
    }
  }
}

//---------------------------------
// set journey types
// ... signals intention to update state
// ... with list of journey types returned from API
//---------------------------------

export const setJourneyTypesFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === HTTP_GET_JOURNEY_TYPES_SUCCESS) {
    dispatch(setJourneyTypes(payload))
  }
}
