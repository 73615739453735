import { compose, isNil, path, propOr, prop } from 'ramda'
import React, { FC } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'

import Icon from '../SVGIcon/SVGIcon.component'
import { IconType } from '../SVGIcon/SVGIcon.types'
import { CARD_STATUS, CARD_TYPE_LABELS, Dropdown, STRIPE_CARD_TYPE } from './constants'
import styles from './PaymentCard.module.css'
import DropdownMenuOptions from '../DropdownMenuOptions'
import { PaymentMethod } from '../PaymentMethods/paymentMethods.types'

export interface Props {
  stripePaymentMethodId: string
  cardBrand: string
  isPrimary: boolean
  cardLast4: number
  billingName: string
  expiryDate: string
  onCreatePaymentMethod: () => void
  onDelete: (stripePaymentMethodId: string) => void
  dropdown?: Dropdown
  paymentMethods: PaymentMethod[]
  onSelect: (stripePaymentMethodId: string) => void
}

export const cardBrandIcons = {
  [STRIPE_CARD_TYPE.MasterCard]: <Icon name={IconType.Mastercard} />,
  [STRIPE_CARD_TYPE.AmericanExpress]: <Icon name={IconType.AmericanExpress} />,
  [STRIPE_CARD_TYPE.Visa]: <Icon name={IconType.Visa} />,
}
export const cardBrandFallbackIcon = <Icon name={IconType.Mastercard} />

const statusMap = {
  [CARD_STATUS.Primary]: {
    statusStyle: styles.primaryCardStyle,
  },
  [CARD_STATUS.Expired]: {
    statusStyle: styles.expiredCardStyle,
  },
  [CARD_STATUS.Declined]: {
    statusStyle: styles.declinedCardStyle,
  },
}

const PaymentCard: FC<Props & WithTranslation> = ({
  t,
  stripePaymentMethodId,
  paymentMethods,
  cardBrand,
  isPrimary,
  cardLast4,
  billingName,
  expiryDate,
  dropdown,
  onSelect,
  onDelete,
}) => {
  const cardStatus = isPrimary ? CARD_STATUS.Primary : null
  const style = isNil(cardStatus) ? styles.container : styles.containerCardStatus
  const dropdownTypeMap = {
    [Dropdown.manage]: (
      <div className={styles.menuPosition}>
        <GBG.OverflowMenu
          onItemSelected={(index: number) => {
            index === 0 && onDelete(stripePaymentMethodId)
          }}
          items={[
            {
              title: t('[payment card] delete card'),
              danger: true,
            },
          ]}
          direction={GBG.OverflowMenuDirection.Left}
        ></GBG.OverflowMenu>
      </div>
    ),
    [Dropdown.select]: <DropdownMenuOptions options={paymentMethods} onSelect={onSelect} />,
    [Dropdown.none]: <></>,
  }
  return (
    <>
      {isNil(cardBrand) ? null : (
        <>
          <div className={style}>
            {isNil(cardStatus) ? null : (
              <span className={path([cardStatus, 'statusStyle'])(statusMap)}>{cardStatus}</span>
            )}
            {propOr(cardBrandFallbackIcon, cardBrand)(cardBrandIcons)}
            <div className={styles.details}>
              <p className={styles.header}>
                {prop(cardBrand)(CARD_TYPE_LABELS)} {t('[payment card] ending text')} {cardLast4}
              </p>
              <p className={styles.explainer}>
                {billingName} <span>&#183;</span> {t('[payment card] expires text')} {expiryDate}
              </p>
            </div>
            {propOr(null, dropdown)(dropdownTypeMap)}
          </div>
        </>
      )}
    </>
  )
}

export default compose(withTranslation('Payment'))(PaymentCard)
