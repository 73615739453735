import { createAction, createReducer } from '@reduxjs/toolkit'
import { assoc, anyPass, isEmpty, isNil } from 'ramda'

import { AuthState, Credentials } from './auth.types'
import { ActionWithPayload } from '../../common/redux/types'

//---------------------------------
// actions
//---------------------------------

export const AUTH_SIGNUP = '[auth] signup'
export const AUTH_SIGNUP_SUCCESS = '[auth] signup success'
export const AUTH_SIGNUP_FAILURE = '[auth] signup failure'
export const authSignup = createAction<Credentials>(AUTH_SIGNUP)
export const authSignupSuccess = createAction(AUTH_SIGNUP_SUCCESS)
export const authSignupFailure = createAction<string>(AUTH_SIGNUP_FAILURE)

export const AUTH_LOGIN = '[auth] login'
export const AUTH_LOGIN_SUCCESS = '[auth] login success'
export const AUTH_LOGIN_FAILURE = '[auth] login failure'
export const authLogin = createAction<Credentials>(AUTH_LOGIN)
export const authLoginSuccess = createAction<string>(AUTH_LOGIN_SUCCESS)
export const authLoginFailure = createAction<string>(AUTH_LOGIN_FAILURE)

export const AUTH_LOGOUT = '[auth] logout'
export const AUTH_LOGOUT_SUCCESS = '[auth] logout success'
export const AUTH_LOGOUT_FAILURE = '[auth] logout failure'
export const authLogout = createAction(AUTH_LOGOUT)
export const authLogoutSuccess = createAction(AUTH_LOGOUT_SUCCESS)
export const authLogoutFailure = createAction<string>(AUTH_LOGOUT_FAILURE)

export const RESET_AUTH_STATE = '[auth] reset auth state'
export const authStateReset = createAction(RESET_AUTH_STATE)

export const AUTH_CHANGE_PASSWORD = '[auth] change password'
export const AUTH_CHANGE_PASSWORD_SUCCESS = '[auth] change password success'
export const AUTH_CHANGE_PASSWORD_FAILURE = '[auth] change password failure'
export const authChangePassword = createAction<{ email: string }>(AUTH_CHANGE_PASSWORD)
export const authChangePasswordSuccess = createAction(AUTH_CHANGE_PASSWORD_SUCCESS)
export const authChangePasswordFailure = createAction<string>(AUTH_CHANGE_PASSWORD_FAILURE)

export const SET_AFTER_LOGIN_REDIRECT_ROUTE = '[auth] set after login redirect route'
export const setAfterLoginRedirectRoute = createAction<string>(SET_AFTER_LOGIN_REDIRECT_ROUTE)

export const UNAUTHORIZED = '[auth] unauthorized'
export const unauthorized = createAction(UNAUTHORIZED)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE: AuthState = {
  accessToken: anyPass([isEmpty, isNil])(process.env.REACT_APP_ACCESS_TOKEN)
    ? null
    : (process.env.REACT_APP_ACCESS_TOKEN as string),
  afterLoginRoute: null,
}

//---------------------------------
// reducers
//---------------------------------

const assocAccessToken = (state: AuthState, { payload }: ActionWithPayload<string>) =>
  assoc('accessToken', payload)(state)

const resetAuthState = assoc('accessToken', null)

const assocAfterLoginRoute = (state: AuthState, { payload }: ActionWithPayload<string>) =>
  assoc('afterLoginRoute', payload)(state)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {
  [AUTH_LOGIN_SUCCESS]: assocAccessToken,
  [AUTH_LOGOUT]: resetAuthState,
  [RESET_AUTH_STATE]: resetAuthState,
  [SET_AFTER_LOGIN_REDIRECT_ROUTE]: assocAfterLoginRoute,
})

export default reducer
