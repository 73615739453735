import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import { setAlert, resetAlert, AlertType } from '../../../../common/modules/Alert'
import {
  createJourneyIntegrationBack,
  createJourneyIntegrationProceed,
} from '../../createJourneyIntegrationCompletedSteps.reducer'
import NameForm from './NameForm.component'
import { setName } from '../../journeyIntegration.reducer'
import { nameSelector } from '../../journeyIntegration.selectors'

const mapStateToProps = nameSelector

const invalidPayload = { type: AlertType.WARNING, title: 'Warning', message: 'please provide all required fields' }

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onBack: () => {
    dispatch(resetAlert())
    dispatch(createJourneyIntegrationBack())
  },
  onSubmit: ({ name }: { name: string }) => {
    dispatch(resetAlert())
    dispatch(setName(name))
    dispatch(createJourneyIntegrationProceed())
  },
  onInvalidSubmit: () => {
    dispatch(setAlert(invalidPayload))
  },
  onValidationChange: (isValid: boolean) => {
    dispatch(isValid ? resetAlert() : setAlert(invalidPayload))
  },
})

export default compose(withTranslation('JourneyIntegration'), connect(mapStateToProps, mapDispatchToProps))(NameForm)
