import React, { FC } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { ModalFooter } from '../../Modal'
import styles from './JourneyIntegrationLinkDetail.module.css'
import QRCodeStyling from 'qr-code-styling'

export interface Props {
  urls: string[]
  onSubmit?: (value: string) => void
}

const noop = () => null

const JourneyIntegrationLinkDetail: FC<Props & WithTranslation> = ({ t, urls, onSubmit = noop }) => {
  const menuOptions = [
    {
      title: 'Copy',
      onSelected: async () => {
        await navigator.clipboard.writeText(urls.length > 1 ? urls.join('\n') : urls[0])
        GBG.toast({
          title: `URL${urls.length > 1 ? 's' : ''} copied`,
          content: '',
          position: GBG.ToastPosition.TopRight,
          type: GBG.ToastType.Success,
        })
      },
    },
  ]

  if (urls.length == 1 && ClipboardItem) {
    menuOptions.push({
      title: 'Copy as QR code',
      onSelected: async () => {
        const qrCode = new QRCodeStyling({
          width: 300,
          height: 300,
          dotsOptions: {
            color: '#000D1A',
            type: 'dots',
          },
          backgroundOptions: {
            color: '#FFFFFF',
          },
          cornersSquareOptions: {
            type: 'extra-rounded',
          },
          cornersDotOptions: {
            type: 'dot',
          },
          data: urls.length > 1 ? urls.join('\n') : urls[0],
        })
        const data = await qrCode.getRawData()
        if (data) {
          try {
            ;(navigator.clipboard as any).write([
              new ClipboardItem({
                'image/png': data,
              }),
            ])
            GBG.toast({
              title: 'QR code copied',
              content: '',
              position: GBG.ToastPosition.TopRight,
              type: GBG.ToastType.Success,
            })
          } catch (error) {
            GBG.toast({
              title: 'There was a problem copying your QR code',
              content: error,
              position: GBG.ToastPosition.TopRight,
              type: GBG.ToastType.Error,
            })
          }
        }
      },
    })
  } else if (urls.length > 1) {
    //TODO: make this work
    // menuOptions.push({
    //   title: 'Download',
    //   onSelected: async () => {
    //     console.log('Download')
    //   },
    // })
  }

  return (
    <>
      <GBG.ModalHeader>
        <GBG.AlertBar icon={GBG.IconKeys.Link24} type={GBG.AlertBarType.Info}>
          <GBG.AlertBarText
            title={t('[create journey integration link] title')}
            text={t('[create journey integration link] description')}
          />
        </GBG.AlertBar>
      </GBG.ModalHeader>
      <GBG.ModalBody>
        <GBG.FormGroup>
          <GBG.Label>{t('[journey integration link] new link', { count: urls.length })}</GBG.Label>
          <div className={styles.row}>
            {urls.length > 1 ? (
              <GBG.Textarea
                className={`m-m-r-1 ${styles.control} m-t-0`}
                aria-label="link-input"
                value={urls.join('\n')}
                disabled
              ></GBG.Textarea>
            ) : (
              <GBG.Text
                className={`m-m-r-1 ${styles.control} m-t-0`}
                aria-label="link-input"
                value={urls[0]}
                disabled
              />
            )}
            <GBG.OverflowMenu
              onItemSelected={(_index: number, item: any) => {
                item.onSelected()
              }}
              items={menuOptions}
              direction={GBG.OverflowMenuDirection.Left}
            ></GBG.OverflowMenu>
          </div>
        </GBG.FormGroup>
        <ModalFooter
          submitButtonClasses="w-100"
          submitLabel={t('[journey integration link] done')}
          onSubmit={onSubmit}
        />
      </GBG.ModalBody>
    </>
  )
}

export default JourneyIntegrationLinkDetail
