import { createReducer, createAction } from '@reduxjs/toolkit'

import { normalize } from '../../utils/data'
import { JourneyType, SetJourneyTypesPayload, JourneyTypesState } from './journeyTypes.types'
import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'

//---------------------------------
// actions
//---------------------------------

export const HTTP_GET_JOURNEY_TYPES = '[journey types] HTTP get'
export const HTTP_GET_JOURNEY_TYPES_SUCCESS = '[journey types] HTTP get success'
export const HTTP_GET_JOURNEY_TYPES_FAILURE = '[journey types] HTTP get failure'
export const httpGetJourneyTypes = (): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_TYPES)({ endpoint: 'journey-types' })
export const httpGetJourneyTypesSuccess = createAction<JourneyType[]>(HTTP_GET_JOURNEY_TYPES_SUCCESS)
export const httpGetJourneyTypesFailure = createAction<string>(HTTP_GET_JOURNEY_TYPES_FAILURE)

export const SET_JOURNEY_TYPES = '[journey types] set'
export const setJourneyTypes = (journeyTypes: JourneyType[]): ActionWithPayload<SetJourneyTypesPayload> =>
  createAction<SetJourneyTypesPayload>(SET_JOURNEY_TYPES)({ journeyTypes })

//---------------------------------
// reducers
//---------------------------------

const overwriteJourneyTypes = (state: JourneyTypesState, { payload }: ActionWithPayload<SetJourneyTypesPayload>) => {
  const { journeyTypes } = payload
  return normalize('id')(journeyTypes)
}

//---------------------------------
// reducer
//---------------------------------

export const INITIAL_STATE = {} as JourneyTypesState

const reducer = createReducer<JourneyTypesState>(INITIAL_STATE, {
  [SET_JOURNEY_TYPES]: overwriteJourneyTypes,
})

export default reducer
