import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { AnyAction } from '@reduxjs/toolkit'
import { mergeDeepRight, reduce } from 'ramda'

import { authLogout } from '../Auth/auth.reducer'
import HeaderAndNavWrapper from './HeaderAndNavWrapper.component'
import { headerAndNavWrapperSelector } from './headerAndNavWrapper.selector'
import { Props } from './HeaderAndNavWrapper.component'
import { isPaymentsEnabledSelector, isAccountSettingsEnabledSelector } from '../../common/feature-flags'
import { navigateTo } from '../../common/modules/Routing'

const mapStateToProps = createSelector(
  [isAccountSettingsEnabledSelector, isPaymentsEnabledSelector, headerAndNavWrapperSelector],
  (...data) => reduce(mergeDeepRight, {})(data),
)

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigate: () => {
    dispatch(authLogout())
  },
  onNavigateTo: (path: string) => dispatch(navigateTo(path)),
})

export default connect<Props>(mapStateToProps, mapDispatchToProps)(HeaderAndNavWrapper)
