import { createAction, createReducer } from '@reduxjs/toolkit'
import { assoc, always, mergeRight, prop } from 'ramda'

import {
  CompletionActionData,
  CompletionActionType,
  JourneyIntegrationState,
  Personalisation,
} from './journeyIntegration.types'
import { ActionWithPayload } from '../../common/redux/types'
import { HTTPPayload } from '../../common/redux-http'

//---------------------------------
// actions
//---------------------------------

export const SET_JOURNEY_TYPE = '[journey integration] create/update set journey type'
export const setJourneyType = createAction<number>(SET_JOURNEY_TYPE)

export const SET_NAME = '[journey integration] create/update set name'
export const setName = createAction<string>(SET_NAME)

export const SET_COMPLETION_ACTION = '[journey integration] create/update set completion action'
export const setCompletionAction = createAction<CompletionActionData>(SET_COMPLETION_ACTION)

export const SET_PERSONALISATION = '[journey integration] create/update set personalisation'
export const setPersonalisation = createAction<Personalisation>(SET_PERSONALISATION)

export const SETUP_LATER = '[journey integration] skip create'
export const setupLater = createAction(SETUP_LATER)

export const RESET = '[journey integration] reset'
export const reset = createAction(RESET)

export const UPDATE_JOURNEY_INTEGRATION_NAVIGATE = '[journey integration] update navigate'
export const updateJourneyIntegrationNavigateTo = createAction<string>(UPDATE_JOURNEY_INTEGRATION_NAVIGATE)

export const HTTP_CREATE_JOURNEY_INTEGRATION = '[journey integration] HTTP create'
export const HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS = '[journey integration] HTTP create success'
export const HTTP_CREATE_JOURNEY_INTEGRATION_FAILURE = '[journey integration] HTTP create failure'
export const httpCreateJourneyIntegration = (data: { [key: string]: unknown }): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_JOURNEY_INTEGRATION)({ endpoint: 'journey-integrations', data })
export const httpCreateJourneyIntegrationSuccess = createAction<{ id: number }>(HTTP_CREATE_JOURNEY_INTEGRATION_SUCCESS)
export const httpCreateJourneyIntegrationFailure = createAction<string>(HTTP_CREATE_JOURNEY_INTEGRATION_FAILURE)

export const UPDATE_JOURNEY_INTEGRATION = '[journey integration] update'
export const updateJourneyIntegration = createAction(UPDATE_JOURNEY_INTEGRATION)

export const HTTP_UPDATE_JOURNEY_INTEGRATION = '[journey integration] HTTP update'
export const HTTP_UPDATE_JOURNEY_INTEGRATION_SUCCESS = '[journey integration] HTTP update success'
export const HTTP_UPDATE_JOURNEY_INTEGRATION_FAILURE = '[journey integration] HTTP update failure'
export const httpUpdateJourneyIntegration = (
  id: number,
  data: { [key: string]: unknown },
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_UPDATE_JOURNEY_INTEGRATION)({ endpoint: `journey-integrations/${id}`, data })
export const httpUpdateJourneyIntegrationSuccess = createAction(HTTP_UPDATE_JOURNEY_INTEGRATION_SUCCESS)
export const httpUpdateJourneyIntegrationFailure = createAction<string>(HTTP_UPDATE_JOURNEY_INTEGRATION_FAILURE)

export const GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL = '[journey integration] get logo upload url'
export const getJourneyIntegrationLogoUploadUrl = createAction<number>(GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL)

export const HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL = '[journey integration] HTTP get logo upload url'
export const HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_SUCCESS =
  '[journey integration] HTTP get logo upload url success'
export const HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_FAILURE =
  '[journey integration] HTTP get logo upload url failure'
export const httpGetJourneyIntegrationLogoUploadURL = (id: number, extension: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL)({
    endpoint: `journey-integrations/${id}/logo-url`,
    params: { extension },
  })
export const httpGetJourneyIntegrationLogoUploadURLSuccess = createAction<{ preSignedUrl: string }>(
  HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_SUCCESS,
)
export const httpGetJourneyIntegrationLogoUploadURLFailure = createAction<string>(
  HTTP_GET_JOURNEY_INTEGRATION_LOGO_UPLOAD_URL_FAILURE,
)

export const S3_UPLOAD_JOURNEY_INTEGRATION_LOGO = '[journey integration] S3 upload logo'
export const S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_SUCCESS = '[journey integration] S3 upload logo success'
export const S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_FAILURE = '[journey integration] S3 upload logo failure'
export const s3UploadJourneyIntegrationLogo = createAction<string>(S3_UPLOAD_JOURNEY_INTEGRATION_LOGO)
export const s3UploadJourneyIntegrationLogoSuccess = createAction(S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_SUCCESS)
export const s3UploadJourneyIntegrationLogoFailure = createAction<string>(S3_UPLOAD_JOURNEY_INTEGRATION_LOGO_FAILURE)

export const VALIDATION_CHANGE = '[journey integration] validation status change'
export const validationChange = createAction<boolean>(VALIDATION_CHANGE)

export const ACTIVATE_JOURNEY_INTEGRATION = '[journey integration] activate'
export const activateJourneyIntegration = createAction<number>(ACTIVATE_JOURNEY_INTEGRATION)

export const DEACTIVATE_JOURNEY_INTEGRATION = '[journey integration] deactivate'
export const deactivateJourneyIntegration = createAction<number>(DEACTIVATE_JOURNEY_INTEGRATION)

export const CONFIRM_DEACTIVATE_JOURNEY_INTEGRATION = '[journey integration] confirm deactivate'
export const confirmDeactivateJourneyIntegration = createAction<number>(CONFIRM_DEACTIVATE_JOURNEY_INTEGRATION)

export const HTTP_GET_JOURNEY_INTEGRATION_HISTORY = '[journey integration] HTTP get history'
export const httpGetJourneyIntegrationHistory = (id: number): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_HISTORY)({ endpoint: `journey-integrations/${id}/history` })
export const HTTP_GET_JOURNEY_INTEGRATION_HISTORY_SUCCESS = '[journey integration] HTTP get history success'
export const httpGetJourneyIntegrationHistorySuccess = createAction<any>(HTTP_GET_JOURNEY_INTEGRATION_HISTORY_SUCCESS)
export const HTTP_GET_JOURNEY_INTEGRATION_HISTORY_FAILURE = '[journey integration] HTTP get history failure'
export const httpGetJourneyIntegrationHistoryFailure = createAction(HTTP_GET_JOURNEY_INTEGRATION_HISTORY_FAILURE)

export const SET_JOURNEY_INTEGRATION_HISTORY = '[journey integration] set history'
export const setJourneyIntegrationHistory = createAction<any>(SET_JOURNEY_INTEGRATION_HISTORY)

export const HTTP_GET_JOURNEY_INTEGRATION_EXPORTS = '[journey integration] HTTP get exports'
export const httpGetJourneyIntegrationExports = (id: number): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_GET_JOURNEY_INTEGRATION_EXPORTS)({
    endpoint: `journey-integrations/${id}/history/export`,
  })
export const HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_SUCCESS = '[journey integration] HTTP get exports success'
export const httpGetJourneyIntegrationExportsSuccess = createAction<any>(HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_SUCCESS)
export const HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_FAILURE = '[journey integration] HTTP get exports failure'
export const httpGetJourneyIntegrationExportsFailure = createAction(HTTP_GET_JOURNEY_INTEGRATION_EXPORTS_FAILURE)

export const SET_JOURNEY_INTEGRATION_EXPORTS = '[journey integration] set exports'
export const setJourneyIntegrationExports = createAction<any>(SET_JOURNEY_INTEGRATION_EXPORTS)

export const HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT = '[journey integration link] HTTP create history export'
export const HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_SUCCESS =
  '[journey integration link] HTTP create history export success'
export const HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_FAILURE =
  '[journey integration link] HTTP create history export failure'
export const httpCreateJourneyIntegrationHistoryExport = (
  id: string,
  data: { selectedItems: string[]; searchFilter: string; resultFilter: string },
): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT)({
    endpoint: `journey-integrations/${id}/history/export`,
    data,
  })
export const httpCreateJourneyIntegrationHistoryExportSuccess = createAction<{ url: String }>(
  HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_SUCCESS,
)
export const httpCreateJourneyIntegrationHistoryExportFailure = createAction<{ message: string; status: number }>(
  HTTP_CREATE_JOURNEY_INTEGRATION_HISTORY_EXPORT_FAILURE,
)

// TODO: remove
export const SET_IS_LOADING = '[journey integration] set is loading'
export const setIsLoading = createAction<boolean>(SET_IS_LOADING)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE: JourneyIntegrationState = {
  journeyTypeId: null,
  name: '',
  completionAction: {
    type: CompletionActionType.ResultRedirect,
    redirectUrl: '',
    ctaText: '',
    successTitle: '',
    successText: '',
    failureTitle: '',
    failureText: '',
    includeIcon: true,
  },
  personalisation: {
    logo: null,
  },
  isLoading: false,
  history: [],
  exports: [],
}

//---------------------------------
// reducers
//---------------------------------

const assocJourneyTypeId = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('journeyTypeId', payload)(state)

const assocName = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('name', payload)(state)

const assocCompletionAction = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('completionAction', mergeRight(prop('completionAction', INITIAL_STATE), payload))(state)

const assocPersonalisation = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('personalisation', payload)(state)

const assocIsLoading = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('isLoading', payload)(state)

const assocHistory = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('history', payload)(state)

const assocExports = (state: JourneyIntegrationState, { payload }: ActionWithPayload<string>) =>
  assoc('exports', payload)(state)

const resetState = always(INITIAL_STATE)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer(INITIAL_STATE, {
  [SET_NAME]: assocName,
  [SET_JOURNEY_TYPE]: assocJourneyTypeId,
  [SET_COMPLETION_ACTION]: assocCompletionAction,
  [SET_PERSONALISATION]: assocPersonalisation,
  [RESET]: resetState,
  [SET_IS_LOADING]: assocIsLoading,
  [SET_JOURNEY_INTEGRATION_HISTORY]: assocHistory,
  [SET_JOURNEY_INTEGRATION_EXPORTS]: assocExports,
})

export default reducer
