import Axios from 'axios'
import React, { FC, useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { __, assoc, compose, has, path, prop, tap, pathOr } from 'ramda'

import Icon from '../../../common/modules/SVGIcon/SVGIcon.component'
import { IconType } from '../../../common/modules/SVGIcon/SVGIcon.types'
import styles from './PaymentMethodForm.module.css'
import { CARD_ELEMENT_OPTIONS } from './constants'
import './PaymentMethodForm.css'
import { useFormValidation } from '../../../common/modules/AJVFormValidation'
import formValidationSchema from './payment-method-schema.json'
import { BillingAddress, PaymentMethodCard } from '../../../common/modules/PaymentMethods/paymentMethods.types'
import { PaymentIntent, SetupIntent } from '@stripe/stripe-js'
import { isEmpty } from 'ramda'

interface Props {
  isSubmitting: boolean
  clientSecret?: string
  isInitialPayment?: boolean
  onCancel?: () => void
  onError?: () => void
  onSubmit?: (paymentMethodCard: PaymentMethodCard) => void
  primaryCardExplainerText: string
  renderFooter: (onCancel: any, onSubmitInternal: any, isSubmitting: boolean, isDisabled: boolean) => any
}

const noop = () => null

const PaymentMethodForm: FC<WithTranslation & Props> = ({
  t,
  clientSecret = null,
  isInitialPayment = false,
  isSubmitting,
  onCancel = noop,
  onError = noop,
  onSubmit = noop,
  primaryCardExplainerText,
  renderFooter = () => null,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const token = useSelector(pathOr('', ['auth', 'accessToken']))

  const validationErrorMap = {
    name: {
      minLength: 'Name is required', // TODO: translate
    },
    areCardDetailsProvided: {
      enum: 'Card details are required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [internalIsSubmitting, setInternalIsSubmitting] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [formData, setFormData] = useState({
    name: '',
    areCardDetailsProvided: false,
    isDefault: false,
  })
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])

  useEffect(() => {
    setIsDisabled(!isEmpty(validationErrors))
  }, [validationErrors])

  const onChangeNameInternal = (event: any) => {
    compose(tap(setFormData), assoc('name', __, formData), path(['target', 'value']))(event)
  }
  const onChangeCardInternal = ({ complete }: { complete: boolean }) => {
    compose(tap(setFormData), assoc('areCardDetailsProvided', __, formData))(complete)
  }
  const onChangeIsDefaultInternal = (event: any) => {
    compose(tap(setFormData), assoc('isDefault', __, formData), path(['target', 'checked']))(event)
  }
  const onBlurInternal = () => {
    validate(formData)
  }
  const stripeConfirmInitialPayment = async () => {
    try {
      // wait for stripe.js load
      if (!stripe || !elements || !clientSecret) {
        return
      }

      // confirm card setup (by calling Stripe)
      const { name } = formData
      const card = elements.getElement(CardElement) as any
      const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card,
          billing_details: { name },
        },
        setup_future_usage: 'off_session',
      })

      if (error) {
        onError()
        return
      }

      const { payment_method } = paymentIntent as PaymentIntent
      return payment_method
    } catch (error) {
      onError()
      return
    }
  }

  const stripeCreatePaymentMethod = async () => {
    try {
      // TODO: Extract this into it's own utility
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}payment-methods/setup-intents`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      const {
        data: {
          data: { clientSecret: clientSecretInternal },
        },
      } = response

      // wait for stripe.js load
      if (!stripe || !elements || !clientSecretInternal) {
        return
      }

      // confirm card setup (by calling Stripe)
      const { name } = formData
      const card = elements.getElement(CardElement) as any
      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecretInternal, {
        payment_method: {
          card,
          billing_details: { name },
        },
      })

      if (error) {
        onError()
        return
      }

      const { payment_method } = setupIntent as SetupIntent
      return payment_method
    } catch (error) {
      onError()
      return
    }
  }

  const ensureAddressIsUpdated = async (billingAddress: BillingAddress) => {
    //patch address up to subscriptions
    await Axios.patch(
      `${process.env.REACT_APP_API_URL}/subscriptions`.replace('//subscriptions', '/subscriptions'),
      {
        address: billingAddress,
        ensureAddressOnly: true,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
  }

  const onSubmitInternal = async (billingAddress: BillingAddress) => {
    await ensureAddressIsUpdated(billingAddress)
    setInternalIsSubmitting(true)
    let stripePaymentMethodId = null
    if (isInitialPayment) {
      stripePaymentMethodId = await stripeConfirmInitialPayment()
    } else {
      stripePaymentMethodId = await stripeCreatePaymentMethod()
    }

    if (stripePaymentMethodId) {
      const _formData = assoc('stripePaymentMethodId', stripePaymentMethodId)(formData)
      setFormData(_formData)
      onSubmit(_formData as PaymentMethodCard)
      return
    }
    setInternalIsSubmitting(false)
  }

  const footer = renderFooter(onCancel, onSubmitInternal, internalIsSubmitting, isDisabled)

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formWrapper}>
          <div className={styles.svgWrapper}>
            <Icon name={IconType.Cards} />
          </div>
          <GBG.FormGroup>
            <GBG.Label htmlFor="card-name-input">{t('[payment method form] card name label')}</GBG.Label>
            <GBG.Text
              id="card-name-input"
              type="text"
              error={has('name', validationErrors)}
              onChange={onChangeNameInternal}
              onBlur={onBlurInternal}
              value={prop('name')(formData)}
              placeholder=""
              aria-label="card-name-input"
            />
            {!has('name', validationErrors) ? null : (
              <GBG.ErrorText>{path(['name', 'message'])(validationErrors)}</GBG.ErrorText>
            )}
          </GBG.FormGroup>

          <GBG.FormGroup>
            <GBG.Label>{t('[payment method form] card details label')}</GBG.Label>
            <GBG.Assistive>{t('[payment method form] card details explainer')}</GBG.Assistive>
            <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChangeCardInternal} onBlur={onBlurInternal} />
            {!has('areCardDetailsProvided', validationErrors) ? null : (
              <GBG.ErrorText>{path(['areCardDetailsProvided', 'message'])(validationErrors)}</GBG.ErrorText>
            )}
          </GBG.FormGroup>

          <GBG.FormGroup type={GBG.FormGroupType.Checkbox} className={styles.checkboxInput}>
            <GBG.Checkbox onChange={onChangeIsDefaultInternal} />
            <GBG.FormGroupInfo>
              <GBG.Label>
                <GBG.LabelText>{t('[payment method form] checkbox label')}</GBG.LabelText>
              </GBG.Label>
              <GBG.Assistive>{primaryCardExplainerText}</GBG.Assistive>
            </GBG.FormGroupInfo>
          </GBG.FormGroup>
        </div>
      </div>
      {footer}
    </>
  )
}

export default withTranslation('Payment')(PaymentMethodForm)
