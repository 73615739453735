//-------------------
// users init
//-------------------

import { Middleware } from 'redux'
import { includes } from 'ramda'
import { httpGetUsers, HTTP_GET_USERS_SUCCESS, setUsers } from './Users.reducer'

export const usersInitFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    dispatch(httpGetUsers())
    return
  }
}

//---------------------------------
// set users
//---------------------------------

export const setUsersFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === HTTP_GET_USERS_SUCCESS) {
    dispatch(setUsers(payload))
  }
}
