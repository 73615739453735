export const STRIPE_CARD_TYPE = {
  AmericanExpress: 'amex',
  DinnersClub: 'diners-club',
  Discover: 'discover',
  JCB: 'jcb',
  MasterCard: 'mastercard',
  Unionpay: 'unionpay',
  Visa: 'visa',
  Unknown: 'unknown',
}
export const CARD_TYPE_LABELS = {
  [STRIPE_CARD_TYPE.AmericanExpress]: 'American Express',
  [STRIPE_CARD_TYPE.DinnersClub]: 'Diners Club',
  [STRIPE_CARD_TYPE.Discover]: 'Discover',
  [STRIPE_CARD_TYPE.JCB]: 'JCB',
  [STRIPE_CARD_TYPE.MasterCard]: 'MasterCard',
  [STRIPE_CARD_TYPE.Unionpay]: 'UnionPay',
  [STRIPE_CARD_TYPE.Visa]: 'Visa',
  [STRIPE_CARD_TYPE.Unknown]: 'Unknown',
}
export const CARD_STATUS = {
  Primary: 'Primary',
  Expired: 'Expired',
  Declined: 'Declined',
}

export enum Dropdown {
  manage = 'manage',
  select = 'select',
  none = 'none',
}
