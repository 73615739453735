import React, { FC } from 'react'
import GBG from '@gbg/gbgcomponentlibrary_react'

import styles from './UpdatePaymentSuccess.module.css'
import { IconType } from '../../../common/modules/SVGIcon/SVGIcon.types'
import Icon from '../../../common/modules/SVGIcon/SVGIcon.component'
import { WithTranslation } from 'react-i18next'
import { formatStripeDate } from '../../../common/utils/dateTime'

interface Props {
  isFirtPayment: boolean
  onNavigateToDashboard: () => void
  stripeSubscriptionCurrentPeriodEnd: number
}

const UpdatePaymentSuccess: FC<Props & WithTranslation> = ({
  t,
  isFirtPayment = true,
  onNavigateToDashboard,
  stripeSubscriptionCurrentPeriodEnd,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.positionConfetti}>
        <Icon name={IconType.Confetti} />
      </div>

      <div className={styles.content}>
        <Icon name={IconType.PaymentComplete} />
        <h1>
          {isFirtPayment ? <>1st</> : null} {t('[payment result] success title')}
        </h1>
        <p>{t('[payment result] success explainer')}</p>

        <GBG.Button
          className={`m-m-r-2 ${styles.submitButton}`}
          worker={true}
          aria-label="navigtate-button"
          onClick={onNavigateToDashboard}
        >
          {t('[payment result] dashboard button')}
        </GBG.Button>
      </div>
      <div className={styles.footer}>
        <div className={styles.info}>
          <h3>{t('[payment result] information section title')}</h3>
          <ul>
            <li>{t('[payment result] information section list item 1')}</li>
            {stripeSubscriptionCurrentPeriodEnd && (
              <li>
                {t('[payment result] information section list item 2')}{' '}
                {formatStripeDate(stripeSubscriptionCurrentPeriodEnd)}
              </li>
            )}
            <li>{t('[payment result] information section list item 3')}</li>
          </ul>
        </div>
        <div className={styles.resources}>
          <h3>{t('[payment result] resources section title')}</h3>
          <ul>
            <li>
              {t('[payment result] resources section list item 1')}{' '}
              <a className={styles.link} rel="noopener noreferrer" target="_blank" href="https://helpdesk@gbgplc.com">
                {t('[payment result] resources section list item 1 link')}
              </a>
            </li>
            <li>
              {t('[payment result] resources section list item 2a')}{' '}
              <a
                className={styles.link}
                rel="noopener noreferrer"
                target="_blank"
                download="GBGRapidFAQsv1.1.pdf"
                href={`${process.env.PUBLIC_URL}/GBGRapidFAQsv1.1.pdf`}
              >
                {t('[payment result] resources section list item 2 link')}
              </a>
              {t('[payment result] resources section list item 2b')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default UpdatePaymentSuccess
