import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { find, isNil, propEq, pipe, mergeLeft, propOr } from 'ramda'

import styles from './UpdatePaymentCheckout.module.css'
import PaymentMethodForm from '../PaymentMethodForm/PaymentMethodForm.component'
import {
  BillingAddress as BillingAddressType,
  PaymentMethod,
  PaymentMethodCard,
} from '../../../common/modules/PaymentMethods'
import { BillingAddress } from './BillingAddress'
import { OrderSummary } from './OrderSummary'
import { useFormValidation } from '../../../common/modules/AJVFormValidation'
import billingAddressFormValidationSchema from './BillingAddress/billing-address-schema.json'
import Dinero from 'dinero.js'
import { Currency } from '../payment.types'
import { PaymentCard } from '../../../common/modules/PaymentCard'
import { Dropdown } from '../../../common/modules/PaymentCard/constants'

export interface Props {
  isSubmitting: boolean
  selectedStripePriceId: string
  clientSecret: string
  totalAmount: number
  currency: Currency
  onSubmitNew: (paymentMethodCard: PaymentMethodCard, billingAddressFormData: BillingAddressType) => void
  onSubmitExisting: (
    stripePriceId: string,
    stripePaymentMethodId: string,
    billingAddressFormData: BillingAddressType,
  ) => void
  onInvalidSubmit: () => void
  onError: () => void
  onCreatePaymentMethod: () => void
  hasPaymentMethods: boolean
  paymentMethods: PaymentMethod[]
  currentPaymentMethodId: string
  onCancel?: () => void
}

const noop = () => null

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`)

// TODO: implement loading state (while fetching payment methods from API)
const UpdatePaymentCheckout: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  selectedStripePriceId,
  clientSecret,
  totalAmount,
  currency,
  onSubmitNew,
  onSubmitExisting,
  onInvalidSubmit,
  onError,
  onCreatePaymentMethod,
  hasPaymentMethods,
  paymentMethods,
  currentPaymentMethodId,
  onCancel = noop,
}) => {
  const validationErrorMap = {
    name: {
      minLength: t('[checkout] name is required'),
    },
    addressLine1: {
      minLength: t('[checkout] address line 1 is required'),
    },
    city: {
      minLength: t('[checkout] city is required'),
    },
    postalCode: {
      minLength: t('[checkout] postal code is required'),
    },
    country: {
      minLength: t('[checkout] country is required'),
    },
  }
  const [billingAddressValidationErrors, billingAddressValidate] = useFormValidation(
    billingAddressFormValidationSchema as any,
    validationErrorMap,
  )
  const [billingAddressFormData, setBillingAddressFormData] = useState<any>({})
  const [isDisabled, setIsDisabled] = useState(true)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(currentPaymentMethodId)
  useEffect(() => {
    setSelectedPaymentMethodId(currentPaymentMethodId)
  }, [currentPaymentMethodId])

  useEffect(() => {
    const selectedBillingAddress = pipe(find(propEq('stripePaymentMethodId', selectedPaymentMethodId)), (x: any) =>
      mergeLeft({
        addressLine1: propOr('', 'billingAddressLine1')(x),
        addressLine2: propOr('', 'billingAddressLine2')(x),
        city: propOr('', 'billingAddressCity')(x),
        postalCode: propOr('', 'billingAddressPostalCode')(x),
        state: propOr('', 'billingAddressState')(x),
        country: propOr('', 'billingAddressCountry')(x),
      })({}),
    )(paymentMethods)
    setBillingAddressFormData(selectedBillingAddress)
  }, [selectedPaymentMethodId])

  useEffect(() => {
    setIsDisabled(!billingAddressValidate(billingAddressFormData))
  }, [billingAddressFormData])

  const onSubmitInternal = (paymentMethodCard?: PaymentMethodCard) => {
    if (!billingAddressValidate(billingAddressFormData)) {
      onInvalidSubmit()
      return
    }
    if (hasPaymentMethods) {
      onSubmitExisting(selectedStripePriceId, selectedPaymentMethodId, billingAddressFormData as BillingAddressType)
      return
    }
    onSubmitNew(paymentMethodCard as PaymentMethodCard, billingAddressFormData as BillingAddressType)
  }
  const onChangeBillingAddress = (formData: BillingAddressType) => {
    setBillingAddressFormData(formData)
  }
  const renderFooter = (onCancel: any, onSubmit: any, isSubmitting: boolean) => (
    <>
      <BillingAddress
        onChange={onChangeBillingAddress}
        validationErrors={billingAddressValidationErrors}
        billingAddress={billingAddressFormData}
      />
      <div className={styles.buttonWrapper}>
        <GBG.Button className={`m-m-r-2 ${styles.cancelButton}`} onClick={onCancel} kind={GBG.ButtonKind.Secondary}>
          {t('[checkout] cancel order button')}
        </GBG.Button>
        <GBG.Button
          className={`m-m-r-2 ${styles.submitButton}`}
          onClick={() => {
            onSubmit(billingAddressFormData)
          }}
          worker={true}
          workerPosition={GBG.ButtonWorkerPosition.Right}
          active={isSubmitting}
          aria-label="pay-button"
          iconAfter={GBG.IconKeys.PadlockLocked24}
          disabled={isDisabled}
        >
          {t('[checkout] pay button')} {isNil(currency) ? null : Dinero({ amount: totalAmount, currency }).toFormat()}
        </GBG.Button>
      </div>
    </>
  )
  const noPaymentMethodsView = (
    <Elements stripe={stripePromise}>
      <PaymentMethodForm
        isInitialPayment={true}
        clientSecret={clientSecret}
        isSubmitting={isSubmitting}
        onSubmit={onSubmitInternal}
        onError={onError}
        onCancel={onCancel}
        primaryCardExplainerText={t('[payment method form checkout] checkbox explainer')}
        renderFooter={renderFooter}
      />
    </Elements>
  )
  const findCardDetailsByPaymentMethodId = (_selectedPaymentMethodId: string) => {
    return find(propEq('stripePaymentMethodId', _selectedPaymentMethodId))(paymentMethods)
  }
  const selectedCardDetails = findCardDetailsByPaymentMethodId(selectedPaymentMethodId)
  const defaultCardDetails = paymentMethods[0]
  const paymentMethodsView = (
    <>
      {selectedCardDetails ? (
        <PaymentCard
          cardBrand={selectedCardDetails.cardBrand}
          cardLast4={selectedCardDetails.cardLast4}
          expiryDate={selectedCardDetails.expiryDate}
          billingName={selectedCardDetails.billingName}
          dropdown={Dropdown.select}
          paymentMethods={paymentMethods}
          onSelect={setSelectedPaymentMethodId}
        />
      ) : (
        hasPaymentMethods && (
          <PaymentCard
            cardBrand={defaultCardDetails.cardBrand}
            cardLast4={defaultCardDetails.cardLast4}
            expiryDate={defaultCardDetails.expiryDate}
            billingName={defaultCardDetails.billingName}
            dropdown={Dropdown.select}
            paymentMethods={paymentMethods}
            onSelect={setSelectedPaymentMethodId}
          />
        )
      )}
      <GBG.Button
        onClick={(): void => onCreatePaymentMethod()}
        className={styles.addCardButton}
        kind={GBG.ButtonKind.Tertiary}
      >
        {t('[payment] add a card button label')}
      </GBG.Button>
      {renderFooter(onCancel, onSubmitInternal, isSubmitting)}
    </>
  )
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>{t('[checkout] secure checkout title')}</h1>
      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <h3>{t('[checkout] pay with card title')}</h3>
          <div className={styles.formWrapper}>{hasPaymentMethods ? paymentMethodsView : noPaymentMethodsView}</div>
        </div>
        <div className={styles.right}>
          <OrderSummary />
        </div>
      </div>
    </div>
  )
}

export default UpdatePaymentCheckout
