import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export { navigateToFlow, onRouteFlow, redirectToLoginFlow, redirectAfterLoginFlow } from './routing.middleware'
export { navigateTo, NAVIGATE_TO, onRoute, ON_ROUTE } from './routing.reducer'
export { currentRouteSelector } from './currentRoute.selector'
export { Routes } from './constants'

export function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
    document?.querySelector('.card--primary-content')?.scrollTo(0, 0)
  }, [pathname])

  return null
}
