//-------------------
// users init
//-------------------

import { Middleware } from 'redux'
import { includes } from 'ramda'
import {
  httpGetUserInvites,
  HTTP_CREATE_USER_INVITE_SUCCESS,
  HTTP_GET_USER_INVITE_SUCCESS,
  HTTP_DELETE_USER_INVITE_SUCCESS,
  setUserInvites,
  pushUserInvite,
} from './Users.reducer'

export const userInvitesInitFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    dispatch(httpGetUserInvites())
    return
  }
}

//---------------------------------
// set userInvites
//---------------------------------

export const setUserInvitesFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === HTTP_GET_USER_INVITE_SUCCESS) {
    dispatch(setUserInvites(payload))
  }
}

//---------------------------------
// create userInvite
//---------------------------------

export const createUserInviteFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_CREATE_USER_INVITE_SUCCESS) {
    dispatch(pushUserInvite(payload))
    //dispatch(httpGetUserInvites())
  }
}

//---------------------------------
// delete userInvite
//---------------------------------

export const deleteUserInviteFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_DELETE_USER_INVITE_SUCCESS) {
    dispatch(httpGetUserInvites())
  }
}
