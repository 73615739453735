import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { reduce, mergeDeepRight } from 'ramda'

import { translations as DeactivateJourneyIntegration } from '../modules/DeactivateJourneyIntegrationModal'
import { translations as JourneyIntegrationLink } from '../../common/modules/JourneyIntegrationLink'
import { translations as App } from '../../modules/App'
import { translations as Dashboard } from '../../modules/Dashboard'
import { translations as JourneyIntegration } from '../../modules/JourneyIntegration'
import { translations as JourneyIntegrations } from '../modules/JourneyIntegrations'
import { translations as Auth } from '../../modules/Auth'
import { translations as JourneyCreditAccount } from '../modules/JourneyCreditAccount'
import { translations as OnboardForm } from '../../modules/OnboardForm'
import { translations as Payment } from '../../modules/Payment'
import { translations as Subscriptions } from '../modules/Subscriptions'
import { translations as PaymentMethods } from '../modules/PaymentMethods'
import { translations as Users } from '../modules/Users'
import { translations as Settings } from '../../modules/Settings'
import { translations as Organisations } from '../../modules/Organisation'
import { translations as DataProtectionFrom } from '../../common/modules/DataProtectionForm'
import { translations as DataProtectionOnboarding } from '../../modules/Onboarding/DataProtectionOnboarding'

const resources = reduce(
  mergeDeepRight,
  {},
)([
  DeactivateJourneyIntegration,
  JourneyIntegrationLink,
  App,
  Dashboard,
  JourneyIntegration,
  JourneyIntegrations,
  Auth,
  OnboardForm,
  JourneyCreditAccount,
  Payment,
  Subscriptions,
  PaymentMethods,
  Users,
  Settings,
  Organisations,
  DataProtectionFrom,
  DataProtectionOnboarding,
])

i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LANGUAGE || 'en',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
