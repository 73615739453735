import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { withTranslation } from 'react-i18next'
import { compose } from 'ramda'

import LogoutHeader from './LogoutHeader.component'
import { authLogout } from '../auth.reducer'

const mapStateToProps = null

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigate: () => {
    dispatch(authLogout())
  },
})

export default compose(withTranslation('Auth'), connect(mapStateToProps, mapDispatchToProps))(LogoutHeader)
