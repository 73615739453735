import { equals, path, prop, compose, map, toPairs } from 'ramda'
import React, { FunctionComponent } from 'react'
import { WithTranslation } from 'react-i18next'

import { COMPLETION_ACTIONS } from '../createJourneyIntegration.constants'
import { CompletionActionType } from '../journeyIntegration.types'
import GBG from '@gbg/gbgcomponentlibrary_react'
import styles from './CompletionActionTypesForm.module.css'

interface Props {
  onChange: (type: CompletionActionType) => void
  type: CompletionActionType
}

const CompletionActionTypesForm: FunctionComponent<Props & WithTranslation> = ({ t, onChange, type }) => {
  const buildSelectionOption = (type: CompletionActionType | undefined) => ([k, v]: [
    string,
    { [key: string]: string },
  ]) => (
    <GBG.FormGroup key={k} type={GBG.FormGroupType.Radio}>
      <GBG.Radio
        id={k}
        name="completion-action-selector"
        aria-label={k}
        onChange={(event: any) => onChange(path(['target', 'value'])(event))}
        checked={equals(type, k) ? true : false}
        value={k}
        error={false}
      />
      <GBG.Label htmlFor={k} inline={true}>
        {t(prop('label', v))}
      </GBG.Label>
    </GBG.FormGroup>
  )

  const typeView = compose(map(buildSelectionOption(type)), toPairs)(COMPLETION_ACTIONS)

  return <ul className={styles.selector}>{typeView}</ul>
}

export default CompletionActionTypesForm
