import { assoc, compose, prop } from 'ramda'
import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import GBG from '@gbg/gbgcomponentlibrary_react'
import ImageUpload from '../../../../common/modules/ImageUpload'
import { Personalisation } from '../../journeyIntegration.types'

import styles from './PersonalisationForm.module.css'
import { AcceptedFileType } from '../../../../common/modules/ImageUpload/ImageUpload.types'

interface Props {
  isSubmitting: boolean
  onProceed: () => void
  onProceedWithPersonalisation: (personalisation: Personalisation) => void
  onBack: () => void
  personalisation: { logo: string }
}

const PersonalisationForm: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  onProceed,
  onProceedWithPersonalisation,
  onBack,
  personalisation,
}) => {
  const [formData, setFormData] = useState({
    logo: prop('logo', personalisation),
  })
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])

  const updateFormData = (key: string) => compose(setFormData, assoc(key))

  const onConfirmPersonalisation = () => {
    setInternalIsSubmitting(true)
    if (formData) {
      setInternalIsSubmitting(false)
      onProceedWithPersonalisation(formData)
    }
    // TODO: Add Failure case https://nonaredteam.atlassian.net/browse/GBG-212
  }

  return (
    <div>
      <h1>{t('[personalisation] title')}</h1>
      <p className="introduction">{t('[personalisation] introduction')}</p>
      <ImageUpload
        defaultImage=""
        onSetImage={updateFormData('logo')}
        label={t('[personalisation] logo input label')}
        explainer={t('[personalisation] logo input explainer')}
        acceptedFileTypes={[AcceptedFileType.JPG, AcceptedFileType.JPEG, AcceptedFileType.PNG]}
      />
      {/*
        TODO: Pull out into common component https://nonaredteam.atlassian.net/browse/GBG-212
      */}
      <div className={`m-m-t-4 ${styles.navigation}`}>
        <GBG.Button
          onClick={() => {
            onBack()
          }}
          iconBefore={GBG.IconKeys.ChevronLeft24}
          kind={GBG.ButtonKind.Tertiary}
          aria-label="back-button"
        >
          {t('[journey integration] back')}
        </GBG.Button>
        <div>
          {internalIsSubmitting ? null : (
            <GBG.Button
              className="m-m-r-2"
              onClick={() => {
                onProceed()
              }}
              kind={GBG.ButtonKind.Secondary}
              aria-label="skip-button"
            >
              {t('[journey integration] skip')}
            </GBG.Button>
          )}
          <GBG.Button
            worker={true}
            active={internalIsSubmitting}
            onClick={() => {
              onConfirmPersonalisation()
            }}
            aria-label="submit-button"
          >
            {t('[journey integration] continue')}
          </GBG.Button>
        </div>
      </div>
    </div>
  )
}

export default PersonalisationForm
