import React, { FC } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import history from '../../../common/redux/history'
import { Routes } from '../../../common/modules/Routing'
import { CompletionActionForm } from './CompletionActionForm'
import { DetailsForm } from './DetailsForm'
import { PersonalisationForm } from './PersonalisationForm'
import { History } from './History'
import { Header } from './Header'
import styles from './UpdateJourneyIntegration.module.css'

const UpdateJourneyIntegrationRoutes: FC = () => (
  <Router history={history}>
    {/* TODO: figure out how to redirect redirects/1/update to redirects/1/update/details */}
    {/* TODO: or how to do nested routing here where you cannot navigate from redirects/1/update to redirects/1/details but instead to redirects/1/update/details */}
    <Switch>
      <Route path={Routes.UPDATE_DETAILS}>
        <DetailsForm />
      </Route>
      <Route path={Routes.UPDATE_COMPLETION_ACTION}>
        <CompletionActionForm />
      </Route>
      <Route path={Routes.UPDATE_PERSONALISATION}>
        <PersonalisationForm />
      </Route>
      <Route path={Routes.UPDATE_HISTORY}>
        <History />
      </Route>
      <Route path={Routes.UPDATE_HISTORY_DOWNLOAD}>
        <History />
      </Route>
    </Switch>
  </Router>
)

const UpdateJourneyIntegration: FC = () => (
  <div className={styles.container}>
    <div className={styles.header}>
      <Header />
    </div>
    <div className={styles.content}>
      <UpdateJourneyIntegrationRoutes />
    </div>
  </div>
)

export default UpdateJourneyIntegration
