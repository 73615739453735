import { createSelector } from 'reselect'
import { AppState } from '../../redux/reducers'
import { UserInvite } from './UserInvites.types'

export const userInvitesSelector = createSelector(
  (state: AppState) => {
    return state.users.userInvites
  },
  (userInvites: UserInvite[]) => {
    return {
      userInvites,
    }
  },
)
