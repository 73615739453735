import { LOCATION_CHANGE, Push } from 'connected-react-router'
import { Middleware } from 'redux'
import { always, compose, isNil, path, prop, when } from 'ramda'

import { NAVIGATE_TO, navigateTo, onRoute } from './routing.reducer'
import { Routes } from './constants'

//---------------------------------
// navigate to
//---------------------------------

export const navigateToFlow = (navigate: Push): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)

  const { type, payload } = action
  if (type === NAVIGATE_TO) {
    const { router } = getState()
    if (payload !== path(['location', 'pathname'])(router)) {
      dispatch(navigate(payload))
    }
  }
}

//---------------------------------
// on route
// ... connected-react-router is dispatching
// ... LOCATION_CHANGE multiple times for
// ... the same route,
// ... this middleware dispatches an on route
// ... action for every unique LOCATION_CHANGE
//---------------------------------

export const onRouteFlow = (): Middleware => {
  let prevLocationPathname = ''
  return ({ dispatch }) => next => action => {
    next(action)
    const { type, payload } = action
    if (type === LOCATION_CHANGE) {
      const pathname = path(['location', 'pathname'])(payload)
      if (pathname !== prevLocationPathname) {
        dispatch(onRoute(pathname))
      }
      prevLocationPathname = pathname
    }
  }
}

//---------------------------------
// redirect to login
//---------------------------------

export const redirectToLoginFlow = (unauthorizedAction: string): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === unauthorizedAction) {
    dispatch(navigateTo(Routes.LOGIN))
  }
}

//---------------------------------
// redirect to login
//---------------------------------

export const redirectAfterLoginFlow = (loginSuccessAction: string): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === loginSuccessAction) {
    const { auth } = getState()

    const afterLoginRoute = compose(when(isNil, always(Routes.OVERVIEW)), prop('afterLoginRoute'))(auth)
    dispatch(navigateTo(afterLoginRoute))
  }
}
