import { includes, isNil, path } from 'ramda'
import { Middleware } from 'redux'

import {
  httpGetOrganisation,
  //HTTP_GET_ORGANISATION_FAILURE,
  setOrganisation,
  httpUpdateOrganisation,
  HTTP_UPDATE_ORGANISATION_SUCCESS,
  HTTP_UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_ORGANISATION,
  CONFIRM_CANCEL_AUTO_RENEW,
  ENABLE_AUTO_RENEW,
  CONFIRM_CANCEL_SUBSCRIPTION,
  httpUpdateSubscription,
} from './organisation.reducer'
import { navigateTo, Routes } from '../../common/modules/Routing'
import { reset } from '../Modal/modal.reducer'
import GBG from '@gbg/gbgcomponentlibrary_react'

//---------------------------------
// get Organisation
//---------------------------------

export const getOrganisationFlow = (actions: string[]): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const { auth } = getState()
  const { accessToken } = auth
  if (includes(type)(actions) && !isNil(accessToken)) {
    dispatch(httpGetOrganisation())
  }
}

//---------------------------------
// set Organisation
// ... signals intention to set a Organisation
// ... returned from the api
//---------------------------------

export const setOrganisationFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)

  const { type, payload } = action
  if (includes(type)(actions)) {
    dispatch(setOrganisation(payload))
  }
}

//---------------------------------
// update Organisation
//---------------------------------

export const updateOrganisationFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_ORGANISATION) {
    const { organisation } = getState()
    const { id } = organisation
    dispatch(httpUpdateOrganisation(payload.data, id))
  }
}

export const updateOrganisationSuccessFlow = (excludedRoutes: string[]): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_ORGANISATION_SUCCESS) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (!includes(pathname, excludedRoutes)) {
      dispatch(navigateTo(Routes.OVERVIEW))
    } else {
      GBG.toast({
        position: GBG.ToastPosition.TopRight,
        type: GBG.ToastType.Success,
        title: 'Organisation updated',
        content: 'Your organisation has been updated',
      })
    }
  }
}

//---------------------------------
// refresh Organisation
// ... retrieves Organisation after successes that impact Organisation
//---------------------------------

export const refreshOrganisationFlow = (actions: string[]): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (includes(type)(actions)) {
    dispatch(httpGetOrganisation())
  }
}

//---------------------------------
// confirm cancel auto renew for Organisation
//---------------------------------

export const confirmCancelAutoRenewFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const state = getState()
  const organisationId = path(['organisation', 'id'])(state)
  const autoRenew = path(['organisation', 'autoRenew'])(state)
  if (isNil(autoRenew)) {
    return
  }
  if (type === CONFIRM_CANCEL_AUTO_RENEW) {
    dispatch(httpUpdateOrganisation({ autoRenew: false }, organisationId))
  }
}

//---------------------------------
// confirm cancel auto renew success
//---------------------------------

export const confirmCancelAutoRenewSuccessFlow = (includedRoute: string): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_ORGANISATION_SUCCESS) {
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (includes(pathname, includedRoute)) {
      dispatch(navigateTo(Routes.PAYMENT))
    }
  }
}

//---------------------------------
// enable auto renew for Organisation
//---------------------------------

export const enableAutoRenewFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const state = getState()
  const organisationId = path(['organisation', 'id'])(state)
  const autoRenew = path(['organisation', 'autoRenew'])(state)
  if (isNil(autoRenew)) {
    return
  }
  if (type === ENABLE_AUTO_RENEW) {
    dispatch(httpUpdateOrganisation({ autoRenew: true }, organisationId))
  }
}

//---------------------------------
// confirm cancel subscription for Organisation
//---------------------------------

export const confirmCancelSubscriptionFlow = (): Middleware => ({ dispatch, getState }) => next => action => {
  next(action)
  const { type } = action
  const state = getState()
  const subscriptionCancelled = path(['organisation', 'subscriptionCancelled'])(state)
  if (isNil(subscriptionCancelled)) {
    return
  }
  if (type === CONFIRM_CANCEL_SUBSCRIPTION) {
    dispatch(httpUpdateSubscription({ cancelSubscription: true }))
  }
}

//---------------------------------
// confirm cancel subscription success
//---------------------------------

export const confirmCancelSubscriptionSuccessFlow = (includedRoute: string): Middleware => ({
  dispatch,
  getState,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_UPDATE_SUBSCRIPTION_SUCCESS) {
    dispatch(httpGetOrganisation())
    const { router } = getState()
    const pathname = path(['location', 'pathname'])(router)
    if (includes(pathname, includedRoute)) {
      dispatch(reset())
    }
  }
}
