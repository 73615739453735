import { Middleware } from 'redux'

import { appInit } from './app.reducer'

//---------------------------------
// app init
//---------------------------------

export const appInitFlow = (): Middleware => {
  // use this state to avoid APP_INIT firing multiple times
  // TODO: describe why we want APP_INIT to fire only after an initial location change
  let hasInitialized = false
  return ({ dispatch }) => next => action => {
    next(action)
    const { type } = action
    // TODO: use constant
    if (type === '@@router/LOCATION_CHANGE') {
      const { payload } = action
      const { isFirstRendering } = payload
      if (isFirstRendering && !hasInitialized) {
        hasInitialized = true
        dispatch(appInit())
      }
    }
  }
}
