import { connect } from 'react-redux'
import { Dispatch } from 'react'
import { AnyAction } from '@reduxjs/toolkit'

import Alert from './Alert.component'
import { AlertSelector } from './Alert.selectors'
import { resetAlert } from './alert.reducer'

const mapStateToProps = AlertSelector
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onDismiss: () => {
    dispatch(resetAlert())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Alert)
