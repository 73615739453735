export interface HistoryItem {
  gbgJourneyUpdatedAt: string
  id: string
  customerId: string
  name: string
  gbgJourneyHighLevelResult: string
  action: HistoryAction
}

export enum HistoryAction {
  CopyLink = 'CopyLink',
  DownloadReport = 'DownloadReport',
  Expired = 'Expired',
}

export enum HighLevelResult {
  Refer = 'Refer',
  Expired = 'Expired',
  Passed = 'Passed',
  Notsupported = 'Notsupported',
  Undefined = 'Undefined',
}
