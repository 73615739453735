import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { compose, path, propOr } from 'ramda'

import TotalUsage from './TotalUsage.component'
import { JourneyIntegrationLinkResult } from '../../../../common/modules/JourneyIntegrationLinkResults'
import { selectedJourneyIntegrationIdSelector } from '../../journeyIntegration.selectors'

// TODO: extract and test
const mapStateToProps = createSelector(
  [path(['journeyIntegrationLinkResults', 'byJourneyIntegrationId']), selectedJourneyIntegrationIdSelector],
  (results: JourneyIntegrationLinkResult, { selectedJourneyIntegration }: any) => ({
    results: compose(propOr({ passed: 0, failed: 0 }, 'week'), propOr({}, selectedJourneyIntegration))(results),
  }),
) as any

export default compose(withTranslation('JourneyIntegration'), connect(mapStateToProps))(TotalUsage)
