import { Middleware } from 'redux'
import { compose, includes, replace } from 'ramda'

import { ON_ROUTE, Routes } from '../Routing'
import { isAuthenticatedSelector } from '../../../modules/Auth/auth.selectors'
import {
  httpGetSubscriptions,
  httpUpdateSubscription,
  HTTP_CREATE_SUBSCRIPTION_SUCCESS,
  UPDATE_DEFAULT_SUBSCRIPTION,
} from './subscriptions.reducer'
import { assocFormData, FormSubmissionStatus, setFormSubmissionStatus } from '../../form'
import { prop } from 'ramda'

//---------------------------------
// get subscriptions
// ... initiates the API get subscriptions flow
// ... on provided actions
// ... or on provided routes (if authenticated)
//---------------------------------

export const getSubscriptionsFlow = (actions: string[], routes: Routes[]): Middleware => {
  let hasInitialized = false
  return ({ dispatch, getState }) => next => action => {
    next(action)
    if (hasInitialized) {
      return
    }
    const { type, payload } = action
    if (includes(type)(actions)) {
      hasInitialized = true
      dispatch(httpGetSubscriptions(process.env.REACT_APP_CURRENCY_CODE as string))
      return
    }
    if (type === ON_ROUTE && includes(replace(/(\d+)/)(':id')(payload), routes)) {
      const { isAuthenticated } = compose(isAuthenticatedSelector, getState)()
      if (isAuthenticated) {
        hasInitialized = true
        dispatch(httpGetSubscriptions(process.env.REACT_APP_CURRENCY_CODE as string))
      }
    }
  }
}

//---------------------------------
// update default subscription
//---------------------------------

export const updateDefaultSubscriptionFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === UPDATE_DEFAULT_SUBSCRIPTION) {
    dispatch(httpUpdateSubscription(payload))
  }
}

//---------------------------------
// create subscription success
//---------------------------------

export const createSubscriptionSuccessFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type, payload } = action
  if (type === HTTP_CREATE_SUBSCRIPTION_SUCCESS) {
    dispatch(assocFormData('update-payment', 'stripePriceId', prop('stripePriceId')(payload)))
    dispatch(assocFormData('update-payment', 'stripeSubscriptionId', prop('stripeSubscriptionId')(payload)))
    dispatch(assocFormData('update-payment', 'stripeClientSecret', prop('stripeClientSecret')(payload)))
    dispatch(assocFormData('update-payment', 'stripePaymentIntentId', prop('stripePaymentIntentId')(payload)))
    dispatch(setFormSubmissionStatus('update-payment', FormSubmissionStatus.UNSUBMITTED))
  }
}
