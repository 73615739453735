import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import Payment from './Payment.component'
import { chooseSubscription, createPaymentMethod } from './payment.reducer'
import { navigateTo, Routes } from '../../common/modules/Routing'
import { paymentPropsSelector } from './payment.selectors'

const mapStateToProps = paymentPropsSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onChooseSubscription: () => {
    dispatch(chooseSubscription())
    dispatch(navigateTo(Routes.UPDATE_PAYMENT))
  },
  onEnableAutoRenew: () => {
    dispatch(navigateTo(Routes.AUTO_RENEW))
  },
  onCreatePaymentMethod: () => {
    dispatch(createPaymentMethod())
  },
})

export default compose(withTranslation('Payment'), connect(mapStateToProps, mapDispatchToProps))(Payment)
