import React, { FC } from 'react'
import { pathOr, propOr } from 'ramda'
import { Steps } from './Steps'
import { JourneyTypeForm } from './JourneyTypeForm'
import { NameForm } from './NameForm'
import { CompletionActionForm } from './CompletionActionForm'
import { PersonalisationForm } from './PersonalisationForm'
import { Review } from './Review'
import { createJourneyIntegrationSteps } from '../createJourneyIntegration.constants'
import GBG from '@gbg/gbgcomponentlibrary_react'
import DataProtectionForm from '../../../common/modules/DataProtectionForm'
import { WithTranslation } from 'react-i18next'

export interface Props {
  createJourneyIntegrationCompletedSteps: number
  includeDataProtection: boolean
  onSkipStep?: () => void
}

const CreateJourneyIntegration: FC<Props & WithTranslation> = ({
  createJourneyIntegrationCompletedSteps,
  includeDataProtection,
  onSkipStep,
  t,
}) => {
  const viewMap = {
    CreateJourneyIntegrationJourneyType: <JourneyTypeForm />,
    CreateJourneyIntegrationName: <NameForm />,
    CreateJourneyIntegrationCompletionAction: <CompletionActionForm />,
    CreateJourneyIntegrationPersonalisation: <PersonalisationForm />,
    CreateJourneyIntegrationDataProtection: (
      <>
        <h1>{t('[data protection] title')}</h1>
        <p className="introduction">{t('[data protection] intro')}</p>
        <DataProtectionForm skipable={true} onSkip={onSkipStep} />
      </>
    ),
  }
  const viewName = pathOr(null, [createJourneyIntegrationCompletedSteps, 'component'])(createJourneyIntegrationSteps)
  const view =
    createJourneyIntegrationCompletedSteps >= (includeDataProtection ? 5 : 4) ? (
      <Review />
    ) : (
      propOr(null, viewName)(viewMap)
    )
  return (
    <div className="structure structure--stepper">
      <GBG.TopBar
        brand={GBG.TopBarBrand.GBG}
        user={{ username: 'Username', company: 'Company' }}
        includeNavLink={false}
        includeUser={false}
        menu={{
          items: [
            {
              title: 'A menu item',
              onSelected: () => {
                alert('Hello world from the item')
              },
            },
          ],
        }}
      ></GBG.TopBar>
      <div className="page__navigation">
        <Steps />
      </div>
      <div className="page__content">
        <GBG.Card isPrimaryContent={true}>
          <GBG.CardBody style={{ maxWidth: '42rem' }} isPrimaryContent={true}>
            {view}
          </GBG.CardBody>
        </GBG.Card>
      </div>
    </div>
  )
}

export default CreateJourneyIntegration
