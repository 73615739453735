import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { compose, assoc, path, __, tap, ifElse, prop, has } from 'ramda'

import { useFormValidation } from '../../../common/modules/AJVFormValidation'
import formValidationSchema from '../schemas/signup-schema.json'
import { Credentials } from '../auth.types'
import styles from '../AuthForm.module.css'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { useLocation } from 'react-router'
import qs from 'qs'

interface Props {
  isSubmitting: boolean
  onSubmit: (formData: Credentials) => void
  onInvalidSubmit: () => void
}

const SignupForm: FC<Props & WithTranslation> = ({ t, isSubmitting, onSubmit, onInvalidSubmit }) => {
  const location = useLocation()
  const { i, o, e, c } = qs.parse(location.search, { ignoreQueryPrefix: true })
  const isInvite =
    typeof i != 'undefined' && typeof o != 'undefined' && typeof e != 'undefined' && typeof c != 'undefined'
  const validationErrorMap = {
    email: {
      minLength: 'email is required', // TODO: translate
    },
    password: {
      pattern: 'please provide a valid password as per requirements below', // TODO: translate
    },
    name: {
      minLength: 'name is required', // TODO: translate
    },
    companyName: {
      minLength: 'company name is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  const [formData, setFormData] = useState({
    email: e || '',
    password: '',
    name: '',
    companyName: c || '',
    inviteId: i || -1,
    orgId: o || -1,
  })

  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onChangeInternal = (key: string) => (event: any) => {
    compose(tap(setFormData), assoc(key, __, formData), path(['target', 'value']))(event)
  }
  const onBlurInternal = () => {
    validate(formData)
  }
  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    ifElse(
      validate,
      tap(onSubmit),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(formData)
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerWrapper}>
        <h1>{t('[auth] signup title')}</h1>
        <p>{t('[auth] signup explainer')}</p>
      </div>
      <div className={styles.form}>
        <input type="hidden" id="inviteId" name="inviteId" value={prop('inviteId')(formData)} />
        <input type="hidden" id="orgId" name="orgId" value={prop('orgId')(formData)} />
        <GBG.FormGroup>
          <GBG.Label htmlFor="email-input">{t('[auth] signup email label')}</GBG.Label>
          <GBG.Text
            readOnly={isInvite}
            disabled={isInvite}
            id="email-input"
            type="email"
            error={has('email', validationErrors)}
            onChange={onChangeInternal('email')}
            onBlur={onBlurInternal}
            value={prop('email')(formData)}
            placeholder={t('[auth] signup email input placeholder')}
            aria-label="email-input"
          />
          {!has('email', validationErrors) ? null : (
            <GBG.ErrorText>{path(['email', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
        </GBG.FormGroup>

        <GBG.FormGroup>
          <GBG.Label htmlFor="name-input">{t('[auth] signup name label')}</GBG.Label>
          <GBG.Text
            error={has('name', validationErrors)}
            onChange={onChangeInternal('name')}
            onBlur={onBlurInternal}
            value={prop('name')(formData)}
            placeholder={t('[auth] signup name input placeholder')}
            aria-label="name-input"
            id="name-input"
          />
          {!has('name', validationErrors) ? null : (
            <GBG.ErrorText>{path(['name', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
        </GBG.FormGroup>

        <GBG.FormGroup>
          <GBG.Label htmlFor="company-name-input">{t('[auth] signup company name label')}</GBG.Label>
          <GBG.Text
            readOnly={isInvite}
            disabled={isInvite}
            error={has('name', validationErrors)}
            onChange={onChangeInternal('companyName')}
            onBlur={onBlurInternal}
            value={prop('companyName')(formData)}
            placeholder={t('[auth] signup company name input placeholder')}
            aria-label="company-name-input"
            id="company-name-input"
          />
          {!has('companyName', validationErrors) ? null : (
            <GBG.ErrorText>{path(['companyName', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
        </GBG.FormGroup>

        <GBG.FormGroup>
          <GBG.Label htmlFor="password-input">{t('[auth] signup password label')}</GBG.Label>
          <GBG.Password
            onChange={onChangeInternal('password')}
            error={has('password', validationErrors)}
            onBlur={onBlurInternal}
            value={prop('password')(formData)}
            placeholder={t('[auth] signup password input placeholder')}
            aria-label="password-input"
          />
          {!has('password', validationErrors) ? null : (
            <GBG.ErrorText>{path(['password', 'message'])(validationErrors)}</GBG.ErrorText>
          )}
          <GBG.Policy
            className="m-s-t-1"
            value={prop('password')(formData)}
            items={[
              {
                title: 'One number',
                pattern: '[1-9]',
              },
              {
                title: '12 characters minimum',
                pattern: '^.{12,}$',
              },
              {
                title: 'One symbol',
                pattern: '[@$!%*?&]',
              },
              {
                title: 'One uppercase character',
                pattern: '[A-Z]',
              },
            ]}
          ></GBG.Policy>
        </GBG.FormGroup>
        <GBG.Button
          className="m-m-t-4 m-m-b-4"
          onClick={() => {
            onSubmitInternal()
          }}
          worker={true}
          active={internalIsSubmitting}
        >
          {t('[auth] signup submit label')}
        </GBG.Button>

        <p className={styles.terms}>
          {t('[auth] signup terms agreement')}{' '}
          <strong>
            <a
              className={styles.link}
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.gbgplc.com/privacy-policy/"
            >
              {t('[auth] signup privacy policy link')}
            </a>
          </strong>{' '}
          &{' '}
          <strong>
            <a
              className={styles.link}
              rel="noopener noreferrer"
              target="_blank"
              download="GBGRapidTermsOfUse"
              href={`${process.env.PUBLIC_URL}/GBGRapidTermsAndConditions.pdf`}
            >
              {t('[auth] signup terms of use link')}
            </a>
          </strong>
        </p>
      </div>
    </div>
  )
}

export default SignupForm
