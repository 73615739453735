import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { compose, ifElse, isEmpty, isNil, mergeRight, tap, prop, pick, assoc } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { useFormValidation } from '../../../../common/modules/AJVFormValidation'
import formValidationSchema from '../../schemas/journey-integration-completion-action-schema.json'
import { CompletionActionType } from '../../journeyIntegration.types'
import {
  CompletionActionForm,
  CompletionActionFormData,
  CompletionActionFormFieldsTypeMap,
} from '../../CompletionActionForm'
import styles from '../../CreateJourneyIntegration/CompletionActionForm/CompletionActionForm.module.css'
interface Props {
  isSubmitting: boolean
  onSubmit: (data: CompletionActionFormData) => void
  onInvalidSubmit: () => void
  onValidationChange: (isValid: boolean) => void
  type: CompletionActionType
  redirectUrl: string
  ctaText: string
  successTitle: string
  successText: string
  failureTitle: string
  failureText: string
  includeIcon: boolean
  isToggleableOutcomeIcon: boolean
}

const UpdateJourneyIntegrationCompletionActionForm: FC<Props & WithTranslation> = ({
  t,
  isSubmitting,
  onSubmit,
  onInvalidSubmit,
  onValidationChange,
  type,
  redirectUrl,
  ctaText,
  successTitle,
  successText,
  failureTitle,
  failureText,
  includeIcon,
  isToggleableOutcomeIcon,
}) => {
  // loading view
  if (isNil(type)) {
    return <div>...</div>
  }

  const initialFormData = {
    type,
    ctaText,
    redirectUrl,
    successTitle,
    successText,
    failureTitle,
    failureText,
    includeIcon,
  }
  const validationErrorMap = {
    redirectUrl: {
      minLength: 'Redirect URL is required', // TODO: translate
    },
    ctaText: {
      minLength: 'CTA text is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [formData, setFormData] = useState(initialFormData)
  const onChangeInternal = compose(tap(setFormData), mergeRight(formData))
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  useEffect(() => {
    if (!isNil(validationErrors)) {
      onValidationChange(isEmpty(validationErrors))
    }
  }, [validationErrors])
  useEffect(() => {
    setInternalIsSubmitting(isSubmitting as boolean)
  }, [isSubmitting])
  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    const { type } = formData
    const formFieldsForType = prop(type)(CompletionActionFormFieldsTypeMap)
    ifElse(
      validate,
      compose(tap(onSubmit), assoc('type', type)),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(pick(formFieldsForType)(formData))
  }

  return (
    <>
      <div className={styles.container}>
        <CompletionActionForm
          formData={formData}
          onChange={onChangeInternal}
          validate={validate}
          validationErrors={validationErrors}
          isToggleableOutcomeIcon={isToggleableOutcomeIcon}
        />
      </div>
      {/* TODO: Pull out into common component*/}
      <div>
        {isNil(formData) ? null : (
          <GBG.Button
            className="m-m-t-4"
            onClick={() => {
              onSubmitInternal()
            }}
            aria-label="submit-button"
            worker={true}
            active={internalIsSubmitting}
          >
            {t('[journey integration update] update redirect button label')}
          </GBG.Button>
        )}
      </div>
    </>
  )
}

export default UpdateJourneyIntegrationCompletionActionForm
