import { Middleware } from 'redux'
import { test, split, compose, last, pathEq } from 'ramda'

import { ON_ROUTE } from '../Routing'
import {
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_FAILURE,
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_SUCCESS,
  httpCreateJourneyIntegrationLinkReport,
} from './journeyIntegrationLink.reducer'
import { setAlert } from '../Alert/alert.reducer'
import { AlertType } from '../Alert/Alert.constants'

//---------------------------------
// download journey integration link report
//---------------------------------

export const downloadJourneyIntegrationLinkReportFlow = (): Middleware => ({ dispatch }) => next => action => {
  next(action)
  const { type } = action
  if (type === ON_ROUTE) {
    const { payload } = action
    if (test(/^\/redirects\/(\d+)\/update\/history\/download\/([a-zA-Z0-9-]+)$/g)(payload)) {
      const linkId = compose(last, split('/'))(payload)
      dispatch(httpCreateJourneyIntegrationLinkReport(linkId))
    }
  }
}

//---------------------------------
// download journey integration link report failure
//---------------------------------

export const httpDownloadJourneyIntegrationLinkReportFailureFlow = (): Middleware => ({
  dispatch,
}) => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_FAILURE) {
    if (!pathEq(['payload', 'status'], 401)(action)) {
      // TODO translate, move to Alert module
      dispatch(
        setAlert({
          type: AlertType.ERROR,
          title: 'Error',
          message: 'We could not download the requested report',
        }),
      )
    }
  }
}

//---------------------------------
// download journey integration link report success
//---------------------------------

export const httpDownloadJourneyIntegrationLinkReportSuccessFlow = (): Middleware => () => next => action => {
  next(action)
  const { type } = action
  if (type === HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_SUCCESS) {
    const { payload } = action
    const { url } = payload
    // TODO: is there a more react/redux way to do this?
    window.location.href = url
  }
}
