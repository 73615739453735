import React, { FC } from 'react'

import styles from './Modal.module.css'
import { ICON_MODAL_TYPE } from './constants'
import Icon from '../SVGIcon/SVGIcon.component'
import { IconType } from '../SVGIcon/SVGIcon.types'
import { path } from 'ramda'

interface Props {
  modalType: string
  title: string
  description: string
}

const ModalHeader: FC<Props> = ({ modalType, title, description }) => {
  const contentMap = {
    [ICON_MODAL_TYPE.ModalLink]: {
      icon: <Icon name={IconType.ModalLink} />,
      titleStyle: styles.titleLink,
      descriptionStyle: styles.descriptionLink,
    },
    [ICON_MODAL_TYPE.ModalWarning]: {
      icon: <Icon name={IconType.ModalWarning} />,
      titleStyle: styles.title,
      descriptionStyle: styles.description,
    },
    [ICON_MODAL_TYPE.ModalCard]: {
      icon: null,
      titleStyle: styles.titleCard,
      descriptionStyle: null,
    },
  }
  return (
    <div className={styles.header}>
      <div className={styles.icon}>{path([modalType, 'icon'])(contentMap)}</div>
      <div>
        <div className={path([modalType, 'titleStyle'])(contentMap)}>{title}</div>
        <div className={path([modalType, 'descriptionStyle'])(contentMap)}>{description}</div>
      </div>
    </div>
  )
}

export default ModalHeader
