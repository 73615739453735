import React, { FC } from 'react'
import { map } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
// import styles from './Navigation.module.css'
import { Routes } from '../../common/modules/Routing'

interface IProps {
  menuOpen: boolean
  onMenuClose: () => void
  currentRoutePathname: string
  isAuthenticated: boolean
  onNavigateTo: (path: string) => void
}

const Navigation: FC<IProps> = ({ currentRoutePathname, isAuthenticated, onMenuClose, menuOpen, onNavigateTo }) => {
  const nav = [
    { path: Routes.OVERVIEW, label: 'Overview' },
    { path: Routes.CREATE_JOURNEY_INTEGRATION, label: 'Create New Journey Template' },
    { path: Routes.PAYMENT, label: 'Payments' },
  ]
  // TODO: Make sure all strings are translated
  return isAuthenticated ? (
    <GBG.Nav
      aria-label="navigation"
      open={menuOpen}
      onClose={() => {
        onMenuClose()
      }}
    >
      <GBG.NavSection>
        {map(({ path, label }: { path: string; label: string }) => (
          <GBG.NavItem key={path} active={path === currentRoutePathname} onClick={() => onNavigateTo(path)}>
            {label}
          </GBG.NavItem>
        ))(nav)}
      </GBG.NavSection>
    </GBG.Nav>
  ) : (
    <div className="page__navigation page__navigation--colored-identity"></div>
  )
}

export default Navigation
