export enum CompletionActionType {
  ResultRedirect = 'ResultRedirect',
  CompletionMessage = 'CompletionMessage',
  CompletionMessageCTA = 'CompletionMessageCTA',
}

export interface CompletionAction {
  // TODO: enforce CompletionActionType.ResultRedirect
  type: CompletionActionType
  redirectUrl: string
}

export interface CompletionAction {
  // TODO: enforce CompletionActionType.CompletionMessage
  type: CompletionActionType
  successTitle: string
  successText: string
  failureTitle: string
  failureText: string
  includeIcon: boolean
}

export interface CompletionAction {
  // TODO: enforce CompletionActionType.CompletionMessageCTA
  type: CompletionActionType
  redirectUrl: string
  ctaText: string
  successTitle: string
  successText: string
  failureTitle: string
  failureText: string
  includeIcon: boolean
}

export interface CompletionActionData {
  type: CompletionActionType
  redirectUrl?: string
  ctaText?: string
  successTitle?: string
  successText?: string
  failureTitle?: string
  failureText?: string
  includeIcon?: boolean
}

export interface Personalisation {
  logo: string | null
}

export interface JourneyIntegrationState {
  journeyTypeId: string | null
  name: string
  completionAction: CompletionAction
  personalisation: Personalisation
  isLoading: boolean
  history?: []
  exports?: []
}

export interface CreateJourneyIntegrationStep {
  label: string
  component: string
  requiredData: { [key: string]: any }
}

export interface CompletionActionOption {
  label: string
}
