import { concat } from 'ramda'
import { createSelector } from 'reselect'
import { journeyTypesSelector, journeyTypeIdSelector } from '../../journeyIntegration.selectors'
import { STATIC_JOURNEY_TYPES } from './JourneyTypeForm.constants'

export const journeyTypeFormSelector = createSelector(
  [journeyTypeIdSelector, journeyTypesSelector],
  ({ journeyTypeId }, { journeyTypes }) => ({
    selectedJourneyTypeId: journeyTypeId,
    journeyTypes: concat(journeyTypes, STATIC_JOURNEY_TYPES),
  }),
)
