import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction, createSelector } from '@reduxjs/toolkit'
import { compose, path } from 'ramda'
import { withTranslation } from 'react-i18next'

import Modal from './Modal.component'
// import { ModalView } from './modal.types'
import { reset } from './modal.reducer'
import { ModalState } from '.'

const mapStateToProps = createSelector([path(['modal'])], ({ view, payload }: ModalState) => ({
  modalView: view,
  payload,
}))

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onReset: () => {
    dispatch(reset())
  },
})

export default compose(withTranslation('Modal'), connect(mapStateToProps, mapDispatchToProps))(Modal)
