import { createReducer, createAction } from '@reduxjs/toolkit'
import { always, assoc } from 'ramda'

import { HTTPPayload } from '../../redux-http'
import { ActionWithPayload } from '../../redux/types'
import {
  CreateJourneyIntegrationLinkStatus,
  JourneyIntegrationLinkResponse,
  JourneyIntegrationLinkState,
} from './journeyIntegrationLink.types'

//---------------------------------
// actions
//---------------------------------

export const CREATE_JOURNEY_INTEGRATION_LINK = '[journey integration link] create'
export const createJourneyIntegrationLink = createAction<number>(CREATE_JOURNEY_INTEGRATION_LINK)

export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK = '[journey integration link] HTTP create'
export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK_SUCCESS = '[journey integration link] HTTP create success'
export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK_FAILURE = '[journey integration link] HTTP create failure'
export const httpCreateJourneyIntegrationLink = (data: { [key: string]: unknown }[]): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_JOURNEY_INTEGRATION_LINK)({ endpoint: 'journey-integration-links', data })
export const httpCreateJourneyIntegrationLinkSuccess = createAction<JourneyIntegrationLinkResponse>(
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_SUCCESS,
)
export const httpCreateJourneyIntegrationLinkFailure = createAction<string>(
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_FAILURE,
)

export const RESET_JOURNEY_INTEGRATION_LINK = '[journey integration link] reset'
export const resetJourneyIntegrationLink = createAction(RESET_JOURNEY_INTEGRATION_LINK)

export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT = '[journey integration link] HTTP create report'
export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_SUCCESS =
  '[journey integration link] HTTP create report success'
export const HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_FAILURE =
  '[journey integration link] HTTP create report failure'
export const httpCreateJourneyIntegrationLinkReport = (id: string): ActionWithPayload<HTTPPayload> =>
  createAction<HTTPPayload>(HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT)({
    endpoint: `journey-integration-links/${id}/report`,
  })
export const httpCreateJourneyIntegrationLinkReportSuccess = createAction<{ url: String }>(
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_SUCCESS,
)
export const httpCreateJourneyIntegrationLinkReportFailure = createAction<{ message: string; status: number }>(
  HTTP_CREATE_JOURNEY_INTEGRATION_LINK_REPORT_FAILURE,
)

//---------------------------------
// initial state
//---------------------------------

export const INITIAL_STATE = {
  journeyIntegrationId: null, // TODO: use selectedJourneyIntegration instead of duplicating data in state
  customerId: null,
  urls: null,
  createStatus: CreateJourneyIntegrationLinkStatus.Ready,
}

//---------------------------------
// reducers
//---------------------------------

const resetState = always(INITIAL_STATE)

const assocJourneyIntegrationId = (state: JourneyIntegrationLinkState, { payload }: ActionWithPayload<number>) =>
  assoc('journeyIntegrationId', payload)(state)

const assocDataAndSetCreateStatusSuccess = (
  state: JourneyIntegrationLinkState,
  { payload }: ActionWithPayload<JourneyIntegrationLinkResponse | JourneyIntegrationLinkResponse[]>,
) => {
  payload = Array.isArray(payload) ? payload : [payload]
  /* eslint-disable-next-line */
  let { url, ...a } = payload[0]
  const trans: JourneyIntegrationLinkState = {
    ...a,
    createStatus: CreateJourneyIntegrationLinkStatus.Success,
    urls: payload.map(pi => pi.url),
  }
  return trans
}

const setCreateStatusLoading = (state: JourneyIntegrationLinkState) =>
  assoc('createStatus', CreateJourneyIntegrationLinkStatus.Loading)(state)

const setCreateStatusFailed = (state: JourneyIntegrationLinkState) =>
  assoc('createStatus', CreateJourneyIntegrationLinkStatus.Failure)(state)

//---------------------------------
// reducer
//---------------------------------

const reducer = createReducer<JourneyIntegrationLinkState>(INITIAL_STATE, {
  [RESET_JOURNEY_INTEGRATION_LINK]: resetState,
  [CREATE_JOURNEY_INTEGRATION_LINK]: assocJourneyIntegrationId,
  [HTTP_CREATE_JOURNEY_INTEGRATION_LINK]: setCreateStatusLoading,
  [HTTP_CREATE_JOURNEY_INTEGRATION_LINK_FAILURE]: setCreateStatusFailed,
  [HTTP_CREATE_JOURNEY_INTEGRATION_LINK_SUCCESS]: assocDataAndSetCreateStatusSuccess,
})

export default reducer
