import React, { FC, useEffect, useState } from 'react'
import { WithTranslation } from 'react-i18next'
import { compose, ifElse, isEmpty, isNil, mergeRight, tap } from 'ramda'
import GBG from '@gbg/gbgcomponentlibrary_react'
import { useFormValidation } from '../../../../common/modules/AJVFormValidation'
import formValidationSchema from '../../schemas/journey-integration-name-schema.json'
import { NameForm, NameFormData } from '../../NameForm'
import styles from './NameForm.module.css'

interface Props {
  name: string
  onBack: () => void
  onSubmit: (data: NameFormData) => void
  onInvalidSubmit: () => void
  onValidationChange: (isValid: boolean) => void
}

const CreateJourneyIntegrationNameForm: FC<Props & WithTranslation> = ({
  t,
  onSubmit,
  onInvalidSubmit,
  onBack,
  onValidationChange,
  name = '',
}) => {
  const validationErrorMap = {
    name: {
      minLength: 'name is required', // TODO: translate
    },
  }
  const [validationErrors, validate] = useFormValidation(formValidationSchema as any, validationErrorMap)
  const [internalIsSubmitting, setInternalIsSubmitting] = useState<boolean>()
  const [formData, setFormData] = useState({ name })
  useEffect(() => {
    setInternalIsSubmitting(false)
  }, [])
  useEffect(() => {
    if (!isNil(validationErrors)) {
      onValidationChange(isEmpty(validationErrors))
    }
  }, [validationErrors])

  const onChangeInternal = compose(tap(setFormData), mergeRight(formData))
  const onSubmitInternal = () => {
    setInternalIsSubmitting(true)
    ifElse(
      validate,
      tap(onSubmit),
      tap(() => {
        setInternalIsSubmitting(false)
        onInvalidSubmit()
      }),
    )(formData)
  }

  return (
    <>
      <h1>{t('[name redirect] title')}</h1>
      <p className="introduction">{t('[name redirect] introduction')}</p>
      <div className={styles.form}>
        <NameForm
          formData={formData}
          onChange={onChangeInternal}
          validate={validate}
          validationErrors={validationErrors}
        />
        {/* TODO: Pull out into common component*/}
        <div className={`m-m-t-4 ${styles.navigation}`}>
          <GBG.Button
            onClick={() => {
              onBack()
            }}
            iconBefore={GBG.IconKeys.ChevronLeft24}
            kind={GBG.ButtonKind.Tertiary}
            aria-label="back-button"
          >
            {t('[journey integration] back')}
          </GBG.Button>
          <GBG.Button
            onClick={() => {
              onSubmitInternal()
            }}
            worker={true}
            workerPosition={GBG.ButtonWorkerPosition.Right}
            active={internalIsSubmitting}
            aria-label="submit-button"
          >
            {t('[journey integration create] continue')}
          </GBG.Button>
        </div>
      </div>
    </>
  )
}

export default CreateJourneyIntegrationNameForm
