import { connect } from 'react-redux'
import { compose } from 'ramda'
import { withTranslation } from 'react-i18next'

import PaymentHistory from './PaymentHistory.component'
import { paymentHistorySelector } from '../Payment/payment.selectors'

const mapStateToProps = paymentHistorySelector

const mapDispatchToProps = null

export default compose(withTranslation('Payment'), connect(mapStateToProps, mapDispatchToProps))(PaymentHistory)
