import { withTranslation } from 'react-i18next'
import { Dispatch } from 'react'
import { connect } from 'react-redux'
import { AnyAction } from '@reduxjs/toolkit'
import { compose } from 'ramda'

import WelcomeHero from './WelcomeHero.component'
import { userSelector } from '../../User'
import { navigateTo, Routes } from '../../../common/modules/Routing'

const mapStateToProps = userSelector

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  onNavigateToCreateJourney: () => dispatch(navigateTo(Routes.CREATE_JOURNEY_INTEGRATION)),
})

export default compose(withTranslation('Dashboard'), connect(mapStateToProps, mapDispatchToProps))(WelcomeHero)
